import React, { useEffect, useState, useCallback, useRef } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  ScrollView,
  TextInput,
  StyleSheet,
} from 'react-native'
import { useTheme } from '@/Hooks'
import { BorderRadius, BorderWidth, Colors, Height } from '@/Theme/Variables'
import { useSelector, useDispatch } from 'react-redux'
import { FadeInDirectionView, FadeInView, Header } from '@/Components'
import { useTranslation } from 'react-i18next'
import { spiritActions } from '@/Store/Spirit'
import { globalActions } from '@/Store/Global'
import { RootState } from '@/Store'
import noteApi from '@/Services/modules/note'
import { DownLoadSign } from '@/Components'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'

// 目前架構可以支援多筆 template ，但目前需求只需要一筆，所以透過帶入 default 參數來統一取得該筆
const DEFAULT_TEMPLATE_ID_NAME = 'default'

const DEFAULT_TEMPLATE_CONTENT =
  '香氣 (Nose)：\n\n風味 (Palate)：\n\n尾韻 (Finish)：\n\n口感 (Taste)：\n\n總結 (Comment)：'

const CommentModal = ({ route, navigation }: any) => {
  const { id, mySpiritId, page, titleUrlSlug } = route.params
  const { Layout, Gutters, Images, Fonts } = useTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const styles = getStyle()

  const commentInputRef = useRef<any>(null)

  const { data: commentTemplateData, isError: notHasTemplate } =
    noteApi.useGetCommentTemplateQuery({
      id: DEFAULT_TEMPLATE_ID_NAME,
    })

  const [
    postCommentTemplate,
    {
      isSuccess: postCommentTemplateIsSuccess,
      error: postCommentTemplateIsError,
      reset: postCommentTemplateReset,
    },
  ] = noteApi.usePostCommentTemplateMutation()

  const [
    patchCommentTemplate,
    {
      isSuccess: patchCommentTemplateIsSuccess,
      error: patchCommentTemplateIsError,
      reset: patchCommentTemplateReset,
    },
  ] = noteApi.usePatchCommentTemplateMutation()

  const { spirit: spiritData } = useSelector((state: RootState) => state.spirit)

  const [content, setContent] = useState<string>('')
  const [templateContent, setTemplateContent] = useState<string>('')
  const [step, setStep] = useState<'normal' | 'template' | 'editTemplate'>(
    'normal',
  )

  // 返回 review detail 頁面
  const handleBackToReviewDetail = useCallback(() => {
    const routeParams: any = {
      titleUrlSlug: titleUrlSlug,
      id,
      mySpiritId,
      page: page !== undefined ? page : '',
    }
    navigation.navigate(AuthPagesEnum.ReviewDetail, routeParams)
  }, [titleUrlSlug, id, mySpiritId, page, navigation])

  // 取得 content 資料
  useEffect(() => {
    if (spiritData.content) {
      setContent(spiritData.content)
    } else {
      setContent('')
    }
  }, [spiritData.content])

  // 判斷成功新增模 板
  useEffect(() => {
    if (postCommentTemplateIsSuccess) {
      dispatch(
        globalActions.openSnackbar({
          visible: true,
          message: '範本儲存成功',
          type: 'default',
          showIcon: false,
        }),
      )
      postCommentTemplateReset()
      setStep('template')
    }
  }, [dispatch, postCommentTemplateIsSuccess, postCommentTemplateReset])

  useEffect(() => {
    if (step === 'editTemplate') {
      commentInputRef.current?.focus()
    }
  }, [step])

  // 判斷成功編輯範本
  useEffect(() => {
    if (patchCommentTemplateIsSuccess) {
      dispatch(
        globalActions.openSnackbar({
          visible: true,
          message: '範本儲存成功',
          type: 'default',
          showIcon: false,
        }),
      )
      patchCommentTemplateReset()
    }
  }, [dispatch, patchCommentTemplateIsSuccess, patchCommentTemplateReset])

  // 判斷更新範本錯誤訊息
  useEffect(() => {
    const postErrorRes = postCommentTemplateIsError as any
    const patchErrorRes = patchCommentTemplateIsError as any
    const postMessage = postErrorRes?.data?.errors?.[0]?.message
    const patchMessage = patchErrorRes?.data?.errors?.[0]?.message
    const contentIsEmpty = 'The content field is required.'

    if (postMessage === contentIsEmpty || patchMessage === contentIsEmpty) {
      openWarningEmptyTemplateDialog()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchCommentTemplateIsError, postCommentTemplateIsError])

  // 提醒尚未儲存 dialog
  const openWarningDialog = () => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        title: '尚未儲存',
        titleStyle: {
          fontSize: 20,
          fontWeight: '700',
        },
        disabledBackgroundClose: false,
        content: (
          <View style={[Layout.center, Gutters.smallBMargin]}>
            <View
              style={[
                Gutters.regularBPadding,
                Gutters.regularHPadding,
                Gutters.regularBMargin,
                {
                  borderBottomWidth: BorderWidth.width2,
                  borderBottomColor: Colors.darkText,
                },
              ]}
            >
              <Text
                style={[Fonts.size14, Fonts.weight500, { color: Colors.white }]}
              >
                您尚有編輯過的文本沒有儲存，確定離開？您的文本將會消失。
              </Text>
            </View>
            <View style={[Gutters.regularHPadding, Layout.fullWidth]}>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.smallBMargin,
                  Gutters.tinyVPadding,
                  {
                    borderRadius: BorderRadius.radius8,
                    height: Height.height48,
                    backgroundColor: Colors.snackbar.error,
                  },
                ]}
                onPress={() => {
                  if (step === 'normal') {
                    handleBackToReviewDetail()
                    dispatch(globalActions.closeBottomDialog())
                    return
                  }
                  if (step === 'template') {
                    setStep('normal')
                    dispatch(globalActions.closeBottomDialog())
                    return
                  }
                  if (step === 'editTemplate') {
                    setStep('template')
                    dispatch(globalActions.closeBottomDialog())
                    return
                  }
                }}
              >
                <Text
                  style={[
                    Fonts.size14,
                    Fonts.weight500,
                    styles.confirmLeaveText,
                  ]}
                >
                  確定離開
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.smallBMargin,
                  { height: Height.height48 },
                ]}
                onPress={() => {
                  dispatch(globalActions.closeBottomDialog())
                }}
              >
                <Text style={[Fonts.bold, { color: Colors.white }]}>取消</Text>
              </TouchableOpacity>
            </View>
          </View>
        ),
      }),
    )
  }

  const openWarningEmptyTemplateDialog = () => {
    dispatch(
      globalActions.openSnackbar({
        visible: true,
        message: '範本尚無內容，請輸入範本文字',
        type: 'error',
        showIcon: false,
      }),
    )
  }

  // 儲存範本
  const handleSaveTemplate = () => {
    if (templateContent?.length === 0) {
      dispatch(
        globalActions.openSnackbar({
          visible: true,
          message: '範本尚無內容，請輸入範本文字',
          type: 'error',
          showIcon: false,
        }),
      )
      return
    }
    // 沒有範本需要新增
    if (notHasTemplate) {
      postCommentTemplate({
        name: DEFAULT_TEMPLATE_ID_NAME,
        content: templateContent,
      })
    }
    // 有範本需要更新
    if (!notHasTemplate) {
      patchCommentTemplate({
        id: DEFAULT_TEMPLATE_ID_NAME,
        name: DEFAULT_TEMPLATE_ID_NAME,
        content: templateContent,
      })
    }
  }

  // 提醒使用範本會覆蓋目前撰寫內容
  const openWarningTemplateDialog = () => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        title: '使用範本',
        titleStyle: {
          fontSize: 20,
          fontWeight: '700',
        },
        disabledBackgroundClose: false,
        content: (
          <View style={[Layout.center, Gutters.smallBMargin]}>
            <View
              style={[
                Gutters.regularBPadding,
                Gutters.regularHPadding,
                Gutters.regularBMargin,
                {
                  borderBottomWidth: BorderWidth.width2,
                  borderBottomColor: Colors.darkText,
                },
              ]}
            >
              <Text
                style={[Fonts.size14, Fonts.weight500, { color: Colors.white }]}
              >
                使用範本將會覆蓋目前的編輯內容，您確定要繼續嗎？
              </Text>
            </View>
            <View style={[Gutters.regularHPadding, Layout.fullWidth]}>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.smallBMargin,
                  Gutters.tinyVPadding,
                  {
                    borderRadius: BorderRadius.radius8,
                    height: Height.height48,
                    backgroundColor: Colors.snackbar.error,
                  },
                ]}
                onPress={() => {
                  setContent(
                    commentTemplateData?.data?.content ||
                      DEFAULT_TEMPLATE_CONTENT,
                  )
                  dispatch(
                    spiritActions.setSpiritData({
                      // 避免外面判斷為 falsy 繼續吃 api 的值
                      content:
                        commentTemplateData?.data?.content ||
                        DEFAULT_TEMPLATE_CONTENT,
                    }),
                  )
                  setStep('normal')
                  dispatch(globalActions.closeBottomDialog())
                  dispatch(
                    globalActions.openSnackbar({
                      visible: true,
                      message: '板模套用並覆蓋成功',
                      type: 'default',
                      showIcon: false,
                    }),
                  )
                }}
              >
                <Text
                  style={[
                    Fonts.size14,
                    Fonts.weight500,
                    styles.confirmLeaveText,
                  ]}
                >
                  確定套用並覆蓋
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.smallBMargin,
                  { height: Height.height48 },
                ]}
                onPress={() => {
                  dispatch(globalActions.closeBottomDialog())
                }}
              >
                <Text style={[Fonts.bold, { color: Colors.white }]}>取消</Text>
              </TouchableOpacity>
            </View>
          </View>
        ),
      }),
    )
  }

  // 一般填寫模式
  const renderNormal = () => {
    return (
      <View
        style={[Layout.fill, { backgroundColor: Colors.background.surface }]}
      >
        <ScrollView keyboardDismissMode="interactive">
          <FadeInView duration={250}>
            <Header
              title={t('commentModal.headerTitle')}
              leftIcon={
                <Image
                  style={[styles.arrowLeftIcon]}
                  source={Images.arrowLeft}
                  resizeMode="contain"
                />
              }
              leftIconPress={() => {
                handleBackToReviewDetail()
              }}
              rightEmptyIconWidth="50"
            />
          </FadeInView>
          <FadeInView duration={350} style={Layout.fullHeight}>
            <View style={[Gutters.regularHPadding, Gutters.regularVPadding]}>
              <TextInput
                ref={commentInputRef}
                placeholder={t('commentModal.flavorPlaceholder')}
                placeholderTextColor="#999999"
                multiline={true}
                numberOfLines={30}
                style={[Gutters.smallBMargin, styles.inputContainer]}
                value={content}
                onChangeText={text => {
                  setContent(text)
                  dispatch(
                    spiritActions.setSpiritData({
                      // 避免外面判斷為 falsy 繼續吃 api 的值
                      content: text === '' ? ' ' : text,
                    }),
                  )
                }}
              />
            </View>
          </FadeInView>
        </ScrollView>
        {renderTemplateButton()}
      </View>
    )
  }

  // 編輯範本模式
  const renderEditTemplate = () => {
    const disabledSave =
      templateContent?.length === 0 ||
      templateContent === commentTemplateData?.data?.content

    return (
      <View style={[Layout.fill]}>
        <View style={styles.filter}>
          <TouchableOpacity style={[Layout.fullHeight]} onPress={() => {}} />
        </View>
        <FadeInDirectionView
          direction="bottom"
          duration={500}
          style={styles.contentContainer}
        >
          <View
            style={[
              Layout.fill,
              { backgroundColor: Colors.background.surface },
            ]}
          >
            <ScrollView keyboardDismissMode="interactive">
              <FadeInView
                duration={250}
                style={{
                  borderBottomColor: Colors.background.onSurface,
                  borderBottomWidth: 1,
                }}
              >
                <Header
                  title={t('commentModal.headerTemplateTitle_edit')}
                  backgroundColor={Colors.background.surface}
                  leftIcon={
                    <Image
                      style={[styles.arrowLeftIcon]}
                      source={Images.close}
                      resizeMode="contain"
                    />
                  }
                  leftIconPress={() => {
                    const needWarningDialog = notHasTemplate
                      ? templateContent !== DEFAULT_TEMPLATE_CONTENT
                      : templateContent !== commentTemplateData?.data?.content

                    if (needWarningDialog) {
                      openWarningDialog()
                      return
                    }

                    setTemplateContent(
                      commentTemplateData?.data?.content ||
                        DEFAULT_TEMPLATE_CONTENT,
                    )
                    setStep('normal')
                  }}
                  rightIcon={
                    <TouchableOpacity
                      onPress={handleSaveTemplate}
                      disabled={disabledSave}
                    >
                      <Text
                        style={[
                          Fonts.size16,
                          Fonts.weight400,
                          {
                            color: disabledSave ? Colors.gray : Colors.primary,
                          },
                        ]}
                      >
                        儲存
                      </Text>
                    </TouchableOpacity>
                  }
                />
              </FadeInView>
              <FadeInView duration={350} style={Layout.fullHeight}>
                <View
                  style={[Gutters.regularHPadding, Gutters.regularVPadding]}
                >
                  <TextInput
                    ref={commentInputRef}
                    placeholder={t('commentModal.templatePlaceholder')}
                    placeholderTextColor="#999999"
                    multiline={true}
                    numberOfLines={30}
                    style={[Gutters.smallBMargin, styles.inputContainer]}
                    value={templateContent}
                    onChangeText={text => {
                      setTemplateContent(text)
                    }}
                  />
                </View>
              </FadeInView>
            </ScrollView>
          </View>
        </FadeInDirectionView>
      </View>
    )
  }

  const renderStep = () => {
    switch (step) {
      case 'normal':
        return renderNormal()
      case 'editTemplate':
        return renderEditTemplate()
      default:
        return renderNormal()
    }
  }

  // 建立板模按鈕區域
  const renderTemplateButton = () => {
    return (
      <View
        style={[
          Layout.fullWidth,
          Layout.row,
          Gutters.smallVPadding,
          Gutters.regularHPadding,
          styles.templateButton,
          { backgroundColor: Colors.background.default },
        ]}
      >
        <TouchableOpacity
          style={[Layout.row, Layout.alignItemsCenter, Gutters.largeRMargin]}
          onPress={() => {
            openWarningTemplateDialog()
          }}
        >
          <Image
            source={Images.comment_modal_use}
            resizeMode="contain"
            style={[Layout.iconSize24, Gutters.tinyRMargin]}
          />
          <Text
            style={[
              Fonts.size14,
              Fonts.weight400,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            範本套用
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[Layout.row, Layout.alignItemsCenter]}
          onPress={() => {
            setStep('editTemplate')
            setTemplateContent(
              commentTemplateData?.data?.content || DEFAULT_TEMPLATE_CONTENT,
            )
          }}
        >
          <Image
            source={Images.comment_modal_edit}
            resizeMode="contain"
            style={[Layout.iconSize24, Gutters.tinyRMargin]}
          />
          <Text
            style={[
              Fonts.size14,
              Fonts.weight400,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            編輯範本
          </Text>
        </TouchableOpacity>
        <DownLoadSign bottom={55} />
      </View>
    )
  }

  return <>{renderStep()}</>
}

const getStyle = () =>
  StyleSheet.create({
    inputContainer: {
      // @ts-ignore
      outlineStyle: 'none',
      color: Colors.white,
      height: '100%',
    },
    confirmLeaveText: {
      color: Colors.fontText.dark.primary2,
    },
    saveButton: {
      backgroundColor: Colors.primary,
      borderRadius: 8,
      height: 47,
    },
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    templateButton: {
      position: 'absolute',
      bottom: 0,
      left: 0,
    },
    filter: {
      width: '100%',
      height: '100%',
      backgroundColor: '#000',
      opacity: 0.8,
      position: 'absolute',
      top: 0,
    },
    contentContainer: {
      width: '100%',
      height: '100%',
      maxWidth: 428,
      marginTop: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: '#1E1E1E',
      opacity: 1,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
  })

export default CommentModal
