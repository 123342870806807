import React from 'react'
import { View, StyleSheet, Text } from 'react-native'

import Slider from '@react-native-community/slider'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'

interface RangeSelectorProps {
  min: number
  max: number
  step: number
  value: number
  onValueChange: (value: number) => void
}

export const limitNumber = {
  max: 5,
  min: 1,
}

const RangeSelector: React.FC<RangeSelectorProps> = ({
  min,
  max,
  step,
  value,
  onValueChange,
}) => {
  const { Images, Layout } = useTheme()

  const renderRatingNumberColor = (number: number) => {
    return number >= value ? Colors.primary : Colors.background.onSurface
  }

  return (
    <View style={styles.container}>
      <View style={styles.sliderContainer}>
        <Slider
          style={styles.slider}
          minimumValue={min}
          maximumValue={max}
          lowerLimit={limitNumber.min}
          step={step}
          value={value}
          onValueChange={onValueChange}
          minimumTrackTintColor={Colors.background.onSurface}
          maximumTrackTintColor={Colors.primary}
          thumbImage={Images.search_filter_range_thumb}
        />
      </View>
      <View
        style={[
          Layout.row,
          Layout.alignItemsCenter,
          Layout.justifyContentBetween,
        ]}
      >
        <Text
          style={[
            styles.ratingIndexNumber,
            { color: renderRatingNumberColor(1) },
          ]}
        >
          Any
        </Text>
        <Text
          style={[
            styles.ratingIndexNumber,
            { color: renderRatingNumberColor(2) },
          ]}
        >
          2
        </Text>
        <Text
          style={[
            styles.ratingIndexNumber,
            { color: renderRatingNumberColor(3) },
          ]}
        >
          3
        </Text>
        <Text
          style={[
            styles.ratingIndexNumber,
            { color: renderRatingNumberColor(4) },
          ]}
        >
          4
        </Text>
        <Text
          style={[
            styles.ratingIndexNumber,
            { color: renderRatingNumberColor(5) },
          ]}
        >
          5
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingVertical: 16,
    marginBottom: 20,
  },
  sliderContainer: {
    height: 40,
  },
  slider: {
    position: 'absolute',
    width: '100%',
  },
  ratingIndexNumber: {
    fontSize: 16,
    fontWeight: '400',
  },
})

export default RangeSelector
