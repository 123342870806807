import React, { useCallback } from 'react'
import { View, Image, Text, StyleSheet, Dimensions } from 'react-native'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { FadeInView, Header } from '@/Components'
import { navigate } from '@/Navigators/utils'
import spiritsApi from '@/Services/modules/spirits'
import { DownLoadSign } from '@/Components'
import { capitalizedSentence } from '@/Util/global'
import { PagesEnum } from '@/Navigators/Application'

const UploadSpiritsInfoContainer = ({ route, navigation }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const styles = getStyle()

  const { data } = spiritsApi.useGetSingleUploadSpiritsQuery({
    id: route.params.id,
  })

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigate(PagesEnum.Main, {
      screen: 'Profile',
    })
  }, [navigation])

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <Header
        title={'協助新增紀錄'}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
      />
      <FadeInView duration={500} style={Layout.fill}>
        <View
          style={[
            Gutters.regularVPadding,
            Gutters.regularHPadding,
            {
              backgroundColor: Colors.background.surface,
            },
          ]}
        >
          <View
            style={[Layout.row, Layout.alignItemsCenter, Gutters.smallBMargin]}
          >
            <View style={[Gutters.regularRMargin]}>
              <Text
                style={[
                  Fonts.size16,
                  Fonts.weight400,
                  Gutters.tinyBMargin,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                正面酒標
              </Text>
              <Image
                source={{
                  uri: data?.data?.images?.[0]?.url || '',
                }}
                resizeMode="cover"
                style={[{ width: 100, height: 100, borderRadius: 8 }]}
              />
            </View>
            {data?.data?.images?.[1]?.url && (
              <View style={[Gutters.regularRMargin]}>
                <Text
                  style={[
                    Fonts.size16,
                    Fonts.weight400,
                    Gutters.tinyBMargin,
                    { color: Colors.fontText.light.primary2 },
                  ]}
                >
                  背面酒標
                </Text>
                <Image
                  source={{
                    uri: data?.data?.images?.[1]?.url || '',
                  }}
                  resizeMode="cover"
                  style={[{ width: 100, height: 100, borderRadius: 8 }]}
                />
              </View>
            )}
          </View>
          <View
            style={[
              Layout.fullWidth,
              Gutters.smallBMargin,
              { height: 1, backgroundColor: Colors.background.onSurface },
            ]}
          />
          <View>
            <Text
              style={[
                Fonts.size16,
                Fonts.weight700,
                Gutters.smallBMargin,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              酒品詳情
            </Text>
            {data?.data?.title && (
              <View
                style={[
                  Layout.row,
                  Layout.justifyContentBetween,
                  Layout.alignItemsCenter,
                  Gutters.tinyBMargin,
                ]}
              >
                <Text
                  style={[
                    Fonts.size14,
                    Fonts.weight500,
                    { color: Colors.fontText.light.primary2 },
                  ]}
                >
                  酒名稱
                </Text>
                <Text
                  style={[
                    Fonts.size14,
                    Fonts.weight400,
                    { color: Colors.fontText.light.primary3 },
                  ]}
                >
                  {data?.data?.title}
                </Text>
              </View>
            )}

            {data?.data?.category && (
              <View
                style={[
                  Layout.row,
                  Layout.justifyContentBetween,
                  Layout.alignItemsCenter,
                  Gutters.tinyBMargin,
                ]}
              >
                <Text
                  style={[
                    Fonts.size14,
                    Fonts.weight500,
                    { color: Colors.fontText.light.primary2 },
                  ]}
                >
                  類別
                </Text>
                <Text
                  style={[
                    Fonts.size14,
                    Fonts.weight400,
                    { color: Colors.fontText.light.primary3 },
                  ]}
                >
                  {data?.data?.category}
                </Text>
              </View>
            )}

            {data?.data?.brand && (
              <View
                style={[
                  Layout.row,
                  Layout.justifyContentBetween,
                  Layout.alignItemsCenter,
                  Gutters.tinyBMargin,
                ]}
              >
                <Text
                  style={[
                    Fonts.size14,
                    Fonts.weight500,
                    { color: Colors.fontText.light.primary2 },
                  ]}
                >
                  品牌
                </Text>
                <Text
                  style={[
                    Fonts.size14,
                    Fonts.weight400,
                    { color: Colors.fontText.light.primary3 },
                  ]}
                >
                  {capitalizedSentence(data?.data?.brand)}
                </Text>
              </View>
            )}

            {/* 沒有資訊 */}
            {!data?.data?.title &&
              !data?.data?.category &&
              !data?.data?.brand && (
                <Text
                  style={[
                    Fonts.textCenter,
                    Fonts.size14,
                    Fonts.weight400,
                    { color: Colors.fontText.light.primary2 },
                  ]}
                >
                  尚無資訊
                </Text>
              )}
          </View>
        </View>
      </FadeInView>
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    CTAButton: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: 100,
      backgroundColor: Colors.background.default,
    },
  })

export default UploadSpiritsInfoContainer
