export const FlavorData = [
  {
    id: '1',
    i18n: 'flavor.winey',
    name: 'winey-extractive',
    zhName: '酒香 / 萃取物',
    enName: 'Winey / Extractive',
    color: '#E6506E',
    linearGradient: ['#E6506E', '#8C1C32'],
    icon: 'flavor_winey',
    flavor: [],
  },
  {
    id: '2',
    i18n: 'flavor.cereal',
    name: 'cereal',
    zhName: '穀物',
    enName: 'Cereal',
    color: '#EBBF00',
    linearGradient: ['#EBBF00', '#A37300'],
    icon: 'flavor_cereal',
    flavor: [],
  },
  {
    id: '3',
    i18n: 'flavor.fruity',
    name: 'fruity',
    zhName: '水果味',
    enName: 'Fruity',
    color: '#FF7913',
    linearGradient: ['#FF7913', '#99490B'],
    icon: 'flavor_fruity',
    flavor: [],
  },
  {
    id: '4',
    i18n: 'flavor.floral',
    name: 'floral',
    zhName: '花香',
    enName: 'Floral',
    color: '#1ECCBB',
    linearGradient: ['#1ECCBB', '#338078'],
    icon: 'flavor_floral',
    flavor: [],
  },
  {
    id: '5',
    i18n: 'flavor.peaty',
    name: 'peaty',
    zhName: '泥煤',
    enName: 'Peaty',
    color: '#CC9F6A',
    linearGradient: ['#CC9F6A', '#73532E'],
    icon: 'flavor_peaty',
    flavor: [],
  },
  {
    id: '6',
    i18n: 'flavor.feinty',
    name: 'feinty',
    zhName: '酒尾',
    enName: 'Feinty',
    color: '#5C84D6',
    linearGradient: ['#5C84D6', '#304570'],
    icon: 'flavor_feinty',
    flavor: [],
  },
  {
    id: '7',
    i18n: 'flavor.sulphury',
    name: 'sulphury',
    zhName: '硫化物',
    enName: 'Sulphury',
    color: '#B3B3B3',
    linearGradient: ['#B3B3B3', '#40403F'],
    icon: 'flavor_sulphury',
    flavor: [],
  },
  {
    id: '8',
    i18n: 'flavor.woody',
    name: 'woody',
    zhName: '木質味',
    enName: 'Woody',
    color: '#AA4700',
    linearGradient: ['#AA4700', '#472923'],
    icon: 'flavor_woody',
    flavor: [],
  },
  {
    id: '9',
    i18n: 'flavor.others',
    name: 'others',
    zhName: '其他',
    enName: 'Others',
    color: '#494949',
    linearGradient: ['#000000', '#494949'],
    icon: '',
    flavor: [],
  },
]

// 各個風味的 map
const WINEY_MAP = new Map()
const CEREAL_MAP = new Map()
const FRUITY_MAP = new Map()
const FLORAL_MAP = new Map()
const PEATY_MAP = new Map()
const FEINTY_MAP = new Map()
const SULPHURY_MAP = new Map()
const WOODY_MAP = new Map()

export const FLAVORS_MAP = [
  WINEY_MAP,
  CEREAL_MAP,
  FRUITY_MAP,
  FLORAL_MAP,
  PEATY_MAP,
  FEINTY_MAP,
  SULPHURY_MAP,
  WOODY_MAP,
]

export const getFlavorColor = (flavor: string) => {
  return FlavorData.find(item => item.name === flavor)?.color
}
