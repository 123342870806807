import React, { useCallback, useEffect, useState } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  FlatList,
  ScrollView,
  Dimensions,
} from 'react-native'
import { useTheme, useGlass } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { FadeInView, Header, ImageLightBox } from '@/Components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { navigateAndSimpleReset } from '@/Navigators/utils'
import noteApi from '@/Services/modules/note'
import { DropdownsType } from '@/Services/modules/note/note.type'
import { useDispatch } from 'react-redux'
import { spiritActions } from '@/Store/Spirit'
import { capitalizedSentence } from '@/Util/global'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'

const GlassContainer = ({ navigation }: any) => {
  const { Layout, Gutters, Images, Fonts } = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const renderGlasses = useGlass()

  const { language } = useSelector((state: RootState) => state.theme)

  // 取得下拉選單（配餐）
  const { data: glassData, isSuccess: glassDataIsSuccess } =
    noteApi.useGetNoteDropdownsOptionsQuery({
      type: DropdownsType.GLASS,
      locale: language,
    })

  const [glassDataList, setGlassDataList] = useState<any>([])
  const [collectedGlassData, setCollectedGlassData] = useState<string[]>([])
  const [openImageLightBox, setOpenImageLightBox] = useState({
    visible: false,
    imagePath: '',
  })

  const styles = getStyle()

  // convert 配餐列表成陣列
  const convertGlassDataToArray = useCallback(() => {
    if (!glassData) {
      return
    }
    if (Object?.keys(glassData?.data)?.length) {
      const result = Object?.keys(glassData?.data).map(key => {
        return {
          key,
          value: glassData?.data[key],
        }
      })
      return result
    }
  }, [glassData])

  useEffect(() => {
    if (glassDataIsSuccess) {
      const data = convertGlassDataToArray()
      setGlassDataList(data)
    }
  }, [convertGlassDataToArray, glassData, glassDataIsSuccess])

  // 將存在 local storage 的收藏酒杯取出
  useEffect(() => {
    const getCollectedGlass = async () => {
      const glassListJSON = await AsyncStorage.getItem('glass')
      if (glassListJSON?.length) {
        const glassList = JSON.parse(glassListJSON).split(',')
        setCollectedGlassData(glassList)
      }
    }
    getCollectedGlass()
  }, [])

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }
    navigateAndSimpleReset(PagesEnum.Main)
  }, [navigation])

  const handleSelectGlassItem = (glass: string) => {
    dispatch(
      spiritActions.setSpiritData({
        glass,
      }),
    )
    handleGoBack()
  }

  const handleClickCollect = async (glass: string) => {
    const glassList = await AsyncStorage.getItem('glass')
    if (glassList) {
      await AsyncStorage.setItem(
        'glass',
        JSON.stringify(`${JSON.parse(glassList)},${glass}`),
      )
      setCollectedGlassData([...JSON.parse(glassList).split(','), glass])
      return
    }
    await AsyncStorage.setItem('glass', JSON.stringify(`${glass}`))
    setCollectedGlassData([glass])
  }

  const handleRemoveCollectedGlass = async (glass: string) => {
    // 移除列表的
    const newCollectedGlassData = collectedGlassData.filter(
      item => item !== glass,
    )
    setCollectedGlassData(newCollectedGlassData)
    // 移除 local 的
    const glassList = await AsyncStorage.getItem('glass')
    if (glassList) {
      const newList = [...JSON.parse(glassList)?.split(',')]?.filter(
        (item: string) => item !== glass,
      )
      const storeGlassData = JSON.stringify(newList.toString())
      const EMPTY_LENGTH = 2
      // 清空
      if (storeGlassData?.length === EMPTY_LENGTH) {
        await AsyncStorage.removeItem('glass')
        return
      }
      await AsyncStorage.setItem('glass', JSON.stringify(newList.toString()))
    }
  }

  // 打開圖片燈箱
  const openImageLightBoxModal = (imagePath: string) => {
    setOpenImageLightBox({ visible: true, imagePath })
  }

  // 渲染已收藏的酒杯列表
  const renderCollectedGlassItem = ({ item }: any) => {
    return (
      <TouchableOpacity
        style={[styles.glassItem]}
        onPress={() => handleSelectGlassItem(item)}
      >
        <TouchableOpacity
          // @ts-ignore
          onPress={() => openImageLightBoxModal(renderGlasses[item])}
        >
          <Image
            // @ts-ignore
            source={renderGlasses[item]}
            resizeMode="contain"
            style={[styles.glassImage, Gutters.smallRMargin]}
          />
        </TouchableOpacity>
        <Text
          style={[
            Fonts.weight500,
            Fonts.size14,
            { color: Colors.fontText.light.primary2 },
          ]}
        >
          {capitalizedSentence(t(`glass:${item}`))}
        </Text>
        <TouchableOpacity
          style={{ marginLeft: 'auto' }}
          onPress={() => handleRemoveCollectedGlass(item)}
        >
          <Image
            source={Images.collected}
            resizeMode="contain"
            style={[Layout.iconSize24]}
          />
        </TouchableOpacity>
      </TouchableOpacity>
    )
  }

  // 渲染酒杯列表
  const renderGlassItem = ({ item }: any) => {
    const isSelected = collectedGlassData?.includes(item?.key)
    return (
      <TouchableOpacity
        style={[styles.glassItem, { display: isSelected ? 'none' : 'flex' }]}
        onPress={() => handleSelectGlassItem(item?.key)}
      >
        <TouchableOpacity
          // @ts-ignore
          onPress={() => openImageLightBoxModal(renderGlasses[item?.key])}
        >
          <Image
            // @ts-ignore
            source={renderGlasses[item?.key]}
            resizeMode="contain"
            style={[styles.glassImage, Gutters.smallRMargin]}
          />
        </TouchableOpacity>
        <Text
          style={[
            Fonts.weight500,
            Fonts.size14,
            { color: Colors.fontText.light.primary2 },
          ]}
        >
          {capitalizedSentence(t(`glass:${item?.key}`))}
        </Text>
        <TouchableOpacity
          style={{ width: 'fit-content', marginLeft: 'auto' }}
          onPress={() => handleClickCollect(item?.key)}
        >
          <Image
            source={Images.collect}
            resizeMode="contain"
            style={[Layout.iconSize24]}
          />
        </TouchableOpacity>
      </TouchableOpacity>
    )
  }

  return (
    <View style={[Layout.fill, { backgroundColor: Colors.background.default }]}>
      <ScrollView
        style={(Layout.fill, { height: Dimensions.get('window').height })}
      >
        <FadeInView duration={500} style={Layout.fill}>
          <Header
            title="品飲容器"
            leftIcon={
              <Image
                style={[styles.arrowLeftIcon]}
                source={Images.arrowLeft}
                resizeMode="contain"
              />
            }
            leftIconPress={() => {
              handleGoBack()
            }}
            rightEmptyIconWidth="50"
          />
          <View
            style={[
              Gutters.tinyBMargin,
              { backgroundColor: Colors.background.surface },
            ]}
          >
            {/* 常用酒杯 */}
            <View style={[Gutters.regularHPadding, Gutters.smallVPadding]}>
              <Text
                style={[
                  Fonts.weight700,
                  Fonts.size16,
                  { color: Colors.secondaryText },
                ]}
              >
                常用酒杯
              </Text>
            </View>
            {/* 常用酒杯列表 */}
            <View
              style={[
                Layout.row,
                Gutters.smallBPadding,
                Gutters.regularHPadding,
                styles.glassSelectedItemContainer,
              ]}
            >
              <FlatList
                data={collectedGlassData || []}
                renderItem={renderCollectedGlassItem}
              />
            </View>
          </View>
          <View style={{ backgroundColor: Colors.background.surface }}>
            <View style={[Gutters.regularHPadding, Gutters.regularVPadding]}>
              <Text
                style={[
                  Fonts.weight700,
                  Fonts.size16,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                酒杯列表
              </Text>
            </View>
            <View style={[Gutters.regularHPadding, styles.marginB60]}>
              <FlatList
                data={glassDataList || []}
                renderItem={renderGlassItem}
              />
            </View>
          </View>
        </FadeInView>
      </ScrollView>
      <ImageLightBox
        visible={openImageLightBox.visible}
        imagePath={openImageLightBox.imagePath}
        imageStyle={{ width: 300, height: 300, borderRadius: 8 }}
        containerStyle={{
          backgroundColor: 'transparent',
          borderRadius: 8,
        }}
        hideModal={() => {
          setOpenImageLightBox({ visible: false, imagePath: '' })
        }}
      />
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    marginB60: { marginBottom: 60 },
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    glassSelectedItemContainer: {
      flexWrap: 'wrap',
    },
    glassItem: {
      height: 80,
      flexDirection: 'row',
      alignItems: 'center',
      borderBottomColor: Colors.background.onSurface,
      borderBottomWidth: 1,
    },
    glassImage: {
      width: 64,
      height: 64,
      borderRadius: 8,
    },
  })

export default GlassContainer
