import React, { useCallback } from 'react'
import { View, TouchableOpacity, Image, StyleSheet, Text } from 'react-native'
import StarRating from 'react-native-star-rating-widget'

import { capitalizedSentence } from '@/Util/global'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { StarIcon14 } from '@/Components/SpiritCard'

interface SliderCardProps {
  image: string
  brand: string
  title: string
  rating: number
  ratingCount: number
  bestMatch: boolean
  isActive: boolean
  onPress: () => void
}

const SliderCard = ({
  image,
  brand,
  title,
  rating,
  ratingCount,
  bestMatch,
  isActive,
  onPress,
}: SliderCardProps) => {
  const { Layout, Gutters, Images, Fonts } = useTheme()
  const styles = getStyles()

  // 如果是整數，如 5 分則顯示 5.0 分，非整數則不調整
  const renderConvertRating = useCallback((rating: number) => {
    return rating.toString().includes('.') ? rating : rating.toFixed(1)
  }, [])

  return (
    <TouchableOpacity
      style={[
        styles.container,
        {
          transform: [{ scale: isActive ? 1 : 0.95 }],
        },
      ]}
      onPress={onPress}
    >
      {bestMatch && (
        <View style={styles.bestMatchContainer}>
          <Image
            source={Images.scan_result_best_match}
            resizeMode="cover"
            style={[Layout.iconSize24, Gutters.tinyRMargin]}
          />
          <Text
            style={[
              Fonts.weight700,
              Fonts.size14,
              { color: Colors.fontText.dark.primary2 },
            ]}
          >
            最相似結果
          </Text>
        </View>
      )}
      <View style={styles.imageContainer}>
        <Image
          source={{ uri: image }}
          resizeMode="cover"
          style={styles.image}
        />
      </View>
      <View style={styles.textContainer}>
        <View style={styles.titleBrandContainer}>
          <Text
            style={[
              Fonts.weight400,
              Fonts.size16,
              { color: Colors.fontText.light.primary3 },
            ]}
          >
            {capitalizedSentence(brand)}
          </Text>
          <Text
            style={[
              Fonts.weight500,
              Fonts.size20,
              Fonts.textLeft,
              { color: Colors.fontText.light.primary2 },
            ]}
            numberOfLines={2}
          >
            {capitalizedSentence(title)}
          </Text>
        </View>
        <View style={styles.ratingContainer}>
          <Text
            style={[
              Fonts.weight400,
              Fonts.size16,
              Gutters.tinyRMargin,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            {rating > 0 ? renderConvertRating(rating) : '暫無評分'}
          </Text>
          <StarRating
            rating={rating || 0}
            starSize={16}
            color="#EECD96"
            onChange={e => {
              console.log(e)
            }}
            StarIconComponent={StarIcon14}
            starStyle={styles.starStyle}
            style={[]}
            animationConfig={{
              scale: 1,
            }}
          />
        </View>
        <Text
          style={[
            Fonts.weight400,
            Fonts.size12,
            { color: Colors.fontText.light.primary3 },
          ]}
        >{`(${ratingCount} 個評分)`}</Text>
      </View>
    </TouchableOpacity>
  )
}

const getStyles = () =>
  StyleSheet.create({
    container: {
      width: 300,
      height: '100%',
      maxHeight: 460,
      backgroundColor: Colors.background.surface,
      borderRadius: 10,
    },
    bestMatchContainer: {
      width: 122,
      height: 36,
      position: 'absolute',
      zIndex: 100,
      top: 20,
      left: -5,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 8,
      backgroundColor: Colors.primary,
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
    },
    titleBrandContainer: {
      alignItems: 'flex-start',
      height: 85,
    },
    imageContainer: {
      width: 300,
      height: 300,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    image: {
      width: '100%',
      height: '100%',
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    textContainer: {
      padding: 16,
      alignItems: 'flex-start',
    },
    ratingContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 4,
      marginTop: 'auto',
    },
    starStyle: {
      marginLeft: 0,
      marginRight: 2,
    },
  })

export default SliderCard
