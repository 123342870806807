import React from "react"
import { Image, Text, TouchableOpacity, View } from "react-native"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import { globalActions } from "@/Store/Global"
import useTheme from '@/Hooks/useTheme'
import { Colors, Height } from "@/Theme/Variables"
import { navigate } from "@/Navigators/utils"
import { PagesEnum } from "@/Navigators/Application"

const useLoginDialog = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { Images, Layout, Fonts, Gutters } = useTheme()

  const open = () => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        title: t('camera.bottomDialogTitle'),
        disabledBackgroundClose: true,
        content: (
          <View
            style={[
              Layout.center,
              Gutters.regularVMargin,
              Gutters.regularHPadding,
            ]}
          >
            <Image
              source={Images.bottom_dialog_login_leave}
              resizeMode="contain"
              style={[Gutters.regularBMargin, { width: 64, height: 64 }]}
            />
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.center,
                Gutters.smallBMargin,
                {
                  backgroundColor: Colors.primary,
                  paddingVertical: 9,
                  borderRadius: 8,
                  height: Height.height48,
                },
              ]}
              onPress={() => {
                dispatch(globalActions.closeBottomDialog())
                // @ts-ignore
                navigate(PagesEnum.LoginMainContainer, undefined)
              }}
            >
              <Text
                style={[
                  Fonts.size14,
                  Fonts.weight500,
                  { color: Colors.primaryText },
                ]}
              >
                {t('camera.bottomDialogButton1')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.center,
                Gutters.smallBMargin,
                {
                  height: Height.height48,
                },
              ]}
              onPress={() => {
                dispatch(globalActions.closeBottomDialog())
              }}
            >
              <Text style={{ color: Colors.white }}>
                {t('camera.bottomDialogButton2')}
              </Text>
            </TouchableOpacity>
          </View>
        ),
      }),
    )
  }

  return open
}

export default useLoginDialog