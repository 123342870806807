import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  FlatList,
  ActivityIndicator,
  StyleSheet,
  Dimensions,
} from 'react-native'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { useIsFocused } from '@react-navigation/native'
import { FadeInView, Header, SpiritCard } from '@/Components'
import { spiritsApi } from '@/Services/modules/spirits'
import { useTranslation } from 'react-i18next'
import userApi from '@/Services/modules/users'
import { RootState, getGlobal } from '@/Store'
import { useDispatch, useSelector } from 'react-redux'
import { spiritActions } from '@/Store/Spirit'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { SpiritCardHeight } from '@/Components/SpiritCard'
import { navigate } from '@/Navigators/utils'
import { DownLoadSign } from '@/Components'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { PagesEnum } from '@/Navigators/Application'
import { MainPagesEnum } from '@/Navigators/Main'

const WishlistContainer = ({ _route, navigation }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isFocused = useIsFocused()
  const styles = getStyle()

  const { language } = useSelector((state: RootState) => state.theme)
  const { wishlist: wishListData } = useSelector(
    (state: RootState) => state.spirit,
  )
  const globalState = useSelector(getGlobal)
  const { productImageLightBox } = globalState

  const { data: getMeData } = userApi.useGetMeQuery()

  const {
    isLoading: wishlistInitIsLoading,
    isFetching: wishlistInitIsFetching,
  } = spiritsApi.useGetWishlistQuery(
    {
      id: 'default',
      page: 1,
    },
    { refetchOnMountOrArgChange: true },
    // 等 wishListData 清空後才打 api 避免重複或是改變新 wishlist 順序
    { skip: !getMeData && wishListData?.data?.length },
  )

  const [getWishlistRequest, { isFetching: getWishlistIsLazyFetching }] =
    spiritsApi.useLazyGetWishlistQuery()

  const [page, setPage] = useState<number>(1)

  // 進來時先清空
  useLayoutEffect(() => {
    dispatch(spiritActions.clearWishlist())
  }, [dispatch])

  // 移除沒有收藏的項目
  useEffect(() => {
    if (isFocused) {
      dispatch(spiritActions.removeWishlistUnCollected())
    }
  }, [dispatch, isFocused])

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigate(PagesEnum.Main, {
      screen: MainPagesEnum.Profile,
    })
  }, [navigation])

  const handleFetchMoreData = () => {
    const hasNextPage =
      wishListData.meta.currentPage !== wishListData.meta.totalPages &&
      wishListData.meta.totalPages !== page
    if (hasNextPage) {
      const nextPage = page + 1
      getWishlistRequest({
        id: 'default',
        page: nextPage,
      })
      setPage(nextPage)
    }
  }

  const handleClickCard = async (id: string, titleUrlSlug: string) => {
    navigation.navigate(PagesEnum.ProductDetail, {
      lang: language,
      titleUrlSlug: titleUrlSlug,
      id,
    })
    await AsyncStorage.setItem('previousScreen', AuthPagesEnum.Wishlist)
  }

  const renderListLoading = () => {
    return (
      <View>
        {getWishlistIsLazyFetching && (
          <ActivityIndicator size="large" color={Colors.primary} />
        )}
      </View>
    )
  }

  const renderCardListItem = ({ item }: any) => {
    const {
      title,
      imageUrl,
      ratings,
      brand,
      id,
      titleUrlSlug,
      collected,
      marketPrice,
      flavors,
    } = item
    return (
      <SpiritCard
        id={id}
        title={title}
        onClick={() => handleClickCard(id, titleUrlSlug)}
        imageUrl={imageUrl}
        rating={ratings?.average}
        ratingCount={ratings?.count}
        brand={brand}
        collect={collected}
        showWishlist
        marketPrice={marketPrice}
        showPrice={true}
        flavors={flavors?.topN}
        showFlavors={true}
      />
    )
  }

  const renderEmpty = () => {
    if (!wishlistInitIsFetching) {
      return (
        <View
          style={[Layout.colCenter, Layout.fullHeight, Gutters.regularHPadding]}
        >
          <Image
            source={Images.wishlist_empty}
            resizeMode="contain"
            style={[Gutters.regularBMargin, Layout.iconSize80]}
          />
          <Text
            style={[
              Fonts.weight700,
              Fonts.size16,
              Gutters.tinyBMargin,
              { color: Colors.white },
            ]}
          >
            尚未加入任何酒
          </Text>
          <Text
            style={[
              Fonts.weight400,
              Fonts.size14,
              Gutters.regularBMargin,
              { color: Colors.secondaryText },
            ]}
          >
            立即搜尋並加入喜愛的烈酒
          </Text>
          <View style={[Layout.fullWidth]}>
            <TouchableOpacity
              style={[Layout.rowCenter, styles.actionTakePicBtn]}
              onPress={() =>
                navigation.navigate(PagesEnum.Main, {
                  screen: MainPagesEnum.Camera,
                })
              }
            >
              <Image
                source={Images.camera}
                resizeMode="contain"
                style={[Layout.iconSize24, Gutters.tinyRMargin]}
              />
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size16,
                  { color: Colors.primaryText },
                ]}
              >
                拍照辨識
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[Layout.rowCenter, styles.actionSearchBtn]}
              onPress={() => navigation.navigate(PagesEnum.SearchListContainer)}
            >
              <Image
                source={Images.search}
                resizeMode="contain"
                style={[Layout.iconSize24, Gutters.tinyRMargin]}
              />
              <Text
                style={[Fonts.weight500, Fonts.size16, { color: Colors.white }]}
              >
                文字搜尋
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      )
    }
  }

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <Header
        title={t('wishlist.title')}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
      />
      <FadeInView duration={500} style={Layout.fill}>
        <View style={[Layout.fill]}>
          {wishlistInitIsLoading ? (
            <View style={[Layout.fill, Layout.center]}>
              <ActivityIndicator size="large" color={Colors.primary} />
            </View>
          ) : (
            <View style={[Layout.fill]}>
              <FlatList
                contentContainerStyle={[Layout.fill]}
                data={wishListData.data || []}
                keyExtractor={item => item.id.toString()}
                renderItem={renderCardListItem}
                refreshing
                onEndReached={handleFetchMoreData}
                scrollEnabled={!productImageLightBox?.visible}
                onEndReachedThreshold={3}
                getItemLayout={(data, index) => {
                  // SpiritCard_HEIGHT 是每個 SpiritCard 的高度，必免返回此頁面時，位置跑掉
                  return {
                    length: SpiritCardHeight,
                    offset: SpiritCardHeight * index,
                    index,
                  }
                }}
                ListFooterComponent={renderListLoading}
                ListEmptyComponent={renderEmpty}
              />
            </View>
          )}
        </View>
      </FadeInView>
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    actionTakePicBtn: {
      width: '100%',
      height: 48,
      borderRadius: 8,
      marginBottom: 16,
      backgroundColor: Colors.primary,
    },
    actionSearchBtn: {
      width: '100%',
      height: 48,
      borderRadius: 8,
      borderColor: Colors.primary,
      borderWidth: 1,
    },
  })

export default WishlistContainer
