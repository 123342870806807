import React, { useCallback, useEffect, useState } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
  ScrollView,
  Platform,
  ActivityIndicator,
} from 'react-native'
import { useTheme } from '@/Hooks'
import { TextInput } from 'react-native-paper'
import { FadeInView, Header, WarningSignComponent } from '@/Components'
import { Colors } from '@/Theme/Variables'
import { userApi } from '@/Services/modules/users'
import { useDispatch } from 'react-redux'
import { navigate } from '@/Navigators/utils'
import { globalActions } from '@/Store/Global'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { SignUpBasketRequestFields } from '@/Services/modules/users/user.type'
import { openApplyInviteCodeWeb, removeEmptyKeys } from '@/Util/global'
import { useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { api } from '@/Services/api'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'
// import { useTranslation } from 'react-i18next'

const SocialBasicInfoContainer = ({ navigation, route }: any) => {
  const dispatch = useDispatch()
  const { Images, Layout, Gutters, Fonts } = useTheme()
  // TODO: 等設計稿確定再改 i18n
  //   const { t } = useTranslation()
  const { register } = useSelector((state: RootState) => state.user)

  const [
    signUpBasket,
    {
      data: signUpBasketData,
      error: signUpBasketError,
      isError: signUpBasketIsError,
      isSuccess: signUpBasketIsSuccess,
      isLoading: signUpBasketIsLoading,
      reset: signUpBasketReset,
    },
  ] = userApi.useSignUpBasketMutation()

  const [
    signUpRequest,
    {
      isSuccess: signUpIsSuccess,
      isLoading: signUpIsLoading,
      reset: signUpReset,
    },
  ] = userApi.useSignUpMutation()

  const { data: siteMetaData } = api.useGetSiteMetaQuery()

  // NOTE:邀請碼選必填機制
  const isMGMCodeRequired = siteMetaData?.features?.invitation || false

  const isLoading = signUpBasketIsLoading || signUpIsLoading

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false)
  const [step, setStep] = useState<'basicInfo' | 'MGMCode'>('basicInfo')
  const [dateData, setDateData] = useState<string>('')
  const [nickname, setNickname] = useState<string>('')
  const [MGMCode, setMGMCode] = useState<string>('')

  const styles = stylesSheet()

  // 如果沒有帳密資訊就跳回註冊頁面
  useEffect(() => {
    if (!register?.account?.length) {
      navigation.navigate(PagesEnum.EmailRegisterContainer)
    }
  }, [register?.account?.length, register?.password?.length, navigation])

  // 判斷錯誤訊息
  useEffect(() => {
    if (signUpBasketIsError) {
      const errorData = signUpBasketError as any
      const message = errorData?.data?.errors?.[0]?.message
      // 註冊相關資料保存時間已過期，請使用者重新註冊
      if (message === 'OldBasketDataExpired') {
        dispatch(
          globalActions.openSnackbar({
            visible: true,
            message: '註冊相關資料保存時間已過期，重新註冊',
            type: 'error',
          }),
        )
        navigate(PagesEnum.Main, {
          screen: PagesEnum.EmailRegisterContainer,
        })
        return
      }

      if (message === 'NotLegalDrinkingAge') {
        setErrorMessage('抱歉，使用本服務必須年滿 18 歲以上')
        return
      }

      if (message === 'InviteCodeMaxUsagesExceed') {
        setStep('MGMCode')
        return
      }

      if (message === 'InvalidInviteCode') {
        setErrorMessage('邀請碼無效')
      }

      if (message === 'InviteCodeMaxUsagesExceed') {
        setErrorMessage('邀請碼兌換已滿額')
      }
    }
  }, [dispatch, signUpBasketError, signUpBasketIsError])

  // 新增個人資訊成功後，註冊帳號
  useEffect(() => {
    if (signUpBasketIsSuccess) {
      signUpRequest({
        basket: signUpBasketData?.data?.basket || '',
      })
    }
  }, [signUpBasketIsSuccess, signUpRequest, signUpBasketData])

  // 判斷成功訊息
  useEffect(() => {
    if (signUpIsSuccess) {
      dispatch(
        globalActions.openSnackbar({
          visible: true,
          message: '註冊成功！',
          type: 'success',
          showIcon: false,
        }),
      )

      if (window?.gtag) {
        // 註冊成功 GA
        window?.gtag('event', 'sign_up', {
          method: register?.socialProvider || 'Google',
        })
      }

      navigation.navigate(PagesEnum.Main, { screen: 'Profile' })
      signUpBasketReset()
      signUpReset()
    }
  }, [
    dispatch,
    navigation,
    register?.socialProvider,
    signUpBasketReset,
    signUpIsSuccess,
    signUpReset,
  ])

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigate(PagesEnum.Main, {
      screen: PagesEnum.EmailLoginContainer,
    })
  }, [navigation])

  const handleSubmit = () => {
    if (isLoading) {
      return
    }

    const payload: Partial<SignUpBasketRequestFields> = {
      birthday:
        dayjs(dateData).format('YYYY-MM-DD') === 'Invalid Date'
          ? ''
          : dayjs(dateData).format('YYYY-MM-DD'),
      name: nickname,
      inviteCode: MGMCode,
    }

    signUpBasket(
      removeEmptyKeys({
        oldBasket: route?.params?.basket,
        fields: {
          ...payload,
        },
      }),
    )
  }

  const renderBasicInfoContainer = () => {
    return (
      <FadeInView duration={550} style={Gutters.regularHPadding}>
        <View style={[Gutters.regularBMargin, Gutters.largeBMargin]}>
          <Text
            style={[
              Fonts.size20,
              Fonts.weight700,
              Gutters.smallTMargin,
              Gutters.tinyBMargin,
              { color: Colors.fontText.light.primary1 },
            ]}
          >
            快完成了！還需要一些基本資訊
          </Text>
          <Text
            style={[
              Fonts.size16,
              Fonts.weight400,
              Gutters.tinyBMargin,
              { color: Colors.fontText.light.primary1 },
            ]}
          >
            出生日期不會公開顯示，本服務含酒資訊，請確認您已年滿 18 歲
          </Text>
          {Platform.OS === 'web' && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                open={openDatePicker}
                disableFuture
                onOpen={() => setOpenDatePicker(true)}
                onClose={() => setOpenDatePicker(false)}
                onChange={(value: any) =>
                  setDateData(dayjs(value).format('YYYY/MM/DD'))
                }
                sx={{
                  '& .MuiInputBase-root': {
                    height: 0,
                    opacity: 0,
                    '& .Mui-selected': {
                      backgroundColor: `${Colors.primary} !important`,
                    },
                  },
                }}
              />
            </LocalizationProvider>
          )}
          <TouchableOpacity onPress={() => setOpenDatePicker(true)}>
            <TextInput
              label="請輸入出生日期"
              value={dateData}
              autoFocus={true}
              style={[
                !errorMessage && Gutters.tinyBMargin,
                { color: Colors.white },
                styles.textInput,
              ]}
              underlineColor="transparent"
              activeUnderlineColor="transparent"
              activeOutlineColor={Colors.primary}
              theme={{
                colors: {
                  onSurfaceVariant: Colors.fontText.light.primary3,
                  error: Colors.snackbar.error,
                  outline: Colors.background.top,
                },
                roundness: 10,
              }}
              textColor="#fff"
              mode="outlined"
              error={!!errorMessage}
            />
          </TouchableOpacity>
          {!!errorMessage && (
            <Text
              style={[
                Gutters.tinyVMargin,
                {
                  color: Colors.snackbar.error,
                },
              ]}
            >
              {errorMessage}
            </Text>
          )}
          <TextInput
            label="請輸入暱稱"
            autoFocus={true}
            style={[{ color: Colors.white }, styles.textInput]}
            underlineColor="transparent"
            activeUnderlineColor="transparent"
            activeOutlineColor={Colors.primary}
            theme={{
              colors: {
                onSurfaceVariant: Colors.fontText.light.primary3,
                error: Colors.snackbar.error,
                outline: Colors.background.top,
              },
              roundness: 10,
            }}
            textColor="#fff"
            mode="outlined"
            onChangeText={(text: string) => {
              setNickname(text)
            }}
          />
        </View>
      </FadeInView>
    )
  }

  const renderMGMCodeContainer = () => {
    return (
      <FadeInView duration={550} style={Gutters.regularHPadding}>
        <View style={[Gutters.regularBMargin, Gutters.largeBMargin]}>
          <View style={[Gutters.largeBMargin, Layout.colCenter]}>
            <Image
              source={Images.email_basic_info}
              resizeMode="cover"
              style={[
                Gutters.tinyBMargin,
                {
                  width: 90,
                  height: 130,
                },
              ]}
            />
            <Text
              style={[
                Fonts.weight700,
                Fonts.size16,
                {
                  color: Colors.fontText.light.primary2,
                },
              ]}
            >
              邀請碼兌換已滿額
            </Text>
            <Text
              style={[
                Fonts.weight400,
                Fonts.size14,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              很抱歉，該邀請碼已被兌換完畢
            </Text>
          </View>
          <Text
            style={[
              Fonts.size16,
              Fonts.weight400,
              Gutters.tinyBMargin,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            請重新輸入邀請碼
          </Text>
          <TextInput
            label="請輸入邀請碼"
            value={MGMCode}
            autoFocus={true}
            style={[
              !errorMessage && Gutters.tinyBMargin,
              { color: Colors.white },
              styles.textInput,
            ]}
            underlineColor="transparent"
            activeUnderlineColor="transparent"
            activeOutlineColor={Colors.primary}
            theme={{
              colors: {
                onSurfaceVariant: Colors.fontText.light.primary3,
                error: Colors.snackbar.error,
                outline: Colors.background.top,
              },
              roundness: 10,
            }}
            textColor="#fff"
            mode="outlined"
            onChangeText={value => {
              setMGMCode(value)
              if (errorMessage) {
                setErrorMessage('')
              }
            }}
            error={!!errorMessage}
          />
          {!!errorMessage && (
            <Text
              style={[
                Gutters.tinyVMargin,
                {
                  color: Colors.snackbar.error,
                },
              ]}
            >
              {errorMessage}
            </Text>
          )}
        </View>
        <View>
          <WarningSignComponent bottomSpace />
        </View>
      </FadeInView>
    )
  }

  const checkSubmitBTNDisabled = () => {
    if (step === 'basicInfo') {
      return {
        color:
          !nickname?.length || !dateData?.length ? '#D9D9D9' : Colors.primary,
        disabled: !nickname?.length || !dateData?.length,
      }
    }
    if (step === 'MGMCode') {
      return {
        color: !MGMCode?.length ? '#D9D9D9' : Colors.primary,
        disabled: !MGMCode?.length,
      }
    }
  }

  return (
    <View style={[Layout.fill, { backgroundColor: Colors.background.surface }]}>
      <ScrollView style={[Layout.fill]}>
        <Header
          title={'註冊（4/4）'}
          leftIcon={
            <Image
              style={[styles.arrowLeftIcon]}
              source={Images.arrowLeft}
              resizeMode="contain"
            />
          }
          leftIconPress={handleGoBack}
          rightEmptyIconWidth="50"
        />
        <View style={[Gutters.smallTPadding]}>
          {step === 'basicInfo'
            ? renderBasicInfoContainer()
            : renderMGMCodeContainer()}
        </View>
      </ScrollView>
      <View style={[Gutters.regularHPadding, styles.ctaButtonContainer]}>
        <TouchableOpacity
          style={[
            Layout.rowCenter,
            Gutters.largeBMargin,
            styles.ctaButton,
            { backgroundColor: checkSubmitBTNDisabled()?.color },
          ]}
          disabled={isLoading || checkSubmitBTNDisabled()?.disabled}
          onPress={handleSubmit}
        >
          {isLoading ? (
            <ActivityIndicator size="large" color={Colors.white} />
          ) : (
            <Text
              style={[
                Fonts.size16,
                Fonts.weight500,
                { color: Colors.fontText.dark.primary2 },
              ]}
            >
              建立帳號
            </Text>
          )}
        </TouchableOpacity>
        {isMGMCodeRequired && (
          <TouchableOpacity
            style={[Layout.rowCenter]}
            onPress={() => openApplyInviteCodeWeb()}
          >
            <Text
              style={[
                Fonts.size16,
                Fonts.weight500,
                {
                  color: Colors.fontText.light.primary2,
                  textDecorationLine: 'underline',
                  // @ts-ignore
                  textUnderlineOffset: 4,
                },
              ]}
            >
              我沒有邀請碼
            </Text>
          </TouchableOpacity>
        )}
      </View>
      <DownLoadSign />
    </View>
  )
}

const stylesSheet = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    textInput: {
      backgroundColor: '#1C1C20',
      height: 56,
    },
    ctaButtonContainer: {
      width: '100%',
      position: 'absolute',
      bottom: 50,
      left: 0,
    },
    ctaButton: {
      height: 48,
      borderRadius: 8,
      backgroundColor: Colors.primary,
      width: '100%',
    },
  })

export default SocialBasicInfoContainer
