import {
  GetSearchMetaResponse,
  metaDataItem,
} from '@/Services/modules/spirits/spirits.type'

export const convertMetaData = (data: GetSearchMetaResponse, type: string) => {
  if (!data) {
    return []
  }
  const result: metaDataItem[] = []
  const aggregations = data.data.aggregations as unknown as Record<
    string,
    metaDataItem[]
  >
  if (type in aggregations && Array.isArray(aggregations[type])) {
    result.push(...aggregations[type])
  }
  return result
}
