import React from 'react'
import { Text, TouchableOpacity } from 'react-native'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'

type Props = {
  title: string
  selected: boolean
  handleClickClip: (title: string) => void
}

const ChipItem = ({ title, selected, handleClickClip }: Props) => {
  const { Fonts } = useTheme()
  const selectedStyle = {
    backgroundColor: Colors.primary,
    borderRadius: 32,
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 4,
    marginRight: 8,
    fontWeight: 'bold',
  }
  const unSelectedStyle = {
    borderColor: Colors.primary,
    borderWidth: 1,
    borderRadius: 32,
    width: 'fit-content',
    height: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 4,
    marginRight: 8,
    fontWeight: 'bold',
  }
  const styleConfig: any = selected ? selectedStyle : unSelectedStyle
  const textColor = selected ? Colors.primaryText : Colors.secondaryText
  return (
    <TouchableOpacity
      style={styleConfig}
      onPress={() => handleClickClip(title)}
    >
      <Text style={[Fonts.size12, Fonts.weight500, { color: textColor }]}>
        {title}
      </Text>
    </TouchableOpacity>
  )
}

export default ChipItem
