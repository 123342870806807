import React from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { useDispatch } from 'react-redux'

import { useTheme } from '@/Hooks'
import { globalActions } from '@/Store/Global'
import { Colors, Height } from '@/Theme/Variables'

const useOpenReadMoreDialog = () => {
  const { Layout, Fonts, Gutters } = useTheme()
  const dispatch = useDispatch()

  const openLoginDialog = () => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        title: '特別聲明',
        disabledBackgroundClose: true,
        titleStyle: {
          fontSize: 20,
          fontWeight: 500,
        },
        content: (
          <View
            style={[
              Layout.center,
              Gutters.tinyVMargin,
              Gutters.regularHPadding,
            ]}
          >
            <Text
              style={[
                Fonts.weight500,
                Fonts.size14,
                Gutters.regularBMargin,
                { color: Colors.white },
              ]}
            >
              本 Chatbot 使用 AI 技術提供回覆和推薦內容，這些內容由 AI
              基於本平台酒資料庫及用戶筆記自動生成，資訊僅供參考。
              請注意！為保障您的個人資訊安全，請勿在對話中留下任何個人資料。
            </Text>
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.center,
                Gutters.smallBMargin,
                {
                  backgroundColor: Colors.primary,
                  paddingVertical: 9,
                  borderRadius: 8,
                  height: Height.height48,
                },
              ]}
              onPress={() => {
                dispatch(globalActions.closeBottomDialog())
              }}
            >
              <Text
                style={[
                  Fonts.size14,
                  Fonts.weight500,
                  { color: Colors.primaryText },
                ]}
              >
                了解
              </Text>
            </TouchableOpacity>
          </View>
        ),
      }),
    )
  }
  return openLoginDialog
}

export default useOpenReadMoreDialog
