/**
 * This file contains the application's variables.
 *
 * Define color, sizes, etc. here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */

/**
 * Colors
 */
export const Colors = {
  // Example colors:
  transparent: 'transparent',
  inputBackground: '#FFFFFF',
  white: '#ffffff',
  black: '#000000',
  gray: '#AAAAAA',
  gray2: '#F2F2F2',
  text: '#212529',
  primary: '#E0B168',
  primaryLight: '#FFB84D',
  primaryText: 'rgba(0, 0, 0, 0.87)',
  secondary: '#EECE96',
  secondaryLight: '#F9ECD4',
  secondaryText: 'rgba(255, 255, 255, 0.87)',
  background: {
    default: '#121212',
    surface: '#202024',
    onSurface: '#323232',
    top: '#505050',
  },
  fontText: {
    light: {
      primary1: '#ffffff',
      primary2: 'rgba(255, 255, 255, 0.87)',
      primary3: 'rgba(255, 255, 255, 0.6)',
      primary4: 'rgba(255, 255, 255, 0.38)',
    },
    dark: {
      primary1: '#000000',
      primary2: 'rgba(0, 0, 0, 0.87)',
      primary3: 'rgba(0, 0, 0, 0.6)',
      primary4: 'rgba(0, 0, 0, 0.38)',
    },
    disabled: 'rgba(255, 255, 255, 0.25)',
  },
  snackbar: {
    success: '#28a745',
    error: '#ED5454',
    default: '#434858',
    warning: '#F8BC44',
    info: '#2D62F2',
  },
  dark: '#1F1F1F',
  darkLight: '#2d2d2d',
  darkText: 'rgba(255, 255, 255, 0.38)',
}

export const NavigationColors = {
  primary: Colors.primary,
}

/**
 * BorderRadius
 */
export const BorderRadius = {
  radius4: 4,
  radius8: 8,
  radius16: 16,
  radius32: 32,
}

/**
 * BorderWidth
 */
export const BorderWidth = {
  width1: 1,
  width2: 2,
}

/**
 * Height
 */
export const Height = {
  height1: 1,
  height32: 32,
  height47: 47,
  height48: 48,
}

/**
 * Width
 */
export const Width = {
  width1: 1,
  width24: 24,
  width48: 48,
  width50: 50,
}

/**
 * FontSize
 */
export const FontSize = {
  small: 16,
  regular: 20,
  large: 40,
}

/**
 * Metrics Sizes
 */
const mini = 4 // 4
const tiny = 8 // 8
const small = tiny * 2 // 16
const regular = tiny * 3 // 24
const large = regular * 2 //  48
export const MetricsSizes = {
  mini,
  tiny,
  small,
  regular,
  large,
}

export default {
  Colors,
  NavigationColors,
  FontSize,
  MetricsSizes,
}
