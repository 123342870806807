import React, { useCallback, useState, useEffect } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  Dimensions,
  useWindowDimensions,
  ActivityIndicator,
  FlatList,
  Linking,
} from 'react-native'
import { useTheme } from '@/Hooks'
import { BorderRadius, Colors, Height } from '@/Theme/Variables'
import { FadeInView, Header } from '@/Components'
import userApi from '@/Services/modules/users'
import { navigate } from '@/Navigators/utils'
import { TabView, SceneMap, TabBar } from 'react-native-tab-view'
import { useSelector } from 'react-redux'
import spiritsApi from '@/Services/modules/spirits'
import { RootState } from '@/Store'
import { useIsFocused } from '@react-navigation/native'
import { spiritActions } from '@/Store/Spirit'
import { useDispatch } from 'react-redux'
import { DownLoadSign } from '@/Components'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { PagesEnum } from '@/Navigators/Application'

const UploadSpiritsContainer = ({ _route, navigation }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const styles = getStyle()
  const isFocused = useIsFocused()
  const dispatch = useDispatch()
  const layout = useWindowDimensions()
  const { spiritReviewList } = useSelector((state: RootState) => state.spirit)

  const { data: getMeData } = userApi.useGetMeQuery()

  const [
    getUploadSpiritRequest,
    {
      isLoading: getUploadSpiritIsLoading,
      isFetching: getUploadSpiritIsFetching,
    },
  ] = spiritsApi.useLazyGetUploadSpiritsQuery()

  const [index, setIndex] = useState(0)
  const [routes] = useState([
    { key: 'Pending', title: '待審核' },
    { key: 'Approved', title: '已接受' },
    { key: 'Rejected', title: '未接受' },
  ])

  // TODO: workaround 強制刷新
  useEffect(() => {
    if (isFocused) {
      getUploadSpiritRequest({ status: 'pending' })
      getUploadSpiritRequest({ status: 'approved' })
      getUploadSpiritRequest({ status: 'rejected' })
    }

    return () => {
      dispatch(spiritActions.clearSpiritReviewList())
    }
  }, [dispatch, getUploadSpiritRequest, isFocused])

  const handleClickCard = (id: string) => {
    navigation.navigate(AuthPagesEnum.UploadSpiritsInfoContainer, { id })
  }

  const handleFetchMoreData = () => {
    if (index === 0 && spiritReviewList?.pending?.meta?.nextCursor) {
      getUploadSpiritRequest({
        status: 'pending',
        cursor: spiritReviewList?.pending?.meta?.nextCursor,
      })
    }
    if (index === 1 && spiritReviewList?.approved?.meta?.nextCursor) {
      getUploadSpiritRequest({
        status: 'approved',
        cursor: spiritReviewList?.approved?.meta?.nextCursor,
      })
    }
    if (index === 2 && spiritReviewList?.rejected?.meta?.nextCursor) {
      getUploadSpiritRequest({
        status: 'rejected',
        cursor: spiritReviewList?.rejected?.meta?.nextCursor,
      })
    }
  }

  const renderEmpty = () => {
    if (getUploadSpiritIsLoading || getUploadSpiritIsFetching) {
      return (
        <View style={[Layout.fill, Layout.center, { paddingTop: 90 }]}>
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      )
    }
    return (
      <View style={[Layout.fill, Layout.center, { paddingTop: 90 }]}>
        <Image
          source={Images.upload_spirits_empty}
          resizeMode="contain"
          style={[Gutters.smallBMargin, { width: 73, height: 130 }]}
        />
        <Text
          style={[
            Fonts.size16,
            Fonts.weight700,
            { color: Colors.fontText.light.primary2 },
          ]}
        >
          尚無資料
        </Text>
      </View>
    )
  }

  const renderPendingCard = ({ item }: any) => {
    return (
      <TouchableOpacity
        style={[
          Gutters.tinyBMargin,
          Gutters.smallVPadding,
          Gutters.smallHPadding,
          Layout.row,
          Layout.alignItemsCenter,
          { backgroundColor: Colors.background.surface },
        ]}
        onPress={() => handleClickCard(item?.id)}
      >
        <Image
          source={item?.images?.[0]?.url}
          style={[
            Gutters.regularRMargin,
            { width: 100, height: 100, borderRadius: 8 },
          ]}
          resizeMode="cover"
        />
        <Text
          style={[
            Fonts.size16,
            Fonts.weight700,
            { color: Colors.fontText.light.primary2 },
          ]}
        >
          {item.title ? item.title : '尚無酒品名稱'}
        </Text>
      </TouchableOpacity>
    )
  }

  const renderPending = () => {
    return (
      <View style={[Layout.fill]}>
        <FlatList
          data={spiritReviewList?.pending?.data || []}
          contentContainerStyle={[{ paddingBottom: 200 }]}
          keyExtractor={item => item?.id}
          renderItem={renderPendingCard}
          refreshing
          onEndReached={({ distanceFromEnd }) => {
            if (distanceFromEnd >= 1) {
              handleFetchMoreData()
            }
          }}
          onEndReachedThreshold={1}
          ListEmptyComponent={renderEmpty}
        />
      </View>
    )
  }

  const renderApproved = () => {
    return (
      <View style={[Layout.fill]}>
        <FlatList
          data={spiritReviewList?.approved?.data || []}
          contentContainerStyle={[{ paddingBottom: 200 }]}
          keyExtractor={item => item?.id}
          renderItem={renderPendingCard}
          refreshing
          onEndReached={({ distanceFromEnd }) => {
            if (distanceFromEnd >= 1) {
              handleFetchMoreData()
            }
          }}
          onEndReachedThreshold={1}
          ListEmptyComponent={renderEmpty}
        />
      </View>
    )
  }

  const openLink = async (url: string) => {
    const canOpen = await Linking.canOpenURL(url)
    if (canOpen) {
      Linking.openURL(url)
    }
  }

  const handleRenderRejectReason = (reason: string) => {
    // 如果後台有改變這邊也要改
    enum RejectReason {
      // 需要跳轉
      REDIRECT = 'redirect:',
    }

    if (reason?.startsWith(RejectReason.REDIRECT)) {
      const url = reason?.replace(RejectReason.REDIRECT, '')
      return (
        <TouchableOpacity onPress={() => openLink(url)}>
          <Text
            style={[
              Fonts.size12,
              Fonts.weight400,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            資料庫已有此產品
          </Text>
          <Text
            style={[Fonts.size12, Fonts.weight400, { color: Colors.primary }]}
          >
            {url}
          </Text>
        </TouchableOpacity>
      )
    }

    return (
      <Text
        style={[
          Fonts.size12,
          Fonts.weight400,
          { color: Colors.fontText.light.primary2 },
        ]}
      >
        {reason}
      </Text>
    )
  }

  const renderRejectCard = ({ item }: any) => {
    return (
      <TouchableOpacity
        style={[
          Gutters.tinyBMargin,
          Gutters.smallVPadding,
          Gutters.smallHPadding,
          { backgroundColor: Colors.background.surface },
        ]}
        onPress={() => handleClickCard(item?.id)}
      >
        <View style={[Layout.row, Layout.alignItemsCenter]}>
          <Image
            source={item?.images?.[0]?.url}
            style={[
              Gutters.regularRMargin,
              { width: 100, height: 100, borderRadius: 8 },
            ]}
            resizeMode="cover"
          />
          <Text
            style={[
              Fonts.size16,
              Fonts.weight700,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            {item.title ? item.title : '尚無酒品名稱'}
          </Text>
        </View>
        {!!item?.rejectReason && (
          <>
            <View
              style={[
                Layout.fullWidth,
                Gutters.smallVMargin,
                { height: 1, backgroundColor: Colors.background.onSurface },
              ]}
            />
            <Text
              style={[
                Fonts.size12,
                Fonts.weight400,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              未通過原因：
            </Text>
            {handleRenderRejectReason(item?.rejectReason)}
          </>
        )}
      </TouchableOpacity>
    )
  }

  const renderRejected = () => {
    return (
      <View style={[Layout.fill]}>
        <FlatList
          data={spiritReviewList?.rejected?.data || []}
          contentContainerStyle={[{ paddingBottom: 200 }]}
          keyExtractor={item => item?.id}
          renderItem={renderRejectCard}
          refreshing
          onEndReached={({ distanceFromEnd }) => {
            if (distanceFromEnd >= 1) {
              handleFetchMoreData()
            }
          }}
          onEndReachedThreshold={1}
          ListEmptyComponent={renderEmpty}
        />
      </View>
    )
  }

  const renderScene = SceneMap({
    Pending: renderPending,
    Approved: renderApproved,
    Rejected: renderRejected,
  })

  const renderTabBar = (props: any) => (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: Colors.primary, height: 4 }}
      style={{ backgroundColor: Colors.background.default }}
    />
  )

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigate(PagesEnum.Main, {
      screen: 'Profile',
    })
  }, [navigation])

  // 下方 CTA 區塊
  const renderCTA = () => {
    return (
      <View
        style={[
          Gutters.smallVPadding,
          Gutters.regularHPadding,
          Layout.fullWidth,
          styles.CTAButton,
        ]}
      >
        <TouchableOpacity
          style={[
            Layout.fullWidth,
            Layout.rowCenter,
            {
              backgroundColor: Colors.primary,
              borderRadius: BorderRadius.radius8,
              height: Height.height47,
            },
          ]}
          onPress={() =>
            navigation.navigate(AuthPagesEnum.UploadSpiritsDetailContainer)
          }
        >
          <Text
            style={[
              Fonts.weight500,
              Fonts.size16,
              {
                color: Colors.fontText.dark.primary2,
              },
            ]}
          >
            協助新增品項
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <Header
        title={'協助新增紀錄'}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
      />
      <FadeInView duration={500} style={Layout.fill}>
        <View style={[Layout.fill]}>
          <TabView
            navigationState={{ index, routes }}
            renderScene={renderScene}
            renderTabBar={renderTabBar}
            onIndexChange={setIndex}
            initialLayout={{ width: layout.width }}
          />
        </View>
      </FadeInView>
      {renderCTA()}
      <DownLoadSign bottom={100} />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    CTAButton: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: 100,
      backgroundColor: Colors.background.default,
    },
  })

export default UploadSpiritsContainer
