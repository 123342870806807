import { useEffect, useState } from 'react'
import { Dimensions } from 'react-native'

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth || Dimensions.get('window').width,
    height: window.innerHeight || Dimensions.get('window').height,
  })

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })

  return windowSize
}

export default useWindowSize
