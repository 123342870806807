import { useEffect, useState } from 'react'

import noteApi from '@/Services/modules/note'
import { NestedSetType } from '@/Services/modules/note/note.type'
import spiritsApi from '@/Services/modules/spirits'

import { convertMetaData } from '../utils/convertMetaData'
import { checkDataAndWaring } from '../utils/checkDataAndWaring'

export interface RegionDataItem {
  zhName: string
  enName: string
  count: number
  i18nKey: string
}

const useGetRegionsData = () => {
  const [initialData, setInitialData] = useState<RegionDataItem[]>([])

  // 取得 meta 條件
  const {
    data: filterMetaData,
    isLoading: filterMetaDataIsLoading,
    isSuccess: filterMetaDataIsSuccess,
  } = spiritsApi.useGetSearchMetaQuery()

  // 取得國家資訊
  const { data, isLoading, isSuccess } = noteApi.useGetNestedSetQuery({
    type: NestedSetType.LOCATION,
  })

  // 整理初始化資料
  useEffect(() => {
    if (isSuccess && filterMetaDataIsSuccess) {
      const regionsArr: RegionDataItem[] = []
      const regionMeta = convertMetaData(filterMetaData, 'region')
      const regionRawData = data?.data?.children
        .map((region: any) => {
          return Object.keys(region.data).map((key: string) => ({
            [key]: region.data[key],
          }))
        })
        .flat()

      const regionData = regionRawData.reduce(
        (acc: { [key: string]: string }, curr: { [key: string]: string }) => ({
          ...acc,
          ...curr,
        }),
        {},
      )

      for (const region of regionMeta) {
        const zhName = regionData?.[region.key] || ''
        const enName = region.key

        const isDataValid = checkDataAndWaring(zhName, region.key, 'region')

        if (isDataValid) {
          regionsArr.push({
            zhName,
            enName,
            count: region.count,
            i18nKey: region.key,
          })
        }
      }

      setInitialData(regionsArr)
    }
  }, [data, filterMetaData, filterMetaDataIsSuccess, isSuccess])

  return {
    regionsData: initialData,
    filterMetaDataIsLoading,
    regionsDataIsLoading: isLoading,
  }
}

export default useGetRegionsData
