import React from 'react'
import { View, Image, Text, StyleSheet } from 'react-native'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
// import { useTranslation } from 'react-i18next'
import { Header } from '@/Components'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'

interface ListType {
  title: string
  content: string
}

const Feature: ListType[] = [
  {
    title: '1.',
    content: '優化新增筆記流程',
  },
  {
    title: '2.',
    content: '新增設定頁面',
  },
  {
    title: '3.',
    content: '可對自己的留言按讚與刪除',
  },
  {
    title: '4.',
    content: '優化移除願望清單流程',
  },
  {
    title: '5.',
    content: '優化產品內頁畫面',
  },
  {
    title: '6',
    content: '優化拍照掃描流程頁面畫面',
  },
  {
    title: '7',
    content: '新增列表頁面，無資料時的樣式',
  },
]

const Bug: ListType[] = [
  {
    title: '1.',
    content: '修復更新評分後，排序順序異常',
  },
]

const ReleaseNote = {
  version: 'v0.0.8',
  feature: Feature,
  bug: Bug,
}

const ReleaseNoteContainer = ({ _route, navigation }: any) => {
  const { Layout, Gutters, Images, Fonts } = useTheme()
  // const { t } = useTranslation()

  const styles = getStyle()

  const renderFeature = (item: any) => {
    return (
      <View style={[Layout.row, Layout.alignItemsCenter, styles.featureItem]}>
        <Text
          style={[
            Fonts.weight700,
            Fonts.size18,
            {
              color: Colors.gray,
            },
          ]}
        >
          {item.title}
        </Text>
        <Text style={[Fonts.size18, { color: Colors.gray }]}>
          {item.content}
        </Text>
      </View>
    )
  }

  return (
    <View style={[Layout.fill, { backgroundColor: Colors.black }]}>
      <Header
        title="發佈公告"
        leftIcon={
          <Image
            style={[styles.closeIcon]}
            source={Images.close}
            resizeMode="contain"
          />
        }
        leftIconPress={() =>
          navigation.navigate(PagesEnum.ProfileDetailContainer)
        }
        rightEmptyIconWidth="50"
      />
      <View
        style={[Gutters.largeHPadding, Gutters.regularTPadding, Layout.fill]}
      >
        <Text style={styles.version}>{ReleaseNote.version}</Text>
        {!!ReleaseNote.feature?.length && (
          <>
            <Text style={styles.section}>Feature</Text>
            {ReleaseNote.feature.map(item => renderFeature(item))}
          </>
        )}
        {!!ReleaseNote.bug?.length && (
          <>
            <Text style={styles.section}>Bug Fixed</Text>
            {ReleaseNote.bug.map(item => renderFeature(item))}
          </>
        )}
      </View>
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    version: {
      textAlign: 'center',
      color: Colors.white,
      fontWeight: 'bold',
      fontSize: 24,
      marginBottom: 16,
    },
    section: {
      color: Colors.white,
      fontWeight: 'bold',
      fontSize: 18,
      marginBottom: 16,
    },
    featureItem: {
      width: '100%',
      marginBottom: 16,
    },
    closeIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
  })

export default ReleaseNoteContainer
