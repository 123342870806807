import { globalActions } from '@/Store/Global'
import { removeEmptyKeys } from '@/Util/global'
import { useDispatch } from 'react-redux'

const useShare = ({
  url,
  text,
  title,
  snackbarContent,
}: {
  url: string
  text: string
  title: string
  snackbarContent: string
}) => {
  const dispatch = useDispatch()

  const handleShare = () => {
    // @ts-ignore
    if (navigator.share) {
      const shareData = {
        title,
        text,
        url,
      }
      // @ts-ignore
      navigator
        .share(removeEmptyKeys(shareData))
        .then(() => console.log('navigator share success！'))
        .catch((error: any) => console.log('navigator share error:', error))
      return
    }
    // @ts-ignore
    navigator.clipboard.writeText(url).then(
      () => {
        dispatch(
          globalActions.openSnackbar({
            visible: true,
            message: snackbarContent,
            type: 'default',
            showIcon: false,
          }),
        )
      },
      () => {
        console.error('Failed to copy to clipboard')
      },
    )
  }

  return handleShare
}

export default useShare
