import { FlavorData } from '@/Containers/data/FlavorData'
import noteApi from '@/Services/modules/note'
import { NestedSetType } from '@/Services/modules/note/note.type'

const useFlavorData = () => {
  // 取得風味資訊
  const { data, isSuccess } = noteApi.useGetNestedSetQuery({
    type: NestedSetType.FLAVOR_WHEEL,
  })

  const result: any = []
  // 放顏色
  const flavorColorMap = new Map()

  if (isSuccess) {
    FlavorData?.forEach(flavor => {
      for (const flavorRes of data?.data?.children) {
        if (flavorRes?.parentSlug === flavor?.name) {
          const flavorList: {
            i18n: string
            key: string
            name: string
            parentSlug: string
          }[] = []
          Object.keys(flavorRes?.data)?.map(key => {
            flavorList.push({
              i18n: `flavor-wheel:${key}`,
              key: key,
              name: flavorRes?.data[key],
              parentSlug: flavorRes?.parentSlug,
            })
            flavorColorMap.set(key, flavor?.color)
          })
          result.push({
            ...flavor,
            flavor: flavorList,
          })
        }
      }
    })
  }

  // 取得風味顏色（用第一層找）
  const handleGetFlavorColorByParent = (flavor: string) => {
    const colorMap = new Map()
    FlavorData.forEach(item => {
      colorMap.set(item?.name, item?.color)
    })
    return colorMap.get(flavor)
  }

  // 取得風味顏色(用第二層找)
  const handleGetFlavorColor = (flavor: string) => {
    return flavorColorMap.get(flavor)
  }

  // 取得風味父層資料
  const handleGetFlavorParent = (flavor: string) => {
    return result?.find((item: any) =>
      item?.flavor?.some((flavorItem: any) => flavorItem?.key === flavor),
    )
  }

  return {
    result,
    handleGetFlavorColor,
    handleGetFlavorParent,
    handleGetFlavorColorByParent,
    isSuccess,
  }
}

export default useFlavorData
