import React from 'react'
import { View, Image, Text, ScrollView, StyleSheet } from 'react-native'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { FadeInView, Header } from '@/Components'
// import { useTranslation } from 'react-i18next'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'

const ServiceData = [
  {
    title: '服務條款',
    section: [
      {
        title: '一、會員服務條款',
        list: [
          {
            content:
              '烈酒探索家平台係由 EchoX Innovation Holding Limited（以下簡稱「本公司」)依據本服務條款提供您烈酒探索家（https://www.spiritzexplorer.com）服務 (以下簡稱「本服務」或「本站」)。本會員服務條款所稱之「會員」，為依照本站所定之加入會員程序加入並通過認證者。',
          },
          {
            content:
              '當您使用本站服務時，即表示您同意及遵守本服務條款的規定事項及相關法律之規定。',
          },
          {
            content:
              '會員必須達到您居住國家的法定飲酒年齡才能使用本網站，如果您尚未達到，請勿進入本網站。會員並應於閱讀、瞭解並同意本服務條款之所有內容(包含其後修改或變更後的內容)，方得註冊為會員、使用或繼續使用本服務。',
          },
          {
            content:
              '本站保留有審核加入會員資格之權利，另外已加入會員者，本站亦保留有暫停、終止或解除其會員資格之權利。',
          },
          {
            content:
              '本會員服務條款之修訂，適用於所有會員，當本站修訂本服務條款時，將於本站上公告，將不會個別通知會員，會員應隨時自行注意該等修改或變更。',
          },
          {
            content:
              '會員於任何修改或變更後繼續使用本服務時，視為會員已閱讀、瞭解並同意接受該等修改或變更。若不同意上述的服務條款修訂或更新方式，或不接受本服務條款的其他任一約定，會員應立即停止使用本服務。',
          },
        ],
      },
      {
        title: '二、隱私權保護',
        list: [
          {
            content:
              '請尊重並依據本站「隱私權保護聲明」保護您的隱私 (請參閱「隱私權保護聲明」條款)。',
          },
        ],
      },
      {
        title: '三、會員帳號、密碼及安全',
        list: [
          {
            content:
              '會員須善盡帳號使用與管理之責任。對於使用該會員之帳號 (無論是會員本身或其他人) 利用本站服務所造成或衍生之所有行為及結果，會員須自行負擔全部責任。',
          },
          {
            content:
              '會員之帳號遺失，或發現無故遭第三者盜用時，應立即通知本站，因未即時通知，導致本站無法有效防止及修改時，所造成的所有損失，會員應自負全責。',
          },
          {
            content:
              '會員的帳號及會員權益均僅供會員個人使用及享有，不得轉借、轉讓他人或與他人合用。',
          },
          {
            content:
              '使用本站所提供之會員服務時，應以加入會員時所登錄之帳號使用之。',
          },
          {
            content:
              '當會員同意使用 Facebook 帳號登入使用本服務時，該 Facebook 帳號之密碼是由 Facebook 網站所處理，本站並不會',
          },
          {
            content:
              '當會員同意使用 Facebook 帳號登入使用本服務時，該 Facebook 帳號之密碼是由 Facebook 網站所處理，本站並不會接觸到該密碼，而且每一個 Facebook 帳號只能綁定一個會員帳號進行登入，第一次綁定後即不能再予修改。',
          },
          {
            content:
              '經由綁定 Facebook 帳號及密碼而登入使用本服務時，該登入的帳號即代表會員本人，使用者於使用本服務之任何行為，均視為會員本人之行為，並應遵循本站會員條款之各項約定。會員應妥善設定、維護及保管自己之 Facebook 帳號及密碼。',
          },
          {
            content:
              '如果會員洩漏自己的個人資料，或使第三人有使用會員於本服務帳號的機會時，會員必須就第三人的行為負全部責任，本站並可隨時暫停或終止提供本服務(包含但不限於使用 Facebook 帳號登入使用本服務之措施)。',
          },
          {
            content:
              '盜用本服務會員之帳號或密碼，導致第三者或本服務遭其他第三人或行政機關之調查、追訴、請求或求償時，您應負起一切法律責任，包含但不限於賠償本站因此所生之訴訟費用、律師費及商譽損失等。',
          },
        ],
      },
      {
        title: '四、使用行為',
        list: [
          {
            content:
              '您使用本服務之一切行為均應符合當地或國際相關法令規範；對於使用者的一切行為，您應自行負擔全部責任。',
          },
          {
            content:
              '您同意絕不以非法之目的或以非法方式使用本服務，與確實遵守您居住國家或所在地之相關法規及網際網路之國際慣例，並保證不得利用本服務從事侵害他人權益或違法之行為。',
          },
          {
            content: '您於使用本站會員服務時應遵守以下限制，不得為以下之行為：',
            detail: [
              'a) 有損害他人人格或商標權、著作權等智慧財產權或其他權利內容。',
              'b) 使用違反公共秩序或善良風俗或其他不法之圖片或文字。',
              'c) 強烈政治、宗教色彩的偏激言論。',
              'd) 未經許可，不得利用本服務或本站所提供之資源，包括但不限於圖文資料庫、編寫製作網頁之軟體等，從事任何商業交易行為，或招攬廣告商或贊助人',
              'e)其他違反本站「會員服務條款」及本站其他規範的內容。',
            ],
          },
        ],
      },
      {
        title: '五、拒絕或終止會員的使用：',
        list: [
          {
            content:
              '會員同意本站得基於維護隱私安全之考量，因任何理由，包含但不限於缺乏使用，或違反本服務條款及精神，終止會員的密碼、帳號（或其任何部分）或本服務（或其任何部分）之使用，或將本服務內任何「會員內容」加以移除並刪除。此外，會員同意若本服務（或其任何部分）之使用被終止，本站對會員或任何第三人均不承擔責任。',
          },
          {
            content:
              '會員有通知取消本站會員資格之義務，並自停止本站會員身份之日起（以本站電子郵件發出日期為準），喪失所有本服務所提供之優惠及權益。',
          },
          {
            content:
              '若會員決定終止本站會員資格，可直接以電子郵件的方式通知本站（電子郵件地址：service@spiritzexplorer.com），本站將儘快註銷您的會員資料。',
          },
          {
            content:
              '為避免惡意情事發生致使會員應享權益損失，當會員通知本站停止會員身份時，本站將再次以電子郵件確認無誤後，再進行註銷會員資格。',
          },
        ],
      },
      {
        title: '六、服務內容之變更與電子報及訊息發送',
        list: [
          {
            content:
              '會員同意本站得視業務需要及實際情形，增減、變更或終止相關服務的項目或內容，且無需個別通知會員。',
          },
          {
            content:
              '會員同意本站得依實際執行情形，增加、修改或終止相關活動，並選擇最適方式告知會員。',
          },
          {
            content:
              '本站有權暫時或永久修改或中止提供本服務給您，您不得因此要求任何賠償或要求本站負擔任何法律責任。',
          },
          {
            content:
              '使用本服務即代表會員同意本站得不定期發送電子報、商品或服務之公告、提醒或行銷等訊息 (包括但不限於 EDM) 至您下載或使用本服務之裝置、註冊或所登入的Facebook 電子信箱。如您對於電子信箱所收到之電子報或行銷訊息表示拒絕接受行銷時，本服務將停止繼續發送電子報或行銷訊息至該電子信箱。',
          },
        ],
      },
      {
        title: '七、本服務專有權利',
        list: [
          {
            content:
              '會員提供給本站之任何資料(包含但不限於圖、文)均應符合法令之規範，若會員無合法權利得授權他人使用、修改、重製、公開播送、改作、散布、發行、公開發表某資料，並將前述權利轉授權第三人，請勿擅自將該資料上載、傳送、輸入或提供至本站。任何資料一經會員上載、傳送、輸入或提供至本站，視為會員已允許本站及本站相關合作單位 (第三人)得無條件使用、修改、重製、公開播送、改作、散布、發行、公開發表該等資料，並得將前述權利轉授權他人，且利用前開資料所製作之衍生著作，其衍生著作之著作權悉歸本站所有，會員對此絕無異議。會員並應保證本站及本站相關合作單位 (第三人)使用、修改、重製、公開播送、改作、散布、發行、公開發表、轉授權該等資料，不致侵害任何第三人之智慧財產權或任何權益，否則應對本站負損害賠償責任（包括但不限於訴訟費用、和解、補償費用、商譽損失及律師費用等）。',
          },
          {
            content:
              '本服務所有頁面出現之廣告看板與活動訊息，所有權及經營權均為本站所有，使用者除事先取得本站同意外，不得使用任何與本服務相關之資訊。',
          },
          {
            content:
              '會員同意並授權本站得為提供個人化服務或相關加值服務之目的，提供該些服務所需之會員資料給任何相關合作單位 (第三人) 並於約定範圍內之運用，如會員不同意將其資料提供給任何相關合作單位 (第三人)，可通知本站進行修改，會員並將同時放棄本服務之任何購物優惠、活動或獲獎權利，本站並得限制部分服務或功能之使用。',
          },
          {
            content:
              '同時為提供行銷、市場分析、統計或研究、或為提供會員個人化服務或加值服務之目的，會員同意本站及本站之策略合作夥伴(第三人)，對於會員上載、傳送、輸入或提供之資料得於合理之範圍內蒐集、處理、保存、傳遞及使用該等資料。並可能將前開所稱資料之全部或一部，於符合蒐集目的之範圍內提供予合作廠商(第三人)為蒐集、處理及利用。',
          },
        ],
      },
      {
        title: '八、免責事項',
        list: [
          {
            content: '下列情形發生時，本站有權可以停止、中斷提供本服務：',
            detail: [
              'a)對本服務相關軟硬體設備進行更換、升級、保養或施工時。',
              'b)發生突發性之電子通信設備故障時。',
              'c)天災或其他不可抗力之因素致使本網站無法提供服務時。',
            ],
          },
          {
            content:
              '本站對於使用者在使用本服務或使用本服務所致生之任何直接、間接、衍生之財產或非財產之損害，均不負任何賠償責任。',
          },
          {
            content:
              '使用者對於上傳留言之文字、圖片及其它資料，應自行備份；本站對於任何原因導致其內容全部或一部之滅失、毀損，不負任何責任。',
          },
          {
            content:
              '本站對使用本服務之用途或所產生的結果，不負任何保證責任，亦不保證與本服務相關之軟體、功能或服務無缺失或會予以修正。',
          },
          {
            content:
              '對於您在本站中的所有言論、意見或行為僅代表您個人；不代表本站的立場，本站不負任何責任。本站對於使用者所自稱之身分及提供之任何資料，不擔保其正確性、真實、合法及無誤。',
          },
          {
            content:
              '本站無須對發生於本服務或透過本服務所涉及之任何恐嚇、誹謗、淫穢或其他一切不法行為對使用者或任何人負責。',
          },
          {
            content:
              '對於您透過本服務所購買或取得，或透過本站之贊助者或廣告商所刊登、銷售或交付之任何貨品或服務，您應自行承擔其可能風險或依法向商品或服務提供者交涉求償，與本站完全無關，本站均不負任何責任。',
          },
        ],
      },
      {
        title: '九、智慧財產權的保護',
        list: [
          {
            content:
              '本站所使用之軟體、程式及網站上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網頁設計，均由本站或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。任何人不得逕行使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。如欲引用或轉載前述之軟體、程式或網站內容，必須依法取得本站或其他權利人的事前書面同意。如有違反之情事，您應對本站或其他權利人負損害賠償責任（包括但不限於訴訟費用及律師費用等）。',
          },
          {
            content:
              '在尊重他人智慧財產權之原則下，會員同意在使用本站之服務時，不得有任何侵害他人智慧財產權或任何權益之行為。',
          },
          {
            content:
              '若會員有涉及侵權之情事，本站可暫停全部或部份之服務，或逕自以取消會員帳號之方式處理。',
          },
          {
            content:
              '若有發現智慧財產權遭侵害之情事，請聯絡本站客服，提供所遭侵權之情形及聯絡方式，並附具真實陳述及擁有合法智慧財產權之聲明，供本站查核。',
          },
          {
            content:
              '本站於接獲權利人之前項通知後，將立即移除或使他人無法進入該涉有侵權之內容或相關資訊，同時本案將透過會員留存之聯絡資訊告知會員前開權利人之通知事項，待會員知悉通知事項後，應自行與權利人聯繫並解決爭議，與本站無涉。',
          },
          {
            content:
              '如權利人所提出之侵害通知之內容有不完整者，本站得於收受前開通知書次日起五個工作天內，要求提出者補正。如權利人未於期限內補正、補正不完全或本站無法判斷者，本站得自行決定處理方式並視為未提出侵害通知。',
          },
          {
            content:
              '本站僅會依照法令或政府機關之要求，提供會員之識別資料予第三人。如權利人提出侵害通知，本站僅會依照權利人所提出之內容移除或使他人無法進入該涉有侵權之內容或相關資訊。如權利人欲取得足以識別會員之資料，應透過司法調查機關或有權調閱機關向本站提出要求，本站於收到合法要求之通知後，始會將會員之識別資料提供予該機關，不會提供任何會員之識別資料予權利人。',
          },
          {
            content: '本活動條款未規定者，悉依相關法令之規範辦理。',
          },
        ],
      },
      {
        title: '十、其他規定',
        list: [
          {
            content:
              '本服務條款之解釋與適用，以及與本服務條款有關或會員與本服務產生之爭議或糾紛，應依本公司設立地之法律處理。',
          },
          {
            content:
              '若因您使用本服務之任何行為，導致本站或其經營、使用人員遭任何第三人或任何機關之調查、追訴、請求或求償時，您應負起一切法律責任，包括但不限於訴訟費用、律師費、和解費用及商譽損失等。',
          },
          {
            content:
              '本站針對可預知之軟硬體維護工作，有可能導致系統中斷或是暫停者，將會於該狀況發生前，以適當之方式告知會員。',
          },
        ],
      },
    ],
  },
]

const PrivacyData = {
  title: '隱私權保護政策',
  content:
    '歡迎使用烈酒探索家平台（以下稱「本站」）相關服務，為了確保使用者之個人資料、隱私及權益之保護，當您已閱讀並同意「隱私權保護政策」時，即表示您願意以電子文件之方式行使法律所賦予同意之權利，並具有書面同意之效果，請您務必詳閱，若不同意請離開本站。',
  subContent: '',
  section: [
    {
      title: '一、 資料蒐集：',
      content:
        '當您進入本站瀏覽資訊時，並不需要提供您個人資料，但當您願意加入我們成為會員，或是希望享有本站為您提供的會員專屬服務時，本站會要求您註冊並登入個人資料，以方便與您相互聯繫、提供服務。',
      subContent: '本站如何取得您個人資料的安全說明',
      list: [
        {
          content:
            '同意註冊加入會員：當您註冊成為會員時，本站會請您留下相關資訊，包括您的 Facebook 帳號、電子郵件信箱。會員在註冊之後，將會獲得一個屬於您個人獨有的會員帳號，除了可為該帳號建立暱稱、個人頭像，您可經由該帳號獲得本站提供給會員的服務。',
        },
        {
          content:
            '尚未註冊只作瀏覽：當您尚未註冊，只在網上瀏覽或查詢時，本站會保留伺服器自行產生的相關記錄，包括您使用的連線設備的IP 位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等。本站會對個別連線者的瀏覽器予以標示，歸納您瀏覽器在本站所瀏覽的網頁，除非您願意告知您的個人資料，否則本站不會，也無法利用此項記錄和您對應。',
        },
        {
          content:
            '本站使用由 Google Inc.（以下稱「Google」）提供的網頁分析服務 - Google Analytics。Google Analytics 會透過預設導入方式收集下列資訊：使用者人數、工作階段統計資料、概略地理位置、瀏覽器和裝置資訊。此外，Google Analytics 也會透過網站資料串流收集加強型評估事件，並透過應用程式資料串流收集應用程式內購資料。另外，Google Analytics 會將 Client-ID 儲存在第一方 Cookie _ga 中，以區分出不重複使用者和使用者在本站上的工作階段。有關 Google Analytics 資料隱私權與安全性更多資訊，請見：https://support.google.com/analytics/topic/2919631?hl=zh-Hant&ref_topic=1008008。',
        },
      ],
    },
    {
      title: '二、蒐集個人資料告知事項及書面同意書',
      content:
        'EchoX Innovation Holding Limited (下稱「本公司」或「本站」)告知以下事項並請您詳細閱讀，當您使用本站服務或以任何方式上傳、告知本站您的個人資料時，即表示您已同意所有內容，並同意本站及相關合作單位 (第三人)得蒐集、使用、處理及傳輸您的個人資料。',
      list: [
        {
          content:
            '蒐集之目的：包含進行行銷業務、消費者、客戶管理與服務、網路購物及其他電子商務服務及調查、統計與研究分析。',
        },
        {
          content:
            '蒐集之類別：本站蒐集台端之個人資料類別包含您提供的資料，例如姓名、電話、電子郵件等聯絡資訊或方式、出生年月日及其他得以直接或間接識別您個人之資料。',
        },
        {
          content:
            '個人資料利用之期間：於主管機關許可業務經營之存續期間內，符合下列要件之一者：1.個人資料蒐集之特定目的存續期間。2.依相關法令規定或契約約定之保存年限（如商業會計法等）。3.本站因執行業務所必須之保存期間。',
        },
        {
          content:
            '個人資料利用之地區：本站及合作夥伴(第三方服務提供者)之營運範圍，包含中華民國境內、境外及法令許可之範圍內加以處理、利用及國際傳輸。',
        },
        {
          content:
            '個人資料利用之對象：個人資料蒐集、處理及利用對象包括：本公司及關係企業、其職員、代理人、專業顧問、與本公司及／或關係企業因業務需要訂有契約關係或業務往來之機構（含受本公司及／或關係企業委託提供委外服務之機構、合作夥伴及合作單位）、顧問、受讓合併或分割全部或部分業務之受讓人、或因併購活動而可能成為交易對象之當事人及其顧問，以及本站依法令得提供個人資料之人。',
        },
        {
          content:
            '方式：符合相關法令以電子或其他非電子之利用方式之蒐集、處理、利用及國際傳輸（包括但不限於使用電子文件、紙本或其他合於當時科學技術之適當方式等）。',
        },
      ],
    },
    {
      title: '三、 資料轉移',
      content:
        '在涉及合併、分割、解散、重組、收購、宣告破產、資產轉讓或類似的交易時，如涉及個人資訊轉移，本站會提前向您告知接收方的名稱和聯絡方式，並要求接收方繼續依照本政策處理您的個人資訊',
    },
    {
      title: '四、 您就本站保有您的個人資料得行使下列權利',
      list: [
        {
          content:
            '您有權向本站請求查詢、閱覽、製給複本、補充、更正、請求停止蒐集、請求停止處理、請求停止利用、請求刪除個人資料內容之一部或全部等權利（註：會員申請查詢、閱覽、製給複製本時，將酌收必要成本費用）。',
        },
        {
          content:
            '您如欲行使上述權利，可與本服務客服連絡（客服電子郵件地址：service@spiritzexplorer.com）進行申請。',
        },
      ],
    },
    {
      title: '五、 注意事項',
      list: [
        {
          content:
            '於您註冊並提供個人資料後，即視為您已同意所填寫之資料，供本站於上開蒐集目的之必要範圍內加以蒐集、處理與利用。此外，您可自行決定是否註冊、提供填寫相關之個人資料欄位，如您選擇不填寫或要求刪除、停止使用會員資料等行為時，則本站將可能無法完整提供會員與本服務或本條款相關之內容及服務。',
        },
        {
          content:
            '請依各項服務需求提供您本人正確、最新及完整之個人資料，若您的個人資料有任何異動，請主動向本站申請更正。',
        },
        {
          content:
            '若您提供錯誤、過時、不完整或具誤導性之資料，而損及您的相關權益，本站將不負任何相關之賠償責任或法律責任。',
        },
        {
          content:
            '會員如果對於本站隱私權聲明，或與個人資料有關之相關事項有任何疑問，可聯繫本站客服。',
        },
      ],
    },
    {
      title: '六、隱私權保護政策修訂',
      content:
        '本站保有隨時修訂本告知內容之權利，修正時亦同，您於任何修改或變更後繼續使用本服務時，視為會員已閱讀、瞭解並同意接受該等修改或變更。若不同意上述的隱私權保護政策條款修訂或更新，或不接受本隱私權保護政策條款的其他任一約定，您應立即停止使用本服務。',
    },
  ],
}

const PrivacyContainer = ({ route, navigation }: any) => {
  const { Layout, Images, Gutters, Fonts } = useTheme()
  // const { t } = useTranslation()
  const styles = getStyle()

  const handleGoBack = () => {
    // 從個人資料頁面進來
    if (route?.params?.from) {
      navigation.navigate(PagesEnum.ProfileDetailContainer)
      return
    }
    navigation.navigate(PagesEnum.LoginMainContainer)
  }

  // TODO: 往上拉 reFresh
  return (
    <View style={[Layout.fill, { backgroundColor: Colors.background.surface }]}>
      <ScrollView>
        <FadeInView duration={500} style={Layout.fill}>
          <Header
            title={'服務條款和隱私政策'}
            leftIcon={
              <Image
                style={[styles.arrowLeftIcon]}
                source={Images.arrowLeft}
                resizeMode="contain"
              />
            }
            leftIconPress={handleGoBack}
          />
          <View style={[Gutters.regularVPadding, Gutters.regularHPadding]}>
            {ServiceData.map(firstItem => {
              return (
                <View>
                  <Text
                    style={[
                      Fonts.weight500,
                      Fonts.size24,
                      Gutters.smallBMargin,
                      { color: Colors.white },
                    ]}
                  >
                    {firstItem.title}
                  </Text>
                  <View style={[Gutters.regularBMargin]}>
                    {firstItem.section.map(secondItem => {
                      return (
                        <View style={[Gutters.regularBMargin]}>
                          <Text
                            style={[
                              Fonts.weight500,
                              Fonts.size20,
                              Gutters.tinyBMargin,
                              { color: Colors.white },
                            ]}
                          >
                            {secondItem.title}
                          </Text>
                          <View>
                            {secondItem.list.map((thirdItem, index) => {
                              return (
                                <View>
                                  <View style={[Layout.row]}>
                                    <Text
                                      style={[
                                        Fonts.size14,
                                        Fonts.weight400,
                                        Gutters.miniRMargin,
                                        {
                                          color: Colors.white,
                                        },
                                      ]}
                                    >{`${index + 1}.`}</Text>
                                    <Text
                                      style={[
                                        Fonts.size14,
                                        Fonts.weight400,
                                        { color: Colors.white },
                                      ]}
                                    >
                                      {thirdItem.content}
                                    </Text>
                                  </View>
                                  {thirdItem.detail &&
                                    thirdItem.detail.map(fourthItem => {
                                      return (
                                        <Text
                                          style={[
                                            Fonts.size14,
                                            Fonts.weight400,
                                            Gutters.smallLPadding,
                                            { color: Colors.white },
                                          ]}
                                        >
                                          {fourthItem}
                                        </Text>
                                      )
                                    })}
                                </View>
                              )
                            })}
                          </View>
                        </View>
                      )
                    })}
                  </View>
                </View>
              )
            })}
          </View>
          <View style={[Gutters.regularVPadding, Gutters.regularHPadding]}>
            <Text
              style={[
                Fonts.weight500,
                Fonts.size24,
                Gutters.smallBMargin,
                { color: Colors.white },
              ]}
            >
              {PrivacyData.title}
            </Text>
            <Text
              style={[
                Fonts.weight400,
                Fonts.size14,
                Gutters.tinyBMargin,
                { color: Colors.white },
              ]}
            >
              {PrivacyData.content}
            </Text>
            <Text
              style={[
                Fonts.weight400,
                Fonts.size14,
                Gutters.tinyBMargin,
                { color: Colors.white },
              ]}
            >
              {PrivacyData.subContent}
            </Text>
            <View>
              {PrivacyData.section.map(firstItem => {
                return (
                  <View style={[Gutters.regularBMargin]}>
                    <Text
                      style={[
                        Fonts.size20,
                        Fonts.weight500,
                        Gutters.tinyBMargin,
                        { color: Colors.white },
                      ]}
                    >
                      {firstItem?.title}
                    </Text>
                    {firstItem?.content && (
                      <Text
                        style={[
                          Fonts.size14,
                          Fonts.weight400,
                          Gutters.smallBMargin,
                          { color: Colors.white },
                        ]}
                      >
                        {firstItem?.content}
                      </Text>
                    )}
                    {firstItem?.content && (
                      <Text
                        style={[
                          Fonts.size14,
                          Fonts.weight400,
                          Gutters.smallBMargin,
                          { color: Colors.white },
                        ]}
                      >
                        {firstItem?.subContent}
                      </Text>
                    )}
                    {firstItem.list &&
                      firstItem.list.map((secondItem, index) => {
                        return (
                          <View style={[Layout.row]}>
                            <Text
                              style={[
                                Fonts.size14,
                                Fonts.weight400,
                                { color: Colors.white },
                              ]}
                            >{`${index + 1}.`}</Text>
                            <Text
                              style={[
                                Fonts.size14,
                                Fonts.weight400,
                                { color: Colors.white },
                              ]}
                            >
                              {secondItem.content}
                            </Text>
                          </View>
                        )
                      })}
                  </View>
                )
              })}
            </View>
          </View>
        </FadeInView>
      </ScrollView>
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
  })

export default PrivacyContainer
