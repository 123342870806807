import React, { useEffect, useMemo, useState } from 'react'
import {
  FlatList,
  Image,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native'

import { FadeInView, LoadingComponent } from '@/Components'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { capitalizedSentence } from '@/Util/global'
import { PagesEnum } from '@/Navigators/Application'

import useGetBrandsData, { BrandDataItem } from '../hooks/useGetBrandsData'

interface BrandContainerProps {
  navigation: any
}

const TITLE = '品牌'

const BrandContainer = ({ navigation }: BrandContainerProps) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()

  const { brandsData, filterMetaDataIsLoading, brandDataIsLoading } =
    useGetBrandsData()

  const [searchText, setSearchText] = useState('')
  const [listData, setListData] = useState(brandsData)

  const styles = getStyle()

  // 如果將來又要把 A-Z 排序加回來的話
  // const groupedBrandsData = useMemo(() => {
  //   if (!brandsData) {
  //     return []
  //   }

  //   const grouped = brandsData.reduce((acc, brand) => {
  //     const firstLetter = brand.enName.charAt(0).toUpperCase()
  //     if (!acc[firstLetter]) {
  //       acc[firstLetter] = []
  //     }
  //     acc[firstLetter].push(brand)
  //     return acc
  //   }, {} as Record<string, typeof brandsData>)

  //   return Object.entries(grouped)
  //     .map(([alphabet, children]) => ({
  //       alphabet,
  //       children: children.sort((a, b) => a.enName.localeCompare(b.enName)),
  //     }))
  //     .sort((a, b) => a.alphabet.localeCompare(b.alphabet))
  // }, [brandsData])

  useEffect(() => {
    if (searchText.length > 0) {
      setListData(
        brandsData.filter(
          brand =>
            brand.enName.toLowerCase().includes(searchText.toLowerCase()) ||
            brand.zhName.toLowerCase().includes(searchText.toLowerCase()),
        ),
      )
    }
    if (searchText.length === 0) {
      setListData(brandsData)
    }
  }, [brandsData, searchText])

  const handleClickCard = (key: string) => {
    navigation.navigate(PagesEnum.FilterSearchListContainer, {
      brand: key,
    })
  }

  const renderLoading = useMemo(() => {
    if (filterMetaDataIsLoading || brandDataIsLoading) {
      return (
        <View style={[Layout.fill, Layout.rowCenter, Gutters.largeTPadding]}>
          <LoadingComponent backgroundColor="transparent" />
        </View>
      )
    }
  }, [
    Layout.fill,
    Layout.rowCenter,
    Gutters,
    brandDataIsLoading,
    filterMetaDataIsLoading,
  ])

  const convertRemoveTitleDash = (title: string) => {
    return title.replace(/-/g, ' ')
  }

  const renderBrandItem = ({ item: brand }: { item: BrandDataItem }) => (
    <TouchableOpacity
      style={[styles.brandCard]}
      onPress={() => handleClickCard(brand.i18nKey)}
    >
      <Text
        style={[
          Fonts.weight500,
          Fonts.size14,
          { color: Colors.fontText.light.primary2 },
        ]}
      >
        {capitalizedSentence(convertRemoveTitleDash(brand.enName))}
      </Text>
      <Text
        style={[
          Fonts.weight500,
          Fonts.size14,
          Gutters.miniBMargin,
          { color: Colors.fontText.light.primary2 },
        ]}
      >
        {capitalizedSentence(convertRemoveTitleDash(brand.zhName))}
      </Text>
      <View style={[Layout.row, Layout.alignItemsCenter, styles.count]}>
        <Image
          source={Images.search_landing_bottle_icon}
          style={[Layout.iconSize14, Gutters.miniRMargin]}
        />
        <Text
          style={[
            Fonts.weight300,
            Fonts.size12,
            { color: Colors.fontText.light.primary2 },
          ]}
        >
          {brand.count}
        </Text>
      </View>
    </TouchableOpacity>
  )

  return (
    <FadeInView duration={500} style={Layout.fill}>
      {/* 20241017 隱藏內容版位 */}
      {/* <Image
        source={Images.search_landing_banner}
        style={[styles.articleBanner]}
        resizeMode="cover"
      /> */}
      <View
        style={[Layout.fill, Gutters.regularHPadding, Gutters.smallTPadding]}
      >
        <View style={[styles.filterCardHeader]}>
          <Text
            style={[
              Fonts.weight700,
              Fonts.size16,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            {`探索 - ${TITLE}`}
          </Text>
        </View>
        <View style={[styles.searchBar]}>
          <Image source={Images.search} style={[Layout.iconSize24]} />
          <TextInput
            style={[
              styles.searchInput,
              { color: Colors.fontText.light.primary2 },
            ]}
            value={searchText}
            onChangeText={setSearchText}
            placeholder={`搜尋${TITLE}`}
            placeholderTextColor={Colors.fontText.light.primary3}
          />
          {searchText.length > 0 && (
            <TouchableOpacity onPress={() => setSearchText('')}>
              <Image source={Images.close} style={[Layout.iconSize24]} />
            </TouchableOpacity>
          )}
        </View>
        <View>
          <View style={[styles.brandCardContainer]}>
            {renderLoading}
            <FlatList
              data={listData}
              renderItem={renderBrandItem}
              keyExtractor={item => item.enName}
              numColumns={2}
              columnWrapperStyle={styles.columnWrapper}
              showsVerticalScrollIndicator={false}
            />
          </View>
        </View>
      </View>
    </FadeInView>
  )
}

const getStyle = () =>
  StyleSheet.create({
    filterCardHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 16,
    },
    articleBanner: {
      width: '100%',
      height: 180,
    },
    searchBar: {
      marginBottom: 16,
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 16,
      paddingVertical: 10,
      borderWidth: 1,
      borderColor: Colors.background.top,
      borderRadius: 8,
    },
    searchInput: {
      flex: 1,
      marginLeft: 10,
      outlineWidth: 0,
      outlineColor: 'transparent',
      outlineStyle: 'none',
    },
    brandCardContainer: {
      flex: 1,
    },
    brandCard: {
      width: '48%',
      height: 84,
      justifyContent: 'center',
      padding: 10,
      borderWidth: 1,
      borderColor: Colors.background.top,
      borderRadius: 8,
      marginBottom: 12,
    },
    count: {
      justifyContent: 'flex-end',
    },
    columnWrapper: {
      gap: 12,
      justifyContent: 'space-between',
    },
  })

export default BrandContainer
