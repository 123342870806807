import React, { useCallback } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  Dimensions,
  Platform,
  ActivityIndicator,
} from 'react-native'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { FadeInView, Header } from '@/Components'
// import { useTranslation } from 'react-i18next'
import userApi from '@/Services/modules/users'
import { useDispatch } from 'react-redux'
import { navigate } from '@/Navigators/utils'
import { globalActions } from '@/Store/Global'
import { getConfig } from '@/Util/global'
import { api } from '@/Services/api'
import { DownLoadSign } from '@/Components'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { PagesEnum } from '@/Navigators/Application'

const InviteFriendContainer = ({ _route, navigation }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  // const { t } = useTranslation()
  const dispatch = useDispatch()
  const styles = getStyle()

  const { data: siteMetaData } = api.useGetSiteMetaQuery()

  // NOTE:邀請碼選必填機制
  const isMGMCodeRequired = siteMetaData?.features?.invitation || false

  const { data: getMeData, isLoading: getMeIsLoading } = userApi.useGetMeQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    },
  )

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigate(PagesEnum.Main, {
      screen: 'Profile',
    })
  }, [navigation])

  // 點擊複製邀請碼
  const handleClickCopyInviteCode = () => {
    if (Platform.OS === 'web') {
      // @ts-ignore
      navigator.clipboard.writeText(`${getMeData?.inviteAccount?.code}`).then(
        () => {
          dispatch(
            globalActions.openSnackbar({
              visible: true,
              message: '成功複製邀請碼',
              type: 'default',
              showIcon: false,
            }),
          )
        },
        () => {
          console.error('Failed to copy to clipboard')
        },
      )
    }
  }

  const handleClickShareInviteCode = () => {
    if (Platform.OS === 'web') {
      // @ts-ignore
      if (navigator.share) {
        // @ts-ignore
        navigator
          .share({
            title: '',
            text: `${
              getMeData?.inviteAccount?.code
            } 這是我的邀請碼，快來一起加入烈酒探索家吧! \n下載連結: https://lihi.cc/bt8vN \n\n我的品飲筆記: ${
              getConfig()?.DOMAIN
            }/profile/${getMeData?.id}`,
          })
          .then(() => console.log('navigator share success！'))
          .catch((error: any) => console.log('navigator share error:', error))
        return
      } else {
        handleClickCopyInviteCode()
      }
    }
  }

  const inviteDescription = isMGMCodeRequired
    ? '烈酒探索家封測中！目前僅限使用邀請碼進行註冊，趕快分享您的專屬邀請碼，邀請好友加入吧！'
    : '立即分享您的專屬邀請碼，邀請好友一起交流烈酒品飲心得！'

  // 如果邀請碼總數為無限的話，會是 null 則不顯示分子分母數量
  const inviteNumber =
    getMeData?.inviteAccount?.maxUsages === null
      ? `成功邀請的好友（${getMeData?.inviteAccount?.uses}）`
      : `成功邀請的好友（${getMeData?.inviteAccount?.uses}/${getMeData?.inviteAccount?.maxUsages}）`

  if (getMeIsLoading) {
    return (
      <View
        style={[
          Layout.fill,
          Layout.colCenter,
          { backgroundColor: Colors.background.default },
        ]}
      >
        <ActivityIndicator size="large" color={Colors.primary} />
      </View>
    )
  }

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <Header
        title={'邀請好友'}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
      />
      <FadeInView duration={500} style={Layout.fill}>
        <View
          style={[Gutters.largeBMargin, Layout.colCenter, { paddingTop: 80 }]}
        >
          <Image
            source={Images.invite_friend_invite}
            resizeMode="contain"
            style={{ width: 150, height: 150 }}
          />
          <Text
            style={[
              Fonts.size16,
              Fonts.weight700,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            您的專屬邀請碼
          </Text>
        </View>
        <View style={[Gutters.regularHPadding]}>
          <Text
            style={[
              Fonts.size16,
              Fonts.weight400,
              Gutters.smallBMargin,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            {inviteDescription}
          </Text>
          <View style={[styles.inviteCodeContainer, Gutters.regularBMargin]}>
            <Text
              style={[
                Fonts.size16,
                Fonts.weight400,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              {getMeData?.inviteAccount?.code || ''}
            </Text>
            <TouchableOpacity onPress={handleClickCopyInviteCode}>
              <Image
                source={Images.invite_friend_invite_copy}
                resizeMode="contain"
                style={[Layout.iconSize24]}
              />
            </TouchableOpacity>
          </View>
          <TouchableOpacity
            style={[Layout.rowCenter, Gutters.largeBMargin, styles.ctaButton]}
            onPress={handleClickShareInviteCode}
          >
            <Image
              source={Images.share_black}
              resizeMode="contain"
              style={[Layout.iconSize24, { marginRight: 4 }]}
            />
            <Text
              style={[
                Fonts.weight500,
                Fonts.size16,
                { color: Colors.fontText.dark.primary2 },
              ]}
            >
              分享邀請碼
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[Layout.rowCenter]}
            onPress={() => navigation.navigate(AuthPagesEnum.InviteFriendList)}
          >
            <Text
              style={[Fonts.size16, Fonts.weight500, { color: Colors.primary }]}
            >
              {inviteNumber}
            </Text>
          </TouchableOpacity>
        </View>
      </FadeInView>
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    inviteCodeContainer: {
      width: '100%',
      height: 56,
      backgroundColor: Colors.background.onSurface,
      borderRadius: 10,
      paddingHorizontal: 12,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    ctaButton: {
      width: '100%',
      height: 48,
      borderRadius: 8,
      backgroundColor: Colors.primary,
    },
  })

export default InviteFriendContainer
