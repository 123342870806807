import React, { useEffect } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { DownLoadSign } from '@/Components'

const NotFound = ({ navigation }: any) => {
  const { Layout, Gutters, Fonts } = useTheme()

  return (
    <View style={[Layout.fill]}>
      <View
        style={[
          Layout.fill,
          Layout.colCenter,
          { backgroundColor: Colors.background.surface },
        ]}
      >
        <Text
          style={[
            Fonts.size46,
            Fonts.weight700,
            Gutters.regularBMargin,
            { color: Colors.white },
          ]}
        >
          404 Not Found
        </Text>
        <TouchableOpacity onPress={() => navigation.navigate('Startup')}>
          <Text
            style={[Fonts.size20, Fonts.weight700, { color: Colors.white }]}
          >
            返回首頁
          </Text>
        </TouchableOpacity>
      </View>
      <DownLoadSign />
    </View>
  )
}

export default NotFound
