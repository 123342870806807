import { useEffect, useState } from 'react'

import noteApi from '@/Services/modules/note'
import { DropdownsType } from '@/Services/modules/note/note.type'
import spiritsApi from '@/Services/modules/spirits'

import { convertMetaData } from '../utils/convertMetaData'
import { checkDataAndWaring } from '../utils/checkDataAndWaring'

export interface BrandDataItem {
  zhName: string
  enName: string
  count: number
  i18nKey: string
}

const useGetBrandsData = () => {
  const [initialData, setInitialData] = useState<BrandDataItem[]>([])

  // 取得 meta 條件
  const {
    data: filterMetaData,
    isSuccess: filterMetaDataIsSuccess,
    isLoading: filterMetaDataIsLoading,
    isFetching: filterMetaDataIsFetching,
  } = spiritsApi.useGetSearchMetaQuery()

  // 取得品牌資訊
  const {
    data: brandData,
    isSuccess: brandDataIsSuccess,
    isLoading: brandDataIsLoading,
    isFetching: brandDataIsFetching,
  } = noteApi.useGetNoteDropdownsOptionsQuery({
    type: DropdownsType.BRAND,
    locale: 'zh',
  })

  const {
    data: brandENData,
    isSuccess: brandENDataIsSuccess,
    isLoading: brandENDataIsLoading,
    isFetching: brandENDataIsFetching,
  } = noteApi.useGetNoteDropdownsOptionsQuery({
    type: DropdownsType.BRAND,
    locale: 'en',
  })

  const isLoading =
    brandDataIsLoading ||
    brandENDataIsLoading ||
    filterMetaDataIsLoading ||
    filterMetaDataIsFetching ||
    brandDataIsFetching ||
    brandENDataIsFetching

  // 整理初始化資料
  useEffect(() => {
    if (brandDataIsSuccess && filterMetaDataIsSuccess && brandENDataIsSuccess) {
      const brandArr: BrandDataItem[] = []
      const brandMeta = convertMetaData(filterMetaData, 'brand')

      for (const brand of brandMeta) {
        const zhName = brandData?.data?.[brand.key] || ''
        const enName = brandENData?.data?.[brand.key] || ''

        const isDataValid = checkDataAndWaring(zhName, brand.key, 'brand')

        if (isDataValid) {
          brandArr.push({
            zhName,
            enName,
            count: brand.count,
            i18nKey: brand.key,
          })
        }
      }
      setInitialData(brandArr)
    }
  }, [
    brandData?.data,
    brandDataIsSuccess,
    filterMetaData,
    filterMetaDataIsSuccess,
    brandENDataIsSuccess,
    brandENData?.data,
  ])

  return {
    brandsData: initialData,
    filterMetaDataIsLoading,
    brandDataIsLoading: isLoading,
  }
}

export default useGetBrandsData
