import React, { useCallback } from 'react'
import {
  View,
  Image,
  Text,
  ScrollView,
  ActivityIndicator,
  StyleSheet,
  Dimensions,
} from 'react-native'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { FadeInView, Header } from '@/Components'
import { navigate } from '@/Navigators/utils'
import achievementsApi from '@/Services/modules/achievements'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'

const AllAchievementContainer = ({ _route, navigation }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const styles = getStyle()

  const {
    data: getAllAchievementListData,
    isLoading: getAllAchievementListIsLoading,
  } = achievementsApi.useGetAllAchievementListQuery()

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigate(PagesEnum.Main, {
      screen: 'Profile',
    })
  }, [navigation])

  if (getAllAchievementListIsLoading) {
    return (
      <View style={[Layout.fill]}>
        <View
          style={[
            Layout.fill,
            Layout.colCenter,
            { backgroundColor: Colors.background.default },
          ]}
        >
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      </View>
    )
  }

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <Header
        title={'所有成就徽章'}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={navigation.canGoBack() ? Images.arrowLeft : Images.close}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
      />
      <FadeInView duration={500} style={Layout.fill}>
        <ScrollView
          style={[
            Layout.fill,
            Gutters.regularVPadding,
            Gutters.regularHPadding,
          ]}
        >
          {getAllAchievementListData?.data?.length &&
            getAllAchievementListData?.data?.map(groupItem => {
              return (
                <View style={[Layout.fullWidth]}>
                  <Text
                    style={[
                      Fonts.size16,
                      Fonts.weight700,
                      Gutters.regularBMargin,
                      { color: Colors.fontText.light.primary2 },
                    ]}
                  >
                    {groupItem?.group}
                  </Text>
                  <View>
                    {groupItem?.data?.map((item: any) => {
                      return (
                        <View
                          style={[
                            Layout.row,
                            Layout.alignItemsCenter,
                            Layout.fullWidth,
                            Gutters.largeBMargin,
                          ]}
                        >
                          <Image
                            source={{ uri: item?.imageUrl }}
                            resizeMode="contain"
                            style={[
                              Gutters.smallRMargin,
                              {
                                width: 70,
                                height: 120,
                              },
                            ]}
                          />
                          <View>
                            <Text
                              style={[
                                Fonts.size16,
                                Fonts.weight700,
                                {
                                  color: Colors.fontText.light.primary2,
                                  marginBottom: 2,
                                },
                              ]}
                            >
                              {item?.name}
                            </Text>
                            {item?.goals?.map((goal: any) => {
                              return (
                                <Text
                                  style={[
                                    Fonts.size14,
                                    Fonts.weight400,
                                    {
                                      color: Colors.fontText.light.primary3,
                                      marginBottom: 2,
                                    },
                                  ]}
                                >{`・${goal}`}</Text>
                              )
                            })}
                          </View>
                        </View>
                      )
                    })}
                  </View>
                </View>
              )
            })}
        </ScrollView>
      </FadeInView>
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
  })

export default AllAchievementContainer
