import { api, prefixProxyEndpoint } from '@/Services/api'
import {
  GetRecognitionHistoriesRequest,
  GetRecognitionHistoriesResponse,
  PatchRecognitionHistoriesRequest,
  PostRecognitionHistoriesRequest,
} from './history.type'
import { spiritActions } from '@/Store/Spirit'
import { globalActions } from '@/Store/Global'

export const historyApi = api.injectEndpoints({
  endpoints: build => ({
    // 取得使用者掃描 history
    getRecognitionHistories: build.query<
      GetRecognitionHistoriesResponse,
      GetRecognitionHistoriesRequest
    >({
      query: params => ({
        url: prefixProxyEndpoint('/v1/my-recognition-histories'),
        method: 'GET',
        params: {
          ...params,
          perPage: 20,
        },
      }),
      providesTags: ['history'],
    }),
    // 新增辨識紀錄
    postRecognitionHistories: build.mutation<
      void,
      PostRecognitionHistoriesRequest
    >({
      query: body => ({
        url: prefixProxyEndpoint('/v1/my-recognition-histories'),
        method: 'POST',
        body,
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(historyApi.util.invalidateTags(['history']))

          // workaround for first time rating will not update the rate immediately
          dispatch(spiritActions.setAddRateLoading(false))
        }
      },
    }),
    // 辨識紀錄列表中回報錯誤
    postRecognitionHistoriesReport: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: prefixProxyEndpoint(
          `/v1/my-recognition-histories/${id}:report-error`,
        ),
        method: 'POST',
      }),
    }),
    // 刪除辨識紀錄列表中資料
    deleteRecognitionHistories: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: prefixProxyEndpoint(`/v1/my-recognition-histories/${id}`),
        method: 'DELETE',
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled
        if (result) {
          dispatch(
            globalActions.openSnackbar({
              visible: true,
              message: '成功移除辨識紀錄',
              type: 'default',
              showIcon: false,
            }),
          )
        }
      },
    }),
    // 變更辨識紀錄列表中資料（使用情境在掃描完成後，點擊非 best match 的其餘四款酒，打這支 api 更改後端預設新增的 history 紀錄）
    patchRecognitionHistories: build.mutation<
      void,
      PatchRecognitionHistoriesRequest
    >({
      query: ({ historyId, spiritId }) => ({
        url: prefixProxyEndpoint(`/v1/my-recognition-histories/${historyId}`),
        method: 'PATCH',
        body: {
          spiritId,
        },
      }),
    }),
  }),
  overrideExisting: false,
})

historyApi.enhanceEndpoints({
  addTagTypes: ['history'],
  endpoints: {},
})

export default historyApi
