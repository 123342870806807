import { useEffect, useState } from 'react'

import { useRoute, useNavigation } from '@react-navigation/native'

import useGetFlavorData, { FlavorDataItem } from './useGetFlavorData'
import { convertParams } from '../filterModal/MainFilterModal'

const useCheckIsAdvanceFlavor = () => {
  const navigation = useNavigation()
  const { params } = useRoute<any>()
  const flavorParams = convertParams(params?.flavor)

  const { flavorsData } = useGetFlavorData()

  const [isAdvanceFlavor, setIsAdvanceFlavor] = useState(false)

  useEffect(() => {
    if (flavorsData && flavorParams) {
      const parentKey = flavorsData.map(
        (flavor: FlavorDataItem) => flavor.enName,
      )

      const flavorParamsArray = flavorParams.split(',')
      const isAdvance = flavorParamsArray.some(
        // 不包含父層的等於是進階口味
        (flavor: string) => !parentKey.includes(flavor),
      )

      // @ts-ignore
      navigation.setParams({ isAdvanceFlavor: isAdvance || undefined })
      setIsAdvanceFlavor(isAdvance)
    } else {
      // @ts-ignore
      navigation.setParams({ isAdvanceFlavor: undefined })
      setIsAdvanceFlavor(false)
    }
  }, [flavorParams, flavorsData, navigation])

  return isAdvanceFlavor
}

export default useCheckIsAdvanceFlavor
