import React from 'react'
import ErrorBoundaryComponent from './ErrorBoundary'

type Props = {
  children: React.ReactNode
}

type State = {
  hasError: boolean
  errorKey: number
}

class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hasError: false,
    errorKey: 0,
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.log(error, errorInfo)
  }

  resetError = () => {
    this.setState(prevState => ({
      hasError: false,
      errorKey: prevState.errorKey + 1,
    }))
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <ErrorBoundaryComponent onClick={this.resetError} />
        </>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
