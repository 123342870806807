import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  Dimensions,
  ImageBackground,
  ScrollView,
} from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { Header } from '@/Components'
import { navigate } from '@/Navigators/utils'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'
import { MainPagesEnum } from '@/Navigators/Main'

import CategoryContainer from './landingContainer/CategoryContainer'
import BrandContainer from './landingContainer/BrandContainer'
import CountryContainer from './landingContainer/CountryContainer'
import PairingContainer from './landingContainer/PairingContainer'
import FlavorContainer from './landingContainer/FlavorContainer'

interface ExploreTabType {
  key: string
  title: string
  icon: any
}

export enum ExploreTabKeyEnum {
  Category = 'category',
  Brand = 'brand',
  Flavor = 'flavor',
  Country = 'country',
  Pairing = 'pairing',
}

const SpiritsCategoriesContainer = ({ navigation, route }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const styles = getStyle()

  const ExploreTab: ExploreTabType[] = useMemo(
    () => [
      {
        key: ExploreTabKeyEnum.Category,
        title: '酒類',
        icon: Images.search_landing_tab_category,
      },
      {
        key: ExploreTabKeyEnum.Brand,
        title: '品牌',
        icon: Images.search_landing_tab_brand,
      },
      {
        key: ExploreTabKeyEnum.Country,
        title: '國家',
        icon: Images.search_landing_tab_country,
      },
      {
        key: ExploreTabKeyEnum.Flavor,
        title: '風味',
        icon: Images.search_landing_tab_flavor,
      },
      {
        key: ExploreTabKeyEnum.Pairing,
        title: '配餐',
        icon: Images.search_landing_tab_pairing,
      },
    ],
    [Images],
  )

  const [selectedTab, setSelectedTab] = useState<ExploreTabType>(ExploreTab[0])

  const isSelectedTab = useCallback(
    (tab: any) => {
      return selectedTab.key === tab.key
    },
    [selectedTab],
  )

  const handleGoBack = useCallback(() => {
    navigate(PagesEnum.Main, {
      screen: MainPagesEnum.Home,
    })
    setSelectedTab(ExploreTab[0])
  }, [ExploreTab])

  const handleTabPress = useCallback(
    (tab: any) => {
      setSelectedTab(tab)
      navigation.navigate(PagesEnum.SpiritsCategoriesContainer, {
        tab: tab.key,
      })
    },
    [navigation],
  )

  // 偵測目前是哪個 tab
  useEffect(() => {
    const routeTab = route.params?.tab as ExploreTabType['key']
    if (routeTab) {
      setSelectedTab(prevTab => {
        if (prevTab.key !== routeTab) {
          return ExploreTab.find(tab => tab.key === routeTab) as ExploreTabType
        }
        return prevTab
      })
    }
  }, [ExploreTab, route.params?.tab])

  const renderSelectedTabContent = useCallback(() => {
    switch (selectedTab.key) {
      case ExploreTabKeyEnum.Category:
        return <CategoryContainer navigation={navigation} />
      case ExploreTabKeyEnum.Brand:
        return <BrandContainer navigation={navigation} />
      case ExploreTabKeyEnum.Country:
        return <CountryContainer navigation={navigation} />
      case ExploreTabKeyEnum.Pairing:
        return <PairingContainer navigation={navigation} />
      case ExploreTabKeyEnum.Flavor:
        return <FlavorContainer navigation={navigation} />
      default:
        return null
    }
  }, [selectedTab.key, navigation])

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <ImageBackground
        style={[Layout.fullWidth, { height: 210 }]}
        source={Images.product_detail_random1}
        resizeMode="cover"
        blurRadius={2}
      >
        <LinearGradient
          style={[styles.backgroundGradient]}
          colors={['rgba(0,0,0,0.8)', 'rgba(0,0,0,0.8)']}
          end={{ y: 1, x: 0 }}
        />
        <Header
          title={'探索'}
          leftIcon={
            <Image
              style={[styles.arrowLeftIcon]}
              source={Images.arrowLeft}
              resizeMode="contain"
            />
          }
          leftIconPress={handleGoBack}
          rightIcon={
            <Image
              style={[Layout.iconSize24]}
              source={Images.search}
              resizeMode="contain"
            />
          }
          rightIconPress={() => {
            navigation.navigate(PagesEnum.SearchListContainer)
          }}
          backgroundColor="transparent"
        />
        <View
          style={[
            Layout.fullWidth,
            Gutters.regularHPadding,
            Gutters.regularTPadding,
          ]}
        >
          <Text
            style={[
              Fonts.weight400,
              Fonts.size16,
              Gutters.smallBMargin,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            探索不同主題
          </Text>
          <View
            style={[
              Layout.row,
              Layout.alignItemsCenter,
              Layout.justifyContentBetween,
            ]}
          >
            {ExploreTab.map(tab => {
              const isSelected = isSelectedTab(tab)
              return (
                <TouchableOpacity
                  key={tab.key}
                  style={[Layout.center]}
                  onPress={() => handleTabPress(tab)}
                >
                  <View
                    style={[
                      styles.tabContainer,
                      isSelected && styles.tabSelected,
                    ]}
                  >
                    <Image
                      style={[Layout.iconSize48]}
                      source={tab.icon}
                      resizeMode="contain"
                    />
                  </View>
                  <Text
                    style={[
                      Fonts.weight500,
                      Fonts.size12,
                      {
                        color: isSelected
                          ? Colors.primary
                          : Colors.fontText.disabled,
                      },
                    ]}
                  >
                    {tab.title}
                  </Text>
                </TouchableOpacity>
              )
            })}
          </View>
        </View>
      </ImageBackground>
      <ScrollView>{renderSelectedTabContent()}</ScrollView>
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    tabContainer: {
      borderRadius: 48,
      marginBottom: 4,
      opacity: 0.5,
    },
    tabSelected: {
      borderWidth: 2,
      width: 52,
      height: 52,
      borderRadius: 52,
      borderColor: Colors.primary,
      opacity: 1,
    },
    backgroundGradient: {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
  })

export default SpiritsCategoriesContainer
