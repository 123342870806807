import React, { useState, useRef, useEffect } from 'react'
import {
  View,
  PanResponder,
  StyleSheet,
  Image,
  LayoutChangeEvent,
  TextInput,
  Text,
} from 'react-native'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'

interface Props {
  min: number
  max: number
  initialLow: number
  initialHigh: number
  step: number
  onValuesChange: (values: [number, number]) => void
  value: [number, number]
}

const DualThumbRangeSlider = ({
  min = 0,
  max = 10000,
  initialLow = 0,
  initialHigh = 10000,
  step = 100,
  onValuesChange,
  value,
}: Props) => {
  const { Images, Gutters } = useTheme()
  const [inputHighValue, setInputHighValue] = useState(initialHigh)
  const [lowValue, setLowValue] = useState(initialLow)
  const [highValue, setHighValue] = useState(initialHigh)
  const [containerWidth, setContainerWidth] = useState(0)
  const containerRef = useRef<View>(null)

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.measure((x, y, width) => {
        setContainerWidth(width)
      })
    }
  }, [])

  useEffect(() => {
    if (value) {
      setLowValue(value?.[0] || initialLow)
      setHighValue(value?.[1] || initialHigh)
      setInputHighValue(value?.[1] || initialHigh)
    }
  }, [value, initialLow, initialHigh])

  const panResponderLow = PanResponder.create({
    onStartShouldSetPanResponder: () => true,
    onPanResponderMove: (_, gestureState) => {
      if (containerWidth === 0) {
        return
      }
      let newValue =
        Math.round(
          (lowValue + (gestureState.dx / containerWidth) * (max - min)) / step,
        ) * step
      newValue = Math.max(min, Math.min(newValue, highValue - step))
      setLowValue(newValue)
      onValuesChange([newValue, highValue])
    },
  })

  const panResponderHigh = PanResponder.create({
    onStartShouldSetPanResponder: () => true,
    onPanResponderMove: (_, gestureState) => {
      if (containerWidth === 0) {
        return
      }
      let newValue =
        Math.round(
          (highValue + (gestureState.dx / containerWidth) * (max - min)) / step,
        ) * step
      newValue = Math.min(max, Math.max(newValue, lowValue + step))
      setHighValue(newValue)
      setInputHighValue(newValue)
      onValuesChange([lowValue, newValue])
    },
  })

  const getPosition = (value: number) => {
    return ((value - min) / (max - min)) * (containerWidth - 20)
  }

  const handleLayout = (event: LayoutChangeEvent) => {
    setContainerWidth(event.nativeEvent.layout.width)
  }

  const handleLowValueChange = (text: string) => {
    const newValue = Math.round(Number(text) / step) * step
    if (newValue >= min) {
      setLowValue(newValue)
      onValuesChange([newValue, highValue])
    }
  }

  const handleHighValueChange = (text: string) => {
    const newValue = Math.round(Number(text) / step) * step
    if (newValue <= max) {
      setHighValue(newValue)
      setInputHighValue(newValue)
      onValuesChange([lowValue, newValue])
    }

    if (newValue > max) {
      setInputHighValue(newValue)
      onValuesChange([lowValue, newValue])
    }
  }

  // 超過 max 的值會被卡在 max 上，所以需要把值轉換回來(拖拉的點位置)
  const handleConvertHighValue = () => {
    if (inputHighValue > max) {
      return getPosition(max)
    }
    return getPosition(highValue)
  }

  // 超過 max 的值會被卡在 max 上，所以需要把值轉換回來(拖拉條的長度)
  const handleConvertHighTrackRight = () => {
    if (inputHighValue > max) {
      return containerWidth - getPosition(max) - 10
    }
    return containerWidth - getPosition(highValue) - 10
  }

  return (
    <View style={[Gutters.regularBMargin]}>
      <View style={styles.container} ref={containerRef} onLayout={handleLayout}>
        <View style={[styles.track, { width: containerWidth - 20 }]} />
        <View
          style={[
            styles.selectedTrack,
            {
              left: getPosition(lowValue) + 10,
              right: handleConvertHighTrackRight(),
            },
          ]}
        />
        <Image
          source={Images.search_filter_range_thumb}
          style={[styles.thumb, { left: getPosition(lowValue) }]}
          {...panResponderLow.panHandlers}
        />
        <Image
          source={Images.search_filter_range_thumb}
          style={[styles.thumb, { left: handleConvertHighValue() }]}
          {...panResponderHigh.panHandlers}
        />
      </View>
      <View style={styles.inputContainer}>
        <View style={styles.input}>
          <Text style={styles.inputPrefix}>$</Text>
          <TextInput
            style={styles.textInput}
            value={lowValue.toString()}
            onChangeText={handleLowValueChange}
            keyboardType="numeric"
          />
        </View>
        <View style={styles.divider} />
        <View style={styles.input}>
          <Text style={styles.inputPrefix}>$</Text>
          <TextInput
            style={styles.textInput}
            value={inputHighValue.toString()}
            onChangeText={handleHighValueChange}
            keyboardType="numeric"
          />
          {inputHighValue === max && <Text style={styles.inputSuffix}>+</Text>}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 40,
    justifyContent: 'center',
    marginBottom: 10,
  },
  track: {
    height: 4,
    backgroundColor: Colors.background.onSurface,
    position: 'absolute',
    left: 10,
  },
  selectedTrack: {
    height: 4,
    backgroundColor: Colors.primary,
    position: 'absolute',
  },
  thumb: {
    width: 20,
    height: 20,
    position: 'absolute',
    top: 10,
  },
  value: {
    marginTop: 25,
    textAlign: 'center',
  },
  inputContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  input: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#FFF',
    paddingHorizontal: 8,
    paddingVertical: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.background.top,
    width: '40%',
    textAlign: 'center',
  },
  textInput: {
    color: Colors.fontText.light.primary3,
    paddingLeft: 16,
    outline: 'none',
    outlineWidth: 0,
    outlineColor: 'transparent',
  },
  inputPrefix: {
    position: 'absolute',
    left: 12,
    fontSize: 16,
    fontWeight: '400',
    color: Colors.fontText.light.primary3,
  },
  inputSuffix: {
    position: 'absolute',
    left: 70,
    top: 15,
    fontSize: 16,
    fontWeight: '400',
    color: Colors.fontText.light.primary3,
  },
  divider: {
    width: '10%',
    height: 1,
    backgroundColor: Colors.background.top,
  },
})

export default DualThumbRangeSlider
