/**
 * @format
 */

// import { AppRegistry } from 'react-native'
// import App from './src/App'
// import { name as appName } from './app.json'

// AppRegistry.registerComponent(appName, () => App)

import { registerRootComponent } from 'expo'
import { Platform } from 'react-native'
import { createRoot } from 'react-dom/client'
import { createElement } from 'react'
import App from './src/App'

if (Platform.OS === 'web') {
  const rootTag = createRoot(
    document.getElementById('root') ?? document.getElementById('main'),
  )
  rootTag.render(createElement(App))
} else {
  registerRootComponent(App)
}
