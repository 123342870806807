import React, { useCallback, useEffect } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
  ScrollView,
  ActivityIndicator,
} from 'react-native'
import { useTheme } from '@/Hooks'
import { FadeInView, Header } from '@/Components'
import { BorderRadius, BorderWidth, Colors } from '@/Theme/Variables'
import { userApi } from '@/Services/modules/users'
import { useTranslation } from 'react-i18next'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { globalActions } from '@/Store/Global'
import { getConfig } from '@/Util/global'

// import AsyncStorage from '@react-native-async-storage/async-storage'
import { useDispatch } from 'react-redux'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'

// TODO: 版號，之後用自動壓
const VERSION = getConfig().IMAGE_VERSION || 'web-v1.0.1'

const ProfileDetailContainer = ({ navigation }: any) => {
  const { Images, Layout, Gutters, Fonts } = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { data: getMeData } = userApi.useGetMeQuery()

  const [logoutRequest, { isSuccess: logoutIsSuccess, reset: logoutReset }] =
    userApi.useDeleteLogoutMutation()

  // 刪除使用者帳號
  const [
    deleteAccountRequest,
    {
      isSuccess: deleteAccountIsSuccess,
      isLoading: deleteAccountIsLoading,
      reset: deleteAccountReset,
    },
  ] = userApi.useDeleteAccountMutation()

  // 判斷登出是否成功
  useEffect(() => {
    if (logoutIsSuccess) {
      dispatch(
        globalActions.openSnackbar({
          visible: true,
          message: deleteAccountIsSuccess ? '刪除帳號成功' : '登出成功！',
          type: 'default',
          showIcon: false,
        }),
      )
      dispatch(userApi.util.resetApiState())
      logoutReset()
      deleteAccountReset()
      navigation.navigate(PagesEnum.Main, { screen: 'Profile' })
    }
  }, [
    logoutIsSuccess,
    dispatch,
    logoutReset,
    navigation,
    deleteAccountIsSuccess,
    deleteAccountReset,
  ])

  const handleLogout = useCallback(async () => {
    const token = await AsyncStorage.getItem('auth-token')
    // TODO: 因為 SPA 先不用 token，透過 cookie 來驗證，所以先註解掉，等到改回 mobile 拿掉
    // if (token) {
    logoutRequest({
      token: token || 'cookie-token',
    })
    // }
  }, [logoutRequest])

  // 判斷刪除帳號是否成功
  useEffect(() => {
    if (deleteAccountIsSuccess) {
      handleLogout()
      dispatch(globalActions.closeBottomDialog())
    }
  }, [deleteAccountIsSuccess, dispatch, handleLogout])

  const handleDeleteAccount = () => {
    deleteAccountRequest()
  }

  // 確認傷儲帳號
  const openConfirmDeleteDialog = () => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        title: '刪除帳號',
        titleStyle: {
          fontSize: 20,
        },
        content: (
          <View>
            <View
              style={[
                Gutters.regularVPadding,
                Gutters.regularHPadding,
                {
                  borderBottomColor: Colors.gray,
                  borderBottomWidth: BorderWidth.width1,
                },
              ]}
            >
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size14,
                  Gutters.tinyBMargin,
                  { color: Colors.secondaryText },
                ]}
              >
                您的帳號將被停用 30天，此期間仍可透過登入來重新啟用您的帳號。30
                天後帳號將被永久刪除，無法透過任何方式復原。
              </Text>
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size14,
                  Gutters.tinyBMargin,
                  { color: Colors.secondaryText },
                ]}
              >
                刪除帳號不會同時刪除筆記，若不想在本平台留下紀錄，請先自行刪除筆記。
              </Text>
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size14,
                  { color: Colors.secondaryText },
                ]}
              >
                是否確定刪除帳號？
              </Text>
            </View>
            <View
              style={[
                Layout.center,
                Gutters.regularVMargin,
                Gutters.regularHPadding,
              ]}
            >
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.rowCenter,
                  Gutters.smallBMargin,
                  Gutters.smallVPadding,
                  {
                    borderRadius: BorderRadius.radius8,
                    backgroundColor: '#ED5454',
                  },
                ]}
                onPress={() => {
                  handleDeleteAccount()
                }}
              >
                <Text
                  style={[
                    Fonts.weight500,
                    Fonts.size16,
                    { color: Colors.dark },
                  ]}
                >
                  確定刪除帳號
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.rowCenter,
                  Gutters.smallBMargin,
                  Gutters.smallVPadding,
                  {
                    borderRadius: BorderRadius.radius8,
                  },
                ]}
                onPress={() => {
                  dispatch(globalActions.closeBottomDialog())
                }}
              >
                <Text style={[Fonts.weight500, { color: Colors.white }]}>
                  {t(
                    'components.spiritCardWithContent.removeSpiritDialogCancel',
                  )}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        ),
      }),
    )
  }

  const handleGoBack = () => {
    navigation.navigate(PagesEnum.Main, {
      screen: 'Profile',
    })
  }

  if (deleteAccountIsLoading) {
    return (
      <View style={[Layout.fill]}>
        <View
          style={[
            Layout.fill,
            Layout.colCenter,
            { backgroundColor: Colors.background.default },
          ]}
        >
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      </View>
    )
  }

  return (
    <View style={[Layout.fill, { backgroundColor: Colors.background.default }]}>
      <ScrollView>
        <Header
          title={'其他功能'}
          leftIcon={
            <Image
              style={[styles.arrowLeftIcon]}
              source={Images.arrowLeft}
              resizeMode="contain"
            />
          }
          leftIconPress={handleGoBack}
          rightEmptyIconWidth="50"
        />
        {/* 列表 */}
        <FadeInView duration={550}>
          <View style={[Gutters.regularBMargin]}>
            {/* 設定 */}
            {/* <TouchableOpacity
              style={[Gutters.regularHPadding, styles.listItem]}
              onPress={() => {
                navigation.navigate(PagesEnum.Main, { screen: 'MySpirit' })
              }}
            >
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Image
                  style={[styles.listIcon, Gutters.smallRMargin]}
                  source={Images.profile_detail_setting}
                  resizeMode="contain"
                />
                <Text style={styles.listTitle}>
                  {t('profileDetail.setting')}
                </Text>
              </View>
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Image
                  style={styles.listIcon}
                  source={Images.profile_rightArrowIcon}
                  resizeMode="contain"
                />
              </View>
            </TouchableOpacity> */}

            {/* 政策條款 */}
            <TouchableOpacity
              style={[Gutters.regularHPadding, styles.listItem]}
              onPress={() => {
                navigation.navigate(PagesEnum.Privacy, { from: 'p' })
              }}
            >
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Image
                  style={[styles.listIcon, Gutters.smallRMargin]}
                  source={Images.profile_detail_policy}
                  resizeMode="contain"
                />
                <Text style={styles.listTitle}>
                  {t('profileDetail.policy')}
                </Text>
              </View>
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Text style={[styles.listTitle, Gutters.miniRMargin]} />
                <Image
                  style={styles.listIcon}
                  source={Images.profile_rightArrowIcon}
                  resizeMode="contain"
                />
              </View>
            </TouchableOpacity>

            {/* Release Notes */}
            <View
              style={[
                Gutters.regularHPadding,
                styles.listItem,
                Gutters.regularBMargin,
              ]}
            >
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Image
                  style={[styles.listIcon, Gutters.smallRMargin]}
                  source={Images.profile_detail_releaseNote}
                  resizeMode="contain"
                />
                <Text style={styles.listTitle}>
                  {t('profileDetail.releaseNote')}
                </Text>
              </View>
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Text
                  style={[
                    Fonts.weight500,
                    Fonts.size14,
                    { color: Colors.fontText.light.primary3 },
                  ]}
                >
                  {VERSION}
                </Text>
              </View>
            </View>

            {/* 登出按鈕 */}
            {getMeData && (
              <TouchableOpacity
                style={[Gutters.regularHPadding, styles.logoutItem]}
                onPress={handleLogout}
              >
                <Text
                  style={[
                    Fonts.textCenter,
                    Fonts.weight500,
                    Fonts.size14,
                    { color: Colors.snackbar.error },
                  ]}
                >
                  {t('profileDetail.logout')}
                </Text>
              </TouchableOpacity>
            )}

            {/* 刪除帳號按鈕 */}
            {getMeData && (
              <TouchableOpacity
                style={[Gutters.regularHPadding, Gutters.largeTMargin]}
                onPress={openConfirmDeleteDialog}
              >
                <Text
                  style={[
                    Fonts.textCenter,
                    Fonts.weight500,
                    Fonts.size16,
                    { color: Colors.snackbar.error },
                  ]}
                >
                  {'刪除帳號'}
                </Text>
              </TouchableOpacity>
            )}
          </View>
        </FadeInView>
      </ScrollView>
      <DownLoadSign />
    </View>
  )
}

const styles = StyleSheet.create({
  listItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
    height: 73,
    backgroundColor: Colors.background.surface,
  },
  logoutItem: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 8,
    height: 54,
    backgroundColor: Colors.background.surface,
  },
  listIcon: {
    width: 24,
    height: 24,
  },
  listTitle: {
    color: Colors.white,
    fontWeight: '500',
  },
  arrowLeftIcon: {
    width: 24,
    height: 24,
    paddingLeft: 50,
  },
})

export default ProfileDetailContainer
