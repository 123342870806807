import {
  GetFollowingNotesResponse,
  GetLatestNotesResponse,
  GetUserNotesResponse,
} from '@/Services/modules/note/note.type'
import {
  GetMySpiritsResponse,
  GetUploadSpiritsResponse,
  GetUserWishlistDataResponse,
  GetWishlistResponse,
} from '@/Services/modules/spirits/spirits.type'
import { GetRecommendationFeedsResponse } from '@/Services/modules/users/user.type'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ReviewDetailState {
  rating: number | null
  content: string | null
  flavors: string[] | null
  tastingVolume: string | null
  location: string | null
  price: string | null
  glass: string | null
  imagePath: string | null
  imageFile: File | null
  pairing: string[] | null
  bottledAge: string | null
  batchNumber: string | null
  purchasePlace: string | null
}

const InitSpiritData = {
  rating: null,
  content: null,
  flavors: null,
  tastingVolume: null,
  location: null,
  price: null,
  glass: null,
  imagePath: null,
  imageFile: null,
  pairing: null,
  bottledAge: null,
  batchNumber: null,
  purchasePlace: null,
}

const InitProfileUserWishlistListData = {
  userId: '',
  data: [],
  meta: {
    perPage: 10,
    totalRows: 0,
    totalPages: 0,
    currentPage: 0,
  },
}

export type SpiritState = {
  spirit: ReviewDetailState
  // workaround for first time rating will not update the rate immediately
  addRatingLoading: boolean
  deleteMySpiritId: string
  mySpiritList: GetMySpiritsResponse
  wishlist: GetWishlistResponse
  searchList: GetMySpiritsResponse & { requestId: string }
  commentsDraft: {
    [key: string]: string
  }
  // 風味與風味搜尋頁面的資料
  flavors: string[]
  // 使用者頁面的 notes 列表
  profileUserNoteList: GetUserNotesResponse & { userId: string }
  // 使用者頁面的 wishlist 列表
  profileUserWishlistList: GetUserWishlistDataResponse & { userId: string }
  // UGC 上傳酒資料頁面資料
  spiritReviewList: {
    pending: GetUploadSpiritsResponse
    approved: GetUploadSpiritsResponse
    rejected: GetUploadSpiritsResponse
  }
  // 首頁下方的 post feeds 資料
  homePage: {
    followingNotes: GetFollowingNotesResponse
    popular_notes: GetRecommendationFeedsResponse
    popular_spirits: GetRecommendationFeedsResponse
    latest_notes: GetLatestNotesResponse
  }
}

const initialState = {
  spirit: InitSpiritData,
  // workaround for first time rating will not update the rate immediately
  addRatingLoading: false,
  deleteMySpiritId: '',
  mySpiritList: {
    data: [],
    meta: {
      perPage: 10,
    },
  },
  wishlist: {
    data: [],
    meta: {
      perPage: 10,
    },
  },
  searchList: {
    data: [],
    meta: {
      perPage: 10,
    },
    requestId: '',
  },
  commentsDraft: {},
  flavors: [],
  profileUserNoteList: {
    userId: '',
    data: [],
    meta: {
      perPage: 10,
      nextCursor: '',
    },
  },
  profileUserWishlistList: InitProfileUserWishlistListData,
  spiritReviewList: {
    pending: {
      data: [],
      meta: {
        perPage: 10,
        nextCursor: '',
      },
    },
    approved: {
      data: [],
      meta: {
        perPage: 10,
        nextCursor: '',
      },
    },
    rejected: {
      data: [],
      meta: {
        perPage: 10,
        nextCursor: '',
      },
    },
  },
  homePage: {
    followingNotes: {
      data: [],
      meta: {
        perPage: 10,
        page: 1,
        totalPage: 0,
      },
    },
    popular_spirits: {
      data: [],
      meta: {
        perPage: 10,
        nextCursor: '',
      },
      x_feed_version: '',
    },
    popular_notes: {
      data: [],
      meta: {
        perPage: 10,
        nextCursor: '',
      },
      x_feed_version: '',
    },
    latest_notes: {
      data: [],
      meta: {
        perPage: 10,
        nextCursor: '',
      },
    },
  },
}

// 將列表的資料從 api 拿回來後放入 store
const setListData = (
  state: any,
  action: PayloadAction<
    GetMySpiritsResponse | GetUserNotesResponse | GetUserWishlistDataResponse
  >,
  property: string,
) => {
  // 如果已經有資料的話，接續原先資料，如沒有則直接放入
  if (state[property].data) {
    // 判斷如果有重複資料就不要放進去
    const dataIdSet = new Set()
    state[property].data.forEach((item: any) => dataIdSet.add(item.id))
    const firstNewItemId = action.payload.data[0]?.id

    if (!dataIdSet.has(firstNewItemId)) {
      const newData = state[property]?.data.concat(action.payload.data)
      state[property].data = newData
      state[property].meta = action.payload.meta
    }
  } else {
    state[property] = action.payload
  }
}

const slice = createSlice({
  name: 'spirit',
  initialState: initialState as SpiritState,
  reducers: {
    resetSpiritStore: () => initialState,
    setSpiritData: (
      state,
      action: PayloadAction<Partial<ReviewDetailState>>,
    ) => {
      state.spirit = {
        ...state.spirit,
        ...action.payload,
      }
    },
    clearSpiritData: state => {
      state.spirit = InitSpiritData
    },
    // workaround for first time rating will not update the rate immediately
    setAddRateLoading: (state, action: PayloadAction<boolean>) => {
      state.addRatingLoading = action.payload
    },
    // 取得刪除的 my spirit id ，將 my spirit list 重新渲染
    setDeleteMySpiritId: (state, action: PayloadAction<string>) => {
      state.deleteMySpiritId = action.payload
    },
    // 紀錄刪除的 my spirit id ，將 my spirit list 重新渲染
    clearMySpiritId: state => {
      state.deleteMySpiritId = ''
    },
    // 從 api 取得 my spirit data
    setMySpiritList: (state, action: PayloadAction<GetMySpiritsResponse>) => {
      setListData(state, action, 'mySpiritList')
    },
    deleteMySpirit: (state, action: PayloadAction<string | number>) => {
      const newSpiritData = state.mySpiritList.data.filter(
        item => item.id !== action.payload,
      )
      state.mySpiritList.data = newSpiritData
    },
    // 清除 my spirit list
    clearMySpiritList: state => {
      state.mySpiritList = {
        data: [],
        meta: {
          perPage: 10,
        },
      }
    },
    // 更新 my spirit list 的資料
    updateMySpirit: (state, action) => {
      const { content, rating, flavors, id } = action.payload
      state.mySpiritList.data.forEach(item => {
        if (item.spirit.id === id) {
          item.content = content
          item.rating = Number(rating)
          item.flavors = flavors
        }
      })
    },
    updateMySpiritCollection: (
      state,
      action: PayloadAction<string | number>,
    ) => {
      const id = action.payload
      state.mySpiritList.data.forEach(item => {
        if (item.spirit.id === id) {
          item.spirit.collected = !item.spirit.collected
        }
      })
    },
    // 從 api 取得 wishlist data
    setWishlist: (state, action: PayloadAction<GetMySpiritsResponse>) => {
      setListData(state, action, 'wishlist')
    },
    clearWishlist: state => {
      state.wishlist = {
        data: [],
        meta: {
          perPage: 10,
        },
      }
    },
    updateWishlistCollection: (
      state,
      action: PayloadAction<string | number>,
    ) => {
      const id = action.payload
      state.wishlist.data.forEach(item => {
        if (item.id === id) {
          item.collected = !item.collected
        }
      })
    },
    removeWishlistUnCollected: state => {
      const newWishlistData = state.wishlist.data.filter(item => item.collected)
      state.wishlist.data = newWishlistData
    },
    // 從 api 取得 search list data
    setSearchList: (
      state,
      action: PayloadAction<GetMySpiritsResponse & { requestId: string }>,
    ) => {
      setListData(state, action, 'searchList')
      state.searchList.requestId = action.payload.requestId
    },
    clearSearchList: state => {
      state.searchList = {
        data: [],
        meta: {
          perPage: 10,
        },
        requestId: '',
      }
    },
    // 儲存該留言的草稿
    setCommentsDraft: (
      state,
      action: PayloadAction<{ id: string; comment: string }>,
    ) => {
      const { id, comment } = action.payload
      // 有內容儲存草稿，沒有則刪除
      if (comment?.length) {
        state.commentsDraft[id] = comment
      } else {
        delete state.commentsDraft[id]
      }
    },
    // 風味與風味搜尋頁面的資料
    setFlavors: (state, action: PayloadAction<string[]>) => {
      state.flavors = action.payload
    },
    // 使用者頁面的 notes 列表
    setProfileUserNoteList: (
      state,
      action: PayloadAction<GetUserNotesResponse & { userId: string }>,
    ) => {
      // 如果切換使用者先清空
      if (action.payload.userId !== state.profileUserNoteList.userId) {
        state.profileUserNoteList = {
          userId: '',
          data: [],
          meta: {
            perPage: 10,
            nextCursor: '',
          },
        }
      }
      setListData(state, action, 'profileUserNoteList')
      state.profileUserNoteList.userId = action.payload.userId
    },
    // 更新使用者頁面 note 列表收藏
    updateProfileUserNoteList: (
      state,
      action: PayloadAction<string | number>,
    ) => {
      const id = action.payload
      state.profileUserNoteList.data.forEach(item => {
        if (item?.spirit.id === id) {
          item.spirit.collected = !item.spirit.collected
        }
      })
    },
    // 刪除使用者頁面 note 列表中的資料
    deleteProfileUserNoteList: (
      state,
      action: PayloadAction<string | number>,
    ) => {
      const id = action.payload
      const newNoteData = state.profileUserNoteList.data.filter(
        item => item.spirit.id !== id,
      )
      state.profileUserNoteList.data = newNoteData
    },
    // 使用者頁面的 wishlist 列表
    setProfileUserWishlistList: (
      state,
      action: PayloadAction<GetUserWishlistDataResponse & { userId: string }>,
    ) => {
      // 如果切換使用者先清空
      if (action.payload.userId !== state.profileUserWishlistList.userId) {
        state.profileUserWishlistList = InitProfileUserWishlistListData
      }
      setListData(state, action, 'profileUserWishlistList')
      state.profileUserWishlistList.userId = action.payload.userId
    },
    // 更新使用者頁面 wishlist 列表收藏
    updateProfileUserWishlistList: (
      state,
      action: PayloadAction<string | number>,
    ) => {
      const id = action.payload
      state.profileUserWishlistList.data.forEach(item => {
        if (item?.id === id) {
          item.collected = !item.collected
        }
      })
    },
    // 使用者頁面 wishlist 列表更新沒有收藏的資料
    removeProfileUserWishlistUnCollected: state => {
      const newWishlistData = state.profileUserWishlistList.data.filter(
        item => item.collected,
      )
      state.profileUserWishlistList.data = newWishlistData
    },
    // 使用者頁面 wishlist 清空列表 防止資料重複
    clearProfileUserWishlistList: state => {
      state.profileUserWishlistList = InitProfileUserWishlistListData
    },
    // UGC 上傳酒資料頁面資料
    setSpiritReviewList: (
      state,
      action: PayloadAction<{ status: string; data: GetUploadSpiritsResponse }>,
    ) => {
      const { status, data } = action.payload
      if (status === 'pending') {
        state.spiritReviewList.pending.data =
          state.spiritReviewList.pending.data?.concat(...data.data)
        state.spiritReviewList.pending.meta = data.meta
      }
      if (status === 'approved') {
        state.spiritReviewList.approved.data =
          state.spiritReviewList.approved.data?.concat(...data.data)
        state.spiritReviewList.approved.meta = data.meta
      }
      if (status === 'rejected') {
        state.spiritReviewList.rejected.data =
          state.spiritReviewList.rejected.data?.concat(...data.data)
        state.spiritReviewList.rejected.meta = data.meta
      }
    },
    // UGC 上傳酒資料頁面資料清空
    clearSpiritReviewList: state => {
      state.spiritReviewList = {
        pending: {
          data: [],
          meta: {
            perPage: 10,
            nextCursor: '',
          },
        },
        approved: {
          data: [],
          meta: {
            perPage: 10,
            nextCursor: '',
          },
        },
        rejected: {
          data: [],
          meta: {
            perPage: 10,
            nextCursor: '',
          },
        },
      }
    },
    // 將首頁下方 post feed 資料放入 store
    setHomePagePostFeedsData: (
      state,
      action: PayloadAction<{
        type: string
        data:
        | GetFollowingNotesResponse
        | GetRecommendationFeedsResponse
        | GetLatestNotesResponse
      }>,
    ) => {
      const { type, data } = action.payload
      // @ts-ignore
      state.homePage[type as keyof typeof state.homePage].data = state.homePage[
        type as keyof typeof state.homePage
      ].data.concat(...data.data)
      state.homePage[type as keyof typeof state.homePage].meta = data.meta
    },
    // 將首頁下方 post feed 資料清空
    clearHomePagePostFeedsData: state => {
      state.homePage = initialState.homePage
    },
    // 首頁下方 post feed 資料按讚變化
    setHomePagePostFeedsLike: (
      state,
      action: PayloadAction<{
        feeds:
        | 'followingNotes'
        | 'popular_notes'
        | 'popular_spirits'
        | 'latest_notes'
        id: string
      }>,
    ) => {
      const { feeds, id } = action.payload
      if (feeds === 'followingNotes' || feeds === 'latest_notes') {
        state.homePage[feeds].data.forEach(item => {
          if (item.id === id) {
            item.counters.likers = item?.likeClicked
              ? item.counters.likers - 1
              : item.counters.likers + 1
            item.likeClicked = !item.likeClicked
          }
        })
      }
      if (feeds === 'popular_notes' || feeds === 'popular_spirits') {
        state.homePage[feeds].data.forEach(item => {
          if (item.note.id === id) {
            item.note.counters.likers = item?.note.likeClicked
              ? item.note.counters.likers - 1
              : item.note.counters.likers + 1
            item.note.likeClicked = !item.note.likeClicked
          }
        })
      }
    },
  },
})

export const spiritReducer = slice.reducer
export const spiritActions = slice.actions

export default slice.reducer
