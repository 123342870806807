const isEmail = (email: string) => {
  const reg = /^\S+@\S+\.\S+$/
  return reg.test(email)
}

const isConfirmPassword = (password: string, confirmPassword: string) => {
  return password === confirmPassword
}

const passwordFormat = (value: string) => {
  // 需超過 8 個字元，且包含英文與數字
  const regex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/
  return regex?.test(value)
}

const signUpPasswordRegex = (value: string) => {
  // 至少八個字元，且包含 1.至少八碼 2.大寫英文 3.小寫英文 4.數字
  const isLongEnough = value.length >= 8
  const hasUpperCase = /[A-Z]/.test(value)
  const hasLowerCase = /[a-z]/.test(value)
  const hasNumber = /[0-9]/.test(value)

  const isValidPassword =
    isLongEnough && hasUpperCase && hasLowerCase && hasNumber

  return isValidPassword
}

export const Validation = {
  isEmail,
  isConfirmPassword,
  passwordFormat,
  signUpPasswordRegex,
}
