import { useTheme } from '@/Hooks'

const useGlass = () => {
  const { Images } = useTheme()
  // 酒杯圖 map
  const renderGlasses = {
    'brandy-glass': Images.glass_brandy_glass,
    'champagne-flute': Images.glass_champagne_flute,
    'champagne-glass': Images.glass_champagne_glass,
    'collins-glass': Images.glass_collins_glass,
    'glencairn-glass': Images.glass_glencairn_glass,
    'highball-glass': Images.glass_highball_glass,
    'martini-glass': Images.glass_martini_glass,
    'old-fashioned-glass': Images.glass_old_fashioned_glass,
    'shot-glass': Images.glass_shot_glass,
    'tulip-shaped-glass': Images.glass_tulip_shaped_glass,
    'iso-glass': Images.glass_iso_glass,
    'blenders-glass': Images.glass_blenders_glass,
    'white-wine-glass': Images.glass_white_wine_glass,
    'red-wine-glass': Images.glass_red_wine_glass,
    'fish-tank-glass': Images.glass_fish_tank_glass,
  }

  return renderGlasses
}

export default useGlass
