import React, { useCallback } from 'react'
import { FlatList, StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'

import { useFlavorData, useTheme } from '@/Hooks'
import { capitalizeFirstLetter } from '@/Util/global'
import { Colors } from '@/Theme/Variables'

interface Props {
  flavors: string[]
}

const FlavorItemWithTopRank = ({ flavors }: Props) => {
  const { t } = useTranslation()
  const { Layout, Gutters, Fonts } = useTheme()
  const styles = getStyle()

  const {
    handleGetFlavorColor,
    handleGetFlavorParent,
    isSuccess: flavorIsSuccess,
  } = useFlavorData()

  const handelRenderFlavors = useCallback(
    (flavorsItem: any) => {
      const color = handleGetFlavorColor(flavorsItem.item.slug)
      const parent = handleGetFlavorParent(flavorsItem.item.slug)
      const firstItem = flavorsItem.index === 0

      return (
        <View style={[Layout.fullWidth, Gutters.regularTMargin]}>
          {firstItem && (
            <View style={[styles.topFlavorContainer]}>
              <Text
                style={[
                  Fonts.size12,
                  Fonts.weight400,
                  { color: Colors.primary },
                ]}
              >
                TOP1
              </Text>
            </View>
          )}
          <View
            style={[
              styles.flavorsItem,
              firstItem ? styles.flavorsItemFirstRadius : {},
            ]}
          >
            <View
              style={[
                styles.flavorDot,
                Gutters.tinyRMargin,
                { backgroundColor: color },
              ]}
            />
            <View>
              <Text
                style={[
                  Fonts.size12,
                  Fonts.weight700,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                {capitalizeFirstLetter(t(`${parent.i18n}`))}
              </Text>
              <Text
                style={[
                  Fonts.size12,
                  Fonts.weight400,
                  { color: Colors.fontText.light.primary3 },
                ]}
              >
                {capitalizeFirstLetter(
                  t(`flavor-wheel:${flavorsItem.item.slug}`),
                )}
              </Text>
            </View>
          </View>
        </View>
      )
    },
    [
      handleGetFlavorColor,
      handleGetFlavorParent,
      Layout,
      Gutters,
      styles,
      Fonts,
      t,
    ],
  )

  if (!flavorIsSuccess) return <></>

  return (
    <View>
      {flavorIsSuccess && flavors.length > 0 && (
        <FlatList
          data={flavors}
          renderItem={handelRenderFlavors}
          keyExtractor={flavorsItem => flavorsItem}
          horizontal
          style={[Layout.fullWidth, Layout.row, Layout.flexWrap]}
          showsHorizontalScrollIndicator={false}
        />
      )}
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    flavorsItem: {
      backgroundColor: Colors.darkLight,
      paddingVertical: 4,
      paddingHorizontal: 8,
      textAlign: 'left',
      borderRadius: 32,
      marginRight: 10,
      marginBottom: 8,
      width: 'fit-content',
      minWidth: 90,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    flavorsItemFirstRadius: {
      borderTopLeftRadius: 0,
    },
    topFlavorContainer: {
      backgroundColor: Colors.darkLight,
      borderTopRightRadius: 8,
      borderTopLeftRadius: 8,
      paddingHorizontal: 8,
      paddingVertical: 4,
      position: 'absolute',
      top: -20,
      left: 0,
      zIndex: 10,
    },
    flavorDot: {
      width: 8,
      height: 8,
      borderRadius: 4,
    },
  })

export default FlavorItemWithTopRank
