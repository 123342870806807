// 如果中英文資料沒對起來，先隱藏且 log 提示
export const checkDataAndWaring = (
  zhName: string,
  i18nKey: string,
  type: string,
) => {
  if (!zhName || !i18nKey) {
    console.log(` ${type} 的 ${zhName} 與 ${i18nKey} 資料沒對起來`)
    return false
  }
  return true
}
