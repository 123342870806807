import * as React from 'react'
import { Image, ImageStyle, View, ViewStyle } from 'react-native'
import { Modal } from 'react-native-paper'

export interface ImageLightBoxProps {
  visible: boolean
  imagePath: string
  imageStyle?: ImageStyle
  containerStyle?: ViewStyle
  modalStyle?: ViewStyle
  resizeMode?: 'cover' | 'contain' | 'stretch' | 'repeat' | 'center'
  hideModal: () => void
}

const ImageLightBox = ({
  visible,
  imagePath,
  imageStyle,
  containerStyle,
  modalStyle,
  resizeMode,
  hideModal,
}: ImageLightBoxProps) => {
  return (
    <Modal visible={visible} onDismiss={hideModal} style={modalStyle}>
      <View style={{ backgroundColor: '#fff', ...containerStyle }}>
        <Image
          // @ts-ignore
          source={imagePath}
          style={{
            ...imageStyle,
          }}
          resizeMode={resizeMode}
        />
      </View>
    </Modal>
  )
}

ImageLightBox.defaultProps = {
  imageStyle: {},
  containerStyle: {},
  modalStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  resizeMode: 'contain',
}

export default ImageLightBox
