import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import {
  ScanLoadingContainer,
  CommentModal,
  RatingAndCommentContainer,
  ReviewDetail,
  FlavorContainer,
  FlavorSearchContainer,
  PairingContainer,
  MySpiritContainer,
  WishlistContainer,
  InviteFriendContainer,
  InviteFriendListContainer,
  ScanResultContainer,
  HistoryScreen,
  GlassContainer,
  EditProfileContainer,
  AchievementContainer,
  UploadSpiritsContainer,
  UploadSpiritsDetailContainer,
  UploadSpiritsInfoContainer,
  ReportSpiritContainer,
  AIChatContainer,
  AIChatRoomContainer,
} from '@/Containers'

const Stack = createStackNavigator()

export enum AuthPagesEnum {
  ScanLoadingContainer = 'ScanLoadingContainer',
  CommentModal = 'CommentModal',
  RatingAndCommentContainer = 'RatingAndCommentContainer',
  ReviewDetail = 'ReviewDetail',
  Flavor = 'Flavor',
  FlavorSearch = 'FlavorSearch',
  Pairing = 'Pairing',
  MySpirit = 'MySpirit',
  Wishlist = 'Wishlist',
  InviteFriend = 'InviteFriend',
  InviteFriendList = 'InviteFriendList',
  ScanResultContainer = 'ScanResultContainer',
  HistoryScreen = 'HistoryScreen',
  Glass = 'Glass',
  EditProfileContainer = 'EditProfileContainer',
  AchievementContainer = 'AchievementContainer',
  UploadSpiritsContainer = 'UploadSpiritsContainer',
  UploadSpiritsInfoContainer = 'UploadSpiritsInfoContainer',
  UploadSpiritsDetailContainer = 'UploadSpiritsDetailContainer',
  ReportSpiritContainer = 'ReportSpiritContainer',
  AIChatContainer = 'AIChatContainer',
  AIChatRoomContainer = 'AIChatRoomContainer',
}

// 需要登入才能進去的頁面
export const withAuthPages = () => {
  return (
    <>
      <Stack.Screen
        name={AuthPagesEnum.ScanLoadingContainer}
        component={ScanLoadingContainer}
        options={{
          headerShown: false,
          title: '掃描辨識 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
      <Stack.Screen
        name={AuthPagesEnum.RatingAndCommentContainer}
        component={RatingAndCommentContainer}
        options={{
          headerShown: false,
          presentation: 'transparentModal',
          cardOverlayEnabled: true,
          title: '評分 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
      <Stack.Screen
        name={AuthPagesEnum.CommentModal}
        component={CommentModal}
        options={{
          headerShown: false,
          title: '文字筆記 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
      <Stack.Screen
        name={AuthPagesEnum.ReviewDetail}
        component={ReviewDetail}
        options={{
          headerShown: false,
          title: '評分詳情 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
      <Stack.Screen
        name={AuthPagesEnum.Flavor}
        component={FlavorContainer}
        options={{
          headerShown: false,
          title: '風味標記 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
      <Stack.Screen
        name={AuthPagesEnum.FlavorSearch}
        component={FlavorSearchContainer}
        options={{
          headerShown: false,
          title: '風味標記 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
      <Stack.Screen
        name={AuthPagesEnum.Pairing}
        component={PairingContainer}
        options={{
          headerShown: false,
          title: '餐飲搭配 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
      <Stack.Screen
        name={AuthPagesEnum.Glass}
        component={GlassContainer}
        options={{
          headerShown: false,
          title: '品飲容器 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
      <Stack.Screen
        name={AuthPagesEnum.ScanResultContainer}
        component={ScanResultContainer}
        options={{
          headerShown: false,
          title: '掃描辨識 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
      <Stack.Screen
        name={AuthPagesEnum.HistoryScreen}
        component={HistoryScreen}
        options={{
          headerShown: false,
          title: '辨識紀錄 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
      <Stack.Screen
        name={AuthPagesEnum.MySpirit}
        component={MySpiritContainer}
        options={{
          headerShown: false,
          title: '我的酒清單 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
      <Stack.Screen
        name={AuthPagesEnum.Wishlist}
        component={WishlistContainer}
        options={{
          headerShown: false,
          title: '願望清單 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
      <Stack.Screen
        name={AuthPagesEnum.InviteFriend}
        component={InviteFriendContainer}
        options={{
          headerShown: false,
          title: '邀請好友 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
      <Stack.Screen
        name={AuthPagesEnum.InviteFriendList}
        component={InviteFriendListContainer}
        options={{
          headerShown: false,
          title: '邀請好友 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
      <Stack.Screen
        name={AuthPagesEnum.EditProfileContainer}
        component={EditProfileContainer}
        options={{
          headerShown: false,
          title: '編輯個人檔案 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
      <Stack.Screen
        name={AuthPagesEnum.AchievementContainer}
        component={AchievementContainer}
        options={{
          headerShown: false,
          title: '成就徽章 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
      <Stack.Screen
        name={AuthPagesEnum.UploadSpiritsContainer}
        component={UploadSpiritsContainer}
        options={{
          headerShown: false,
          title: '協助新增紀錄 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
      <Stack.Screen
        name={AuthPagesEnum.UploadSpiritsInfoContainer}
        component={UploadSpiritsInfoContainer}
        options={{
          headerShown: false,
          title: '協助新增紀錄 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
      <Stack.Screen
        name={AuthPagesEnum.UploadSpiritsDetailContainer}
        component={UploadSpiritsDetailContainer}
        options={{
          headerShown: false,
          title: '新增的酒資料 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
      <Stack.Screen
        name={AuthPagesEnum.ReportSpiritContainer}
        component={ReportSpiritContainer}
        options={{
          headerShown: false,
          title: '回報 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
      <Stack.Screen
        name={AuthPagesEnum.AIChatContainer}
        component={AIChatContainer}
        options={{
          headerShown: false,
          title: '烈酒答人 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
      <Stack.Screen
        name={AuthPagesEnum.AIChatRoomContainer}
        component={AIChatRoomContainer}
        options={{
          headerShown: false,
          title: '烈酒答人 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
    </>
  )
}
