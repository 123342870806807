import 'react-native-gesture-handler'
import React, {
  useCallback,
  useMemo,
  useLayoutEffect,
  useState,
  Suspense,
  useEffect,
} from 'react'
import { useFonts } from 'expo-font'
import { Provider } from 'react-redux'
import { Dimensions, View } from 'react-native'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { store, persistor } from '@/Store'
import ApplicationNavigator from '@/Navigators/Application'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { getConfig } from '@/Util/global'
import { ActivityIndicator } from 'react-native-paper'
import './Translations'
import { LinearGradient } from 'expo-linear-gradient'
import { useWindowSize } from '@/Hooks'
import { Colors } from './Theme/Variables'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import ReactGA from 'react-ga4'
// import VConsole from 'vconsole'

serviceWorkerRegistration.register()

const App = () => {
  // 如果需要手機 debug 再開
  // const vConsole = new VConsole()
  const windowSize = useWindowSize()
  const [fromServer, setFromServer] = useState<boolean>(false)

  // 將需要載入的字體放這邊
  const [fontsLoaded] = useFonts({
    Poppins: require('@/Assets/Fonts/Poppins-Medium.ttf'),
  })

  // 判斷是不是 prerender
  useLayoutEffect(() => {
    // @ts-ignore
    if (navigator.userAgent.includes('prerender')) {
      setFromServer(true)
    }
  }, [])

  const inEnvs = useCallback((envs: string[]) => {
    return envs.includes(getConfig().ENV)
  }, [])

  const renderLoading = () => {
    return (
      <LinearGradient
        colors={['#2D2D2D', '#000000']}
        end={{ x: 10, y: 10 }}
        style={[
          {
            width: window ? windowSize?.width : Dimensions.get('window').width,
            height: window
              ? windowSize?.height
              : Dimensions.get('window').height,
            position: 'absolute',
            top: 0,
            left: 0,
          },
        ]}
      >
        <ActivityIndicator size="large" color={Colors.primary} />
      </LinearGradient>
    )
  }

  const GA_Tag = useCallback(() => {
    ReactGA.initialize(getConfig().GA_CODE)
    // @ts-ignore
    if (window?.gtag) {
      // @ts-ignore
      window?.gtag('event', 'view_search_results')
    }

    // @ts-ignore
    const script = document.createElement('script')
    script.type = 'text/javascript'
    // 添加 GTM 內聯 script 避免 CSP nonce 問題（添加後面的 ?xxx 避免快取）
    script.src = `assets/script/GTM/gtm-init.js?${new Date().getTime()}`
    script.async = true
    // @ts-ignore
    document.head.appendChild(script)
  }, [])

  useEffect(() => {
    if (inEnvs(['gamma', 'production'])) {
      GA_Tag()
    }
  }, [GA_Tag, inEnvs])

  const GeneralDescription = useCallback(() => {
    return (
      <Helmet>
        <meta
          name="description"
          content="使用烈酒探索家探索新的烈酒、輕鬆入手各式酒款品飲筆記。即刻註冊烈酒探索家開啟您的品飲紀錄旅程！"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-wep-app-capable" content="yes" />
      </Helmet>
    )
  }, [])

  const LoadingScreen = useMemo(() => {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator size="large" color={Colors.primary} />
      </View>
    )
  }, [])

  if (!fontsLoaded) {
    return null
  }

  return (
    <Provider store={store}>
      <Suspense fallback={renderLoading()}>
        <HelmetProvider>
          {/* 通用的描述 */}
          {GeneralDescription()}
          {/* Clarity */}
          {/* {inEnvs(['gamma', 'production']) && Clarity_Tag()} */}
          {/**
           * PersistGate delays the rendering of the app's UI until the persisted state has been retrieved
           * and saved to redux.
           * The `loading` prop can be `null` or any react instance to show during loading (e.g. a splash screen),
           * for example `loading={<SplashScreen />}`.
           * @see https://github.com/rt2zz/redux-persist/blob/master/docs/PersistGate.md
           */}
          <PersistGate loading={LoadingScreen} persistor={persistor}>
            <ApplicationNavigator fromServer={fromServer} />
          </PersistGate>
        </HelmetProvider>
      </Suspense>
    </Provider>
  )
}

export default App
