import React, { useEffect, useRef } from 'react'
import { View, StyleSheet, Animated } from 'react-native'

interface TypingIndicatorProps {
  isTyping: boolean
}

const TypingIndicator: React.FC<TypingIndicatorProps> = ({ isTyping }) => {
  const fadeAnim = useRef(new Animated.Value(0)).current

  useEffect(() => {
    if (isTyping) {
      Animated.loop(
        Animated.sequence([
          Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 500,
            useNativeDriver: true,
          }),
          Animated.timing(fadeAnim, {
            toValue: 0.3,
            duration: 500,
            useNativeDriver: true,
          }),
        ]),
      ).start()
    } else {
      fadeAnim.setValue(0)
    }
  }, [isTyping, fadeAnim])

  if (!isTyping) return null

  return (
    <View style={styles.container}>
      <Animated.View style={[styles.dot, { opacity: fadeAnim }]} />
      <Animated.View
        style={[
          styles.dot,
          { opacity: fadeAnim, marginLeft: 4, marginRight: 4 },
        ]}
      />
      <Animated.View style={[styles.dot, { opacity: fadeAnim }]} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    backgroundColor: '#e0e0e0',
    borderRadius: 16,
    alignSelf: 'flex-start',
    marginLeft: 8,
    marginBottom: 8,
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: '#333',
  },
})

export default TypingIndicator
