import React, { useEffect, useRef } from 'react'
import { Animated, StyleSheet } from 'react-native'

interface FadeInDirectionViewProps {
  style: any
  children: any
  duration: number
  position: number
  direction: 'left' | 'right' | 'top' | 'bottom'
}

const FadeInDirectionView = (props: Partial<FadeInDirectionViewProps>) => {
  const fadeAnim = useRef(new Animated.Value(0)).current // 透明度初始值
  const positionAnim = useRef(
    new Animated.Value(props.position || -100),
  ).current // 位置初始值

  const generalStyleConfig = {
    ...styles.container,
    ...props.style,
    opacity: fadeAnim, // 將透明度指定為 fadeAnim
  }

  const styleConfig = {
    left: {
      ...generalStyleConfig,
      left: positionAnim, // 將 direction 指定為 positionAnim
    },
    right: {
      ...generalStyleConfig,
      right: positionAnim, // 將 direction 指定為 positionAnim
    },
    top: {
      ...generalStyleConfig,
      top: positionAnim, // 將 direction 指定為 positionAnim
    },
    bottom: {
      ...generalStyleConfig,
      bottom: positionAnim, // 將 direction 指定為 positionAnim
    },
  }

  useEffect(() => {
    Animated.parallel([
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: props.duration || 1000,
        useNativeDriver: true,
      }),
      Animated.timing(positionAnim, {
        toValue: 0,
        duration: props.duration || 1000,
        useNativeDriver: true,
      }),
    ]).start()
  }, [fadeAnim, positionAnim, props.duration])

  return (
    <Animated.View
      style={styleConfig[props.direction || 'top']} // 如果沒有指定位置，預設為 top
    >
      {props.children}
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  container: {},
})

export default FadeInDirectionView
