import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'

interface Props {
  progress: number
  text: string
  completeTextColor?: string
}

const ProgressBar = ({ progress, text, completeTextColor = '#fff' }: Props) => {
  const { Layout } = useTheme()

  const clampedProgress = Math.min(Math.max(progress, 0), 100)

  return (
    <View
      style={[
        Layout.row,
        Layout.alignItemsCenter,
        Layout.justifyContentBetween,
      ]}
    >
      <View style={styles.container}>
        <View style={[styles.progressBar, { width: `${clampedProgress}%` }]} />
      </View>
      <Text
        style={[
          styles.progressText,
          {
            color: progress === 100 ? completeTextColor : '#fff',
            fontWeight: progress === 100 ? '500' : '700',
          },
        ]}
      >
        {text}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '80%',
    height: 4,
    backgroundColor: '#323232',
    borderRadius: 10,
    position: 'relative',
    overflow: 'hidden',
  },
  progressBar: {
    height: '100%',
    backgroundColor: Colors.primary,
    position: 'absolute',
    left: 0,
    top: 0,
  },
  progressText: {
    color: 'white',
    textAlignVertical: 'center',
    paddingRight: 5,
  },
})

ProgressBar.defaultProps = {
  completeTextColor: '#fff',
}

export default ProgressBar
