const Colors = {
  primary: 'lightblue',
  text: 'white',
  inputBackground: 'gray',
}

const NavigationColors = {
  primary: Colors.primary,
}

export default {
  Colors,
  NavigationColors,
}
