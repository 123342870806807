import React from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { ProfileContainer, CameraContainer, HomeContainer } from '@/Containers'
import { Colors, Height } from '@/Theme/Variables'
import { Image, View, StyleSheet, Text, TouchableOpacity } from 'react-native'
import useTheme from '@/Hooks/useTheme'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import userApi from '@/Services/modules/users'
import { globalActions } from '@/Store/Global'
import { navigate } from '@/Navigators/utils'
import { PagesEnum } from '@/Navigators/Application'

export enum MainPagesEnum {
  Home = 'Home',
  Camera = 'Camera',
  Profile = 'Profile',
}

const Tab = createBottomTabNavigator()

// @refresh reset
const MainNavigator = () => {
  const { Images, Layout, Fonts, Gutters } = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { data: getMeData } = userApi.useGetMeQuery()

  const openLoginDialog = () => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        title: t('camera.bottomDialogTitle'),
        disabledBackgroundClose: true,
        content: (
          <View
            style={[
              Layout.center,
              Gutters.regularVMargin,
              Gutters.regularHPadding,
            ]}
          >
            <Image
              source={Images.bottom_dialog_login_leave}
              resizeMode="contain"
              style={[Gutters.regularBMargin, { width: 64, height: 64 }]}
            />
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.center,
                Gutters.smallBMargin,
                {
                  backgroundColor: Colors.primary,
                  paddingVertical: 9,
                  borderRadius: 8,
                  height: Height.height48,
                },
              ]}
              onPress={() => {
                dispatch(globalActions.closeBottomDialog())
                // @ts-ignore
                navigate(PagesEnum.LoginMainContainer)
              }}
            >
              <Text
                style={[
                  Fonts.size14,
                  Fonts.weight500,
                  { color: Colors.primaryText },
                ]}
              >
                {t('camera.bottomDialogButton1')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.center,
                Gutters.smallBMargin,
                {
                  height: Height.height48,
                },
              ]}
              onPress={() => {
                dispatch(globalActions.closeBottomDialog())
              }}
            >
              <Text style={{ color: Colors.white }}>
                {t('camera.bottomDialogButton2')}
              </Text>
            </TouchableOpacity>
          </View>
        ),
      }),
    )
  }

  // 需要登入才能進入的 tab
  const handleCheckIsLogin = (navigation: any, route: string) => {
    if (!getMeData) {
      openLoginDialog()
      return
    }
    navigation.navigate(route)
  }

  const renderTabIcon = (routeName: string, focused: boolean) => {
    let renderIcon
    switch (routeName) {
      case 'profile':
        renderIcon = focused
          ? Images.mainTabProfileActive
          : Images.mainTabProfile
        break
      case 'camera':
        renderIcon = Images.mainTabCamera
        break
      case 'home':
        renderIcon = focused ? Images.mainTabHomeActive : Images.mainTabHome
        break
      default:
        break
    }

    if (routeName === 'camera') {
      return (
        <View style={styles.cameraTabIcon}>
          <Image
            style={Layout.fullSize}
            source={renderIcon}
            resizeMode="contain"
          />
        </View>
      )
    }

    return (
      <View style={styles.tabIcon}>
        <Image
          style={Layout.fullSize}
          source={renderIcon}
          resizeMode="contain"
        />
      </View>
    )
  }

  // TODO: 到時候要換是不是 active 的 icon
  return (
    <Tab.Navigator
      screenOptions={() => {
        return {
          tabBarStyle: styles.tabBarStyle,
          headerStyle: { backgroundColor: Colors.dark },
          headerTintColor: Colors.white,
          tabBarLabelPosition: 'below-icon',
          tabBarActiveTintColor: Colors.white,
          tabBarInactiveTintColor: Colors.gray,
          // 避免切換 tab 後，返回上一頁 go back 時，會回到上一個 main tab
          // https://reactnavigation.org/docs/bottom-tab-navigator/#props
          backBehavior: 'order',
        }
      }}
    >
      <Tab.Screen
        name={MainPagesEnum.Home}
        component={HomeContainer}
        aria-label="Home"
        options={{
          tabBarLabel: t('mainTab.home'),
          tabBarIcon: ({ focused }) => renderTabIcon('home', focused),
          headerShown: false,
          title: '烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
      <Tab.Screen
        name={MainPagesEnum.Camera}
        component={CameraContainer}
        aria-label="Camera"
        options={{
          headerShown: false,
          tabBarStyle: { display: 'none' },
          tabBarLabelStyle: { display: 'none' },
          tabBarIcon: ({ focused }) => renderTabIcon('camera', focused),
          title: '掃描辨識 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
        listeners={({ navigation, route }) => ({
          tabPress: e => {
            e.preventDefault()
            handleCheckIsLogin(navigation, route.name)
          },
        })}
      />
      <Tab.Screen
        name={MainPagesEnum.Profile}
        component={ProfileContainer}
        aria-label="Profile"
        options={{
          tabBarLabel: t('mainTab.profile'),
          tabBarIcon: ({ focused }) => renderTabIcon('profile', focused),
          headerShown: false,
          title: '我的酒櫃 | 烈酒探索家 - 您的全方位烈酒品飲助理',
        }}
      />
    </Tab.Navigator>
  )
}

const styles = StyleSheet.create({
  tabBarStyle: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 70,
    backgroundColor: Colors.background.default,
    paddingVertical: 10,
    paddingHorizontal: 10,
    paddingBottom: 10,
    marginHorizontal: 'auto',
    borderTopColor: Colors.darkText,
  },
  tabIcon: {
    height: 32,
    width: 32,
  },
  cameraTabIcon: {
    boxSizing: 'border-box',
    height: 72,
    width: 72,
    paddingBottom: 5,
    marginBottom: 32,
  },
})

export default MainNavigator
