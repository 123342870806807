import { useEffect, useState } from 'react'

import noteApi from '@/Services/modules/note'
import { NestedSetType } from '@/Services/modules/note/note.type'
import spiritsApi from '@/Services/modules/spirits'

import { convertMetaData } from '../utils/convertMetaData'
import { checkDataAndWaring } from '../utils/checkDataAndWaring'

const countryEmojis: { [key: string]: string } = {
  scotland: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
  ireland: '🇮🇪',
  india: '🇮🇳',
  russia: '🇷🇺',
  england: '🇬🇧',
  barbados: '🇧🇧',
  jamaica: '🇯🇲',
  japan: '🇯🇵',
  canada: '🇨🇦',
  mexico: '🇲🇽',
  cuba: '🇨🇺',
  caribbean: '🇧🇶',
  france: '🇫🇷',
  'the-usa': '🇺🇸',
  taiwan: '🇹🇼',
  netherlands: '🇳🇱',
  'the-philippines': '🇵🇭',
  colombia: '🇨🇴',
  dominica: '🇩🇲',
  'cooperative-republic-of-guyana': '🇬🇾',
  germany: '🇩🇪',
  italy: '🇮🇹',
  spain: '🇪🇸',
  'czech-republic': '🇨🇿',
  guatemala: '🇬🇹',
  venezuela: '🇻🇪',
  sweden: '🇸🇪',
  poland: '🇵🇱',
  nicaragua: '🇳🇮',
  latvia: '🇱🇻',
  'republic-of-trinidad-and-tobago': '🇹🇹',
  panama: '🇵🇦',
  armenia: '🇦🇲',
  australia: '🇦🇺',
  austria: '🇦🇹',
  belgium: '🇧🇪',
  bhutan: '🇧🇹',
  brazil: '🇧🇷',
  china: '🇨🇳',
  denmark: '🇩🇰',
  'el-salvador': '🇸🇻',
  'hong-kong': '🇭🇰',
  hungary: '🇭🇺',
  israel: '🇮🇱',
  laos: '🇱🇦',
  malaysia: '🇲🇾',
  moldova: '🇲🇩',
  'new-zealand': '🇳🇿',
  norway: '🇳🇴',
  peru: '🇵🇪',
  'republic-of-estonia': '🇪🇪',
  'south-africa': '🇿🇦',
  'sri-lanka': '🇱🇰',
  'st-lucia': '🇱🇨',
  switzerland: '🇨🇭',
  vietnam: '🇻🇳',
}

export interface CountryDataItem {
  zhName: string
  enName: string
  count: number
  emoji: string
  i18nKey: string
}

const useGetCountriesData = () => {
  const [initialData, setInitialData] = useState<CountryDataItem[]>([])

  // 取得 meta 條件
  const {
    data: filterMetaData,
    isLoading: filterMetaDataIsLoading,
    isSuccess: filterMetaDataIsSuccess,
  } = spiritsApi.useGetSearchMetaQuery()

  // 取得國家資訊
  const {
    data: countryData,
    isLoading: countryDataIsLoading,
    isSuccess: countryDataIsSuccess,
  } = noteApi.useGetNestedSetQuery({
    type: NestedSetType.LOCATION,
  })

  // 整理初始化資料
  useEffect(() => {
    if (countryDataIsSuccess && filterMetaDataIsSuccess) {
      const countryArr: CountryDataItem[] = []
      const countryMeta = convertMetaData(filterMetaData, 'country')

      for (const country of countryMeta) {
        const zhName = countryData?.data?.roots?.[country.key] || ''
        const enName = country.key

        const isDataValid = checkDataAndWaring(zhName, country.key, 'country')

        if (isDataValid) {
          countryArr.push({
            zhName,
            enName,
            count: country.count,
            emoji: countryEmojis[country.key],
            i18nKey: country.key,
          })
        }
      }

      setInitialData(countryArr)
    }
  }, [
    countryData?.data?.roots,
    filterMetaData,
    filterMetaDataIsSuccess,
    countryDataIsSuccess,
    countryData,
  ])

  return {
    countriesData: initialData,
    filterMetaDataIsLoading,
    countryDataIsLoading,
  }
}

export default useGetCountriesData
