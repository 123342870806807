import React, { useCallback, useEffect, useState } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
  ScrollView,
} from 'react-native'
import { useTheme } from '@/Hooks'
import { TextInput } from 'react-native-paper'
import { FadeInView, Header } from '@/Components'
import { Colors } from '@/Theme/Variables'
import { userApi } from '@/Services/modules/users'
import { useDispatch } from 'react-redux'
import { navigate } from '@/Navigators/utils'
import { globalActions } from '@/Store/Global'
import Jssha from 'jssha'
import { Validation } from '@/Util/validation'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'
// import { useTranslation } from 'react-i18next'

const COUNT_DOWN = 125

interface RegisterType {
  account: string
  password: string
  confirmPassword: string
  code: string
}

const InitData = {
  account: '',
  password: '',
  confirmPassword: '',
  code: '',
}

const ForgotPasswordContainer = ({ navigation }: any) => {
  const dispatch = useDispatch()
  const { Images, Layout, Gutters, Fonts } = useTheme()
  // TODO: 等設計稿確定再改 i18n
  //   const { t } = useTranslation()

  const [
    getOTPCodeRequest,
    {
      isSuccess: OTPCodeIsSuccess,
      isError: OTPCodeIsError,
      error: OTPError,
      reset: OTPCodeReset,
    },
  ] = userApi.useGetOtpCodeMutation()

  const [
    resetPasswordRequest,
    {
      isSuccess: resetPasswordIsSuccess,
      isError: resetPasswordIsError,
      error: resetPasswordError,
      reset: resetPasswordReset,
    },
  ] = userApi.useResetPasswordMutation()

  const [changePasswordData, setChangePasswordData] =
    useState<RegisterType>(InitData)
  const [otpCounters, setOtpCounters] = useState(COUNT_DOWN)
  const [openPassword, setOpenPassword] = useState<boolean>(false)
  const [openConfirmPassword, setOpenConfirmPassword] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState(InitData)

  const styles = stylesSheet()

  // 送出 opt 後，開始倒數 125 秒 才能再次發送
  useEffect(() => {
    if (OTPCodeIsSuccess) {
      let countDown = COUNT_DOWN
      const timer = setInterval(() => {
        setOtpCounters(prev => prev - 1)
        countDown--
        if (countDown === 0) {
          clearInterval(timer)
          setOtpCounters(0)
        }
      }, 1000)

      dispatch(
        globalActions.openSnackbar({
          visible: true,
          message: '已成功寄出驗證碼，請至信箱收取',
          type: 'success',
          showIcon: false,
        }),
      )

      return () => clearInterval(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OTPCodeIsSuccess, OTPCodeReset, dispatch])

  // 檢查寄送驗證碼 api 是否有錯誤
  useEffect(() => {
    if (OTPCodeIsError) {
      const errorMessageData: any = OTPError
      const { message } = errorMessageData?.data?.errors?.[0]
      if (message === 'accountIsNotExisted') {
        setErrorMessage({
          ...errorMessage,
          account: '帳號尚未註冊',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OTPCodeIsError, OTPError])

  // 檢查重設密碼 api 是否有錯誤
  useEffect(() => {
    if (resetPasswordIsError) {
      const errorMessageData: any = resetPasswordError
      const { message } = errorMessageData?.data?.errors?.[0]
      if (
        message === 'otpCodeVerifyFailed' ||
        message === 'The code field format is invalid.'
      ) {
        setErrorMessage({
          ...errorMessage,
          code: '驗證碼錯誤',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OTPError, resetPasswordIsError])

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      setChangePasswordData(InitData)
      return
    }

    navigate(PagesEnum.Main, {
      screen: PagesEnum.EmailLoginContainer,
    })
    setChangePasswordData(InitData)
  }, [navigation])

  // 送出 OTP
  const handleSubmitOtpCode = () => {
    if (Validation.isEmail(changePasswordData?.account)) {
      getOTPCodeRequest({
        type: 'resetPassword',
        account: changePasswordData?.account,
      })
    } else {
      setErrorMessage({
        ...errorMessage,
        account: '電子郵件格式錯誤',
      })
    }
  }

  const handleSubmit = () => {
    if (!Validation.signUpPasswordRegex(changePasswordData?.password)) {
      setErrorMessage({
        ...errorMessage,
        password: '密碼必須至少 8 個字符，含一個數字，一個大小寫字母',
      })
      return
    }
    if (changePasswordData.password !== changePasswordData.confirmPassword) {
      setErrorMessage({
        ...errorMessage,
        confirmPassword: '密碼不一致',
      })
      return
    }

    const shaPassword = new Jssha('SHA3-512', 'TEXT')
    shaPassword.update(changePasswordData.password)

    resetPasswordRequest({
      account: changePasswordData?.account,
      password: shaPassword.getHash('HEX'),
      code: changePasswordData?.code,
    })
  }

  const handleBackToLogin = () => {
    navigation.navigate(PagesEnum.EmailLoginContainer)
    resetPasswordReset()
    setChangePasswordData(InitData)
  }

  const handleOnChangeInput = (type: string, value: string) => {
    setChangePasswordData({
      ...changePasswordData,
      [type]: value,
    })
    setErrorMessage({
      ...errorMessage,
      [type]: '',
    })
  }

  // 輸入資訊
  const renderKeyInfo = () => {
    return (
      <View style={[Gutters.smallTPadding]}>
        {/* 輸入資訊欄位 */}
        <FadeInView duration={550} style={Gutters.regularHPadding}>
          <View style={[Gutters.regularBMargin, Gutters.largeBMargin]}>
            <Text
              style={[
                Fonts.size20,
                Fonts.weight700,
                Gutters.smallVMargin,
                { color: Colors.fontText.light.primary1 },
              ]}
            >
              忘記密碼嗎？
            </Text>
            <Text
              style={[
                Fonts.size16,
                Fonts.weight400,
                Gutters.smallBMargin,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              請輸入您註冊時的電子郵件，我們將發送驗證碼至您已註冊的電子郵件
            </Text>
            <View style={[Gutters.tinyBMargin]}>
              <TextInput
                label="電子郵件地址"
                value={changePasswordData?.account}
                autoFocus={true}
                style={[{ color: Colors.white }, styles.textInput]}
                underlineColor="transparent"
                activeUnderlineColor="transparent"
                activeOutlineColor={Colors.primary}
                theme={{
                  colors: {
                    onSurfaceVariant: Colors.fontText.light.primary3,
                    error: Colors.snackbar.error,
                    outline: Colors.background.top,
                  },
                  roundness: 10,
                }}
                textColor="#fff"
                mode="outlined"
                error={!!errorMessage?.account}
                onChangeText={(text: string) => {
                  handleOnChangeInput('account', text)
                }}
              />
              {!!errorMessage.account && (
                <Text
                  style={[
                    Gutters.tinyTMargin,
                    {
                      color: Colors.snackbar.error,
                    },
                  ]}
                >
                  {errorMessage.account}
                </Text>
              )}
            </View>
            <View style={[Gutters.tinyBMargin]}>
              <TextInput
                label="輸入您的新密碼"
                value={changePasswordData?.password}
                secureTextEntry={!openPassword}
                style={[{ color: Colors.white }, styles.textInput]}
                underlineColor="transparent"
                activeUnderlineColor="transparent"
                activeOutlineColor={Colors.primary}
                theme={{
                  colors: {
                    onSurfaceVariant: Colors.fontText.light.primary3,
                    error: Colors.snackbar.error,
                    outline: Colors.background.top,
                  },
                  roundness: 10,
                }}
                textColor="#fff"
                mode="outlined"
                error={!!errorMessage?.password}
                onChangeText={value => handleOnChangeInput('password', value)}
                right={
                  <TextInput.Icon
                    icon={() => (
                      <TouchableOpacity
                        onPress={() => setOpenPassword(!openPassword)}
                      >
                        <Image
                          source={
                            openPassword ? Images.eye_open : Images.eye_off
                          }
                          resizeMode="contain"
                          style={[Layout.iconSize20, { top: 5 }]}
                        />
                      </TouchableOpacity>
                    )}
                  />
                }
              />
              {!!errorMessage?.password && (
                <View
                  style={[
                    Layout.row,
                    Layout.alignItemsCenter,
                    Gutters.tinyTPadding,
                  ]}
                >
                  <Image
                    source={Images.forgot_password_error_icon}
                    style={[Layout.iconSize16, Gutters.tinyBMargin]}
                    resizeMode="contain"
                  />
                  <Text
                    style={[
                      Gutters.tinyBMargin,
                      {
                        color: Colors.snackbar.error,
                      },
                    ]}
                  >
                    {errorMessage?.password}
                  </Text>
                </View>
              )}
            </View>
            <View style={[Gutters.tinyBMargin]}>
              <TextInput
                label="再次確認密碼"
                value={changePasswordData?.confirmPassword}
                secureTextEntry={!openConfirmPassword}
                style={[{ color: Colors.white }, styles.textInput]}
                underlineColor="transparent"
                activeUnderlineColor="transparent"
                activeOutlineColor={Colors.primary}
                theme={{
                  colors: {
                    onSurfaceVariant: Colors.fontText.light.primary3,
                    error: Colors.snackbar.error,
                    outline: Colors.background.top,
                  },
                  roundness: 10,
                }}
                textColor="#fff"
                mode="outlined"
                error={!!errorMessage?.confirmPassword}
                onChangeText={value =>
                  handleOnChangeInput('confirmPassword', value)
                }
                right={
                  <TextInput.Icon
                    icon={() => (
                      <TouchableOpacity
                        onPress={() =>
                          setOpenConfirmPassword(!openConfirmPassword)
                        }
                      >
                        <Image
                          source={
                            openConfirmPassword
                              ? Images.eye_open
                              : Images.eye_off
                          }
                          resizeMode="contain"
                          style={[Layout.iconSize20, { top: 5 }]}
                        />
                      </TouchableOpacity>
                    )}
                  />
                }
              />
            </View>
            {!!errorMessage?.confirmPassword && (
              <View style={[Layout.row, Layout.alignItemsCenter]}>
                <Image
                  source={Images.forgot_password_error_icon}
                  style={[Layout.iconSize16, Gutters.tinyBMargin]}
                  resizeMode="cover"
                />
                <Text
                  style={[
                    Gutters.tinyBMargin,
                    {
                      color: Colors.snackbar.error,
                    },
                  ]}
                >
                  {errorMessage?.confirmPassword}
                </Text>
              </View>
            )}
            <View style={[Gutters.tinyBMargin]}>
              <TextInput
                label="驗證碼"
                value={changePasswordData?.code}
                autoFocus={true}
                style={[{ color: Colors.white }, styles.textInput]}
                underlineColor="transparent"
                activeUnderlineColor="transparent"
                activeOutlineColor={Colors.primary}
                theme={{
                  colors: {
                    onSurfaceVariant: Colors.fontText.light.primary3,
                    error: Colors.snackbar.error,
                    outline: Colors.background.top,
                  },
                  roundness: 10,
                }}
                textColor="#fff"
                mode="outlined"
                error={!!errorMessage?.code}
                onChangeText={(text: string) => {
                  handleOnChangeInput('code', text)
                }}
              />
              {!!errorMessage.code && (
                <Text
                  style={[
                    Gutters.tinyVMargin,
                    {
                      color: Colors.snackbar.error,
                    },
                  ]}
                >
                  {errorMessage.code}
                </Text>
              )}
            </View>
            {OTPCodeIsSuccess ? (
              <View style={[Layout.row]}>
                <Text
                  style={[
                    Fonts.size14,
                    Fonts.weight400,
                    { color: Colors.fontText.light.primary2 },
                  ]}
                >
                  如果沒有收到驗證碼，請點此{' '}
                </Text>
                <TouchableOpacity
                  disabled={otpCounters !== 0}
                  onPress={() => handleSubmitOtpCode()}
                >
                  <Text
                    style={[
                      Fonts.size14,
                      Fonts.weight400,
                      { color: Colors.primary },
                    ]}
                  >
                    {`重新發送(${otpCounters})`}
                  </Text>
                </TouchableOpacity>
              </View>
            ) : (
              <TouchableOpacity onPress={() => handleSubmitOtpCode()}>
                <Text
                  style={[
                    Fonts.size14,
                    Fonts.weight400,
                    { color: Colors.primary },
                  ]}
                >
                  點擊取得驗證碼
                </Text>
              </TouchableOpacity>
            )}
          </View>
        </FadeInView>
      </View>
    )
  }

  // 寄送驗證碼 CTA Button
  const renderKeyInfoCTAButton = () => {
    return (
      <View style={[Gutters.regularHPadding, styles.ctaButtonContainer]}>
        <TouchableOpacity
          style={[Layout.rowCenter, styles.ctaButton]}
          onPress={handleSubmit}
        >
          <Text
            style={[
              Fonts.size16,
              Fonts.weight500,
              { color: Colors.fontText.dark.primary2 },
            ]}
          >
            確定
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  // 成功頁面
  const renderSendEmailSuccess = () => {
    return (
      <View
        style={[Gutters.largeTPadding, Layout.fullHeight, Layout.colHCenter]}
      >
        <FadeInView duration={550} style={Gutters.regularHPadding}>
          <View
            style={[
              Gutters.regularBMargin,
              Gutters.largeBMargin,
              Layout.colCenter,
            ]}
          >
            <Image
              source={Images.forgot_password_success}
              resizeMode="contain"
              style={[Layout.iconSize80, Gutters.smallBMargin]}
            />
            <Text
              style={[
                Fonts.size16,
                Fonts.weight700,
                Gutters.tinyBMargin,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              重設密碼成功
            </Text>
            <Text
              style={[
                Fonts.size14,
                Fonts.weight400,
                Gutters.regularBMargin,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              請使用新密碼來進行登入
            </Text>
            <TouchableOpacity
              style={[Layout.rowCenter, styles.ctaButton]}
              onPress={handleBackToLogin}
            >
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size16,
                  { color: Colors.fontText.dark.primary2 },
                ]}
              >
                返回登入
              </Text>
            </TouchableOpacity>
          </View>
        </FadeInView>
      </View>
    )
  }

  return (
    <View style={[Layout.fill, { backgroundColor: Colors.background.surface }]}>
      <ScrollView style={[Layout.fill]}>
        <Header
          title={'忘記密碼'}
          leftIcon={
            <Image
              style={[styles.arrowLeftIcon]}
              source={Images.arrowLeft}
              resizeMode="contain"
            />
          }
          leftIconPress={handleGoBack}
          rightEmptyIconWidth="50"
        />
        {resetPasswordIsSuccess ? renderSendEmailSuccess() : renderKeyInfo()}
      </ScrollView>
      {!resetPasswordIsSuccess && renderKeyInfoCTAButton()}
      <DownLoadSign />
    </View>
  )
}

const stylesSheet = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    textInput: {
      backgroundColor: '#1C1C20',
      height: 56,
    },
    ctaButtonContainer: {
      width: '100%',
      position: 'absolute',
      bottom: 50,
      left: 0,
    },
    ctaButton: {
      height: 48,
      borderRadius: 8,
      backgroundColor: Colors.primary,
      width: '100%',
    },
  })

export default ForgotPasswordContainer
