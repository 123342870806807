export interface ScanPicRequest {
  imageDataUri: string
}

export interface ScanPicResponse {
  data: [
    {
      id: string
      title: string
      brand: string
      vintage: string
      ratings: {
        average: number
        count: number
      }
      country: string
      imageUrl: string
      marketPrice: string
    },
  ]
}

export interface GetMySpiritsRequest {
  cursor: string
  perPage: number
  with: 'spirit' | 'note'
}

export interface GetMySpiritsResponse {
  data: Booze[]
  meta: Meta
}

export interface Booze {
  id: number | string
  scannedImageUrl: string
  spirit: {
    country: string
    id: number
    marketPrice: string
    ratings: {
      average: number
      count: number
    }
    title: string
    collected: boolean
    brand: string
    imageUrl?: string
    titleUrlSlug: string
  }
  content: string
  flavors: string[]
  rating: number
  createdAt: string
  updatedAt: string
  collected?: boolean
  image?: {
    url: string
  }
}

export interface Meta {
  perPage: number
  nextCursor?: null | string
  currentPage?: number
  totalPages?: number
  totalRows?: number
}

export interface GetMySingleSpiritResponse {
  id: number
  category: string
  country: string
  description: string
  imageUrl: string
  marketPrice: string
  notes: any[]
  ratings: {
    average: number
    count: number
  }
  flavors: {
    breakdown: {
      count: number
      slug: string
      children: {
        count: number
        slug: string
      }[]
    }[]
  }
  region: string
  strength: number
  title: string
  type: string
  volume: number
  brand: string
  facts: {
    bottler: string
  }
}

export interface PostMySpiritReviewRequest {
  id: string
  content: string
  flavors: string[]
  rating: string
  imagePath: string
  expertise: {
    tastingVolume: number
    price: number
    glass: string
    pairing: string[]
    bottledAge: number
    batchNumber: string
  }
}

export interface PostMySpiritReviewResponse {
  data: {
    id: string
    createdAt: string
    updatedAt: string
  }
}

export enum SpiritSort {
  RANK_SCORE = '-rank_score',
  PRICE_HIGH_TO_LOW = '-market_price_zh',
  PRICE_LOW_TO_HIGH = 'market_price_zh',
}

export interface GetSearchSpiritsRequest {
  query: string
  filters: any
  perPage: number
  page: number
  sorts: SpiritSort
}

export interface GetWishlistRequest {
  id: string
  page: number
  perPage: number
}

export interface GetWishlistResponse extends GetMySpiritsResponse { }

export interface PostAddOrRemoveWishlistRequest {
  id: string
  spiritId: string
}

export interface GetSpiritNotesResponse {
  data: {
    content: string
    flavors: string[]
    rating: number
    user: {
      id: number
      name: string
    }
  }
  meta: Meta
}

export interface GetSpiritNotesRequest {
  id: string
  cursor: string
  perPage: number
}

export interface PostMediaRequest {
  media: string
}

export interface PostMediaResponse {
  data: {
    path: string
    displayUrl: string
  }
}

export interface GetUserWishlistDataRequest {
  userId: string
  collectId: string
  perPage: number
  page: number
}

export interface GetUserWishlistDataResponse {
  data: {
    id: string
    title: string
    titleUrlSlug: string
    imageUrl: string
    brand: string
    ratings: {
      average: number
      count: number
    }
    collected: boolean
  }[]
  meta: {
    totalRows: number
    totalPages: number
    currentPage: number
    perPage: number
  }
}

export interface PostUploadSpiritsRequest {
  imagePath: {
    foreground: string
    background: string
  }
  historyId: string
  title: string
  category: string
  subcategory: string
  description: string
  brand: string
  country: string
  region: string
  marketPrice: number
  facts: {
    age: number
  }
}

export interface GetUploadSpiritsRequest {
  status: 'pending' | 'approved' | 'rejected'
  perPage: number
  cursor: string
}

export interface GetUploadSpiritsResponse {
  data: {
    id: string
    title: string
    images: {
      kind: string
      url: string
    }[]
    rejectReason: string
  }[]
  meta: {
    nextCursor: string
    perPage: number
  }
}

export interface metaDataItem {
  key: string
  count: number
}

export interface GetSearchMetaResponse {
  data: {
    hits: number
    aggregations: {
      category: metaDataItem[]
      brand: metaDataItem[]
      country: metaDataItem[]
      region: metaDataItem[]
      pairings: metaDataItem[]
      flavors: {
        slug: {
          key: string
          count: number
          children: metaDataItem[]
        }[]
      }
    }
  }
}
