import React, { useState, useEffect, useRef } from 'react'
import { useIsFocused, CommonActions } from '@react-navigation/native'
import {
  View,
  TouchableOpacity,
  StyleSheet,
  Image,
  Text,
  Dimensions,
  SafeAreaView,
} from 'react-native'
import { useTheme } from '@/Hooks'
import { useTranslation } from 'react-i18next'
import { Camera, CameraType } from 'expo-camera'
import { userApi } from '@/Services/modules/users'
import { useDispatch, useSelector } from 'react-redux'
import { globalActions } from '@/Store/Global'
import { manipulateAsync } from 'expo-image-manipulator'
import { BorderRadius, Colors, Height } from '@/Theme/Variables'
import { RootState } from '@/Store'
import { launchImageLibrary } from 'react-native-image-picker'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { PagesEnum } from '@/Navigators/Application'

const CameraContainer = ({ navigation }: any) => {
  const dispatch = useDispatch()
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const { t } = useTranslation()
  const isFocused = useIsFocused()

  const { openDownloadSign } = useSelector((state: RootState) => state.global)

  const camera = useRef<Camera>(null)

  const { data: getMeData, isError: getMeDataIsError } = userApi.useGetMeQuery()

  const [isLogin, setIsLogin] = useState<boolean>(false)
  const [openCamera, setOpenCamera] = useState<boolean>(false)
  const [type, _setType] = useState(CameraType.back)
  const [renderImageURI, setRenderImageURI] = useState<string>('')
  const [imageURI, setImageURI] = useState<string>('')
  const [onCameraReady, setOnCameraReady] = useState<boolean>(false)
  const [step, setStep] = useState<number>(1)
  const [isOpenGallery, setIsOpenGallery] = useState<boolean>(false)

  const desktop = Dimensions.get('window').width > 768
  const styles = getStyles(openCamera, desktop)

  const onPictureSaved = async ({ uri }: any) => {
    // 因 manipulateAsync 只能裁切左上角，所以透過旋轉角度來達到裁切右下角的效果
    // 左上
    const cropImageStep1 = await manipulateAsync(uri, [
      {
        crop: {
          originX: desktop ? 220 : 80,
          originY: desktop ? 40 : 150,
          width: desktop ? 640 : 1000,
          height: desktop ? 480 : 580,
        },
      },
      { rotate: 180 },
    ])
    // 右下
    const cropImageStep2 = await manipulateAsync(
      cropImageStep1.uri,
      [
        {
          crop: {
            originX: desktop ? 220 : 80,
            originY: desktop ? 55 : 150,
            width: desktop ? 640 : 1000,
            height: desktop ? 480 : 580,
          },
        },
        { rotate: 180 },
      ],
      { compress: 0.6 },
    )
    // 檢查檔案大小
    const response = await fetch(cropImageStep2.uri)
    const blob = await response.blob()
    const fileSizeInMB = blob.size / 1024 / 1024
    if (fileSizeInMB >= 5) {
      dispatch(
        globalActions.openSnackbar({
          visible: true,
          message: '照片大小超過 5MB，請重新拍攝照片',
          type: 'error',
        }),
      )
      return
    }
    setImageURI(cropImageStep2.uri)
    setRenderImageURI(uri)
    setIsOpenGallery(false)
  }

  const handleTakePicture = async () => {
    try {
      if (!camera.current) {
        return
      }
      camera.current.takePictureAsync().then(onPictureSaved)
    } catch (error) {
      console.error(`${error}`)
    }
  }

  const handleCancel = () => {
    setImageURI('')
    setOpenCamera(true)
    setStep(1)
    setIsOpenGallery(false)
  }

  // 開啟相簿
  const handleOpenGallery = async () => {
    const result = await launchImageLibrary({
      mediaType: 'photo',
      selectionLimit: 1,
    })
    if (result?.assets) {
      const dataURI = result?.assets[0]?.uri
      setImageURI(dataURI || '')
      setRenderImageURI(dataURI || '')
      setIsOpenGallery(true)
    }
  }

  const handleSearch = () => {
    navigation.navigate(AuthPagesEnum.ScanLoadingContainer, { imageURI })
  }

  const handleGoBack = () => {
    navigation.dispatch(CommonActions.goBack())
  }

  useEffect(() => {
    navigation.setOptions({
      headerShow: false,
      title: '掃描辨識 | 烈酒探索家 - 您的全方位烈酒品飲助理',
    })
  }, [navigation])

  useEffect(() => {
    if (getMeData && !getMeDataIsError) {
      setIsLogin(true)
    } else {
      setIsLogin(false)
    }
  }, [getMeData, getMeDataIsError])

  // 判斷是否彈出登入引導窗
  useEffect(() => {
    if (!getMeData && isFocused && getMeDataIsError) {
      dispatch(
        globalActions.openBottomDialog({
          visible: true,
          title: t('camera.bottomDialogTitle'),
          disabledBackgroundClose: true,
          content: (
            <View
              style={[
                Layout.center,
                Gutters.regularVMargin,
                Gutters.regularHPadding,
              ]}
            >
              <Image
                source={Images.bottom_dialog_login_leave}
                resizeMode="contain"
                style={[Gutters.regularBMargin, Layout.iconSize64]}
              />
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.smallBMargin,
                  Gutters.tinyVPadding,
                  {
                    backgroundColor: Colors.primary,
                    borderRadius: BorderRadius.radius8,
                    height: Height.height48,
                  },
                ]}
                onPress={() => {
                  dispatch(globalActions.closeBottomDialog())
                  navigation.navigate(PagesEnum.LoginMainContainer)
                }}
              >
                <Text
                  style={[
                    Fonts.weight500,
                    Fonts.size14,
                    { color: Colors.primaryText },
                  ]}
                >
                  {t('camera.bottomDialogButton1')}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.smallBMargin,
                  Gutters.tinyVPadding,
                  {
                    height: Height.height48,
                  },
                ]}
                onPress={() => {
                  dispatch(globalActions.closeBottomDialog())
                  navigation.goBack()
                }}
              >
                <Text style={{ color: Colors.white }}>
                  {t('camera.bottomDialogButton2')}
                </Text>
              </TouchableOpacity>
            </View>
          ),
        }),
      )
    }
  }, [
    dispatch,
    Images,
    Layout,
    Gutters,
    Fonts,
    navigation,
    isFocused,
    t,
    getMeData,
    getMeDataIsError,
  ])
  useEffect(() => {
    if (isFocused) {
      setOpenCamera(true)
      setStep(1)
    }
  }, [isFocused])

  // 離開頁面關掉相機
  useEffect(() => {
    if (isFocused && getMeData) {
      setOpenCamera(true)
      setStep(1)
    } else {
      setOpenCamera(false)
    }
    setImageURI('')
  }, [isFocused, getMeData])

  useEffect(() => {
    if (imageURI?.length) {
      setOpenCamera(false)
      setStep(2)
    }
  }, [imageURI])

  const renderTakePic = () => {
    return (
      <Camera
        ref={camera}
        style={[Layout.fill]}
        type={type}
        onCameraReady={() => setOnCameraReady(true)}
        ratio="1:1"
      />
    )
  }

  const renderShowPic = () => {
    // 如果是從相簿讀取的照片
    if (isOpenGallery) {
      return (
        <View style={[Layout.colCenter, Layout.fullWidth]}>
          <Image
            style={[{ width: 300, height: 400 }]}
            source={{ uri: renderImageURI }}
            resizeMode={'contain'}
          />
        </View>
      )
    }
    return (
      <View style={[Layout.fill]}>
        <Image
          style={Layout.fullSize}
          source={{ uri: renderImageURI }}
          resizeMode="cover"
        />
      </View>
    )
  }

  // 下載提示框
  const renderDownloadSign = () => {
    return (
      <View style={[Layout.fullWidth, styles.downloadContainer]}>
        <View
          style={[
            Layout.row,
            Gutters.tinyVPadding,
            Layout.alignItemsCenter,
            { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
          ]}
        >
          <View style={[Layout.row, Layout.alignItemsCenter, { width: '80%' }]}>
            <TouchableOpacity
              onPress={() => dispatch(globalActions.closeDownloadSign())}
            >
              <Image
                source={Images.close}
                resizeMode="contain"
                style={[Layout.iconSize16, Gutters.tinyRMargin]}
              />
            </TouchableOpacity>
            <Image
              source={Images.app_icon}
              resizeMode="contain"
              style={[
                Layout.iconSize32,
                Gutters.tinyRMargin,
                { borderRadius: 8 },
              ]}
            />
            <View style={{ width: '80%' }}>
              <Text
                style={[
                  Fonts.weight700,
                  Fonts.size12,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                立即下載烈酒探索家
                APP！享受掃描辨識烈酒、紀錄品飲筆記與同好交流心得的便利功能！
              </Text>
            </View>
          </View>
          <TouchableOpacity
            style={[
              Layout.center,
              {
                backgroundColor: Colors.primary,
                borderRadius: BorderRadius.radius8,
                width: 76,
                height: Height.height32,
              },
            ]}
            // @ts-ignore
            onPress={() => window.open('https://lihi.cc/bt8vN')}
          >
            <Text
              style={[
                Fonts.weight500,
                Fonts.size14,
                { color: Colors.fontText.dark.primary2 },
              ]}
            >
              取得
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  const renderBottomContainer = () => {
    const opacityValue = onCameraReady ? 1 : 0
    if (openCamera) {
      return (
        <View style={[styles.buttonContainer]}>
          <View
            style={[
              Layout.row,
              Layout.alignItemsCenter,
              Layout.justifyContentBetween,
              styles.actionButtonContainer,
              Gutters.regularHPadding,
              {
                opacity: opacityValue,
              },
            ]}
          >
            <TouchableOpacity onPress={handleOpenGallery}>
              <Image
                style={[Layout.iconSize48]}
                source={Images.camera_gallery}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={handleTakePicture}>
              <Image
                style={styles.takePicButton}
                source={Images.camera_takePicCamera}
              />
            </TouchableOpacity>
            <View style={[Layout.iconSize48]} />
          </View>
          <View
            style={[
              Layout.fullWidth,
              Layout.row,
              Layout.alignItemsCenter,
              Gutters.smallVPadding,
              Gutters.smallHPadding,
              {
                backgroundColor: Colors.background.default,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              },
            ]}
          >
            <Image
              source={Images.camera_example}
              style={[Layout.iconSize64, Gutters.smallRMargin]}
              resizeMode="contain"
            />
            <View>
              <Text
                style={[
                  Fonts.size14,
                  Fonts.weight700,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                酒標辨識拍攝小提示：
              </Text>
              <Text
                style={[
                  Fonts.size14,
                  Fonts.weight500,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                酒標置於對準框內、背景乾淨無文字
              </Text>
            </View>
          </View>
        </View>
      )
    }
    return (
      <View style={styles.buttonContainer}>
        <View
          style={[
            Layout.row,
            Layout.justifyContentBetween,
            Layout.alignItemsCenter,
            Gutters.regularHPadding,
            styles.actionButtonContainer,
          ]}
        >
          <TouchableOpacity style={[]} onPress={handleCancel}>
            <Image
              style={styles.reFreshButton}
              source={Images.camera_reFresh}
            />
          </TouchableOpacity>
          <TouchableOpacity style={styles.identifyBtn} onPress={handleSearch}>
            <Text
              style={[
                Fonts.size16,
                Fonts.weight500,
                Fonts.textCenter,
                { color: Colors.primaryText },
              ]}
            >
              開始辨識
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  const renderCameraLabel = () => {
    return (
      <View style={styles.scanLabelContainer}>
        <View
          style={[
            Layout.center,
            Layout.fill,
            Layout.fullWidth,
            {
              height: Dimensions.get('window').height - 235,
            },
          ]}
        >
          <View>
            <Text style={[styles.textShadow, Fonts.size40]}>
              {t('camera.scanLabelTitle1')}
            </Text>
          </View>
        </View>
      </View>
    )
  }

  const renderStep = () => {
    const opacityValue = (value: number) => (step === value ? 1 : 0.3)
    return (
      <View
        style={[
          Layout.fullWidth,
          Layout.row,
          Layout.justifyContentAround,
          Gutters.smallBPadding,
          styles.stepContainer,
        ]}
      >
        <View style={[Layout.alignItemsCenter]}>
          <Text
            style={[
              Fonts.size16,
              Fonts.weight500,
              { color: Colors.white, opacity: opacityValue(1) },
            ]}
          >
            1.
          </Text>
          <Text
            style={[
              Fonts.size16,
              Fonts.weight500,
              { color: Colors.white, opacity: opacityValue(1) },
            ]}
          >
            拍攝酒標
          </Text>
        </View>
        <View style={[Layout.alignItemsCenter]}>
          <Text
            style={[
              Fonts.size16,
              Fonts.weight500,
              { color: Colors.white, opacity: opacityValue(2) },
            ]}
          >
            2.
          </Text>
          <Text
            style={[
              Fonts.size16,
              Fonts.weight500,
              { color: Colors.white, opacity: opacityValue(2) },
            ]}
          >
            確定辨識
          </Text>
        </View>
        <View style={[Layout.alignItemsCenter]}>
          <Text
            style={[
              Fonts.size16,
              Fonts.weight500,
              { color: Colors.white, opacity: opacityValue(3) },
            ]}
          >
            3.
          </Text>
          <Text
            style={[
              Fonts.size16,
              Fonts.weight500,
              { color: Colors.white, opacity: opacityValue(3) },
            ]}
          >
            辨識結果
          </Text>
        </View>
      </View>
    )
  }

  return (
    <SafeAreaView style={[Layout.fill]}>
      <View style={[Layout.fill, Layout.row]}>
        {/* 步驟 */}
        {renderStep()}
        {/* 下載 */}
        {openDownloadSign && renderDownloadSign()}
        {/* 邊框黑影 */}
        <View style={styles.topFilter}>
          <View
            style={[
              Layout.row,
              Layout.justifyContentBetween,
              styles.topContainer,
            ]}
          >
            <TouchableOpacity onPress={handleGoBack}>
              <Image
                style={Layout.iconSize26}
                source={Images.camera_close}
                resizeMode="contain"
              />
            </TouchableOpacity>
            {/* <TouchableOpacity>
              <Image
                style={{ width: 20, height: 20 }}
                source={Images.camera_flash}
                resizeMode="contain"
              />
            </TouchableOpacity> */}
          </View>
        </View>
        {!isLogin && renderCameraLabel()}
        {openCamera && renderCameraLabel()}
        {openCamera ? renderTakePic() : renderShowPic()}
        {/* 下方區塊 */}
        {renderBottomContainer()}
        <View style={[styles.cameraMask]}>
          <Image
            source={
              openCamera ? Images.camera_mask_light : Images.camera_mask_dark
            }
            style={[Layout.fullSize]}
            resizeMode="cover"
          />
        </View>
      </View>
    </SafeAreaView>
  )
}

const getStyles = (openCamera: boolean, desktop: boolean) =>
  StyleSheet.create({
    camera: {
      flex: 1,
    },
    topFilter: {
      position: 'absolute',
      zIndex: 100,
      top: 0,
      height: 60,
      width: '100%',
      justifyContent: 'center',
    },
    cameraMask: {
      position: 'absolute',
      zIndex: 1,
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
    },
    identifyBtn: {
      width: '85%',
      height: 48,
      backgroundColor: Colors.primary,
      borderRadius: 8,
      justifyContent: 'center',
      alignItems: 'center',
    },
    scanLabelContainer: {
      position: 'absolute',
      zIndex: 100,
      top: 150,
      right: 30,
      width: desktop ? 368 : Dimensions.get('window').width - 60,
      height: Dimensions.get('window').height - 300,
    },
    textShadow: {
      textShadowColor: 'rgba(0, 0, 0, 0.5)',
      textShadowOffset: { width: 0, height: 0 },
      textShadowRadius: 5,
      color: Colors.white,
      textAlign: 'center',
      fontWeight: '700',
      opacity: 0.8,
    },
    topContainer: {
      width: '100%',
      paddingHorizontal: 24,
    },
    buttonContainer: {
      width: '100%',
      position: 'absolute',
      zIndex: 100,
      bottom: 0,
      height: 90,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    actionButtonContainer: {
      width: '100%',
      position: 'absolute',
      left: 0,
      bottom: openCamera ? 110 : 55,
    },
    takePicButton: {
      marginRight: 'auto',
      marginLeft: 'auto',
      width: 56,
      height: 56,
      pointerEvents: 'auto',
    },
    reFreshButton: {
      width: 24,
      height: 24,
    },
    stepContainer: {
      position: 'absolute',
      zIndex: 100,
      top: 0,
      left: 0,
      backgroundColor: openCamera ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
      height: 110,
      alignItems: 'flex-end',
    },
    downloadContainer: {
      position: 'absolute',
      zIndex: 100,
      top: 110,
      left: 0,
      height: 60,
      alignItems: 'flex-end',
    },
  })

export default CameraContainer
