import React, { useCallback, useEffect, useState } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  ActivityIndicator,
  StyleSheet,
  Dimensions,
  useWindowDimensions,
  FlatList,
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar } from 'react-native-paper'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { FadeInView, Header, LoadingComponent } from '@/Components'
// import { useTranslation } from 'react-i18next'
import userApi from '@/Services/modules/users'
import { useIsFocused } from '@react-navigation/native'
import { RootState } from '@/Store'
import { navigate } from '@/Navigators/utils'
import { TabView, SceneMap, TabBar } from 'react-native-tab-view'
import achievementsApi from '@/Services/modules/achievements'
import { userActions } from '@/Store/User'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'

const InitTabPage = {
  Followers: 1,
  Followings: 1,
}

const FollowerContainer = ({ route, navigation }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  //   const { t } = useTranslation()
  const dispatch = useDispatch()
  const isFocused = useIsFocused()
  const styles = getStyle()
  const layout = useWindowDimensions()

  const { followers, followings } = useSelector(
    (state: RootState) => state.user,
  )

  const { data: getMeData } = userApi.useGetMeQuery()

  const {
    data: getUserData,
    isLoading: getUserDataIsLoading,
    isSuccess: getUserDataIsSuccess,
  } = userApi.useGetUserDataQuery({
    id: route?.params?.id,
  })

  const {
    data: getAllAchievementListData,
    isSuccess: getAllAchievementListDataIsSuccess,
  } = achievementsApi.useGetAllAchievementListQuery()

  const [getUserFollowersRequest, { isFetching: getUserFollowersIsFetching }] =
    userApi.useLazyGetUserFollowersQuery()

  const [
    getUserFollowingsRequest,
    { isFetching: getUserFollowingsIsFetching },
  ] = userApi.useLazyGetUserFollowingsQuery()

  const [postFollowUserRequest] = userApi.usePostFollowUserMutation()

  const [index, setIndex] = useState(0)
  const [routes, setRoutes] = useState([
    { key: 'Followers', title: '' },
    { key: 'Followings', title: '' },
  ])
  const [tabPage, setTabPage] = useState(InitTabPage)

  const initFetch = useCallback(() => {
    dispatch(userActions.clearFollowersAndFollowings())
    getUserFollowersRequest({
      id: route?.params?.id,
      page: InitTabPage.Followers,
    })
    getUserFollowingsRequest({
      id: route?.params?.id,
      page: InitTabPage.Followings,
    })
    setTabPage(InitTabPage)
  }, [
    dispatch,
    getUserFollowersRequest,
    getUserFollowingsRequest,
    route?.params?.id,
  ])

  // 首次進入重置追蹤頁
  useEffect(() => {
    if (isFocused) {
      initFetch()
    }
  }, [isFocused, dispatch, initFetch])

  // 取得資料後，更新 tab 資訊
  useEffect(() => {
    if (getUserDataIsSuccess && route) {
      setRoutes([
        {
          key: 'Followers',
          title: `粉絲(${getUserData?.data?.counters?.followers || 0})`,
        },
        {
          key: 'Followings',
          title: `追蹤中(${getUserData?.data?.counters?.followings || 0})`,
        },
      ])
    }
  }, [getUserDataIsSuccess, getUserData, route])

  // 判斷 params 是在哪一個 tab
  useEffect(() => {
    setIndex(route?.params?.type === 'followers' ? 0 : 1)
  }, [route?.params?.type])

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigate(PagesEnum.Main, {
      screen: 'Profile',
    })
  }, [navigation])

  const convertUserTitle = useCallback(
    (slug: string) => {
      if (getAllAchievementListDataIsSuccess) {
        const titleArr = []
        for (const group of getAllAchievementListData.data) {
          for (const titleObj of group.data) {
            titleArr.push({
              name: titleObj.name,
              slug: titleObj.slug,
            })
          }
        }
        return titleArr.find(item => item.slug === slug)?.name
      }
    },
    [getAllAchievementListDataIsSuccess, getAllAchievementListData],
  )

  // 追隨或取消追隨按鈕
  const handleFollowBtn = (id: string) => {
    postFollowUserRequest({ id })
  }

  const renderFollowBtn = ({ follow, id }: { follow: boolean; id: string }) => {
    const text = follow ? '追蹤中' : '追蹤'

    // 如果是自己就不顯示
    if (id === (getMeData?.id as unknown as string)) {
      return null
    }
    return (
      <TouchableOpacity
        style={[
          Layout.colCenter,
          {
            width: 90,
            height: 32,
            borderWidth: 1,
            borderColor: Colors.primary,
            borderRadius: 8,
            backgroundColor: follow ? 'transparent' : Colors.primary,
          },
        ]}
        onPress={() => handleFollowBtn(id)}
      >
        <Text
          style={[
            Fonts.weight500,
            Fonts.size14,
            {
              color: follow
                ? Colors.fontText.light.primary2
                : Colors.fontText.dark.primary2,
            },
          ]}
        >
          {text}
        </Text>
      </TouchableOpacity>
    )
  }

  const renderFollowItem = ({ item }: any) => {
    return (
      <TouchableOpacity
        style={[
          Gutters.smallTPadding,
          Gutters.regularHPadding,
          {
            backgroundColor: Colors.background.surface,
          },
        ]}
        onPress={() =>
          navigation.navigate(PagesEnum.ProfileMainContainer, { id: item?.id })
        }
      >
        <View
          style={[
            Layout.row,
            Layout.alignItemsCenter,
            Layout.justifyContentBetween,
          ]}
        >
          <View style={[Layout.row, Layout.alignItemsCenter]}>
            <Avatar.Image
              source={{
                uri: item?.avatar || Images.profile_profile,
              }}
              style={[Gutters.smallRMargin]}
              size={48}
            />
            <View>
              <Text
                style={[
                  Fonts.size14,
                  Fonts.weight700,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                {item?.name || ''}
              </Text>
              <Text
                style={[
                  Fonts.size14,
                  Fonts.weight400,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                {convertUserTitle(item?.titleSlug || '')}
              </Text>
            </View>
          </View>
          {renderFollowBtn({
            id: item.id,
            follow: item.followed,
          })}
        </View>
        <View
          style={[
            Layout.fullWidth,
            Gutters.smallTMargin,
            Gutters.smallHPadding,
            {
              height: 1,
              backgroundColor: Colors.background.onSurface,
            },
          ]}
        />
      </TouchableOpacity>
    )
  }

  const renderFollowers = () => {
    return (
      <View style={[Layout.fill]}>
        <FlatList
          data={followers?.data || []}
          renderItem={renderFollowItem}
          keyExtractor={item => item.id.toString()}
          onRefresh={initFetch}
          refreshing={getUserFollowersIsFetching}
          onEndReached={({ distanceFromEnd }) => {
            if (distanceFromEnd >= 1) {
              handleFetchMoreData('followers')
            }
          }}
          onEndReachedThreshold={1}
          ListFooterComponent={renderListLoading}
          ListEmptyComponent={renderEmpty}
        />
      </View>
    )
  }

  const renderFollowings = () => {
    return (
      <View style={[Layout.fill]}>
        <FlatList
          data={followings?.data || []}
          renderItem={renderFollowItem}
          keyExtractor={item => item.id.toString()}
          onRefresh={initFetch}
          refreshing={getUserFollowingsIsFetching}
          onEndReached={({ distanceFromEnd }) => {
            if (distanceFromEnd >= 1) {
              handleFetchMoreData('followings')
            }
          }}
          onEndReachedThreshold={1}
          ListFooterComponent={renderListLoading}
          ListEmptyComponent={renderEmpty}
        />
      </View>
    )
  }

  const renderScene = SceneMap({
    Followers: renderFollowers,
    Followings: renderFollowings,
  })

  const renderTabBar = (props: any) => (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: Colors.primary, height: 4 }}
      style={{ backgroundColor: Colors.background.default }}
    />
  )

  const handleFetchMoreData = (type: 'followers' | 'followings') => {
    if (getUserFollowersIsFetching || getUserFollowingsIsFetching) {
      return
    }

    if (
      type === 'followers' &&
      tabPage?.Followers < followers.meta.totalPages
    ) {
      const nextPage = tabPage?.Followers + 1
      getUserFollowersRequest({
        id: route?.params?.id,
        page: nextPage,
      })
      setTabPage({ ...tabPage, Followers: nextPage })
    }

    if (
      type === 'followings' &&
      tabPage?.Followings < followings.meta.totalPages
    ) {
      const nextPage = tabPage?.Followings + 1
      getUserFollowingsRequest({
        id: route?.params?.id,
        page: nextPage,
      })
      setTabPage({ ...tabPage, Followings: nextPage })
    }
  }

  const renderListLoading = () => {
    return (
      <View>
        {getUserFollowersIsFetching ||
          (getUserFollowingsIsFetching && (
            <ActivityIndicator size="large" color={Colors.primary} />
          ))}
      </View>
    )
  }

  const renderEmpty = () => {
    return (
      <View
        style={[Layout.colCenter, Layout.fullHeight, Gutters.regularHPadding]}
      ></View>
    )
  }

  if (getUserDataIsLoading) {
    return <LoadingComponent />
  }

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <Header
        title={getUserData?.data?.name || ''}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
      />
      <FadeInView duration={500} style={Layout.fill}>
        <View style={[Layout.fill]}>
          <TabView
            navigationState={{ index, routes }}
            renderScene={renderScene}
            renderTabBar={renderTabBar}
            onIndexChange={setIndex}
            initialLayout={{ width: layout.width }}
          />
        </View>
      </FadeInView>
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
  })

export default FollowerContainer
