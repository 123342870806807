import React from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  ScrollView,
} from 'react-native'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { FadeInView } from '@/Components'
import { removeEmptyKeys } from '@/Util/global'
import spiritsApi from '@/Services/modules/spirits'
import { ActivityIndicator } from 'react-native-paper'
import { DownLoadSign } from '@/Components'
import { MainPagesEnum } from '@/Navigators/Main'

const ReportSpiritContainer = ({ route, navigation }: any) => {
  const { Layout, Gutters, Images, Fonts } = useTheme()

  // 新增酒資料
  const [
    postUploadSpiritsRequest,
    {
      isSuccess: postUploadSpiritsRequestIsSuccess,
      isLoading: postUploadSpiritsRequestIsLoading,
      reset: postUploadSpiritsRequestReset,
    },
  ] = spiritsApi.usePostUploadSpiritsMutation()

  // 因為 api 錯誤 history id 會是 null 但是要確保流程，所以必須讓使用者繼續，但是不打回報錯誤 api
  const [passStatus, setPassStatus] = React.useState(false)

  const styles = getStyle()

  const handleReOpenCamera = () => {
    navigation.navigate(MainPagesEnum.Camera)
    setPassStatus(false)
    postUploadSpiritsRequestReset()
  }

  const handleGoBack = () => {
    navigation.navigate(MainPagesEnum.Home)
    setPassStatus(false)
    postUploadSpiritsRequestReset()
  }

  // 確認回報辨識錯誤
  const handleConfirmReport = () => {
    if (route?.params?.historyId) {
      postUploadSpiritsRequest(
        removeEmptyKeys({
          historyId: route?.params?.historyId,
        }),
      )
    } else {
      setPassStatus(true)
    }
  }

  // 成功回報後的畫面
  if (postUploadSpiritsRequestIsSuccess || passStatus) {
    return (
      <View
        style={[
          Layout.fill,
          Gutters.regularHPadding,
          { backgroundColor: Colors.background.default },
        ]}
      >
        <FadeInView duration={500} style={Layout.fill}>
          <View style={[Layout.fill, Layout.colCenter]}>
            <Image
              style={[Layout.iconSize180]}
              source={Images.scan_result_done}
              resizeMode="contain"
            />
            <View style={[Gutters.largeBMargin]}>
              <Text
                style={[
                  Fonts.weight700,
                  Fonts.size20,
                  Fonts.textCenter,
                  Gutters.regularBMargin,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                感謝您的協助！
              </Text>
              <Text
                style={[
                  Fonts.weight400,
                  Fonts.size16,
                  Fonts.textCenter,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                資料已在審核中，若審核通過即可累積蒸餾師成就。審核狀態請前往協助新增紀錄查詢。
              </Text>
            </View>
            <TouchableOpacity
              style={[styles.checkBtn, Gutters.regularBMargin]}
              onPress={handleReOpenCamera}
            >
              <Text
                style={[
                  Fonts.size16,
                  Fonts.weight500,
                  { color: Colors.fontText.dark.primary2 },
                ]}
              >
                繼續拍照辨識
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                Gutters.regularBMargin,
                Layout.center,
                Layout.fullWidth,
                {
                  borderWidth: 1,
                  borderColor: Colors.primary,
                  borderRadius: 8,
                  height: 48,
                },
              ]}
              onPress={handleGoBack}
            >
              <Text
                style={[
                  Fonts.size16,
                  Fonts.weight500,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                返回首頁
              </Text>
            </TouchableOpacity>
          </View>
        </FadeInView>
      </View>
    )
  }

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: '100%',
        },
      ]}
    >
      <ScrollView
        style={[Layout.fill, Gutters.regularHPadding]}
        contentContainerStyle={[
          Layout.alignItemsCenter,
          Layout.justifyContentBetween,
        ]}
      >
        <View style={[Gutters.regularBMargin, { paddingTop: 80 }]}>
          <Image
            source={Images.scan_result_done}
            style={[Layout.iconSize180]}
            resizeMode="contain"
          />
          <Text
            style={[
              Fonts.weight700,
              Fonts.size20,
              Fonts.textCenter,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            已收到您的回報！
          </Text>
        </View>
        <Text
          style={[
            Fonts.weight400,
            Fonts.size16,
            Gutters.largeBMargin,
            { color: Colors.fontText.light.primary2 },
          ]}
        >
          是否願意協助新增此品項，並同意授權酒標照作為產品圖？若審核成功，對平台社群有莫大幫助。
        </Text>
        <TouchableOpacity
          style={[styles.checkBtn, Gutters.smallBMargin]}
          onPress={handleConfirmReport}
        >
          {postUploadSpiritsRequestIsLoading ? (
            <ActivityIndicator size={'small'} color={Colors.white} />
          ) : (
            <Text
              style={[
                Fonts.weight500,
                Fonts.size16,
                { color: Colors.fontText.dark.primary2 },
              ]}
            >
              我願意協助新增
            </Text>
          )}
        </TouchableOpacity>
        <View style={[Layout.rowCenter]}>
          <Text
            style={[
              Fonts.weight500,
              Fonts.size16,
              Gutters.largeBMargin,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            略過，繼續{' '}
          </Text>
          <TouchableOpacity onPress={handleReOpenCamera}>
            <Text
              style={[
                Fonts.weight500,
                Fonts.size16,
                Gutters.largeBMargin,
                { color: Colors.primary },
              ]}
            >
              拍照辨識{' '}
            </Text>
          </TouchableOpacity>
          <Text
            style={[
              Fonts.weight500,
              Fonts.size16,
              Gutters.largeBMargin,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            或{' '}
          </Text>
          <TouchableOpacity onPress={handleGoBack}>
            <Text
              style={[
                Fonts.weight500,
                Fonts.size16,
                Gutters.largeBMargin,
                { color: Colors.primary },
              ]}
            >
              返回首頁
            </Text>
          </TouchableOpacity>
        </View>
        <View style={[Layout.fullWidth, { height: 225 }]}>
          <Image
            source={Images.camera_report_spirit_example_mark}
            style={[Layout.fullSize]}
            resizeMode="contain"
          />
        </View>
      </ScrollView>
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    checkBtn: {
      width: '100%',
      height: 48,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 8,
      backgroundColor: Colors.primary,
    },
  })

export default ReportSpiritContainer
