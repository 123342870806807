import React, { useCallback, useEffect, useState } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  FlatList,
  ActivityIndicator,
  StyleSheet,
  Dimensions,
  Platform,
} from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from '@/Hooks'
import { BorderRadius, BorderWidth, Colors, Height } from '@/Theme/Variables'
import { FadeInView, Header, LoadingComponent, SpiritCard } from '@/Components'
import { spiritsApi } from '@/Services/modules/spirits'
import { historyApi } from '@/Services/modules/history'
import userApi from '@/Services/modules/users'
import { RootState, getGlobal } from '@/Store'
import { navigate } from '@/Navigators/utils'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { RecognitionBooze } from '@/Services/modules/history/history.type'
import { globalActions } from '@/Store/Global'
import { DownLoadSign } from '@/Components'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { MainPagesEnum } from '@/Navigators/Main'
import { PagesEnum } from '@/Navigators/Application'

const HistoryScreen = ({ _route, navigation }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const dispatch = useDispatch()
  const styles = getStyle()

  const { language } = useSelector((state: RootState) => state.theme)
  const globalState = useSelector(getGlobal)
  const { productImageLightBox } = globalState
  // workaround for first time rating will not update the rate immediately
  const addRatingLoading = useSelector(
    (state: RootState) => state.spirit.addRatingLoading,
  )

  const { data: getMeData } = userApi.useGetMeQuery()

  // 原始 history 資料
  const {
    data: historyData,
    isLoading: historyDataLoading,
    isSuccess: historyDataSuccess,
  } = historyApi.useGetRecognitionHistoriesQuery(
    {},
    { skip: !getMeData, refetchOnMountOrArgChange: true, refetchOnFocus: true },
  )

  // lazy load my spirits 資料
  const [
    getHistoryRequest,
    {
      data: lazyHistoryData,
      isFetching: isLazyFetching,
      isSuccess: lazyHistoryDataSuccess,
    },
  ] = spiritsApi.useLazyGetRecognitionHistoriesQuery()

  // 回報辨識紀錄錯誤 （先回報錯誤才能新增酒資料）
  const [
    postRecognitionHistoriesReportRequest,
    {
      isLoading: postRecognitionHistoriesReportIsLoading,
      isSuccess: postRecognitionHistoriesReportIsSuccess,
    },
  ] = historyApi.usePostRecognitionHistoriesReportMutation()

  // 新增酒資料
  const [
    postUploadSpiritsRequest,
    {
      isSuccess: postUploadSpiritsRequestIsSuccess,
      isLoading: postUploadSpiritsRequestIsLoading,
      reset: postUploadSpiritsRequestReset,
    },
  ] = spiritsApi.usePostUploadSpiritsMutation()

  // 刪除辨識紀錄
  const [
    deleteHistoriesRequest,
    {
      isSuccess: deleteHistoriesIsSuccess,
      isLoading: deleteHistoriesIsLoading,
    },
  ] = spiritsApi.useDeleteRecognitionHistoriesMutation()

  const isLoading =
    postRecognitionHistoriesReportIsLoading || postUploadSpiritsRequestIsLoading

  const [dataList, setDataList] = useState<RecognitionBooze[]>()
  const [nextCursor, setNextCursor] = useState<string | null>(null)
  const [reportId, setReportId] = useState<string | null>(null)
  const [deleteID, setDeleteId] = useState<string | null>(null)

  // 將首次 api 拿回來的資料放到 state 中
  useEffect(() => {
    if (historyDataSuccess) {
      setDataList(historyData?.data || [])
      setNextCursor(historyData.meta.nextCursor)
    }
  }, [historyData, historyDataSuccess])

  // 將 lazy call api 拿回來的資料放到 state 中
  useEffect(() => {
    if (lazyHistoryDataSuccess && !isLazyFetching) {
      // 因觸發 onEndReached 會有非預期的行為，所以這邊要做判斷，如果重複就不放入 state
      const firstItemID = lazyHistoryData.data[0]?.image?.url

      if (!dataList?.some(item => item?.image?.url === firstItemID)) {
        setDataList(prev => [...(prev || []), ...lazyHistoryData?.data])
        setNextCursor(lazyHistoryData.meta.nextCursor)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lazyHistoryData?.data,
    lazyHistoryDataSuccess,
    lazyHistoryData,
    isLazyFetching,
  ])

  // 回報辨識紀錄錯誤後，重整資料
  useEffect(() => {
    if (postRecognitionHistoriesReportIsSuccess) {
      const newDataList = dataList?.map(item => {
        if (item?.id === reportId) {
          return {
            ...item,
            flags: {
              ...item.flags,
              userReportError: true,
            },
          }
        }
        return item
      })
      setDataList(newDataList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postRecognitionHistoriesReportIsSuccess, reportId])

  // 新增酒紀錄後，重整資料
  useEffect(() => {
    if (postUploadSpiritsRequestIsSuccess) {
      const newDataList = dataList?.map(item => {
        if (item?.id === reportId) {
          return {
            ...item,
            flags: {
              ...item.flags,
              newSpiritApplied: true,
            },
          }
        }
        return item
      })
      setDataList(newDataList)
      dispatch(globalActions.closeBottomDialog())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, postUploadSpiritsRequestIsSuccess, reportId])

  // 刪除酒紀錄後，重整資料
  useEffect(() => {
    if (deleteHistoriesIsSuccess) {
      const newDataList = dataList?.filter(item => item?.id !== deleteID)
      setDataList(newDataList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteHistoriesIsSuccess, deleteID])

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigate(PagesEnum.Main, {
      screen: MainPagesEnum.Profile,
    })
  }, [navigation])

  const handleFetchMoreData = () => {
    if (nextCursor && !isLazyFetching) {
      getHistoryRequest({
        cursor: nextCursor,
      })
    }
  }

  const handleClickCard = async (id: string, titleUrlSlug: string) => {
    navigation.navigate(PagesEnum.ProductDetail, {
      lang: language,
      id,
      titleUrlSlug: titleUrlSlug,
    })
    await AsyncStorage.setItem('previousScreen', AuthPagesEnum.Wishlist)
  }

  // 協助新增
  const handleConfirmReport = async (id: string) => {
    await postRecognitionHistoriesReportRequest({ id })
    await postUploadSpiritsRequest({
      historyId: id,
    })
    setReportId(id)
  }

  // 刪除辨識紀錄
  const handleDeleteHistoryReport = async (id: string) => {
    await deleteHistoriesRequest({ id })
    setDeleteId(id)
    dispatch(globalActions.closeBottomDialog())
  }

  // 打開回報 dialog
  const handleOpenReportDialog = (id: string) => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        title: '確認協助新增此品項？',
        titleStyle: {
          fontSize: 20,
        },
        content: (
          <View>
            <View
              style={[
                Gutters.regularHPadding,
                {
                  borderBottomColor: Colors.gray,
                  borderBottomWidth: BorderWidth.width1,
                },
              ]}
            >
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size14,
                  Fonts.textCenter,
                  Gutters.regularBMargin,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                按下確認即代表同意授權酒標照作為產品圖。
              </Text>
              <View style={[Layout.fullWidth, { height: 225 }]}>
                <Image
                  source={Images.history_example_mark}
                  resizeMode="contain"
                  style={[Layout.fullSize]}
                />
              </View>
            </View>
            <View
              style={[
                Layout.center,
                Gutters.regularVMargin,
                Gutters.regularHPadding,
              ]}
            >
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.rowCenter,
                  Gutters.smallBMargin,
                  {
                    backgroundColor: Colors.primary,
                    height: Height.height48,
                    borderRadius: BorderRadius.radius8,
                  },
                ]}
                disabled={isLoading}
                onPress={() => handleConfirmReport(id)}
              >
                {isLoading ? (
                  <ActivityIndicator size={'small'} color="white" />
                ) : (
                  <Text
                    style={[
                      Fonts.weight500,
                      Fonts.size16,
                      { color: Colors.fontText.dark.primary2 },
                    ]}
                  >
                    確認協助新增
                  </Text>
                )}
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.rowCenter,
                  Gutters.smallBMargin,
                  {
                    height: Height.height48,
                    borderRadius: BorderRadius.radius8,
                  },
                ]}
                onPress={() => {
                  dispatch(globalActions.closeBottomDialog())
                }}
              >
                <Text style={[Fonts.weight500, { color: Colors.white }]}>
                  取消
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        ),
      }),
    )
  }

  // 打開更多 dialog
  const handleOpenMoreDialog = ({
    id,
    showReportBtn,
  }: {
    id: string
    showReportBtn: boolean
  }) => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        titleStyle: {
          fontSize: 20,
        },
        content: (
          <View
            style={[
              Layout.center,
              Gutters.regularVMargin,
              Gutters.regularHPadding,
            ]}
          >
            {/* 刪除品飲筆記 */}
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.rowCenter,
                Gutters.smallBMargin,
                styles.moreDialogBtn,
              ]}
              disabled={deleteHistoriesIsLoading}
              onPress={() => handleDeleteHistoryReport(id)}
            >
              {deleteHistoriesIsLoading ? (
                <ActivityIndicator size={'small'} color={Colors.primary} />
              ) : (
                <>
                  <Image
                    source={Images.components_spirit_delete}
                    resizeMode="contain"
                    style={Layout.iconSize24}
                  />
                  <Text style={[Fonts.weight500, { color: Colors.white }]}>
                    刪除辨識紀錄
                  </Text>
                </>
              )}
            </TouchableOpacity>
            {/* 修改品飲筆記 */}
            {showReportBtn && (
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.rowCenter,
                  Gutters.smallBMargin,
                  styles.moreDialogBtn,
                ]}
                onPress={() => openConfirmReportDialog(id)}
              >
                <Image
                  source={Images.history_report}
                  resizeMode="contain"
                  style={Layout.iconSize24}
                />
                <Text style={[Fonts.weight500, { color: Colors.white }]}>
                  回報辨識錯誤
                </Text>
              </TouchableOpacity>
            )}
          </View>
        ),
      }),
    )
  }

  // 確認回報辨識錯誤
  const openConfirmReportDialog = (id: string) => {
    const confirmBtn = async () => {
      await postRecognitionHistoriesReportRequest({ id })
      dispatch(globalActions.closeBottomDialog())
      navigation.navigate(AuthPagesEnum.ReportSpiritContainer, {
        historyId: id,
      })
    }

    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        title: '確認回報？',
        titleStyle: {
          fontSize: 20,
        },
        content: (
          <View>
            <View
              style={[
                Gutters.regularVPadding,
                Gutters.regularHPadding,
                {
                  borderBottomColor: Colors.gray,
                  borderBottomWidth: BorderWidth.width1,
                },
              ]}
            >
              <Text
                style={[Fonts.weight500, Fonts.size14, { color: Colors.white }]}
              >
                收到您的回報後，我們將檢查酒資料並盡快修正。此回報機制不會蒐集用戶個人資訊。
              </Text>
            </View>
            <View
              style={[
                Layout.center,
                Gutters.regularVMargin,
                Gutters.regularHPadding,
              ]}
            >
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.rowCenter,
                  Gutters.smallBMargin,
                  {
                    backgroundColor: Colors.primary,
                    height: Height.height48,
                    borderRadius: BorderRadius.radius8,
                  },
                ]}
                onPress={confirmBtn}
              >
                <Text
                  style={[
                    Fonts.weight500,
                    Fonts.size16,
                    { color: Colors.black },
                  ]}
                >
                  確認回報
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.rowCenter,
                  Gutters.smallBMargin,
                  {
                    height: Height.height48,
                    borderRadius: BorderRadius.radius8,
                  },
                ]}
                onPress={() => {
                  dispatch(globalActions.closeBottomDialog())
                }}
              >
                <Text style={[Fonts.weight500, { color: Colors.white }]}>
                  取消
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        ),
      }),
    )
  }

  const renderCardListItem = ({ item }: any) => {
    const { image: userScanImage } = item

    const showSpiritCard =
      // 使用者回報過
      !item?.flags?.userReportError &&
      // 使用者新增過
      !item?.flags?.newSpiritApplied &&
      item?.spirit

    if (!showSpiritCard) {
      return (
        <View
          style={[
            Gutters.tinyBMargin,
            Gutters.smallTPadding,
            Layout.fullWidth,
            Layout.row,
            {
              height: 150,
              backgroundColor: Colors.background.surface,
            },
          ]}
        >
          <Image
            style={[
              {
                width: 100,
                height: 100,
                borderRadius: 8,
                marginRight: 16,
                marginLeft: 24,
              },
            ]}
            source={{ uri: Images.history_search_no_result }}
            resizeMode="cover"
          />
          <View style={[Gutters.smallTPadding, { width: '50%' }]}>
            <View style={[Gutters.smallBMargin, Layout.fullWidth]}>
              <Text
                style={[
                  item?.flags?.newSpiritApplied
                    ? Gutters.tinyBMargin
                    : Gutters.smallBMargin,
                  {
                    color: Colors.white,
                    fontSize: 16,
                    fontWeight: '700',
                    width: '100%',
                    wordBreak: 'break-word',
                  },
                ]}
                ellipsizeMode="tail"
                numberOfLines={2}
              >
                找不到相似的結果
              </Text>
              {item?.flags?.newSpiritApplied ? (
                <View style={[Layout.row]}>
                  <Image
                    source={Images.history_checked}
                    style={[Layout.iconSize16, { marginRight: 6 }]}
                    resizeMode="contain"
                  />
                  <Text
                    style={[
                      Fonts.size12,
                      Fonts.weight400,
                      { color: Colors.fontText.light.primary2 },
                    ]}
                  >
                    已協助新增
                  </Text>
                </View>
              ) : (
                <TouchableOpacity
                  style={[
                    Gutters.smallHPadding,
                    {
                      backgroundColor: Colors.primary,
                      borderRadius: 8,
                      alignSelf: 'flex-start',
                      paddingVertical: 5,
                    },
                  ]}
                  onPress={() => handleOpenReportDialog(item?.id)}
                >
                  <Text
                    style={[
                      Fonts.size14,
                      Fonts.weight500,
                      Fonts.textCenter,
                      { color: Colors.fontText.dark.primary2 },
                    ]}
                  >
                    協助新增品項
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          </View>
          <Image
            source={{ uri: userScanImage?.url }}
            resizeMode="cover"
            style={{
              width: 56,
              height: 56,
              position: 'absolute',
              left: 12,
              bottom: 25,
              borderWidth: 2,
              borderColor: Colors.secondaryText,
              borderRadius: 8,
            }}
          />
          <TouchableOpacity
            style={[
              Layout.rowCenter,
              {
                position: 'absolute',
                top: 15,
                right: 16,
              },
            ]}
            onPress={() =>
              handleOpenMoreDialog({ id: item?.id, showReportBtn: false })
            }
          >
            <Image
              source={Images.more}
              resizeMode="contain"
              style={Layout.iconSize24}
            />
          </TouchableOpacity>
        </View>
      )
    }

    return (
      <SpiritCard
        id={item?.spirit?.id}
        title={item?.spirit?.title}
        onClick={() =>
          handleClickCard(item?.spirit?.id, item?.spirit?.titleUrlSlug)
        }
        imageUrl={item?.spirit?.imageUrl}
        rating={item?.spirit?.ratings?.average}
        ratingCount={item?.spirit?.ratings?.count}
        brand={item?.spirit?.brand}
        collect={item?.spirit?.collected}
        userScanImage={userScanImage?.url || ''}
        showMoreBtn
        onMoreBtnClick={() =>
          handleOpenMoreDialog({
            id: item?.id,
            showReportBtn: true,
          })
        }
      />
    )
  }

  const renderListLoading = () => {
    if (isLazyFetching) {
      return <ActivityIndicator size="large" color={Colors.primary} />
    }

    if (dataList?.length) {
      return (
        <View>
          <View style={[Gutters.largeVPadding]}>
            <Text
              style={[
                Fonts.textCenter,
                Fonts.size14,
                Fonts.weight400,
                { color: Colors.fontText.light.primary3 },
              ]}
            >
              辨識紀錄最多顯示 200 筆
            </Text>
          </View>
        </View>
      )
    }
  }

  const renderEmpty = () => {
    return (
      <View
        style={[Layout.colCenter, Layout.fullHeight, Gutters.regularHPadding]}
      >
        <Image
          source={Images.wishlist_empty}
          resizeMode="contain"
          style={[Gutters.regularBMargin, Layout.iconSize80]}
        />
        <Text
          style={[
            Fonts.weight700,
            Fonts.size16,
            Gutters.tinyBMargin,
            { color: Colors.white },
          ]}
        >
          尚未有任何紀錄
        </Text>
        <Text
          style={[
            Fonts.weight400,
            Fonts.size14,
            Gutters.regularBMargin,
            { color: Colors.secondaryText },
          ]}
        >
          立即開始辨識各類烈酒
        </Text>
        <View style={[Layout.fullWidth]}>
          <TouchableOpacity
            style={[Layout.rowCenter, styles.actionTakePicBtn]}
            onPress={() =>
              navigation.navigate(PagesEnum.Main, {
                screen: MainPagesEnum.Camera,
              })
            }
          >
            <Image
              source={Images.camera}
              resizeMode="contain"
              style={[Layout.iconSize24, Gutters.miniRMargin]}
            />
            <Text
              style={[
                Fonts.weight500,
                Fonts.size16,
                { color: Colors.primaryText },
              ]}
            >
              拍照辨識
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  // 成功回報後的畫面
  if (postUploadSpiritsRequestIsSuccess) {
    return (
      <View
        style={[
          Layout.fill,
          Gutters.regularHPadding,
          { backgroundColor: Colors.background.default },
        ]}
      >
        <FadeInView duration={500} style={Layout.fill}>
          <View style={[Layout.fill, Layout.colCenter]}>
            <Image
              style={[Layout.iconSize180]}
              source={Images.scan_result_done}
              resizeMode="contain"
            />
            <View style={[Gutters.largeBMargin]}>
              <Text
                style={[
                  Fonts.weight700,
                  Fonts.size20,
                  Fonts.textCenter,
                  Gutters.regularBMargin,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                感謝您的協助！
              </Text>
              <Text
                style={[
                  Fonts.weight400,
                  Fonts.size16,
                  Fonts.textCenter,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                資料已在審核中，若審核通過即可累積蒸餾師成就。審核狀態請前往協助新增紀錄查詢。
              </Text>
            </View>
            <TouchableOpacity
              style={[
                Gutters.regularBMargin,
                {
                  width: '100%',
                  height: 48,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 8,
                  backgroundColor: Colors.primary,
                },
              ]}
              onPress={() => postUploadSpiritsRequestReset()}
            >
              <Text
                style={[
                  Fonts.size16,
                  Fonts.weight500,
                  { color: Colors.fontText.dark.primary2 },
                ]}
              >
                我知道了
              </Text>
            </TouchableOpacity>
          </View>
        </FadeInView>
      </View>
    )
  }

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      {addRatingLoading && <LoadingComponent />}
      <Header
        title={'辨識紀錄'}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
      />
      <FadeInView duration={500} style={Layout.fill}>
        <View style={[Layout.fill]}>
          {historyDataLoading ? (
            <View style={[Layout.fill, Layout.center]}>
              <ActivityIndicator size="large" color={Colors.primary} />
            </View>
          ) : (
            <View
              style={[Layout.fill, { height: Dimensions.get('window').height }]}
            >
              <FlatList
                data={dataList || []}
                contentContainerStyle={[Layout.fill]}
                keyExtractor={item => item?.image?.url}
                renderItem={renderCardListItem}
                scrollEnabled={!productImageLightBox?.visible}
                refreshing
                onEndReached={({ distanceFromEnd }) => {
                  if (Platform.OS === 'android') {
                    handleFetchMoreData()
                    return
                  }
                  if (distanceFromEnd >= 1) {
                    handleFetchMoreData()
                  }
                }}
                onEndReachedThreshold={1}
                ListFooterComponent={renderListLoading}
                ListEmptyComponent={renderEmpty}
              />
            </View>
          )}
        </View>
      </FadeInView>
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    actionTakePicBtn: {
      width: '100%',
      height: 48,
      borderRadius: 8,
      marginBottom: 16,
      backgroundColor: Colors.primary,
    },
    actionSearchBtn: {
      width: '100%',
      height: 48,
      borderRadius: 8,
      borderColor: Colors.primary,
      borderWidth: 1,
    },
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    moreDialogBtn: {
      borderWidth: 1,
      borderColor: '#505050',
      paddingVertical: 9,
      borderRadius: 8,
    },
  })

export default HistoryScreen
