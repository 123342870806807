import React from 'react'
import { Image, StyleSheet } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import { useTheme } from '@/Hooks'

interface SpiritImageProps {
  imageUrl: string
  title?: string
  style?: any
  linearGradientContainerStyle?: any
  needSEO?: boolean
}

const SpiritImage = ({
  imageUrl,
  style,
  title,
  needSEO,
  linearGradientContainerStyle,
}: SpiritImageProps) => {
  const { Layout } = useTheme()
  return (
    <LinearGradient
      colors={['#F5F7FA', '#97A6BE']}
      end={{ y: 1, x: 0.5 }}
      style={[
        Layout.center,
        linearGradientContainerStyle || styles.linearGradientContainer,
        { borderRadius: 10 },
      ]}
    >
      {needSEO ? (
        <img
          src={imageUrl || ''}
          alt={title || ''}
          title={title || ''}
          style={style || styles.cardImage}
        />
      ) : (
        <Image
          style={[style || styles.cardImage]}
          source={{ uri: imageUrl }}
          resizeMode="cover"
        />
      )}
    </LinearGradient>
  )
}

const styles = StyleSheet.create({
  linearGradientContainer: {
    width: 100,
    height: 100,
    borderRadius: 8,
    marginRight: 16,
  },
  cardImage: {
    width: 100,
    height: 100,
    borderRadius: 8,
    objectFit: 'cover',
  },
})

export default SpiritImage
