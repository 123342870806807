import { useEffect, useState } from 'react'
import noteApi from '@/Services/modules/note'
import { NestedSetType } from '@/Services/modules/note/note.type'

const useGetNestedSetI18n = () => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { isSuccess: flavorIsSuccess, isLoading: flavorIsLoading } =
    noteApi.useGetNestedSetQuery({
      type: NestedSetType.FLAVOR_WHEEL,
    })

  const { isSuccess: categoryIsSuccess, isLoading: categoryIsLoading } =
    noteApi.useGetNestedSetQuery({
      type: NestedSetType.CATEGORY,
    })

  const { isSuccess: locationIsSuccess, isLoading: locationIsLoading } =
    noteApi.useGetNestedSetQuery({
      type: NestedSetType.LOCATION,
    })

  useEffect(() => {
    if (flavorIsSuccess && categoryIsSuccess && locationIsSuccess) {
      setIsSuccess(true)
      setIsLoading(false)
    }
  }, [flavorIsSuccess, categoryIsSuccess, locationIsSuccess])

  useEffect(() => {
    if (flavorIsLoading || categoryIsLoading || locationIsLoading) {
      setIsLoading(true)
    }
  }, [flavorIsLoading, categoryIsLoading, locationIsLoading])

  return {
    isSuccess,
    isLoading,
  }
}

export default useGetNestedSetI18n
