import {
  GetInviteFriendListResponse,
  GetUserFollowResponse,
} from '@/Services/modules/users/user.type'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type UserState = {
  register: {
    account: string
    password: string
    socialHasAccount: boolean
    socialProvider: string
  }
  followers: GetUserFollowResponse
  followings: GetUserFollowResponse
  inviteFriendList: GetInviteFriendListResponse
}

const initialState = {
  register: {
    account: '',
    password: '',
    socialHasAccount: false,
    socialProvider: '',
  },
  followers: {
    data: [],
    meta: {
      totalRows: 0,
      totalPages: 0,
      currentPage: 0,
      perPage: 0,
    },
  },
  followings: {
    data: [],
    meta: {
      totalRows: 0,
      totalPages: 0,
      currentPage: 0,
      perPage: 0,
    },
  },
  inviteFriendList: {
    data: [],
    meta: {
      nextCursor: '',
      perPage: 0,
    },
  },
}

const slice = createSlice({
  name: 'user',
  initialState: initialState as UserState,
  reducers: {
    resetUserStore: () => initialState,
    setUserRegisterData: (
      state,
      action: PayloadAction<
        Partial<{
          account: string
          password: string
          socialHasAccount: boolean
          socialProvider: string
        }>
      >,
    ) => {
      state.register = {
        ...state.register,
        ...action.payload,
      }
    },
    setFollowers: (state, action: PayloadAction<GetUserFollowResponse>) => {
      state.followers.data = state.followers.data.concat(...action.payload.data)
      state.followers.meta = action.payload.meta
    },
    setFollowings: (state, action: PayloadAction<GetUserFollowResponse>) => {
      state.followings.data = state.followings.data.concat(
        ...action.payload.data,
      )
      state.followings.meta = action.payload.meta
    },
    toggleFollow: (state, action: PayloadAction<{ id: string }>) => {
      state.followers.data.forEach(item => {
        if (item.id === action.payload.id) {
          item.followed = !item.followed
        }
      })

      state.followings.data.forEach(item => {
        if (item.id === action.payload.id) {
          item.followed = !item.followed
        }
      })
    },
    clearFollowersAndFollowings: state => {
      state.followers = initialState.followers
      state.followings = initialState.followings
    },
    setInviteFriendList: (
      state,
      action: PayloadAction<GetInviteFriendListResponse>,
    ) => {
      state.inviteFriendList.data = state.inviteFriendList.data.concat(
        ...action.payload.data,
      )
      state.inviteFriendList.meta = action.payload.meta
    },
    setInviteFriendListFollow: (
      state,
      action: PayloadAction<{ id: string }>,
    ) => {
      state.inviteFriendList.data.forEach(item => {
        if (item.id === action.payload.id) {
          item.followed = !item.followed
        }
      })
    },
    clearInviteFriendList: state => {
      state.inviteFriendList = initialState.inviteFriendList
    },
  },
})

export const userReducer = slice.reducer
export const userActions = slice.actions

export default slice.reducer
