import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  FlatList,
  ActivityIndicator,
  TextInput,
  Dimensions,
  Keyboard,
} from 'react-native'
import { useIsFocused } from '@react-navigation/native'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme, useCategory } from '@/Hooks'
import { BorderRadius, Colors, Height } from '@/Theme/Variables'
import {
  FadeInView,
  Header,
  SpiritCard,
  WarningSignComponent,
} from '@/Components'
import { Appbar } from 'react-native-paper'
import { spiritsApi } from '@/Services/modules/spirits'
import { useTranslation } from 'react-i18next'
import useDebounce from '@/Util/useDebounce'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { removeEmptyKeys } from '@/Util/global'
import { RootState, getGlobal } from '@/Store'
import { LogSearchTextResultEnum, logApi } from '@/Services/logApi'
import { navigate } from '@/Navigators/utils'
import { spiritActions } from '@/Store/Spirit'
import { SpiritCardHeight } from '@/Components/SpiritCard'
import { globalActions } from '@/Store/Global'
import userApi from '@/Services/modules/users'
import { DownLoadSign } from '@/Components'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { PagesEnum } from '@/Navigators/Application'
import { MainPagesEnum } from '@/Navigators/Main'

const SearchListContainer = ({ route, navigation }: any) => {
  const { Layout, Gutters, Images, Fonts } = useTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isFocused = useIsFocused()
  const categoryData = useCategory()
  const { language } = useSelector((state: RootState) => state.theme)
  const { searchList: searchListData } = useSelector(
    (state: RootState) => state.spirit,
  )
  const globalState = useSelector(getGlobal)
  const { productImageLightBox } = globalState
  const textInputRef = useRef<any>()
  const flatListRef = useRef<any>()

  const { data: getMeData } = userApi.useGetMeQuery()

  const [
    getSearchSpiritsRequest,
    {
      isSuccess: isGetSearchSpiritsSuccess,
      isLoading: isGetSearchSpiritsLoading,
      isFetching: isGetSearchSpiritsFetching,
    },
  ] = spiritsApi.useLazyGetSearchSpiritsQuery()

  // 紀錄文字搜尋 log 後續點擊卡片行為
  const [lazyGetSearchLogRequest] = logApi.useLazyGetSearchLogQuery()

  // 記錄文字搜尋的 keyword log
  const [lazyGetSearchResultLogRequest] =
    logApi.useLazyGetSearchResultLogQuery()

  const [searchKeyword, setSearchKeyword] = useState<string>(
    route?.params?.q ? route?.params?.q : '',
  )
  // 除了 q 之外的 params
  const [filters, setFilters] = useState<any>({})
  const [page, setPage] = useState<number>(1)

  //TODO: 將來不只 category 要補上
  const initFilters = useMemo(() => Object.keys(filters)?.length, [filters])
  const initQuery = useMemo(() => {
    if (isFocused) {
      return route.params?.q
    }
  }, [route.params?.q, isFocused])
  const showEmptyComponentCondition = useMemo(() => {
    // 在類別搜尋頁面直接回傳 true , 在文字搜尋頁面需要等到 api 回傳以及有 keyword 才能判斷
    if (initFilters) {
      return true
    } else {
      return isGetSearchSpiritsSuccess && !!searchKeyword?.length
    }
  }, [initFilters, isGetSearchSpiritsSuccess, searchKeyword?.length])

  const styles = getStyle(searchKeyword)

  const handleClearSearchKeyword = useCallback(() => {
    setSearchKeyword('')
    dispatch(spiritActions.clearSearchList())
    setPage(1)
    textInputRef?.current?.focus()
    navigation.setParams({ q: undefined })
  }, [dispatch, navigation])

  const handleGoBack = useCallback(() => {
    handleClearSearchKeyword()
    dispatch(spiritActions.clearSearchList())
    setPage(1)
    setFilters({})

    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }
    navigation.navigate(PagesEnum.Main, {
      screen: MainPagesEnum.Home,
    })
  }, [dispatch, handleClearSearchKeyword, navigation])

  // 將 metadata 放入 filters 中
  useLayoutEffect(() => {
    const paramsObj = {
      [route?.params?.filters]: route.params?.keys,
    }
    setFilters(removeEmptyKeys(paramsObj))
  }, [route.params])

  useEffect(() => {
    if (textInputRef.current) {
      textInputRef.current.focus()
    }
  }, [initFilters])

  // 初次近來此頁面時，如果有帶入 query，則直接發送 request
  useEffect(() => {
    if ((initQuery || initFilters) && isFocused) {
      // 避免打到重複的 page 資料
      if (searchListData.data?.length) {
        return
      }
      const params = {
        query: initQuery ? initQuery : '',
        filters: initFilters ? filters : '',
        page,
      }
      getSearchSpiritsRequest(removeEmptyKeys(params))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, isFocused])

  // 紀錄文字搜尋的 keyword log
  useEffect(() => {
    if (
      route?.params?.q &&
      isGetSearchSpiritsSuccess &&
      !!searchListData?.requestId?.length
    ) {
      const hasResult =
        searchListData?.data?.length > 0 &&
        searchListData?.meta?.totalRows !== 0

      lazyGetSearchResultLogRequest({
        query: route?.params?.q,
        request_id: searchListData?.requestId,
        has_result: hasResult
          ? LogSearchTextResultEnum.HAS_RESULT
          : LogSearchTextResultEnum.NO_RESULT,
      })
    }
  }, [
    isGetSearchSpiritsSuccess,
    lazyGetSearchResultLogRequest,
    route?.params?.q,
    searchListData,
  ])

  // 如果清空 searchKeyword，則清空 boozesList
  useEffect(() => {
    if (!searchKeyword) {
      handleClearSearchKeyword()
    }
  }, [handleClearSearchKeyword, searchKeyword])

  const handleFetchMoreData = useCallback(() => {
    const isNeedFetch =
      searchListData.meta.currentPage !== searchListData.meta.totalPages &&
      page !== searchListData.meta.totalPages

    if (isNeedFetch) {
      const nextPage = page + 1
      const params = {
        query: searchKeyword || '',
        filters: filters || '',
        page: nextPage,
      }
      getSearchSpiritsRequest(removeEmptyKeys(params))
      setPage(nextPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, getSearchSpiritsRequest, searchKeyword, searchListData.meta])

  const fetchData = useCallback(() => {
    if (searchKeyword?.length) {
      const params = {
        query: searchKeyword || '',
        filters: filters || '',
        page: 1,
      }

      dispatch(spiritActions.clearSearchList())
      getSearchSpiritsRequest(removeEmptyKeys(params))
      navigation.setParams({ q: searchKeyword })
      // web 需要這樣寫 GA 才偵測得到
      // @ts-ignore
      if (window?.gtag) {
        // @ts-ignore
        window?.gtag('event', 'view_search_results', {
          search_term: searchKeyword,
        })
      }
    }
  }, [searchKeyword, filters, dispatch, getSearchSpiritsRequest, navigation])

  useDebounce(fetchData, 1000, [searchKeyword])

  const openLoginDialog = () => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        title: t('camera.bottomDialogTitle'),
        disabledBackgroundClose: false,
        content: (
          <View
            style={[
              Layout.center,
              Gutters.regularVMargin,
              Gutters.regularHPadding,
            ]}
          >
            <Image
              source={Images.bottom_dialog_login_leave}
              resizeMode="contain"
              style={[Gutters.regularBMargin, Layout.iconSize64]}
            />
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.center,
                Gutters.smallBMargin,
                Gutters.tinyVPadding,
                {
                  backgroundColor: Colors.primary,
                  borderRadius: BorderRadius.radius8,
                  height: Height.height48,
                },
              ]}
              onPress={() => {
                dispatch(globalActions.closeBottomDialog())
                navigation.navigate(PagesEnum.LoginMainContainer)
              }}
            >
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size14,
                  { color: Colors.primaryText },
                ]}
              >
                {t('camera.bottomDialogButton1')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.center,
                Gutters.smallBMargin,
                Gutters.tinyVPadding,
                {
                  height: Height.height48,
                },
              ]}
              onPress={() => {
                dispatch(globalActions.closeBottomDialog())
              }}
            >
              <Text style={{ color: Colors.white }}>
                {t('camera.bottomDialogButton2')}
              </Text>
            </TouchableOpacity>
          </View>
        ),
      }),
    )
  }

  const handleClickReportSpirit = () => {
    if (!getMeData) {
      openLoginDialog()
      return
    }
    navigation.navigate(AuthPagesEnum.UploadSpiritsDetailContainer)
  }

  const handleClickCard = (
    id: string,
    titleUrlSlug: string,
    itemPage: number,
    selectedRow: number,
    requestId: string,
  ) => {
    if (searchKeyword) {
      AsyncStorage.setItem('searchKeyword', searchKeyword)
    }
    if (filters) {
      AsyncStorage.setItem('searchFilters', JSON.stringify(filters))
    }
    // 如果是文字搜尋的話，要帶上 searchKeyword , page , selectedRow 打 log
    if (!initFilters) {
      lazyGetSearchLogRequest({
        page: itemPage,
        selected_row: selectedRow,
        request_id: requestId,
        selected_spirit_id: id,
      })
    }
    navigate(PagesEnum.ProductDetail, {
      lang: language,
      titleUrlSlug,
      id,
    })
  }

  const renderCardList = ({ item }: any) => {
    const {
      title,
      id,
      imageUrl,
      page: itemPage,
      selectedRow,
      ratings,
      titleUrlSlug,
      brand,
      requestId,
    } = item
    return (
      <SpiritCard
        key={id}
        id={id}
        onClick={() =>
          handleClickCard(id, titleUrlSlug, itemPage, selectedRow, requestId)
        }
        imageUrl={imageUrl}
        title={title}
        rating={ratings?.average}
        ratingCount={ratings?.count}
        brand={brand}
      />
    )
  }

  const renderListFooter = () => {
    if (isGetSearchSpiritsFetching) {
      return (
        <View>
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      )
    }
    if (
      searchListData.data?.length &&
      searchListData.meta.currentPage === searchListData.meta.totalPages
    ) {
      return (
        <View style={[Layout.center, Gutters.largeTPadding]}>
          <Text
            style={[
              Fonts.weight500,
              Fonts.size16,
              Gutters.regularBMargin,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            找不到酒？
          </Text>
          <TouchableOpacity onPress={handleClickReportSpirit}>
            <Text
              style={[
                Fonts.weight500,
                Fonts.size16,
                Gutters.regularBMargin,
                { color: Colors.primary },
              ]}
            >
              協助新增品項
            </Text>
          </TouchableOpacity>
        </View>
      )
    }
  }

  const renderEmptyView = () => {
    if (isGetSearchSpiritsFetching || isGetSearchSpiritsLoading) {
      return (
        <View style={[Layout.colCenter, Layout.fullHeight]}>
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      )
    }
    return (
      <View
        style={[Layout.colCenter, Layout.fullHeight, Gutters.regularHPadding]}
      >
        {showEmptyComponentCondition && (
          <>
            <Image
              source={Images.search_list_empty}
              resizeMode="contain"
              style={[Gutters.regularBMargin, styles.emptyIcon]}
            />
            <Text
              style={[
                Fonts.weight700,
                Fonts.size16,
                Gutters.tinyBMargin,
                { color: Colors.white },
              ]}
            >
              抱歉，找不到相似的結果
            </Text>
            <Text
              style={[
                Fonts.weight400,
                Fonts.size14,
                Gutters.regularBMargin,
                { color: Colors.secondaryText },
              ]}
            >
              請嘗試搜尋其他烈酒
            </Text>
            <TouchableOpacity onPress={handleClickReportSpirit}>
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size16,
                  Gutters.regularBMargin,
                  { color: Colors.primary },
                ]}
              >
                協助新增品項
              </Text>
            </TouchableOpacity>
          </>
        )}
      </View>
    )
  }

  // 文字搜尋的 title
  const renderTextSearchTitle = () => {
    return (
      <Appbar.Header
        style={[
          Layout.fullWidth,
          Layout.rowCenter,
          Gutters.regularHPadding,
          {
            backgroundColor: Colors.background.default,
          },
        ]}
      >
        <TouchableOpacity onPress={handleGoBack}>
          <Image
            style={[Layout.iconSize24]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        </TouchableOpacity>
        <View
          style={[
            Layout.row,
            Gutters.smallHPadding,
            styles.searchInputContainer,
          ]}
        >
          <TextInput
            ref={textInputRef}
            style={[styles.searchInput, { color: Colors.white }]}
            value={searchKeyword}
            placeholder="搜尋今天喝的烈酒，例如 Jimbeam"
            placeholderTextColor={Colors.gray}
            returnKeyType="search"
            returnKeyLabel="search"
            onChangeText={text => setSearchKeyword(text)}
            onSubmitEditing={fetchData}
          />
          {!!searchKeyword?.length && (
            <TouchableOpacity onPress={handleClearSearchKeyword}>
              <Image
                source={Images.close}
                style={Layout.iconSize24}
                resizeMode="contain"
              />
            </TouchableOpacity>
          )}
        </View>
      </Appbar.Header>
    )
  }

  // 利用 filter metadata 搜尋的 title
  const renderMetaDataTitle = () => {
    const categoryItem = categoryData.find(
      item => item.keys === filters?.category,
    )
    const title =
      language === 'zh' ? categoryItem?.zhTitle : categoryItem?.title

    return (
      <Header
        title={title}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
      />
    )
  }

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <FadeInView duration={500} style={Layout.fill}>
        {initFilters ? renderMetaDataTitle() : renderTextSearchTitle()}
        <View style={[Layout.fill]}>
          {isGetSearchSpiritsLoading ? (
            <View style={[Layout.fill, Layout.center]}>
              <ActivityIndicator size="large" color={Colors.primary} />
            </View>
          ) : (
            <View
              style={[Layout.fill, { height: Dimensions.get('window').height }]}
            >
              <FlatList
                ref={flatListRef}
                data={searchListData.data || []}
                contentContainerStyle={[Layout.fill]}
                keyExtractor={item => item.id.toString()}
                renderItem={renderCardList}
                onScroll={() => Keyboard?.dismiss()}
                refreshing
                scrollEnabled={!productImageLightBox?.visible}
                maxToRenderPerBatch={10}
                onEndReached={() => {
                  handleFetchMoreData()
                }}
                onEndReachedThreshold={0.1}
                getItemLayout={(data, index) => {
                  return {
                    length: SpiritCardHeight,
                    offset: SpiritCardHeight * index,
                    index,
                  }
                }}
                ListFooterComponent={renderListFooter}
                ListEmptyComponent={renderEmptyView}
              />
            </View>
          )}
        </View>
      </FadeInView>
      <View>
        <WarningSignComponent bottomSpace />
      </View>
      <DownLoadSign />
    </View>
  )
}

const getStyle = (searchKeyword: string) =>
  StyleSheet.create({
    emptyIcon: {
      width: 150,
      height: 150,
    },
    searchInputContainer: {
      width: '100%',
      height: 40,
      backgroundColor: Colors.background.default,
      borderRadius: 8,
      borderColor: searchKeyword?.length
        ? Colors.primary
        : 'rgba(255, 255, 255, 0.6)',
      borderWidth: 1,
      alignItems: 'center',
    },
    searchInput: {
      width: '90%',
      marginLeft: 4,
      outlineWidth: 0,
      outlineColor: 'transparent',
      outlineStyle: 'none',
    },
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
  })

export default SearchListContainer
