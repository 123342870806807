import React from 'react'
import { View, Image, Text, TouchableOpacity } from 'react-native'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { navigate } from '@/Navigators/utils'
import { PagesEnum } from '@/Navigators/Application'
import { MainPagesEnum } from '@/Navigators/Main'

interface Props {
  onClick: () => void
}

const ErrorBoundary = ({ onClick }: Props) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()

  return (
    <View style={[Layout.fill, Layout.center, Gutters.regularHPadding]}>
      <Image
        source={Images.empty}
        resizeMode="contain"
        style={[
          Gutters.tinyBMargin,
          {
            width: 150,
            height: 150,
          },
        ]}
      />
      <Text
        style={[
          Fonts.size16,
          Fonts.weight700,
          Gutters.tinyBMargin,
          { color: Colors.fontText.light.primary2 },
        ]}
      >
        發生錯誤
      </Text>
      <Text
        style={[
          Fonts.size14,
          Fonts.weight500,
          Gutters.smallBMargin,
          { color: Colors.fontText.light.primary2 },
        ]}
      >
        抱歉，發生了一些錯誤。請返回首頁再試一次。
      </Text>
      <TouchableOpacity
        style={[
          Layout.fullWidth,
          Layout.center,
          {
            backgroundColor: Colors.primary,
            borderRadius: 8,
            height: 48,
          },
        ]}
        onPress={() => {
          onClick()
          navigate(PagesEnum.Main, {
            screen: MainPagesEnum.Home,
          })
        }}
      >
        <Text
          style={[
            Fonts.size16,
            Fonts.weight500,
            { color: Colors.fontText.dark.primary2 },
          ]}
        >
          返回首頁
        </Text>
      </TouchableOpacity>
    </View>
  )
}

export default ErrorBoundary
