// @ts-nocheck
export function register() {
  if ('serviceWorker' in navigator) {
    // window load後才執行，以免增加頁面讀取的時間
    window.addEventListener('load', function () {
      // 建立sw.js (需要是 js檔案，瀏覽器吃不到 ts檔案)
      navigator.serviceWorker
        .register('./service-worker.js')
        .then(() => {
          console.log('SW success')
        })
        // 註冊失敗
        .catch(err => {
          console.log('error: ', err)
        })

      // 附上刪除sw.js的寫法
      navigator.serviceWorker.getRegistrations().then(function (reg) {
        for (var reg of reg) {
          reg.unregister()
        }
      })
    })
  }
}
