import { useEffect, useState } from 'react'

const useFullScreen = () => {
  const [fullScreen, setFullScreen] = useState<boolean>(false)

  useEffect(() => {
    // @ts-ignore
    const isInWebAppiOS = window.navigator.standalone === true
    // @ts-ignore
    const isInWebAppChrome = window.matchMedia(
      '(display-mode: standalone)',
    ).matches
    // @ts-ignore
    if (isInWebAppiOS || isInWebAppChrome) {
      setFullScreen(true)
    } else {
      setFullScreen(false)
    }
  }, [])

  return fullScreen
}

export default useFullScreen
