import React, { useEffect, useRef } from 'react'
import { Animated, StyleSheet } from 'react-native'

interface FadeInViewProps {
  style: any
  children: any
  duration: number
}

const FadeInView = (props: Partial<FadeInViewProps>) => {
  const fadeIn = useRef(new Animated.Value(0)).current // 透明度初始值設為 0

  useEffect(() => {
    Animated.timing(fadeIn, {
      toValue: 1,
      duration: props.duration || 1000,
      useNativeDriver: true,
    }).start()
  }, [fadeIn, props.duration])

  return (
    <Animated.View // 使用 Animated.View
      style={{
        ...styles.container,
        ...props.style,
        opacity: fadeIn, // 將透明度指定為 fadeIn
      }}
    >
      {props.children}
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  container: {},
})

export default FadeInView
