import { api, prefixProxyEndpoint } from '@/Services/api'
import {
  GetUserAchievementsResponse,
  GetMeAchievementsResponse,
  GetAllAchievementResponse,
} from './achievements.type'

export const achievementsApi = api.injectEndpoints({
  endpoints: build => ({
    // 取得特定 user 成就
    getUserAchievements: build.query<
      GetUserAchievementsResponse,
      { id: string }
    >({
      query: ({ id }) => ({
        url: prefixProxyEndpoint(`v1/users/${id}/achievements`),
        method: 'GET',
      }),
      providesTags: ['achievements'],
    }),
    // 取得自己所有成就
    getMyAchievements: build.query<GetMeAchievementsResponse, void>({
      query: () => ({
        url: prefixProxyEndpoint('v1/my-achievements'),
        method: 'GET',
      }),
      providesTags: ['achievements'],
    }),
    // 取得所有成就列表
    getAllAchievementList: build.query<GetAllAchievementResponse, void>({
      query: () => ({
        url: prefixProxyEndpoint('v1/achievement-definitions'),
        method: 'GET',
      }),
      providesTags: ['achievements'],
    }),
  }),
  overrideExisting: false,
})

achievementsApi.enhanceEndpoints({
  addTagTypes: ['achievements'],
  endpoints: {},
})

export default achievementsApi
