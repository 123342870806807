/**
 * Images should be stored in the `App/Images` directory and referenced using variables defined here.
 */

export default function () {
  return {
    logo: {
      uri: 'https://thecodingmachine.github.io/react-native-wenlibaimobile/img/TOM-small.png',
    },
  }
}
