import React, { useCallback, useEffect, useState, useRef } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  FlatList,
  ScrollView,
  Dimensions,
  TextInput,
} from 'react-native'
import { useFlavorData, useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { FadeInView, Header } from '@/Components'
import { capitalizeFirstLetter } from '@/Util/global'
import { spiritsApi } from '@/Services/modules/spirits'
import { useTranslation } from 'react-i18next'
import { LinearGradient } from 'expo-linear-gradient'
import { spiritActions } from '@/Store/Spirit'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/Store'
import { navigateAndSimpleReset } from '@/Navigators/utils'
import noteApi from '@/Services/modules/note'
import { NestedSetType } from '@/Services/modules/note/note.type'
import { DownLoadSign } from '@/Components'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { PagesEnum } from '@/Navigators/Application'

const FlavorContainer = ({ route, navigation }: any) => {
  const { id, mySpiritId, titleUrlSlug } = route.params
  const { Layout, Gutters, Images, Fonts } = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { flavors: selectedFlavorList } = useSelector(
    (state: RootState) => state.spirit.spirit,
  )
  const { result: FlavorData, handleGetFlavorColor } = useFlavorData()

  const flavorListRef = useRef<any>()

  // 取得該支酒的個人資訊，包括是否有收藏、個人評論
  spiritsApi.useGetSpiritPersonalInfoQuery({
    id: route.params?.id,
  })

  // 取得風味資訊
  const { isSuccess: nestedSetIsSuccess } = noteApi.useGetNestedSetQuery({
    type: NestedSetType.FLAVOR_WHEEL,
  })

  const [flavorList, setFlavorList] = useState<any>(FlavorData)
  const [selectedFlavorCard, setSelectedFlavorCard] = useState<any>(null)

  const styles = getStyle()

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }
    navigateAndSimpleReset(PagesEnum.Main)
  }, [navigation])

  // 將風味資料存進來
  useEffect(() => {
    if (nestedSetIsSuccess) {
      setFlavorList(FlavorData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nestedSetIsSuccess])

  const handleSaveFlavorDataInStore = (data: string[]) => {
    dispatch(
      spiritActions.setSpiritData({
        flavors: data,
      }),
    )
  }

  const handleSelectFlavorCard = (itemId: string) => {
    const selectedFlavorItem = FlavorData?.find(
      (flavor: any) => flavor?.id === itemId,
    )
    setSelectedFlavorCard(selectedFlavorItem)
    flavorListRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  const handleSelectFlavorItem = (flavor: string) => {
    if (selectedFlavorList?.includes(flavor)) {
      const newSelectedFlavorList = selectedFlavorList?.filter(
        item => item !== flavor,
      )
      handleSaveFlavorDataInStore(newSelectedFlavorList)
      return
    }
    handleSaveFlavorDataInStore([...(selectedFlavorList || []), flavor])
  }

  const handleRemoveSelectFlavorItem = (flavor: string) => {
    const newSelectedFlavorList = selectedFlavorList?.filter(
      item => item !== flavor,
    )
    handleSaveFlavorDataInStore(newSelectedFlavorList || [])
  }

  const countSelectedNum = (array1: string[], array2: string[]): number => {
    const set1 = new Set(array1)
    const set2 = new Set(array2)

    let count = 0

    for (const value of set1) {
      if (set2.has(value)) {
        count++
      }
    }

    return count
  }

  const renderFlavorCard = ({ item }: any) => {
    const isSelected = selectedFlavorCard?.id === item.id
    const selectedNum = countSelectedNum(
      selectedFlavorList || [],
      item.flavor.map((flavor: any) => flavor.key),
    )

    return (
      <TouchableOpacity
        style={[
          styles.flavorCard,
          {
            opacity: isSelected ? 0.5 : 1,
          },
        ]}
        disabled={isSelected}
        onPress={() => handleSelectFlavorCard(item.id)}
      >
        <LinearGradient
          style={styles.linearGradientContainer}
          colors={item.linearGradient}
          end={{ x: 90, y: 80 }}
        >
          <Text
            style={[
              Fonts.size20,
              Fonts.weight500,
              Gutters.miniBMargin,
              styles.flavorTitle,
              {
                color: Colors.white,
              },
            ]}
          >
            {capitalizeFirstLetter(item.enName)}
          </Text>
          <Text style={[Fonts.size14, { color: Colors.white }]}>
            {`${item.zhName} ${selectedNum ? `( ${selectedNum} )` : ''}`}
          </Text>
          <Image
            // @ts-ignore
            source={Images[item.icon]}
            resizeMode="contain"
            style={[styles.flavorCardIcon, { opacity: 0.5 }]}
          />
        </LinearGradient>
      </TouchableOpacity>
    )
  }

  return (
    <View style={[Layout.fill, { backgroundColor: Colors.background.default }]}>
      <ScrollView
        style={(Layout.fill, { height: Dimensions.get('window').height })}
      >
        <FadeInView duration={500} style={Layout.fill}>
          <Header
            title="風味標記"
            leftIcon={
              <Image
                style={[styles.arrowLeftIcon]}
                source={Images.arrowLeft}
                resizeMode="contain"
              />
            }
            leftIconPress={handleGoBack}
            rightEmptyIconWidth="50"
          />
          <View style={[Gutters.smallHPadding]}>
            <TouchableOpacity
              style={[Layout.row, styles.searchInputContainer]}
              onPress={() =>
                navigation.navigate(AuthPagesEnum.FlavorSearch, {
                  id,
                  mySpiritId,
                  titleUrlSlug,
                })
              }
            >
              <Image
                style={Layout.iconSize24}
                source={Images.home_search}
                resizeMode="contain"
              />
              <TextInput
                style={[styles.searchInput, { color: Colors.white }]}
                placeholder="搜尋風味"
                placeholderTextColor={Colors.gray}
              />
            </TouchableOpacity>
          </View>
          <View
            ref={flavorListRef}
            style={[
              Gutters.tinyBMargin,
              { backgroundColor: Colors.background.surface },
            ]}
          >
            {/* 已標注風味 */}
            <View style={[Gutters.smallHPadding, Gutters.smallVPadding]}>
              <Text
                style={[
                  Fonts.weight700,
                  Fonts.size16,
                  { color: Colors.secondaryText },
                ]}
              >
                已標注風味
              </Text>
            </View>
            {/* 被選取的風味列表 */}
            <View
              style={[
                Layout.row,
                Gutters.smallBPadding,
                Gutters.smallHPadding,
                styles.flavorSelectedItemContainer,
              ]}
            >
              {selectedFlavorList?.length ? (
                selectedFlavorList?.map(item => {
                  const color = handleGetFlavorColor(item)
                  return (
                    <View
                      style={[
                        Layout.row,
                        Layout.alignItemsCenter,
                        styles.flavorSelectedItem,
                      ]}
                    >
                      <View
                        style={[
                          Gutters.miniRMargin,
                          styles.flavorDot,
                          { backgroundColor: color },
                        ]}
                      />
                      <Text
                        style={[
                          Fonts.size14,
                          Fonts.weight700,
                          Gutters.miniRMargin,
                          {
                            color: Colors.fontText.light.primary2,
                          },
                        ]}
                      >
                        {t(`flavor-wheel:${item}`)}
                      </Text>
                      <TouchableOpacity
                        onPress={() => handleRemoveSelectFlavorItem(item)}
                      >
                        <Image
                          source={Images.close}
                          resizeMode="contain"
                          style={Layout.iconSize14}
                        />
                      </TouchableOpacity>
                    </View>
                  )
                })
              ) : (
                <Text
                  style={[
                    Fonts.weight400,
                    Fonts.size14,
                    { color: Colors.darkText },
                  ]}
                >
                  尚無紀錄
                </Text>
              )}
            </View>
          </View>
          <View style={{ backgroundColor: Colors.background.surface }}>
            {/* 風味分類 */}
            <View style={[Gutters.smallHPadding, Gutters.smallVPadding]}>
              <Text
                style={[
                  Fonts.weight700,
                  Fonts.size16,
                  { color: Colors.secondaryText },
                ]}
              >
                風味分類
              </Text>
            </View>
            {/* 被選取的風味卡片 */}
            {selectedFlavorCard && (
              <FadeInView duration={350}>
                <View style={[Gutters.smallHPadding, Gutters.smallTMargin]}>
                  <TouchableOpacity style={styles.flavorMainCard}>
                    <LinearGradient
                      style={styles.linearGradientContainer}
                      colors={selectedFlavorCard.linearGradient}
                      end={{ x: 90, y: 80 }}
                    >
                      <Text
                        style={[
                          Fonts.size24,
                          Fonts.weight500,
                          Gutters.miniBMargin,
                          styles.flavorTitle,
                          {
                            color: Colors.white,
                          },
                        ]}
                      >
                        {capitalizeFirstLetter(selectedFlavorCard?.enName)}
                      </Text>
                      <Text
                        style={[
                          Fonts.size14,
                          {
                            color: Colors.white,
                          },
                        ]}
                      >
                        {`${selectedFlavorCard?.zhName} (${countSelectedNum(
                          selectedFlavorList || [],
                          selectedFlavorCard.flavor?.map(
                            (flavor: any) => flavor.key,
                          ),
                        )})`}
                      </Text>
                      <Image
                        // @ts-ignore
                        source={Images[selectedFlavorCard.icon]}
                        resizeMode="contain"
                        style={styles.selectedFlavorCardIcon}
                      />
                    </LinearGradient>
                  </TouchableOpacity>
                  {/* 卡片內的風味列表 */}
                  <View
                    style={[
                      Gutters.smallVMargin,
                      Layout.row,
                      styles.flavorItemContainer,
                    ]}
                  >
                    {selectedFlavorCard.flavor.map((flavor: any) => {
                      const isSelected = selectedFlavorList?.includes(
                        flavor.key,
                      )
                      const color = handleGetFlavorColor(flavor?.key)
                      return (
                        <TouchableOpacity
                          style={[
                            Layout.row,
                            Layout.alignItemsCenter,
                            styles.flavorItem,
                            {
                              opacity: isSelected ? 0.5 : 1,
                            },
                          ]}
                          disabled={isSelected}
                          onPress={() => handleSelectFlavorItem(flavor.key)}
                        >
                          <View
                            style={[
                              Gutters.miniRMargin,
                              styles.flavorDot,
                              { backgroundColor: color },
                            ]}
                          />
                          <Text
                            style={[
                              Fonts.bold,
                              Fonts.size14,
                              {
                                color: Colors.fontText.light.primary2,
                              },
                            ]}
                          >
                            {t(flavor.i18n)}
                          </Text>
                          <Image
                            source={Images.rating_comment_add_flavor}
                            resizeMode="contain"
                            style={Layout.iconSize20}
                          />
                        </TouchableOpacity>
                      )
                    })}
                  </View>
                </View>
              </FadeInView>
            )}
            {/* 風味卡片 */}
            <View
              style={[
                Gutters.smallHPadding,
                Gutters.smallTMargin,
                styles.marginB100,
              ]}
            >
              <FlatList
                keyExtractor={item => item.id}
                data={flavorList}
                renderItem={renderFlavorCard}
                numColumns={2}
                columnWrapperStyle={[
                  Layout.justifyContentBetween,
                  Gutters.smallBMargin,
                ]}
              />
            </View>
          </View>
        </FadeInView>
      </ScrollView>
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    letterSpacing10: {
      letterSpacing: 10,
    },
    marginB100: { marginBottom: 100 },
    headerContainer: {
      backgroundColor: Colors.background.default,
      borderBottomColor: Colors.darkText,
      borderBottomWidth: 1,
    },
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    searchInputContainer: {
      width: '100%',
      height: 40,
      backgroundColor: Colors.background.default,
      borderRadius: 8,
      alignItems: 'center',
      paddingLeft: 12,
      borderWidth: 1,
      borderColor: Colors.gray,
      marginBottom: 16,
    },
    searchInput: {
      width: '100%',
      marginLeft: 4,
      outlineWidth: 0,
      outlineColor: 'transparent',
      outlineStyle: 'none',
    },
    flavorSelectedItemContainer: {
      flexWrap: 'wrap',
    },
    flavorSelectedItem: {
      width: 'fit-content',
      marginLeft: 16,
      marginBottom: 16,
      paddingVertical: 8,
      paddingHorizontal: 16,
      borderRadius: 80,
      backgroundColor: Colors.darkLight,
    },
    flavorTitle: {
      lineHeight: 24,
      letterSpacing: 2,
      fontFamily: 'Poppins',
    },
    flavorDot: {
      width: 8,
      height: 8,
      borderRadius: 4,
    },
    flavorMainCard: {
      width: '100%',
      height: 120,
      borderRadius: 10,
      overflow: 'hidden',
    },
    flavorCard: {
      width: '48%',
      height: 120,
      borderRadius: 10,
      overflow: 'hidden',
    },
    flavorCardIcon: {
      width: 91,
      height: 89,
      position: 'absolute',
      right: -15,
      bottom: -15,
    },
    selectedFlavorCardIcon: {
      width: 91,
      height: 89,
      position: 'absolute',
      right: 0,
      bottom: 0,
    },
    linearGradientContainer: {
      width: '100%',
      height: '100%',
      padding: 16,
      borderRadius: 10,
    },
    flavorItemContainer: {
      flexWrap: 'wrap',
    },
    flavorItem: {
      marginLeft: 16,
      marginBottom: 16,
      paddingVertical: 8,
      paddingHorizontal: 16,
      borderRadius: 80,
      backgroundColor: Colors.darkLight,
    },
    doneButtonContainer: {
      width: '100%',
      paddingHorizontal: 16,
      position: 'absolute',
      bottom: 0,
    },
    doneButton: {
      width: '100%',
      height: 56,
      backgroundColor: Colors.primary,
      borderRadius: 32,
    },
    submitBtn: {
      backgroundColor: Colors.primary,
      borderRadius: 8,
      height: 47,
    },
    CTAButton: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: 100,
      borderTopColor: Colors.darkText,
      borderTopWidth: 1,
      backgroundColor: Colors.background.default,
    },
  })

export default FlavorContainer
