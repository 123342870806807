import React, { useCallback } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  ScrollView,
  ActivityIndicator,
  StyleSheet,
  Dimensions,
} from 'react-native'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { FadeInView, Header } from '@/Components'
import { navigate } from '@/Navigators/utils'
import achievementsApi from '@/Services/modules/achievements'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'

const UserAchievementContainer = ({ route, navigation }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const styles = getStyle()

  const {
    data: getUserAchievementData,
    isLoading: getUserAchievementIsLoading,
  } = achievementsApi.useGetUserAchievementsQuery({
    id: route?.params?.id,
  })

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigate(PagesEnum.Main, {
      screen: 'Profile',
    })
  }, [navigation])

  if (getUserAchievementIsLoading) {
    return (
      <View style={[Layout.fill]}>
        <View
          style={[
            Layout.fill,
            Layout.colCenter,
            { backgroundColor: Colors.background.default },
          ]}
        >
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      </View>
    )
  }

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <Header
        title={'成就徽章'}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={navigation.canGoBack() ? Images.arrowLeft : Images.close}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
      />
      <FadeInView duration={500} style={Layout.fill}>
        <ScrollView style={[Layout.fill]}>
          <View
            style={[
              Gutters.smallVPadding,
              Gutters.smallHPadding,
              Layout.row,
              Layout.fill,
              Layout.flexWrap,
            ]}
          >
            {getUserAchievementData?.data?.map(item => {
              return (
                <View
                  style={[Layout.colCenter, { width: '50%', marginBottom: 60 }]}
                >
                  <Image
                    source={{ uri: item?.imageUrl }}
                    resizeMode="contain"
                    style={{
                      width: 50,
                      height: 120,
                    }}
                  />
                  <Text
                    style={[
                      Fonts.size14,
                      Fonts.weight400,
                      { color: Colors.fontText.light.primary2 },
                    ]}
                  >
                    {item?.group}
                  </Text>
                  <Text
                    style={[
                      Fonts.size16,
                      Fonts.weight700,
                      { color: Colors.fontText.light.primary2 },
                    ]}
                  >
                    {item?.name}
                  </Text>
                </View>
              )
            })}
          </View>
          <View style={[Layout.fullWidth, Layout.colCenter]}>
            <TouchableOpacity
              onPress={() =>
                navigation.navigate(PagesEnum.AllAchievementContainer)
              }
            >
              <Text
                style={[
                  Fonts.size16,
                  Fonts.weight700,
                  { color: Colors.primary },
                ]}
              >
                查看所有成就說明
              </Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </FadeInView>
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
  })

export default UserAchievementContainer
