import React, { useRef, useState } from 'react'
import { Text, StyleSheet, Animated, Image } from 'react-native'
import { useTheme } from '@/Hooks'
import { useDispatch } from 'react-redux'
import { globalActions } from '@/Store/Global'
import { Colors } from '@/Theme/Variables'

export interface SnackbarProps {
  visible: boolean
  message: string
  type: 'info' | 'success' | 'warning' | 'error' | 'default'
  position: 'bottom' | 'top'
  duration: number
  showIcon: boolean
}

const SnackBackgroundColor = {
  success: Colors.snackbar.success,
  error: Colors.snackbar.error,
  default: Colors.snackbar.default,
  warning: Colors.snackbar.warning,
  info: Colors.snackbar.info,
}

const Snackbar = ({
  visible,
  position,
  message,
  type,
  duration = 3000,
  showIcon = true,
}: SnackbarProps) => {
  const dispatch = useDispatch()
  const { Images, Layout, Gutters } = useTheme()
  const translateY = useRef(new Animated.Value(0)).current
  const [show, setShow] = useState(visible)
  const [readyToHideBar, setReadyToHideBar] = useState<boolean>(false)
  const [hideBar, setHideBar] = useState<boolean>(true)

  const styles = style(hideBar, translateY, position)

  React.useEffect(() => {
    Animated.timing(translateY, {
      toValue: show ? 1 : 0,
      duration: 500,
      useNativeDriver: true,
    }).start()
  }, [show, translateY])

  React.useEffect(() => {
    setShow(visible)
    if (visible) {
      setHideBar(false)
      const timeout = setTimeout(() => {
        setShow(false)
        setReadyToHideBar(true)
        dispatch(globalActions.closeSnackbar())
      }, duration)
      return () => clearTimeout(timeout)
    }
  }, [visible, duration, dispatch])

  React.useEffect(() => {
    if (readyToHideBar) {
      const timeout = setTimeout(() => {
        setHideBar(true)
        setReadyToHideBar(false)
      }, 500)
      return () => clearTimeout(timeout)
    }
  }, [readyToHideBar])

  const iconSrc =
    type === 'success'
      ? Images.components_snackbar_success
      : Images.components_snackbar_warning
  const backgroundColor = SnackBackgroundColor[type]

  return (
    <Animated.View style={[styles.container, { backgroundColor }]}>
      {showIcon && (
        <Image
          source={iconSrc}
          style={[Layout.iconSize24, Gutters.smallRMargin]}
          resizeMode="contain"
        />
      )}
      <Text style={styles.text}>{message}</Text>
    </Animated.View>
  )
}

const style = (hideBar: boolean, translateY: any, position: 'bottom' | 'top') =>
  StyleSheet.create({
    container: {
      position: 'absolute',
      top: 50,
      left: 0,
      right: 0,
      padding: 16,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '80%',
      height: 56,
      marginHorizontal: 'auto',
      marginVertical: 0,
      borderRadius: 10,
      display: hideBar ? 'none' : 'flex',
      transform: [
        {
          translateY: translateY.interpolate({
            inputRange: [0, 1],
            outputRange: position === 'top' ? [-80, 0] : [510, 0],
          }),
        },
      ],
    },
    text: {
      color: 'white',
      fontSize: 16,
    },
  })

export default Snackbar
