import { useEffect, useState } from 'react'

import noteApi from '@/Services/modules/note'
import { NestedSetType } from '@/Services/modules/note/note.type'
import spiritsApi from '@/Services/modules/spirits'
import { FlavorData } from '@/Containers/data/FlavorData'

export interface FlavorDataItem {
  zhName: string
  enName: string
  count: number
  linearGradient: string[]
  icon: string
  children: FlavorDataItem[]
  i18nKey: string
}

const useGetFlavorData = () => {
  const [initialData, setInitialData] = useState<FlavorDataItem[]>([])
  const [isFinish, setIsFinish] = useState<boolean>(false)

  // 取得 meta 條件
  const {
    data: filterMetaData,
    isLoading: filterMetaDataIsLoading,
    isSuccess: filterMetaDataIsSuccess,
  } = spiritsApi.useGetSearchMetaQuery()

  // 取得國家資訊
  const {
    data: flavorData,
    isLoading: flavorDataIsLoading,
    isSuccess: flavorDataIsSuccess,
  } = noteApi.useGetNestedSetQuery({
    type: NestedSetType.FLAVOR_WHEEL,
  })

  // 整理初始化資料
  useEffect(() => {
    if (flavorDataIsSuccess && filterMetaDataIsSuccess) {
      const flavorArr: FlavorDataItem[] = []
      const flavorMeta = filterMetaData?.data?.aggregations?.flavors?.slug

      for (const flavor of flavorMeta) {
        const flavorDataCardInfo = FlavorData.find(
          item => item.name === flavor.key,
        )
        const obj: FlavorDataItem = {
          zhName: flavorData?.data?.roots?.[flavor.key] || '',
          enName: flavor.key,
          count: flavor.count,
          linearGradient: flavorDataCardInfo?.linearGradient || [],
          icon: flavorDataCardInfo?.icon || '',
          children: [],
          i18nKey: flavor.key,
        }

        for (const child of flavor.children) {
          const childI18n = flavorData?.data?.children.find(
            (item: any) => item.parentSlug === flavor.key,
          )
          const childObj = {
            zhName: childI18n?.data?.[child.key] || '',
            enName: child.key,
            count: child.count,
            linearGradient: flavorDataCardInfo?.linearGradient || [],
            icon: flavorDataCardInfo?.icon || '',
            children: [],
            i18nKey: child.key,
          }
          obj.children.push(childObj)
        }
        flavorArr.push(obj)
      }

      setInitialData(flavorArr)
      setIsFinish(true)
    }
  }, [
    flavorData?.data?.roots,
    filterMetaData,
    filterMetaDataIsSuccess,
    flavorDataIsSuccess,
    flavorData,
  ])

  return {
    flavorsData: initialData,
    filterMetaDataIsLoading,
    flavorDataIsLoading,
    flavorIsFinish: isFinish,
  }
}

export default useGetFlavorData
