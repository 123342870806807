import React, { ReactElement } from 'react'
import { Appbar } from 'react-native-paper'
import { View, Text, TouchableOpacity, ViewStyle } from 'react-native'
import { useTheme } from '@/Hooks'
import { Colors, Width } from '@/Theme/Variables'

type Props = {
  title?: string
  subTitle?: string
  leftIcon?: ReactElement
  leftIconPress?: () => void
  rightIcon?: ReactElement
  rightIconPress?: () => void
  rightEmptyIconWidth?: string
  backgroundColor?: string
  styles?: ViewStyle
}

const Header = ({
  title,
  subTitle,
  leftIcon,
  leftIconPress,
  rightIcon,
  rightIconPress,
  backgroundColor,
  rightEmptyIconWidth,
  styles,
}: Props) => {
  const { Layout, Fonts, Gutters } = useTheme()

  const renderEmptyRightIcon = () => {
    if (rightEmptyIconWidth === '50') {
      return <View style={{ width: Width.width50 }} />
    } else {
      return <View style={{ width: Width.width24 }} />
    }
  }

  const headerStyle = styles || {}

  return (
    <Appbar.Header
      style={[
        Layout.fullWidth,
        Layout.row,
        Layout.justifyContentBetween,
        Gutters.smallHPadding,
        headerStyle,
        {
          backgroundColor: backgroundColor || Colors.background.default,
        },
      ]}
    >
      <TouchableOpacity onPress={leftIconPress}>
        {leftIcon && leftIcon}
      </TouchableOpacity>
      <View>
        {title && (
          <Text
            style={[Fonts.weight500, Fonts.size16, { color: Colors.white }]}
          >
            {title}
          </Text>
        )}
        {subTitle && (
          <Text
            style={[
              Fonts.size12,
              Fonts.weight400,
              Fonts.textCenter,
              { color: Colors.gray },
            ]}
          >
            {subTitle}
          </Text>
        )}
      </View>
      {rightIcon ? (
        <TouchableOpacity onPress={rightIconPress}>
          {rightIcon}
        </TouchableOpacity>
      ) : (
        renderEmptyRightIcon()
      )}
    </Appbar.Header>
  )
}

Header.defaultProps = {
  title: '',
  subTitle: '',
  leftIcon: null,
  leftIconPress: null,
  rightIcon: null,
  rightIconPress: null,
  backgroundColor: null,
  rightEmptyIconWidth: null,
  styles: null,
}

export default Header
