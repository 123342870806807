import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import {
  AccessibilityRole,
  ActivityIndicator,
  Dimensions,
  FlatList,
  Image,
  LayoutChangeEvent,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native'
import { Avatar } from 'react-native-paper'
import { AvatarImageSource } from 'react-native-paper/lib/typescript/components/Avatar/AvatarImage'
import StarRating from 'react-native-star-rating-widget'
import { useDispatch, useSelector } from 'react-redux'
import ReactTimeAgo from 'react-time-ago'

import {
  DownLoadSign,
  FadeInView,
  FlavorItems,
  Header,
  ImageLightBox,
  LoadingComponent,
  SpiritImage,
} from '@/Components'
import { StarIcon12 } from '@/Components/ReviewCard'
import { useGlass, useShare, useTheme } from '@/Hooks'
import { navigateAndSimpleReset } from '@/Navigators/utils'
import { noteApi } from '@/Services/modules/note'
import { DropdownsType } from '@/Services/modules/note/note.type'
import spiritsApi from '@/Services/modules/spirits'
import userApi from '@/Services/modules/users'
import { RootState } from '@/Store'
import { globalActions } from '@/Store/Global'
import { spiritActions } from '@/Store/Spirit'
import { BorderRadius, BorderWidth, Colors, Height } from '@/Theme/Variables'
import {
  capitalizedSentence,
  getConfig,
  handleFormatRating,
  removeEmptyKeys,
} from '@/Util/global'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { PagesEnum } from '@/Navigators/Application'

const ReviewNoteContainer = ({ route, navigation }: any) => {
  const { id } = route.params
  const { Layout, Gutters, Images, Fonts } = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const renderGlasses = useGlass()
  const { language } = useSelector((state: RootState) => state.theme)
  // workaround for first time rating will not update the rate immediately
  const addRatingLoading = useSelector(
    (state: RootState) => state.spirit.addRatingLoading,
  )
  const { commentsDraft } = useSelector((state: RootState) => state.spirit)
  const DELAY_DISABLED_SUBMIT = 500
  const flatListRef = useRef<FlatList>(null)
  const scrollViewRef = useRef<ScrollView>(null)

  const { data: getMeData } = userApi.useGetMeQuery()

  // 取得 note 資料
  const {
    data: noteDetail,
    isLoading: noteDetailIsLoading,
    isSuccess: noteDetailIsSuccess,
  } = noteApi.useGetNoteDetailQuery({
    id,
  })

  const [componentLiked, setComponentLiked] = React.useState(false)

  // 加入 wishlist
  const [addWishlistRequest] = spiritsApi.usePostAddWishlistMutation({})

  // 移除 wishlist
  const [removeWishlistRequest] = spiritsApi.usePostRemoveWishlistMutation({})

  const shareData = useShare({
    url: `${getConfig()?.DOMAIN}/ReviewNote?id=${id}`,
    title: '',
    text: '',
    snackbarContent: '成功複製分享連結！',
  })

  // 取得 comment 資料
  const {
    data: commentsData,
    isSuccess: commentsDataIsSuccess,
    isLoading: commentsDataIsLoading,
  } = noteApi.useGetNoteCommentsQuery({ id })

  // 取得 comment 資料 lazy
  const [
    getLazyCommentsRequest,
    {
      data: lazyGetCommentsData,
      isSuccess: isLazyGetCommentsSuccess,
      isFetching: isLazyGetCommentsFetching,
    },
  ] = noteApi.useLazyGetNoteCommentsQuery()

  // 新增 comment 資料
  const [postCommentRequest, { isSuccess: postCommentRequestIsSuccess }] =
    noteApi.usePostNoteCommentsMutation()

  // 刪除 comment 資料
  const [deleteCommentRequest] = noteApi.useDeleteNoteCommentsMutation()

  // 將這支酒從 my spirit 中移除
  const [
    removeMySpiritRequest,
    { isSuccess: removeMySpiritSuccess, reset: removeMySpiritReset },
  ] = spiritsApi.useDeleteMySpiritMutation({
    fixedCacheKey: 'shared-delete-note-mutation',
  })

  // 按讚
  const [toggleLikeRequest] = noteApi.usePostToggleLikeMutation({})

  // 取得下拉選單（配餐）
  noteApi.useGetNoteDropdownsOptionsQuery({
    type: DropdownsType.PAIRING,
    locale: language,
  })

  // 取得下拉選單（酒杯）
  noteApi.useGetNoteDropdownsOptionsQuery({
    type: DropdownsType.GLASS,
    locale: language,
  })

  const [reviewNoteList, setReviewNoteList] = useState<any[]>(
    commentsData?.data || [],
  )
  const [cursor, setCursor] = useState<string>('')
  const [inputFocus, setInputFocus] = useState<boolean>(false)
  const [content, setContent] = useState<string>('')
  const [textInputHeight, setTextInputHeight] = useState<number>(0)
  const [openLightBox, setOpenLightBox] = useState({
    open: false,
    path: '',
  })
  const [flatListOffset, setFlatListOffset] = useState(0)

  const styles = getStyle(textInputHeight, inputFocus)

  // 判斷有沒有更多資訊筆記
  const hasMoreReviewNote =
    Object.keys(removeEmptyKeys(noteDetail?.data?.expertise) || {})?.length > 0

  // 判斷是不是自己的筆記
  const isMyNote = noteDetail?.data?.user?.id === getMeData?.id

  const onLayout = (event: LayoutChangeEvent) => {
    setFlatListOffset(event.nativeEvent.layout.y)
  }

  // 取得是否按讚
  useEffect(() => {
    if (noteDetailIsSuccess && noteDetail?.data?.likeClicked) {
      setComponentLiked(true)
    }
  }, [noteDetailIsSuccess, noteDetail?.data?.likeClicked])

  // 新增完留言，滾動到該留言
  useEffect(() => {
    if (
      postCommentRequestIsSuccess &&
      flatListRef?.current &&
      scrollViewRef?.current
    ) {
      scrollViewRef.current?.scrollTo({ y: flatListOffset, animated: true })
    }
  }, [postCommentRequestIsSuccess, flatListRef, flatListOffset])

  const handleGoBack = useCallback(() => {
    dispatch(
      spiritActions.setCommentsDraft({
        id,
        comment: content,
      }),
    )

    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }
    navigateAndSimpleReset(PagesEnum.Main)
    setContent('')
  }, [content, navigation, dispatch, id])

  // 加入或移除 wishlist
  const handleAddWishlist = () => {
    if (noteDetail?.data?.spirit?.collected) {
      removeWishlistRequest({
        id: 'default',
        spiritId: noteDetail?.data?.spirit?.id?.toString(),
      })
    } else {
      addWishlistRequest({
        id: 'default',
        spiritId: noteDetail?.data?.spirit?.id?.toString(),
      })
    }
  }

  // 點擊酒資訊
  const handleClickSpiritInfo = () => {
    navigation.navigate(PagesEnum.ProductDetail, {
      lang: language,
      titleUrlSlug: noteDetail?.data?.spirit?.titleUrlSlug,
      id: noteDetail?.data?.spirit?.id?.toString(),
    })
  }

  // 清除 navigation params page
  useLayoutEffect(() => {
    navigation.setParams({
      page: undefined,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation])

  // 如果有儲存草稿留言的話自動帶入
  useEffect(() => {
    if (commentsDraft[id]) {
      setContent(commentsDraft[id])
    }
  }, [commentsDraft, id])

  useEffect(() => {
    if (commentsDataIsSuccess) {
      setReviewNoteList(commentsData?.data || [])
      setCursor(commentsData?.meta?.nextCursor || '')
    }
  }, [commentsData, commentsDataIsSuccess])

  useEffect(() => {
    if (isLazyGetCommentsSuccess) {
      setReviewNoteList(prevBoozesList => {
        return [...prevBoozesList, ...(lazyGetCommentsData?.data || [])]
      })
      setCursor(lazyGetCommentsData?.meta?.nextCursor || '')
    }
  }, [lazyGetCommentsData, isLazyGetCommentsSuccess])

  // 如果成功刪除 my spirit note 跳轉回去
  useEffect(() => {
    if (removeMySpiritSuccess) {
      handleGoBack()
      removeMySpiritReset()
    }
  }, [removeMySpiritSuccess, removeMySpiritReset, handleGoBack])

  const handleFetchMoreData = () => {
    if (cursor) {
      getLazyCommentsRequest({
        id,
        cursor,
      })
    }
  }

  const handleSubmit = () => {
    if (!getMeData) {
      openLoginDialog()
      return
    }
    // 留言字數限制為五百字
    if (content?.length > 500) {
      dispatch(
        globalActions.openSnackbar({
          visible: true,
          message: '已超過500字數上限',
          type: 'error',
          showIcon: true,
        }),
      )
      return
    }
    if (content?.length) {
      postCommentRequest({
        id,
        content,
      })
      setContent('')
    }
  }

  const handleDeleteComment = (commentId: string) => {
    deleteCommentRequest({
      id: commentId,
    })
  }

  const handleClickEditNote = async () => {
    const routeParams = {
      id: noteDetail?.data?.spirit?.id,
      title: noteDetail?.data?.spirit?.titleUrlSlug,
      mySpiritId: id,
    }

    navigation.navigate(AuthPagesEnum.ReviewDetail, routeParams)
    // 用 card 區分是否點擊卡片中的 edit note
    AsyncStorage.setItem('previousScreen', PagesEnum.ReviewNote)
    dispatch(globalActions.closeBottomDialog())
  }

  const handleClickLike = () => {
    if (!getMeData) {
      openLoginDialog()
    }
    toggleLikeRequest({ id })
    setComponentLiked(!componentLiked)
  }

  const openLoginDialog = () => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        title: t('camera.bottomDialogTitle'),
        disabledBackgroundClose: true,
        content: (
          <View
            style={[
              Layout.center,
              Gutters.regularVMargin,
              Gutters.regularHPadding,
            ]}
          >
            <Image
              source={Images.bottom_dialog_login_leave}
              resizeMode="contain"
              style={[Gutters.regularBMargin, Layout.iconSize64]}
            />
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.center,
                Gutters.smallBMargin,
                Gutters.tinyVPadding,
                {
                  backgroundColor: Colors.primary,
                  borderRadius: BorderRadius.radius8,
                  height: Height.height48,
                },
              ]}
              onPress={() => {
                dispatch(globalActions.closeBottomDialog())
                navigation.navigate(PagesEnum.LoginMainContainer)
              }}
            >
              <Text
                style={[
                  Fonts.size14,
                  Fonts.weight500,
                  { color: Colors.primaryText },
                ]}
              >
                {t('camera.bottomDialogButton1')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.center,
                Gutters.smallBMargin,
                Gutters.tinyVPadding,
                {
                  height: Height.height48,
                },
              ]}
              onPress={() => {
                dispatch(globalActions.closeBottomDialog())
              }}
            >
              <Text style={{ color: Colors.white }}>
                {t('camera.bottomDialogButton2')}
              </Text>
            </TouchableOpacity>
          </View>
        ),
      }),
    )
  }

  const deleteCommentDialog = (commentId: string) => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        title: '刪除留言',
        titleStyle: {
          fontSize: 20,
          fontWeight: '700',
        },
        disabledBackgroundClose: false,
        content: (
          <View style={[Layout.center, Gutters.smallVMargin]}>
            <View
              style={[
                Layout.fullWidth,
                Gutters.regularBPadding,
                Gutters.regularHPadding,
                Gutters.regularBMargin,
                {
                  borderBottomWidth: BorderWidth.width2,
                  borderBottomColor: Colors.darkText,
                },
              ]}
            >
              <Text
                style={[
                  Fonts.size14,
                  Fonts.weight500,
                  Fonts.textCenter,
                  { color: Colors.white },
                ]}
              >
                留言刪除後將無法復原，確定要刪除？
              </Text>
            </View>
            <View style={[Gutters.regularHPadding, Layout.fullWidth]}>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.center,
                  Gutters.smallBMargin,
                  styles.confirmDeleteBg,
                  {
                    borderRadius: BorderRadius.radius8,
                    height: 48,
                  },
                ]}
                onPress={() => {
                  handleDeleteComment(commentId)
                  dispatch(globalActions.closeBottomDialog())
                }}
              >
                <Text
                  style={[
                    Fonts.size14,
                    Fonts.weight500,
                    { color: Colors.dark },
                  ]}
                >
                  確定刪除
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[Layout.fullWidth, Layout.center, Gutters.smallBMargin]}
                onPress={() => {
                  dispatch(globalActions.closeBottomDialog())
                }}
              >
                <Text style={[Fonts.bold, { color: Colors.white }]}>取消</Text>
              </TouchableOpacity>
            </View>
          </View>
        ),
      }),
    )
  }

  // 點擊更多按鈕
  const openMoreDialog = () => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        content: (
          <View
            style={[
              Layout.center,
              Gutters.regularVMargin,
              Gutters.regularHPadding,
            ]}
          >
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.rowCenter,
                Gutters.smallBMargin,
                Gutters.tinyVPadding,
                {
                  borderWidth: BorderWidth.width1,
                  borderColor: Colors.gray2,
                  borderRadius: BorderRadius.radius8,
                },
              ]}
              onPress={handleClickEditNote}
            >
              <Image
                source={Images.components_spirit_edit}
                resizeMode="contain"
                style={Layout.iconSize24}
              />
              <Text style={[Fonts.weight500, { color: Colors.white }]}>
                {t('components.spiritCardWithContent.editSpirit')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.rowCenter,
                Gutters.smallBMargin,
                Gutters.tinyVPadding,
                {
                  borderWidth: BorderWidth.width1,
                  borderColor: Colors.gray2,
                  borderRadius: BorderRadius.radius8,
                },
              ]}
              onPress={openConfirmDeleteDialog}
            >
              <Image
                source={Images.components_spirit_delete}
                resizeMode="contain"
                style={Layout.iconSize24}
              />
              <Text style={[Fonts.weight500, { color: Colors.white }]}>
                {t('components.spiritCardWithContent.removeSpirit')}
              </Text>
            </TouchableOpacity>
          </View>
        ),
      }),
    )
  }

  // 確認將酒從清單移除
  const openConfirmDeleteDialog = () => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        title: t('components.spiritCardWithContent.removeSpiritDialogTitle'),
        titleStyle: {
          fontSize: 20,
        },
        content: (
          <View>
            <View
              style={[
                Gutters.regularVPadding,
                Gutters.regularHPadding,
                {
                  borderBottomColor: Colors.gray,
                  borderBottomWidth: BorderWidth.width1,
                },
              ]}
            >
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size14,
                  { color: Colors.secondaryText },
                ]}
              >
                {t(
                  'components.spiritCardWithContent.removeSpiritDialogContent',
                )}
              </Text>
            </View>
            <View
              style={[
                Layout.center,
                Gutters.regularVMargin,
                Gutters.regularHPadding,
              ]}
            >
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.rowCenter,
                  Gutters.smallBMargin,
                  styles.confirmDeleteBg,
                  {
                    borderRadius: BorderRadius.radius8,
                    height: 48,
                  },
                ]}
                onPress={() => {
                  removeMySpiritRequest({ id })
                  // 取得刪除的 my spirit id ，將 my spirit list 重新渲染
                  dispatch(spiritActions.setDeleteMySpiritId(id.toString()))
                  dispatch(globalActions.closeBottomDialog())
                }}
              >
                <Text
                  style={[
                    Fonts.weight500,
                    Fonts.size16,
                    { color: Colors.dark },
                  ]}
                >
                  {t(
                    'components.spiritCardWithContent.removeSpiritDialogConfirm',
                  )}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.rowCenter,
                  Gutters.smallBMargin,
                  Gutters.smallVPadding,
                  {
                    borderRadius: BorderRadius.radius8,
                  },
                ]}
                onPress={() => {
                  dispatch(globalActions.closeBottomDialog())
                }}
              >
                <Text style={[Fonts.weight500, { color: Colors.white }]}>
                  {t(
                    'components.spiritCardWithContent.removeSpiritDialogCancel',
                  )}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        ),
      }),
    )
  }

  const renderInputBox = () => {
    return (
      <View
        style={[
          Layout.fullWidth,
          Layout.rowCenter,
          Gutters.smallVPadding,
          Gutters.smallHPadding,
          styles.searchInputContainer,
        ]}
      >
        <TextInput
          style={[
            styles.searchInput,
            { color: Colors.white, height: Math.max(0, textInputHeight) },
          ]}
          onFocus={() => setInputFocus(true)}
          onBlur={() => {
            setTimeout(() => {
              setInputFocus(false)
            }, DELAY_DISABLED_SUBMIT)
          }}
          value={content}
          placeholder={t('reviewNote.inputPlaceholder')}
          placeholderTextColor={Colors.gray}
          onSubmitEditing={handleSubmit}
          onChangeText={text => setContent(text)}
          // 紀錄輸入框的高度
          onContentSizeChange={event =>
            setTextInputHeight(event.nativeEvent.contentSize.height)
          }
          multiline
          numberOfLines={5}
        />
        {inputFocus && !!content?.length && (
          <TouchableOpacity style={[styles.submitBtn]} onPress={handleSubmit}>
            <Image
              source={Images.review_note_send}
              style={[Layout.iconSize24]}
              resizeMode="contain"
            />
          </TouchableOpacity>
        )}
      </View>
    )
  }

  const renderComment = ({ item }: any) => {
    const {
      content: commentContent,
      createdAt,
      commentator,
      id: commentId,
    } = item

    // 只能操作自己的留言
    const isUserComment = commentator.id === getMeData?.id

    return (
      <View style={[Layout.fullWidth, Gutters.smallBMargin]}>
        <View style={[Layout.row]}>
          <TouchableOpacity
            onPress={() =>
              navigation.navigate(PagesEnum.ProfileMainContainer, {
                id: commentator?.id,
              })
            }
          >
            <Avatar.Image
              source={{ uri: commentator.avatar }}
              style={styles.avatar}
              size={32}
            />
          </TouchableOpacity>
          <View
            style={[Layout.fullWidth, Gutters.tinyRPadding, styles.width90]}
          >
            <View
              style={[
                Layout.row,
                Layout.alignItemsCenter,
                Layout.justifyContentBetween,
                { height: Height.height32 },
              ]}
            >
              <Text
                style={[
                  Fonts.size12,
                  Fonts.weight400,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                {commentator.name}
              </Text>
              {isUserComment && (
                <TouchableOpacity
                  onPress={() => deleteCommentDialog(commentId)}
                >
                  <Image
                    source={Images.more}
                    resizeMode="contain"
                    style={[Layout.iconSize24, Gutters.tinyBMargin]}
                  />
                </TouchableOpacity>
              )}
            </View>
            <Text
              style={[
                Fonts.size14,
                Fonts.weight400,
                Gutters.tinyBMargin,
                Layout.fullWidth,
                { color: Colors.white },
              ]}
            >
              {commentContent}
            </Text>
            {createdAt && (
              <Text
                style={[Fonts.size12, Fonts.weight400, { color: Colors.gray }]}
              >
                <ReactTimeAgo
                  date={Date.parse(createdAt || '')}
                  locale={language || 'en'}
                />
              </Text>
            )}
          </View>
        </View>
      </View>
    )
  }

  const renderListLoading = () => {
    return (
      <View style={[Gutters.regularTPadding]}>
        {isLazyGetCommentsFetching && (
          <ActivityIndicator size="large" color={Colors.primary} />
        )}
      </View>
    )
  }

  // SEO Metadata
  const renderSEOMetadata = () => {
    const ratingStarString: Record<number, string> = {
      1: '★☆☆☆☆',
      2: '★★☆☆☆',
      3: '★★★☆☆',
      4: '★★★★☆',
      5: '★★★★★',
    }

    const og_url = `${getConfig()?.DOMAIN}/ReviewNote?id=${
      route?.params.id
    }&spiritId=${noteDetail?.data?.spirit?.id}`

    const titleString = `烈酒探索家 - ${
      noteDetail?.data?.user?.name || ''
    }品飲了「${capitalizedSentence(noteDetail?.data?.spirit?.title || '')}」`

    const descriptionString = `${
      ratingStarString[noteDetail?.data?.rating || 1]
    } | ${noteDetail?.data?.content || ''}`

    return (
      <Helmet>
        <meta name="title" content={titleString} />
        <meta name="description" content={descriptionString} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={titleString} />
        <meta property="og:description" content={descriptionString} />
        <meta property="og:url" content={og_url} />
        <meta property="og:locale" content={getMeData?.locale || 'zh'} />
        <meta
          property="og:image"
          content={
            noteDetail?.data?.image?.url ||
            `${getConfig()?.DOMAIN}/assets/noteMetaDataImage.png`
          }
        />
      </Helmet>
    )
  }

  const isLoading = noteDetailIsLoading || commentsDataIsLoading

  if (isLoading) {
    return (
      <View style={[Layout.fill]}>
        <View
          style={[
            Layout.fill,
            Layout.colCenter,
            { backgroundColor: Colors.background.default },
          ]}
        >
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      </View>
    )
  }

  return (
    <View style={[Layout.fill, { backgroundColor: Colors.background.default }]}>
      {renderSEOMetadata()}
      <Header
        title={'筆記詳情'}
        leftIcon={
          <Image
            style={[styles.closeIcon]}
            source={navigation.canGoBack() ? Images.arrowLeft : Images.close}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
      />
      <ScrollView
        ref={scrollViewRef}
        onLayout={onLayout}
        style={[{ height: Dimensions.get('window').height }]}
      >
        {addRatingLoading && <LoadingComponent />}
        <FadeInView duration={500} style={Layout.fill}>
          <View style={[Layout.fill]}>
            {isLoading ? (
              <View style={[Layout.fill, Layout.center]}>
                <ActivityIndicator size="large" color={Colors.primary} />
              </View>
            ) : (
              <View style={[Layout.fill]}>
                {/* 酒資訊 */}
                <TouchableOpacity
                  style={[
                    Gutters.regularHPadding,
                    Gutters.regularVPadding,
                    Gutters.tinyBMargin,
                    Layout.justifyContentBetween,
                    Layout.row,
                    { backgroundColor: Colors.background.surface },
                  ]}
                  onPress={handleClickSpiritInfo}
                >
                  <View style={[Layout.row]}>
                    <View style={[Gutters.tinyRMargin]}>
                      <SpiritImage
                        style={{
                          width: 60,
                          height: 60,
                          borderRadius: 10,
                          objectFit: 'cover',
                        }}
                        linearGradientContainerStyle={{
                          width: 60,
                          height: 60,
                          borderRadius: 10,
                          objectFit: 'cover',
                        }}
                        title={noteDetail?.data?.spirit?.title || ''}
                        imageUrl={noteDetail?.data?.spirit?.imageUrl || ''}
                        needSEO
                      />
                    </View>
                    <View style={[{ maxWidth: 260 }]}>
                      <Text
                        style={[
                          Fonts.weight400,
                          Fonts.size14,
                          { color: Colors.fontText.light.primary3 },
                        ]}
                      >
                        {capitalizedSentence(
                          noteDetail?.data?.spirit?.brand || '',
                        )}
                      </Text>
                      <Text
                        style={[
                          Fonts.weight500,
                          Fonts.size14,
                          { color: Colors.fontText.light.primary2 },
                        ]}
                      >
                        {capitalizedSentence(
                          noteDetail?.data?.spirit?.title || '',
                        )}
                      </Text>
                      <View style={[Layout.row, Layout.alignItemsCenter]}>
                        <Text
                          style={[
                            Fonts.weight700,
                            Fonts.size14,
                            Gutters.miniRMargin,
                            { color: Colors.white },
                          ]}
                        >
                          {noteDetail?.data?.spirit?.ratings?.average
                            ? handleFormatRating(
                                noteDetail?.data?.spirit?.ratings
                                  ?.average as number,
                              )
                            : '暫無評分'}
                        </Text>
                        {noteDetail?.data?.spirit?.ratings?.average && (
                          <Image
                            source={Images.components_spirit_rating_star}
                            resizeMode="contain"
                            style={[Layout.iconSize14, Gutters.miniRMargin]}
                          />
                        )}
                        <Text
                          style={[
                            Fonts.weight400,
                            Fonts.size14,
                            { color: Colors.fontText.light.primary3 },
                          ]}
                        >
                          {`( ${noteDetail?.data?.spirit?.ratings?.count}個評分 )`}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <TouchableOpacity onPress={handleAddWishlist}>
                    <Image
                      source={
                        noteDetail?.data?.spirit?.collected
                          ? Images.collected
                          : Images.collect
                      }
                      resizeMode="contain"
                      style={[Layout.iconSize20]}
                    />
                  </TouchableOpacity>
                </TouchableOpacity>
                {/* 筆記資訊 */}
                {noteDetail && (
                  <View
                    style={[
                      Gutters.regularHPadding,
                      Gutters.regularVPadding,
                      { backgroundColor: Colors.background.surface },
                    ]}
                  >
                    <View
                      accessibilityRole={'article' as AccessibilityRole}
                      nativeID="user-review"
                      style={[Layout.fullWidth]}
                    >
                      {isMyNote && (
                        <TouchableOpacity
                          style={styles.moreBtn}
                          onPress={openMoreDialog}
                        >
                          <Image
                            source={Images.more}
                            resizeMode="contain"
                            style={Layout.iconSize24}
                          />
                        </TouchableOpacity>
                      )}
                      <TouchableOpacity
                        style={[
                          Layout.row,
                          Layout.alignItemsCenter,
                          Gutters.smallBMargin,
                        ]}
                        onPress={() =>
                          navigation.navigate(PagesEnum.ProfileMainContainer, {
                            id: noteDetail?.data?.user?.id,
                          })
                        }
                      >
                        {noteDetail?.data?.user?.avatar ? (
                          <Avatar.Image
                            size={48}
                            source={{
                              uri: noteDetail?.data?.user
                                ?.avatar as AvatarImageSource,
                            }}
                            style={[Gutters.tinyRMargin]}
                          />
                        ) : (
                          <Avatar.Image
                            size={48}
                            source={Images.defaultAvatar}
                            style={[Gutters.tinyRMargin]}
                          />
                        )}

                        <View>
                          <Text
                            style={[
                              Fonts.size14,
                              Fonts.weight400,
                              {
                                color: Colors.fontText.light.primary2,
                                marginBottom: 4,
                              },
                            ]}
                          >
                            {`${
                              noteDetail?.data?.user?.name || '此用戶不存在'
                            }`}
                          </Text>
                          <View style={[Layout.row, Layout.alignItemsCenter]}>
                            {noteDetail?.data?.user?.title?.name && (
                              <View style={[styles.titleContainer]}>
                                <Text
                                  style={[
                                    Fonts.size12,
                                    Fonts.weight500,
                                    { color: Colors.fontText.light.primary2 },
                                  ]}
                                >
                                  {noteDetail?.data?.user?.title?.name}
                                </Text>
                              </View>
                            )}
                            {noteDetail?.data?.user?.notesCount && (
                              <Text
                                style={[
                                  Fonts.size12,
                                  Fonts.weight400,
                                  { color: Colors.fontText.light.primary3 },
                                ]}
                              >
                                {`${noteDetail?.data?.user?.notesCount} Ratings`}
                              </Text>
                            )}
                          </View>
                        </View>
                      </TouchableOpacity>
                      <View
                        style={[
                          Gutters.tinyBMargin,
                          Layout.fullWidth,
                          Layout.alignItemsStart,
                        ]}
                      >
                        <View style={[Gutters.tinyBMargin]}>
                          <StarRating
                            rating={noteDetail?.data?.rating}
                            starSize={12}
                            color="#EECD96"
                            StarIconComponent={StarIcon12}
                            onChange={e => {
                              console.log(e)
                            }}
                            starStyle={styles.starStyle}
                            animationConfig={{
                              scale: 1,
                            }}
                          />
                        </View>
                        {noteDetail?.data?.flavors && (
                          <FlavorItems
                            flavors={noteDetail?.data?.flavors || []}
                            rowWrap={true}
                            openFlavors={true}
                          />
                        )}
                      </View>
                      <View style={[Gutters.smallBMargin]}>
                        <Text
                          accessibilityRole={'heading' as AccessibilityRole}
                          // @ts-ignore
                          accessibilityLevel={3}
                          nativeID="user-content"
                          style={{ color: Colors.white }}
                          numberOfLines={999}
                        >
                          {noteDetail?.data?.content}
                        </Text>
                      </View>
                      {!!noteDetail?.data?.image?.url?.length && (
                        <View style={[Gutters.smallBMargin]}>
                          <TouchableOpacity
                            onPress={() => {
                              setOpenLightBox({
                                open: true,
                                path: noteDetail?.data?.image?.url,
                              })
                            }}
                          >
                            {Platform.OS === 'web' ? (
                              <img
                                src={noteDetail?.data?.image?.url || ''}
                                alt={
                                  `${noteDetail?.data?.spirit?.title}/${noteDetail?.data?.user?.id}` ||
                                  ''
                                }
                                title={
                                  `${noteDetail?.data?.spirit?.title}/${noteDetail?.data?.user?.id}` ||
                                  ''
                                }
                                style={{
                                  width: 60,
                                  height: 60,
                                  borderRadius: 10,
                                  objectFit: 'cover',
                                }}
                              />
                            ) : (
                              <Image
                                source={{
                                  uri: noteDetail?.data?.image?.url || '',
                                }}
                                resizeMode="cover"
                                style={[Layout.iconSize60, { borderRadius: 8 }]}
                              />
                            )}
                          </TouchableOpacity>
                        </View>
                      )}
                    </View>
                  </View>
                )}
                {/* 進階資訊區域 */}
                {hasMoreReviewNote && (
                  <View>
                    <View
                      style={[
                        {
                          width: '100%',
                          height: 1,
                          backgroundColor: Colors.background.top,
                        },
                      ]}
                    />
                    <View
                      style={[
                        Gutters.regularHPadding,
                        Gutters.regularVPadding,
                        { backgroundColor: Colors.background.surface },
                      ]}
                    >
                      <View
                        style={[
                          Layout.row,
                          Layout.alignItemsCenter,
                          Gutters.regularBMargin,
                        ]}
                      >
                        <Image
                          source={Images.review_note_cup}
                          resizeMode="contain"
                          style={[Gutters.tinyRMargin, Layout.iconSize16]}
                        />
                        <Text
                          style={[
                            Fonts.size16,
                            Fonts.weight700,
                            { color: Colors.fontText.light.primary2 },
                          ]}
                        >
                          品飲細節
                        </Text>
                      </View>
                      {/* 餐飲搭配 */}
                      {!!noteDetail?.data?.expertise?.pairing?.length && (
                        <View style={[styles.expertiseItem]}>
                          <Text
                            style={[
                              Fonts.size14,
                              Fonts.weight400,
                              Gutters.tinyBMargin,
                              { color: Colors.fontText.light.primary2 },
                            ]}
                          >
                            餐飲搭配
                          </Text>
                          <Text>
                            {noteDetail?.data?.expertise?.pairing.map(
                              (item, index) => {
                                const lastIndex =
                                  index ===
                                  noteDetail?.data?.expertise?.pairing?.length -
                                    1
                                return (
                                  <Text
                                    style={[
                                      Fonts.size14,
                                      Fonts.weight400,
                                      { color: Colors.fontText.light.primary3 },
                                    ]}
                                  >
                                    {t(`pairing:${item}`)}
                                    {lastIndex ? '' : '、'}
                                  </Text>
                                )
                              },
                            )}
                          </Text>
                        </View>
                      )}
                      {/* 品飲容器 */}
                      {!!noteDetail?.data?.expertise?.glass && (
                        <View
                          style={[
                            styles.expertiseItem,
                            Layout.row,
                            Layout.justifyContentBetween,
                          ]}
                        >
                          <Text
                            style={[
                              Fonts.size14,
                              Fonts.weight400,
                              Gutters.tinyBMargin,
                              { color: Colors.fontText.light.primary2 },
                            ]}
                          >
                            品飲容器
                          </Text>
                          <View style={[{ alignItems: 'flex-end' }]}>
                            <TouchableOpacity
                              onPress={() => {
                                setOpenLightBox({
                                  open: true,
                                  // @ts-ignore
                                  path: renderGlasses[
                                    noteDetail?.data?.expertise?.glass
                                  ],
                                })
                              }}
                            >
                              <Image
                                source={
                                  // @ts-ignore
                                  renderGlasses[
                                    noteDetail?.data?.expertise?.glass
                                  ]
                                }
                                resizeMode="contain"
                                style={[styles.glassImage, Gutters.tinyBMargin]}
                              />
                            </TouchableOpacity>
                            <Text
                              style={[
                                Fonts.size14,
                                Fonts.weight400,
                                { color: Colors.fontText.light.primary3 },
                              ]}
                            >
                              {capitalizedSentence(
                                t(
                                  `glass:${noteDetail?.data?.expertise?.glass}`,
                                ),
                              )}
                            </Text>
                          </View>
                        </View>
                      )}
                      {/* 品飲量 */}
                      {noteDetail?.data?.expertise?.tastingVolume && (
                        <View
                          style={[
                            styles.expertiseItem,
                            Layout.row,
                            Layout.justifyContentBetween,
                          ]}
                        >
                          <Text
                            style={[
                              Fonts.size14,
                              Fonts.weight400,
                              { color: Colors.fontText.light.primary2 },
                            ]}
                          >
                            品飲量
                          </Text>
                          <Text
                            style={[
                              Fonts.size14,
                              Fonts.weight400,
                              { color: Colors.fontText.light.primary3 },
                            ]}
                          >{`${noteDetail?.data?.expertise?.tastingVolume}ml`}</Text>
                        </View>
                      )}
                      {/* 批號 */}
                      {noteDetail?.data?.expertise?.batchNumber && (
                        <View
                          style={[
                            styles.expertiseItem,
                            Layout.row,
                            Layout.justifyContentBetween,
                          ]}
                        >
                          <Text
                            style={[
                              Fonts.size14,
                              Fonts.weight400,
                              { color: Colors.fontText.light.primary2 },
                            ]}
                          >
                            批號
                          </Text>
                          <Text
                            style={[
                              Fonts.size14,
                              Fonts.weight400,
                              { color: Colors.fontText.light.primary3 },
                            ]}
                          >{`${noteDetail?.data?.expertise?.batchNumber}`}</Text>
                        </View>
                      )}
                      {/* 裝瓶年份 */}
                      {noteDetail?.data?.expertise?.bottledAge && (
                        <View
                          style={[
                            styles.expertiseItem,
                            Layout.row,
                            Layout.justifyContentBetween,
                          ]}
                        >
                          <Text
                            style={[
                              Fonts.size14,
                              Fonts.weight400,
                              { color: Colors.fontText.light.primary2 },
                            ]}
                          >
                            裝瓶年份
                          </Text>
                          <Text
                            style={[
                              Fonts.size14,
                              Fonts.weight400,
                              { color: Colors.fontText.light.primary3 },
                            ]}
                          >{`${noteDetail?.data?.expertise?.bottledAge}`}</Text>
                        </View>
                      )}
                      {/* 購買價格 */}
                      {noteDetail?.data?.expertise?.price && (
                        <View
                          style={[
                            styles.expertiseItem,
                            Layout.row,
                            Layout.justifyContentBetween,
                          ]}
                        >
                          <Text
                            style={[
                              Fonts.size14,
                              Fonts.weight400,
                              { color: Colors.fontText.light.primary2 },
                            ]}
                          >
                            購買價格
                          </Text>
                          <Text
                            style={[
                              Fonts.size14,
                              Fonts.weight400,
                              { color: Colors.fontText.light.primary3 },
                            ]}
                          >{`${noteDetail?.data?.expertise?.price?.toLocaleString()} NTD`}</Text>
                        </View>
                      )}
                      {/* 購買地 */}
                      {noteDetail?.data?.expertise?.purchasePlace && (
                        <View
                          style={[
                            styles.expertiseItem,
                            Layout.row,
                            Layout.justifyContentBetween,
                          ]}
                        >
                          <Text
                            style={[
                              Fonts.size14,
                              Fonts.weight400,
                              { color: Colors.fontText.light.primary2 },
                            ]}
                          >
                            購買地
                          </Text>
                          <Text
                            style={[
                              Fonts.size14,
                              Fonts.weight400,
                              { color: Colors.fontText.light.primary3 },
                            ]}
                          >{`${noteDetail?.data?.expertise?.purchasePlace}`}</Text>
                        </View>
                      )}
                    </View>
                  </View>
                )}
                {/* 時間與按讚留言分享區 */}
                <View
                  style={[
                    Layout.row,
                    Layout.alignItemsCenter,
                    Layout.justifyContentBetween,
                    Gutters.regularHPadding,
                    Gutters.smallTMargin,
                    Gutters.largeBMargin,
                  ]}
                >
                  <Text style={[Fonts.size12, { color: Colors.gray }]}>
                    <ReactTimeAgo
                      date={Date.parse(noteDetail?.data?.createdAt || '')}
                      locale={language || 'en'}
                    />
                  </Text>
                  <View style={[Layout.row, Layout.alignItemsCenter]}>
                    <View style={[Layout.row, Gutters.smallRMargin]}>
                      <TouchableOpacity onPress={handleClickLike}>
                        <Image
                          source={
                            componentLiked
                              ? Images.product_detail_thumb_up_fill
                              : Images.product_detail_thumb_up
                          }
                          resizeMode="contain"
                          style={[Layout.iconSize16, Gutters.tinyRMargin]}
                        />
                      </TouchableOpacity>
                      <Text style={{ color: Colors.fontText.light.primary2 }}>
                        {noteDetail?.data?.counters.likers || 0}
                      </Text>
                    </View>
                    <View style={[Layout.row, Gutters.smallRMargin]}>
                      <Image
                        source={Images.product_detail_comment}
                        resizeMode="contain"
                        style={[Layout.iconSize16, Gutters.tinyRMargin]}
                      />
                      <Text style={{ color: Colors.fontText.light.primary2 }}>
                        {noteDetail?.data?.counters.comments || 0}
                      </Text>
                    </View>
                    <TouchableOpacity
                      style={[Layout.row, Gutters.smallRMargin]}
                      onPress={shareData}
                    >
                      <Image
                        source={Images.product_detail_share}
                        resizeMode="contain"
                        style={[Layout.iconSize16, Gutters.tinyRMargin]}
                      />
                      <Text style={{ color: Colors.fontText.light.primary2 }}>
                        分享
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
                {/* 時間與按讚留言區 */}

                {/* 留言區 */}
                <View
                  style={[Gutters.regularHPadding, Gutters.regularTPadding]}
                >
                  <Text
                    style={[
                      Fonts.size16,
                      Fonts.weight700,
                      { color: Colors.white },
                    ]}
                  >
                    {t('reviewNote.comments')}
                  </Text>
                </View>
                {/* 留言區 */}
                {reviewNoteList && (
                  <View
                    style={[
                      Gutters.smallVPadding,
                      Gutters.regularHPadding,
                      styles.reviewNoteListContainer,
                    ]}
                  >
                    <FlatList
                      ref={flatListRef}
                      keyExtractor={(_item, index) => index.toString()}
                      data={reviewNoteList}
                      renderItem={renderComment}
                      refreshing
                      onEndReached={handleFetchMoreData}
                      onEndReachedThreshold={3}
                      ListFooterComponent={renderListLoading}
                    />
                  </View>
                )}
              </View>
            )}
          </View>
        </FadeInView>
      </ScrollView>
      {renderInputBox()}
      {/* 點擊圖片放大 */}
      <ImageLightBox
        visible={openLightBox.open}
        imagePath={openLightBox.path}
        imageStyle={{
          width: 300,
          height: 300,
          borderRadius: 10,
        }}
        containerStyle={{
          backgroundColor: 'transparent',
        }}
        hideModal={() => {
          setOpenLightBox({
            open: false,
            path: '',
          })
        }}
      />
      <DownLoadSign />
    </View>
  )
}

const getStyle = (textInputHeight: number, inputFocus: boolean) =>
  StyleSheet.create({
    width90: {
      width: '90%',
    },
    closeIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    divider: {
      width: '100%',
      height: 8,
      backgroundColor: Colors.background.default,
    },
    searchInputContainer: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      backgroundColor: Colors.background.default,
      height: 44 + textInputHeight,
      maxHeight: 120,
      borderTopColor: Colors.background.top,
      borderTopWidth: 1,
    },
    searchInput: {
      width: '100%',
      boxSizing: 'border-box',
      padding: 10,
      paddingRight: 40,
      maxHeight: 85,
      minHeight: 42,
      borderColor: inputFocus ? Colors.primary : Colors.gray,
      borderWidth: 1,
      borderRadius: 8,
      outlineWidth: 0,
      outlineColor: 'transparent',
      outlineStyle: 'none',
    },
    submitBtn: {
      position: 'absolute',
      bottom: 28,
      right: 25,
    },
    avatar: {
      marginRight: 8,
    },
    reviewNoteListContainer: {
      paddingBottom: 100,
    },
    confirmDeleteText: {
      color: '#ED5454',
    },
    confirmDeleteBg: {
      backgroundColor: '#ED5454',
    },
    moreBtn: {
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 1,
    },
    starStyle: {
      marginLeft: 0,
      marginRight: 2,
      marginBottom: 5,
    },
    flavorsItem: {
      backgroundColor: Colors.background.onSurface,
      paddingVertical: 4,
      paddingHorizontal: 8,
      textAlign: 'center',
      borderRadius: 32,
      marginRight: 10,
      marginBottom: 8,
      width: 'fit-content',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    flavorsDot: {
      width: 8,
      height: 8,
      borderRadius: 4,
    },
    expertiseItem: {
      width: '100%',
      borderBottomColor: Colors.background.onSurface,
      borderBottomWidth: 1,
      marginBottom: 16,
      paddingBottom: 16,
      boxSizing: 'border-box',
    },
    glassImage: {
      width: 48,
      height: 48,
      borderRadius: 8,
    },
    titleContainer: {
      backgroundColor: '#6147E3',
      alignSelf: 'flex-start',
      borderTopRightRadius: 16,
      borderBottomRightRadius: 16,
      borderBottomLeftRadius: 16,
      marginRight: 4,
      paddingVertical: 4,
      paddingHorizontal: 8,
    },
  })

export default ReviewNoteContainer
