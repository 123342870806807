import { ImageLightBoxProps } from '@/Components/ImageLightBox'
import { SnackbarProps } from '@/Components/Snackbar'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type GlobalState = {
  snackbar: SnackbarProps
  bottomDialog: {
    disabledBackgroundClose: boolean
    visible: boolean
    title?: string
    titleStyle?: Object
    content?: string | JSX.Element | React.FC
    handleClickBackground: () => void
  }
  productImageLightBox: ImageLightBoxProps
  openDownloadSign: boolean
}

const InitImageLightBox = {
  visible: false,
  resizeMode: 'contain',
  imageStyle: {},
  containerStyle: {},
  modalStyle: {},
}

const initialState = {
  snackbar: {
    visible: false,
    message: '',
    type: 'success',
    position: 'top',
    duration: 3000,
    showIcon: true,
  },
  bottomDialog: {
    visible: false,
    title: undefined,
    titleStyle: undefined,
    content: undefined,
  },
  productImageLightBox: InitImageLightBox,
  openDownloadSign: true,
}

const slice = createSlice({
  name: 'global',
  initialState: initialState,
  reducers: {
    resetGlobalStore: () => initialState,
    openSnackbar: (state, action: PayloadAction<Partial<SnackbarProps>>) => {
      state.snackbar = {
        ...state.snackbar,
        ...action.payload,
      }
    },
    closeSnackbar: state => {
      state.snackbar = {
        ...state.snackbar,
        visible: false,
      }
    },
    openBottomDialog: (state, action) => {
      state.bottomDialog = {
        ...state.bottomDialog,
        ...action.payload,
      }
    },
    closeBottomDialog: state => {
      state.bottomDialog = {
        ...state.bottomDialog,
        visible: false,
        title: undefined,
        titleStyle: undefined,
        content: undefined,
      }
    },
    // 打開 image light box
    openProductImageLightBox: (
      state,
      action: PayloadAction<Partial<ImageLightBoxProps>>,
    ) => {
      state.productImageLightBox = {
        ...state.productImageLightBox,
        ...action.payload,
      }
    },
    // 關閉 image light box
    closeProductImageLightBox: state => {
      state.productImageLightBox = InitImageLightBox
    },
    // 關閉下載提示
    closeDownloadSign: state => {
      state.openDownloadSign = false
    },
  },
})

export const globalReducer = slice.reducer
export const globalActions = slice.actions

export default slice.reducer
