import userApi from '@/Services/modules/users'
import { useEffect, useState } from 'react'

const useCheckLogin = () => {
  const {
    data: getMeData,
    isError: getMeIsError,
    isSuccess: getMeIsSuccess,
  } = userApi.useGetMeQuery()

  const [isLogin, setIsLogin] = useState(false)

  // 如果沒登入，返回首頁
  useEffect(() => {
    if (!getMeData && getMeIsError) {
      setIsLogin(false)
    }

    if (getMeIsSuccess) {
      setIsLogin(true)
    }
  }, [getMeData, getMeIsError, getMeIsSuccess])

  return isLogin
}

export default useCheckLogin
