import React, { useMemo } from 'react'
import {
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { useDispatch } from 'react-redux'
import { capitalizedSentence, handleFormatRating } from '@/Util/global'
import StarRating, { StarIconProps } from 'react-native-star-rating-widget'
import spiritsApi from '@/Services/modules/spirits'
import Svg, { Path, SvgProps } from 'react-native-svg'
import { globalActions } from '@/Store/Global'

import FlavorItemWithTopRank from './FlavorItemWithTopRank'
import SpiritImage from './SpiritImage/SpiritImage'

interface Props {
  onClick: () => void
  id: number
  imageUrl: string
  title: string
  rating: number
  ratingCount: number
  brand: string
  userScanImage?: string
  collect?: boolean
  showWishlist?: boolean
  onWishBtnClick?: () => void
  showMoreBtn?: boolean
  onMoreBtnClick?: () => void
  showPrice?: boolean
  showFlavors?: boolean
  marketPrice?: number
  flavors?: string[]
}

const StarEmpty = (props: SvgProps) => (
  <Svg {...props}>
    <Path
      d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
      fill="white"
      fillOpacity="0.38"
    />
  </Svg>
)

const StarHalf = (props: SvgProps) => (
  <Svg {...props}>
    <Path
      d="M8.00005 0L9.79617 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8.00005 11.0557L3.29777 14.4721L5.09388 8.94427L0.391602 5.52786H6.20394L8.00005 0Z"
      fill="white"
      fillOpacity="0.38"
    />
    <Path
      d="M3.29777 14.4721L8.00005 11.0557V0L6.20394 5.52786H0.391602L5.09388 8.94427L3.29777 14.4721Z"
      fill="#EECD96"
    />
  </Svg>
)

const StarFull = (props: SvgProps) => (
  <Svg {...props}>
    <Path
      d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
      fill="#EECD96"
    />
  </Svg>
)

export const StarIcon14 = ({ color, size, type }: StarIconProps) => {
  if (type === 'empty') {
    return <StarEmpty fill={color} width={size} height={size} />
  }

  if (type === 'half') {
    return <StarHalf fill={color} width={size} height={size} />
  }

  return <StarFull fill={color} width={size} height={size} />
}

export const SpiritCardHeight = 150

const SpiritCard = ({
  onClick,
  onWishBtnClick,
  imageUrl,
  id,
  title,
  rating,
  ratingCount,
  brand,
  userScanImage = '',
  collect = false,
  showWishlist = false,
  showMoreBtn = false,
  onMoreBtnClick,
  marketPrice = 0,
  flavors = [],
  showPrice = false,
  showFlavors = false,
}: Props) => {
  const dispatch = useDispatch()
  const { Layout, Gutters, Fonts, Images } = useTheme()

  const cardHeight = useMemo(() => {
    if (showPrice || showFlavors || flavors.length > 0 || marketPrice > 0) {
      return '100%'
    }

    return SpiritCardHeight
  }, [showPrice, showFlavors, flavors, marketPrice])

  const styles = getStyle({
    cardHeight,
  })

  // 移除 wishlist
  const [removeWishlistRequest] = spiritsApi.usePostRemoveWishlistMutation({})

  // 加入 wishlist
  const [addWishlistRequest] = spiritsApi.usePostAddWishlistMutation({})

  const handleClickWishBtn = () => {
    if (onWishBtnClick) {
      onWishBtnClick()
    }
    if (collect) {
      removeWishlistRequest({
        id: 'default',
        spiritId: id.toString(),
      })
    } else {
      addWishlistRequest({
        id: 'default',
        spiritId: id.toString(),
      })
    }
  }

  const handleOpenImageLightBox = () => {
    dispatch(
      globalActions.openProductImageLightBox({
        visible: true,
        resizeMode: 'cover',
        imagePath: imageUrl,
        imageStyle: { width: 300, height: 300, borderRadius: 10 },
        containerStyle: {
          backgroundColor: 'transparent',
        },
        modalStyle: {
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }),
    )
  }

  return (
    <TouchableOpacity style={[Gutters.tinyBMargin]} onPress={onClick}>
      <View style={[styles.cardContainer]}>
        <View style={[Layout.row]}>
          <TouchableOpacity onPress={handleOpenImageLightBox}>
            <SpiritImage
              style={styles.cardImage}
              linearGradientContainerStyle={styles.cardImage}
              imageUrl={imageUrl}
              needSEO
            />
          </TouchableOpacity>
          <View style={[styles.contentContainer]}>
            <View style={[Gutters.smallBMargin, Layout.fullWidth]}>
              <Text style={[styles.cardBrand]}>
                {capitalizedSentence(brand)}
              </Text>
              <Text
                style={[styles.cardName, Gutters.smallBMargin]}
                ellipsizeMode="tail"
                numberOfLines={2}
              >
                {capitalizedSentence(title)}
              </Text>
              <View style={[Layout.row, Layout.rowHCenter]}>
                {rating ? (
                  <Text
                    style={[
                      Fonts.size32,
                      Fonts.weight400,
                      Gutters.smallRMargin,
                      { color: Colors.white },
                    ]}
                  >
                    {handleFormatRating(rating)}
                  </Text>
                ) : (
                  <Text
                    style={[
                      Fonts.size16,
                      Fonts.weight400,
                      Gutters.smallRMargin,
                      { color: Colors.white },
                    ]}
                  >
                    暫無評分
                  </Text>
                )}
                <View>
                  <StarRating
                    rating={rating || 0}
                    starSize={20}
                    color="#EECD96"
                    onChange={e => {
                      console.log(e)
                    }}
                    StarIconComponent={StarIcon14}
                    starStyle={styles.starStyle}
                    style={[]}
                    animationConfig={{
                      scale: 1,
                    }}
                  />
                  <Text
                    style={[
                      Fonts.size12,
                      Fonts.weight400,
                      { color: Colors.gray },
                    ]}
                  >
                    {`(${ratingCount} 個評分)`}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        {(showPrice || showFlavors) && (
          <View style={[Gutters.regularHPadding]}>
            <FlavorItemWithTopRank flavors={flavors} />
            {marketPrice && (
              <Text
                style={[
                  Fonts.weight400,
                  Fonts.size12,
                  { color: Colors.fontText.light.primary3 },
                ]}
              >
                {`參考價格：NTD $${marketPrice.toLocaleString()}`}
              </Text>
            )}
          </View>
        )}
      </View>

      {/* 如果需要顯示 wishlist 按鈕 */}
      {showWishlist && (
        <TouchableOpacity
          style={[Layout.rowCenter, styles.wishBtn]}
          onPress={handleClickWishBtn}
        >
          <Image
            source={collect ? Images.collected : Images.collect}
            resizeMode="contain"
            style={[Gutters.tinyRMargin, styles.wishIcon]}
          />
        </TouchableOpacity>
      )}
      {/* 如果需要顯示 more 按鈕 */}
      {showMoreBtn && (
        <TouchableOpacity
          style={[Layout.rowCenter, styles.wishBtn]}
          onPress={onMoreBtnClick}
        >
          <Image
            source={Images.more}
            resizeMode="contain"
            style={Layout.iconSize24}
          />
        </TouchableOpacity>
      )}
      {/* 如果是在辨識結果頁面，需要呈現使用者辨識的圖片 */}
      {!!userScanImage?.length && (
        <Image
          source={{ uri: userScanImage }}
          resizeMode="cover"
          style={styles.scanImage}
        />
      )}
    </TouchableOpacity>
  )
}

const getStyle = ({ cardHeight }: { cardHeight: number | string }) =>
  StyleSheet.create({
    cardContainer: {
      width: '100%',
      height: cardHeight,
      backgroundColor: Colors.background.surface,
      paddingTop: 16,
      paddingBottom: 16,
    },
    cardImage: {
      width: 100,
      height: 100,
      borderRadius: 8,
      marginRight: 16,
      marginLeft: 16,
      objectFit: 'cover',
    },
    cardBrand: {
      color: Colors.gray,
      fontSize: 14,
      marginBottom: 4,
    },
    cardName: {
      color: Colors.white,
      fontSize: 16,
      fontWeight: '700',
      marginBottom: 4,
      width: '100%',
      wordBreak: 'break-word',
    },
    contentContainer: {
      width: '50%',
    },
    wishBtn: {
      position: 'absolute',
      top: 15,
      right: 16,
    },
    starStyle: {
      marginLeft: 0,
      marginRight: 0,
    },
    wishIcon: {
      width: 14,
      height: 18,
    },
    scanImage: {
      width: 56,
      height: 56,
      position: 'absolute',
      left: 12,
      bottom: 25,
      borderWidth: 2,
      borderColor: Colors.secondaryText,
      borderRadius: 8,
    },
  })

SpiritCard.defaultProps = {
  showWishlist: false,
  collect: false,
  userScanImage: '',
  onWishBtnClick: () => {},
  showMoreBtn: false,
  onMoreBtnClick: () => {},
  marketPrice: 0,
  flavors: [],
  showPrice: false,
  showFlavors: false,
}

export default SpiritCard
