import { api, prefixProxyEndpoint } from '@/Services/api'

export const threadsApi = api.injectEndpoints({
  endpoints: build => ({
    // 重新連結新的 thread
    postThreadsRenew: build.mutation<void, void>({
      query: () => ({
        url: prefixProxyEndpoint('/v1/assistants/threads:renew'),
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
})

threadsApi.enhanceEndpoints({
  addTagTypes: ['threads'],
  endpoints: {},
})

export default threadsApi
