import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'

import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'

export interface ExploreCardProps {
  title: string
  description: string
  onPress?: () => void
}

const ExploreCard = ({ title, description, onPress }: ExploreCardProps) => {
  const { Layout, Fonts, Gutters } = useTheme()
  return (
    <TouchableOpacity style={[styles.exploreCard]} onPress={onPress}>
      <View
        style={[
          Layout.row,
          Layout.alignItemsCenter,
          Gutters.tinyBMargin,
          { gap: 8 },
        ]}
      >
        <View style={styles.dot} />
        <Text
          style={[
            Fonts.size14,
            Fonts.weight400,

            { color: Colors.fontText.light.primary2 },
          ]}
        >
          {title}
        </Text>
      </View>
      <Text
        style={[
          Fonts.size14,
          Fonts.weight400,
          { color: Colors.fontText.light.primary3 },
        ]}
      >
        {description}
      </Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  dot: {
    width: 8,
    height: 8,
    backgroundColor: Colors.primary,
    borderRadius: 8,
  },
  exploreCard: {
    width: '48%',
    backgroundColor: Colors.background.surface,
    padding: 16,
    borderRadius: 8,
  },
})

ExploreCard.defaultProps = {
  onPress: () => {},
}

export default ExploreCard
