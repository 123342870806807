import {
  View,
  TouchableOpacity,
  Text,
  StyleSheet,
  Image,
  Platform,
} from 'react-native'
import React from 'react'
import useTheme from '@/Hooks/useTheme'
import { Colors } from '@/Theme/Variables'

interface AddFavorKeywordProps {
  openFavorKeyword: boolean
  setOpenFavorKeyword: React.Dispatch<React.SetStateAction<boolean>>
}

const AddFavorKeyword = ({
  openFavorKeyword,
  setOpenFavorKeyword,
}: AddFavorKeywordProps) => {
  const { Images } = useTheme()

  const text = openFavorKeyword ? 'Done' : '#Add my flavors'
  const image = openFavorKeyword
    ? Images.rating_comment_close
    : Images.rating_comment_add_circle

  return (
    <TouchableOpacity
      style={[styles.container, openFavorKeyword && styles.containerOpen]}
      onPress={() => setOpenFavorKeyword(!openFavorKeyword)}
    >
      <View
        style={[
          styles.iconContainer,
          openFavorKeyword && styles.iconContainerOpen,
        ]}
      >
        <Image style={styles.icon} source={image} resizeMode="contain" />
      </View>
      <Text style={[styles.text, openFavorKeyword && styles.textOpen]}>
        {text}
      </Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginTop: 15,
    marginLeft: 30,
    padding: 6,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    backgroundColor: Colors.primary,
    zIndex: 1,
    ...Platform.select({
      android: {
        marginTop: 3,
      },
    }),
  },
  containerOpen: {
    backgroundColor: Colors.transparent,
    borderWidth: 2,
    borderLeftColor: Colors.transparent,
    borderRightColor: Colors.primary,
    borderTopColor: Colors.primary,
    borderBottomColor: Colors.primary,
    marginTop: 15,
    marginLeft: 30,
    paddingLeft: 8,
    ...Platform.select({
      ios: {
        marginTop: 15,
        marginLeft: 30,
      },
      android: {
        marginTop: 3,
        marginLeft: 36,
      },
    }),
  },
  iconContainer: {
    position: 'absolute',
    left: -35,
    top: -7,
    width: 42,
    height: 43,
    borderRadius: 100,
    borderRightColor: Colors.transparent,
    borderTopColor: Colors.primary,
    borderBottomColor: Colors.primary,
    borderLeftColor: Colors.primary,
    backgroundColor: Colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 0,
  },
  iconContainerOpen: {
    backgroundColor: Colors.transparent,
    paddingLeft: 4,
    borderWidth: 2,
    width: 41,
    height: 45,
    left: -34,
    top: -8,
    ...Platform.select({
      ios: {
        left: -33,
        top: -7,
      },
      android: {
        left: -36,
        top: -6,
      },
    }),
  },
  icon: {
    width: 24,
    height: 24,
  },
  text: {
    color: Colors.white,
    fontWeight: 'bold',
  },
  textOpen: {
    color: Colors.primary,
  },
})

export default AddFavorKeyword
