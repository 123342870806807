import { useCallback, useState } from 'react'
import SparkMD5 from 'spark-md5'

import userApi from '@/Services/modules/users'

const fileToMd5 = async (file: File) => {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = e => {
      if (e?.target?.result && e?.target?.result instanceof ArrayBuffer) {
        const md5 = SparkMD5.ArrayBuffer.hash(e?.target?.result)
        resolve(md5)
      }
    }

    reader.onerror = e => {
      reject(e)
    }

    reader.readAsArrayBuffer(file)
  })
}

const useUploadFile = () => {
  const [uploadFileRequest] = userApi.useUploadFileMutation({})

  const [isLoading, setIsLoading] = useState(false)

  const uploadFile = useCallback(async (file: File) => {
    setIsLoading(true)
    const md5Hash = await fileToMd5(file)

    const uploadFileRes = await uploadFileRequest({
      contentType: file.type,
      contentMd5: md5Hash as string,
      limitSize: 1000,
    })

    if ('error' in uploadFileRes) {
      // handle error
      setIsLoading(false)
    } else {
      const options: RequestInit = {
        body: file,
        credentials: 'omit',
        headers: uploadFileRes?.data?.data?.headers,
        method: 'PUT',
      }
      const putUploadFileRes = await fetch(
        uploadFileRes?.data?.data?.signedUrl,
        options,
      )
      if (putUploadFileRes?.status === 200) {
        const path = uploadFileRes?.data?.data?.path
        return path
      }
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { uploadFile, isLoading }
}

export default useUploadFile
