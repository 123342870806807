export const Config = {
  API_URL: 'https://api.wenlibai-demo.leadproject.net',
  API_URL_LOG: '',
  ENV: 'local',
  FB_APPID: '2336372899867472',
  GOOGLE_WEB_ID:
    '462522817646-h8f93p5hl93qr90nt968j1hqr1ndtugu.apps.googleusercontent.com',
  APPLE_WEB_ID: 'com.spiritshub.main.dev',
  APPLE_WEB_REDIRECT_URL:
    'https://www.wenlibai-beta.leadproject.net/LoginMainContainer',
  DOMAIN: 'https://www.wenlibai-demo.leadproject.net',
  GA_ID: '',
  GA_CODE: '',
  CLARITY_ID: '',
  IMAGE_VERSION: '',
  OPEN_SOCIAL_LOGIN_BUTTON_GOOGLE: true,
  OPEN_SOCIAL_LOGIN_BUTTON_FACEBOOK: true,
  OPEN_SOCIAL_LOGIN_BUTTON_APPLE: false,
}
