import React, { useCallback, useEffect, useState, useRef } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  Dimensions,
  ActivityIndicator,
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollView } from 'react-native-gesture-handler'
import { launchImageLibrary } from 'react-native-image-picker'
import { List, TextInput } from 'react-native-paper'

import { useTheme } from '@/Hooks'
import { BorderRadius, Colors, Height } from '@/Theme/Variables'
import { FadeInView, Header } from '@/Components'
import { navigate } from '@/Navigators/utils'
import { removeEmptyKeys, capitalizedSentence } from '@/Util/global'
import { dataUriToFile } from '@/Util/global'
import { globalActions } from '@/Store/Global'
import spiritsApi from '@/Services/modules/spirits'
import SelectDropdown from 'react-native-select-dropdown'
import noteApi from '@/Services/modules/note'
import { DropdownsType, NestedSetType } from '@/Services/modules/note/note.type'
import { RootState } from '@/Store'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'
import useUploadFile from '@/Hooks/useUploadFile'

interface initType {
  imagePath: {
    foreground: string
    background: string
  }
  title: string
  category: string
  subcategory: string
  description: string
  brand: string
  brandRemark: string
  country: string
  countryRemark: string
  region: string
  regionRemark: string
  marketPrice: number | null
  volume: number | null
  strength: number | null
  facts: {
    age: number | null
    bottler: string
  }
}

const initSpiritsData: initType = {
  imagePath: {
    foreground: '',
    background: '',
  },
  title: '',
  category: '',
  subcategory: '',
  description: '',
  brand: '',
  brandRemark: '',
  country: '',
  countryRemark: '',
  region: '',
  regionRemark: '',
  marketPrice: null,
  volume: null,
  strength: null,
  facts: {
    age: null,
    bottler: '',
  },
}

const UploadSpiritsDetailContainer = ({ _route, navigation }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  const dispatch = useDispatch()
  const { uploadFile: uploadFileRequest, isLoading: uploadFileIsLoading } =
    useUploadFile()
  const styles = getStyle()
  const { language } = useSelector((state: RootState) => state.theme)

  const brandDropdownRef = useRef<any>(null)
  const countryDropdownRef = useRef<any>(null)
  const regionDropdownRef = useRef<any>(null)
  const subcategoryRef = useRef<any>(null)

  // 上傳圖檔至 image server
  const [postMediaRequest, { isLoading: postMediaIsLoading }] =
    spiritsApi.usePostMediaMutation()

  // 新增酒資料
  const [
    postUploadSpiritsRequest,
    {
      isSuccess: postUploadSpiritsRequestIsSuccess,
      isLoading: postUploadSpiritsRequestIsLoading,
      reset: postUploadSpiritsRequestReset,
    },
  ] = spiritsApi.usePostUploadSpiritsMutation()

  // 取得地區資訊
  const { data: locationData, isSuccess: locationDataIsSuccess } =
    noteApi.useGetNestedSetQuery({
      type: NestedSetType.LOCATION,
    })

  // 取得種類資訊
  const { data: categoryData, isSuccess: categoryDataIsSuccess } =
    noteApi.useGetNestedSetQuery({
      type: NestedSetType.CATEGORY,
    })

  // 取得下拉選單（品牌）
  const { data: brandData, isSuccess: brandDataIsSuccess } =
    noteApi.useGetNoteDropdownsOptionsQuery({
      type: DropdownsType.BRAND,
      locale: language,
    })

  const [advanceNoteInfoExpanded, setAdvanceNoteInfoExpanded] =
    useState<boolean>(false)
  const [IBNoteInfoExpanded, setIBNoteInfoExpanded] = useState<boolean>(false)
  const [spiritData, setSpiritData] = useState(initSpiritsData)
  const [brandList, setBrandList] = useState<any[]>([])
  const [countriesList, setCountriesList] = useState<any[]>([])
  const [regionList, setRegionList] = useState<any[]>([])
  // TODO: 詢問是否改下拉選單
  const [categoryList, setCategoryList] = useState<any[]>([])
  const [subCategoryList, setSubCategoryList] = useState<any[]>([])

  const handleGoBack = useCallback(() => {
    setSpiritData(initSpiritsData)
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigate(PagesEnum.Main, {
      screen: 'Profile',
    })
  }, [navigation])

  useEffect(() => {
    if (postUploadSpiritsRequestIsSuccess) {
      dispatch(
        globalActions.openSnackbar({
          visible: true,
          message: '上傳成功',
          type: 'success',
        }),
      )
      handleGoBack()
      postUploadSpiritsRequestReset()
      setSpiritData(initSpiritsData)
    }
  }, [
    dispatch,
    handleGoBack,
    postUploadSpiritsRequestIsSuccess,
    postUploadSpiritsRequestReset,
  ])

  // 整理品牌下拉選單
  useEffect(() => {
    if (brandDataIsSuccess) {
      const listArr: any[] = []
      Object.keys(brandData?.data).forEach(key => {
        listArr.push({
          title: brandData?.data?.[key] as any,
          slug: key,
        })
      })
      setBrandList(listArr)
    }
  }, [brandDataIsSuccess, brandData])

  // 整理國家下拉選單
  useEffect(() => {
    if (locationDataIsSuccess) {
      const listArr: any[] = []
      Object.keys(locationData?.data?.roots).forEach(key => {
        listArr.push({
          title: locationData?.data?.roots?.[key] as any,
          slug: key,
        })
      })
      setCountriesList(listArr)
    }
  }, [locationDataIsSuccess, locationData])

  // 整理地區下拉選單（依據國家篩選地區）
  useEffect(() => {
    const selectedCountry = countriesList.find(
      item => item?.title === spiritData?.country,
    )

    const regionArr = locationData?.data?.children?.find(
      (item: any) => item?.parentSlug === selectedCountry?.slug,
    )
    if (regionArr) {
      const listArr: any[] = []
      Object.keys(regionArr?.data).forEach(key => {
        listArr.push({
          title: regionArr?.data?.[key] as any,
          slug: key,
        })
      })
      setRegionList(listArr)
      return
    }
    setRegionList([])
  }, [countriesList, locationData?.data?.children, spiritData?.country])

  // 整理類別下拉選單
  useEffect(() => {
    if (categoryDataIsSuccess) {
      const listArr: any[] = []
      Object.keys(categoryData?.data?.roots).forEach(key => {
        listArr.push({
          title: categoryData?.data?.roots?.[key] as any,
          slug: key,
        })
      })
      setCategoryList(listArr)
    }
  }, [categoryDataIsSuccess, categoryData])

  // 整理副類別下拉選單（依據國家篩選地區）
  useEffect(() => {
    const selectedCategory = categoryList.find(
      item => item?.title === spiritData?.category,
    )

    const subCategoryArr = categoryData?.data?.children?.find(
      (item: any) => item?.parentSlug === selectedCategory?.slug,
    )
    if (subCategoryArr) {
      const listArr: any[] = []
      Object.keys(subCategoryArr?.data).forEach(key => {
        listArr.push({
          title: subCategoryArr?.data?.[key] as any,
          slug: key,
        })
      })
      setSubCategoryList(listArr)
      return
    }
    setSubCategoryList([])
  }, [categoryList, categoryData?.data?.children, spiritData?.category])

  // 開啟相簿
  const handleOpenGallery = async (path: 'foreground' | 'background') => {
    const result = await launchImageLibrary({
      mediaType: 'photo',
      selectionLimit: 1,
    })

    if (result?.assets) {
      // 防止使用者上傳超過 5mb 的檔案
      const file = dataUriToFile(result?.assets[0]?.uri || '', '')
      const fileSizeInMB = file?.size / 1024 / 1024
      if (fileSizeInMB >= 5) {
        dispatch(
          globalActions.openSnackbar({
            visible: true,
            message: '檔案大小超過 5MB，請重新選擇照片',
            type: 'default',
          }),
        )
        return
      }

      const dataURI = result?.assets[0]?.uri || ''
      setSpiritData({
        ...spiritData,
        imagePath: {
          ...spiritData.imagePath,
          [path]: dataURI,
        },
      })
    }
  }

  const handleInputTextNumber = (value: string) => {
    const result = Number(value.replace(/[^0-9]/, ''))
    if (isNaN(result)) {
      return null
    }
    if (result === 0) {
      return ''
    }
    return result
  }

  const handleInputTextNumberWithDecimalPoint = (input: string) => {
    const result = input
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1')
      .replace(/(\.\d{2})\d+/, '$1')

    if (Number(result) >= 999) {
      return '999'
    }
    return result
  }

  // 送出資料
  const handleSubmit = async () => {
    let foregroundPath = ''
    let backgroundPath = ''
    if (spiritData?.imagePath?.foreground?.length) {
      const mediaResponse =
        (await uploadFileRequest(
          dataUriToFile(spiritData?.imagePath?.foreground, 'foreground'),
        )) || ''
      foregroundPath = mediaResponse
    }

    if (spiritData?.imagePath?.background?.length) {
      const mediaResponse =
        (await uploadFileRequest(
          dataUriToFile(spiritData?.imagePath?.background, 'background'),
        )) || ''
      backgroundPath = mediaResponse
    }

    const payload = {
      imagePath: {
        foreground: foregroundPath,
        background: backgroundPath,
      },
      title: spiritData?.title,
      category: spiritData?.category,
      subcategory: spiritData?.subcategory,
      description: spiritData?.description,
      brand: spiritData?.brand || spiritData?.brandRemark,
      country: spiritData?.country || spiritData?.countryRemark,
      region: spiritData?.region || spiritData?.regionRemark,
      marketPrice: spiritData?.marketPrice,
      volume: spiritData?.volume,
      strength: spiritData?.strength,
      facts: {
        age: spiritData?.facts?.age,
        bottler: spiritData?.facts?.bottler,
      },
    }

    postUploadSpiritsRequest(removeEmptyKeys(payload))
  }

  // IB區塊
  const renderIBNoteInfo = () => {
    return (
      <List.Accordion
        left={() => <View style={{ marginLeft: -16 }} />}
        title={
          <View style={[Layout.fullWidth]}>
            <View
              style={[
                Layout.row,
                Layout.fullWidth,
                Layout.alignItemsCenter,
                Gutters.tinyBMargin,
              ]}
            >
              <Text
                style={[
                  Fonts.size16,
                  Fonts.weight700,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                獨立裝瓶 (IB) 建議填寫欄位
              </Text>
            </View>
            <View>
              <Text
                style={[Fonts.size16, Fonts.weight400, { color: '#999999' }]}
              >
                若此產品為 IB，請協助補充更完整資訊
              </Text>
            </View>
          </View>
        }
        right={({ isExpanded }) => {
          return (
            <Image
              source={Images.arrowRight}
              resizeMode="contain"
              style={[
                Layout.iconSize24,
                { transform: [{ rotate: isExpanded ? '-90deg' : '90deg' }] },
              ]}
            />
          )
        }}
        style={[styles.toggleList, { padding: 0 }]}
        expanded={IBNoteInfoExpanded}
        onPress={() => setIBNoteInfoExpanded(!IBNoteInfoExpanded)}
      >
        {/* 照片 */}
        <View style={[Gutters.regularBMargin, { paddingLeft: 0 }]}>
          <Text
            style={[
              Fonts.size16,
              Fonts.weight400,
              Gutters.tinyBMargin,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            背面酒標
          </Text>
          <TouchableOpacity onPress={() => handleOpenGallery('background')}>
            {spiritData?.imagePath?.background.length ? (
              <Image
                source={{ uri: spiritData?.imagePath?.background }}
                style={[{ width: 100, height: 100, borderRadius: 8 }]}
                resizeMode="cover"
              />
            ) : (
              <Image
                source={Images.profile_upload_spirits_detail_uploadImage}
                style={[{ width: 100, height: 100 }]}
                resizeMode="cover"
              />
            )}
          </TouchableOpacity>
        </View>

        {/* 品牌 */}
        <View style={[Gutters.tinyBMargin, { paddingLeft: 0 }]}>
          <View
            style={[
              Layout.row,
              Layout.justifyContentBetween,
              { alignItems: 'baseline' },
            ]}
          >
            <SelectDropdown
              ref={brandDropdownRef}
              data={brandList || []}
              // defaultValueByIndex={titleIndex || 0}
              defaultButtonText="品牌"
              // disabledIndexs={titleGroup?.length === 1 ? [0] : []}
              onSelect={selectedItem => {
                if (spiritData?.brandRemark?.length) {
                  setSpiritData({
                    ...spiritData,
                    brandRemark: '',
                    brand: selectedItem.title,
                  })
                  return
                }
                setSpiritData({
                  ...spiritData,
                  brand: selectedItem.title,
                })
              }}
              buttonTextAfterSelection={selectedItem => {
                // text represented after item is selected
                // if data array is an array of objects then return selectedItem.property to render after item is selected
                return capitalizedSentence(selectedItem.title)
              }}
              rowTextForSelection={item => {
                // text represented for each item in dropdown
                // if data array is an array of objects then return item.property to represent item in dropdown
                return capitalizedSentence(item.title)
              }}
              dropdownStyle={{
                backgroundColor: Colors.background.onSurface,
              }}
              rowTextStyle={{
                color: Colors.fontText.light.primary3,
                textAlign: 'left',
                paddingLeft: 8,
                paddingVertical: 8,
              }}
              buttonStyle={{
                width: '48%',
                borderWidth: 1,
                borderColor: 'rgba(255, 255, 255, 0.6)',
                borderRadius: 10,
                height: 48,
                backgroundColor: Colors.background.default,
              }}
              buttonTextStyle={{
                color: spiritData?.brand
                  ? Colors.fontText.light.primary2
                  : Colors.fontText.light.primary3,
                textAlign: 'left',
                fontSize: 16,
              }}
              renderDropdownIcon={() => (
                <Image
                  source={Images.arrowRight}
                  resizeMode="contain"
                  style={[
                    Layout.iconSize20,
                    Gutters.smallRPadding,
                    {
                      transform: [{ rotate: '90deg' }],
                    },
                  ]}
                />
              )}
            />
            <TextInput
              value={spiritData?.brandRemark}
              label="備註"
              underlineColor="transparent"
              activeUnderlineColor="transparent"
              style={[styles.textInput, { width: '48%' }]}
              theme={{
                colors: { onSurfaceVariant: Colors.fontText.light.primary3 },
                roundness: 10,
              }}
              textColor="#fff"
              mode="outlined"
              outlineStyle={{
                width: '100%',
                borderWidth: 1,
              }}
              activeOutlineColor={Colors.primary}
              onChangeText={brandRemark => {
                if (spiritData.brand.length) {
                  setSpiritData({
                    ...spiritData,
                    brand: '',
                    brandRemark,
                  })
                  brandDropdownRef.current?.reset()
                  return
                }
                setSpiritData({
                  ...spiritData,
                  brandRemark,
                })
              }}
            />
          </View>
          <Text
            style={[
              Fonts.size14,
              Fonts.weight400,
              { color: Colors.fontText.light.primary3 },
            ]}
          >
            若無該選項，請填寫於備註欄位
          </Text>
        </View>

        {/* 酒精 */}
        <View style={[Gutters.tinyBMargin, { paddingLeft: 0 }]}>
          <TextInput
            value={spiritData?.strength?.toString()}
            label="酒精濃度"
            underlineColor="transparent"
            activeUnderlineColor="transparent"
            style={[styles.textInput]}
            theme={{
              colors: { onSurfaceVariant: Colors.fontText.light.primary3 },
              roundness: 10,
            }}
            textColor="#fff"
            mode="outlined"
            outlineStyle={{
              borderWidth: 1,
            }}
            activeOutlineColor={Colors.primary}
            keyboardType={'number-pad'}
            onChangeText={strength => {
              setSpiritData({
                ...spiritData,
                strength: handleInputTextNumberWithDecimalPoint(strength),
              })
            }}
          />
        </View>

        {/* 裝瓶廠 */}
        <View style={[Gutters.tinyBMargin, { paddingLeft: 0 }]}>
          <TextInput
            value={spiritData?.facts?.bottler}
            label="裝瓶廠"
            underlineColor="transparent"
            activeUnderlineColor="transparent"
            style={[styles.textInput]}
            theme={{
              colors: { onSurfaceVariant: Colors.fontText.light.primary3 },
              roundness: 10,
            }}
            textColor="#fff"
            mode="outlined"
            outlineStyle={{
              borderWidth: 1,
            }}
            activeOutlineColor={Colors.primary}
            onChangeText={bottler => {
              setSpiritData({
                ...spiritData,
                facts: {
                  ...spiritData.facts,
                  bottler,
                },
              })
            }}
          />
        </View>
      </List.Accordion>
    )
  }

  // 進階區塊
  const renderAdvanceNoteInfo = () => {
    return (
      <List.Accordion
        left={() => <View style={{ marginLeft: -16 }} />}
        title={
          <View style={[Layout.fullWidth]}>
            <View
              style={[
                Layout.row,
                Layout.fullWidth,
                Layout.alignItemsCenter,
                Gutters.tinyBMargin,
              ]}
            >
              <Text
                style={[
                  Fonts.size16,
                  Fonts.weight700,
                  { color: Colors.fontText.light.primary2 },
                ]}
              >
                進階資訊
              </Text>
            </View>
            <View>
              <Text
                style={[Fonts.size16, Fonts.weight400, { color: '#999999' }]}
              >
                協助補充更完整資訊
              </Text>
            </View>
          </View>
        }
        right={({ isExpanded }) => {
          return (
            <Image
              source={Images.arrowRight}
              resizeMode="contain"
              style={[
                Layout.iconSize24,
                { transform: [{ rotate: isExpanded ? '-90deg' : '90deg' }] },
              ]}
            />
          )
        }}
        style={[styles.toggleList, { padding: 0 }]}
        expanded={advanceNoteInfoExpanded}
        onPress={() => setAdvanceNoteInfoExpanded(!advanceNoteInfoExpanded)}
      >
        {/* 描述 */}
        <View style={[Gutters.tinyBMargin, { paddingLeft: 0 }]}>
          <TextInput
            value={spiritData?.description}
            label="描述"
            underlineColor="transparent"
            activeUnderlineColor="transparent"
            style={[styles.textInput]}
            theme={{
              colors: { onSurfaceVariant: Colors.fontText.light.primary3 },
              roundness: 10,
            }}
            textColor="#fff"
            mode="outlined"
            outlineStyle={{
              borderWidth: 1,
            }}
            activeOutlineColor={Colors.primary}
            onChangeText={description => {
              setSpiritData({
                ...spiritData,
                description,
              })
            }}
          />
        </View>

        {/* 子類別 */}
        <View style={[Gutters.tinyBMargin, { paddingLeft: 0 }]}>
          <SelectDropdown
            data={subCategoryList || []}
            ref={subcategoryRef}
            disabled={!subCategoryList?.length}
            // defaultValueByIndex={titleIndex || 0}
            defaultButtonText="子類別"
            // disabledIndexs={titleGroup?.length === 1 ? [0] : []}
            onSelect={selectedItem => {
              setSpiritData({
                ...spiritData,
                subcategory: selectedItem.title,
              })
            }}
            buttonTextAfterSelection={selectedItem => {
              // text represented after item is selected
              // if data array is an array of objects then return selectedItem.property to render after item is selected
              return selectedItem.title
            }}
            rowTextForSelection={item => {
              // text represented for each item in dropdown
              // if data array is an array of objects then return item.property to represent item in dropdown
              return item.title
            }}
            dropdownStyle={{
              backgroundColor: Colors.background.onSurface,
            }}
            rowTextStyle={{
              color: Colors.fontText.light.primary3,
              textAlign: 'left',
              paddingLeft: 8,
              paddingVertical: 8,
            }}
            buttonStyle={{
              width: '100%',
              borderWidth: 1,
              borderColor: 'rgba(255, 255, 255, 0.6)',
              borderRadius: 10,
              height: 48,
              backgroundColor: !subCategoryList?.length
                ? Colors.background.onSurface
                : Colors.background.default,
              opacity: !subCategoryList?.length ? 0.8 : 1,
            }}
            buttonTextStyle={{
              color: spiritData?.subcategory
                ? Colors.fontText.light.primary2
                : Colors.fontText.light.primary3,
              textAlign: 'left',
              opacity: !subCategoryList?.length ? 0.8 : 1,
              fontSize: 16,
            }}
            renderDropdownIcon={() => (
              <Image
                source={Images.arrowRight}
                resizeMode="contain"
                style={[
                  Layout.iconSize20,
                  Gutters.smallRPadding,
                  {
                    transform: [{ rotate: '90deg' }],
                  },
                ]}
              />
            )}
          />
        </View>

        {/* 國家 */}
        <View style={[Gutters.tinyBMargin, { paddingLeft: 0 }]}>
          <View
            style={[
              Layout.row,
              Layout.justifyContentBetween,
              { alignItems: 'baseline' },
            ]}
          >
            <SelectDropdown
              ref={countryDropdownRef}
              data={countriesList || []}
              // defaultValueByIndex={titleIndex || 0}
              defaultButtonText="國家"
              // disabledIndexs={titleGroup?.length === 1 ? [0] : []}
              onSelect={selectedItem => {
                if (spiritData?.countryRemark?.length) {
                  setSpiritData({
                    ...spiritData,
                    countryRemark: '',
                    country: selectedItem.title,
                  })
                  return
                }
                setSpiritData({
                  ...spiritData,
                  country: selectedItem.title,
                })
              }}
              buttonTextAfterSelection={selectedItem => {
                // text represented after item is selected
                // if data array is an array of objects then return selectedItem.property to render after item is selected
                return selectedItem.title
              }}
              rowTextForSelection={item => {
                // text represented for each item in dropdown
                // if data array is an array of objects then return item.property to represent item in dropdown
                return item.title
              }}
              dropdownStyle={{
                backgroundColor: Colors.background.onSurface,
              }}
              rowTextStyle={{
                color: Colors.fontText.light.primary3,
                textAlign: 'left',
                paddingLeft: 8,
                paddingVertical: 8,
              }}
              buttonStyle={{
                width: '48%',
                borderWidth: 1,
                borderColor: 'rgba(255, 255, 255, 0.6)',
                borderRadius: 10,
                height: 48,
                backgroundColor: Colors.background.default,
              }}
              buttonTextStyle={{
                color: spiritData?.country
                  ? Colors.fontText.light.primary2
                  : Colors.fontText.light.primary3,
                textAlign: 'left',
                fontSize: 16,
              }}
              renderDropdownIcon={() => (
                <Image
                  source={Images.arrowRight}
                  resizeMode="contain"
                  style={[
                    Layout.iconSize20,
                    Gutters.smallRPadding,
                    {
                      transform: [{ rotate: '90deg' }],
                    },
                  ]}
                />
              )}
            />
            <TextInput
              value={spiritData?.countryRemark}
              label="備註"
              underlineColor="transparent"
              activeUnderlineColor="transparent"
              style={[styles.textInput, { width: '48%' }]}
              theme={{
                colors: { onSurfaceVariant: Colors.fontText.light.primary3 },
                roundness: 10,
              }}
              textColor="#fff"
              mode="outlined"
              outlineStyle={{
                width: '100%',
                borderWidth: 1,
              }}
              activeOutlineColor={Colors.primary}
              onChangeText={countryRemark => {
                if (spiritData?.country?.length) {
                  setSpiritData({
                    ...spiritData,
                    country: '',
                    countryRemark,
                  })
                  countryDropdownRef?.current?.reset()
                }
                setSpiritData({
                  ...spiritData,
                  countryRemark,
                })
              }}
            />
          </View>
          <Text
            style={[
              Fonts.size14,
              Fonts.weight400,
              { color: Colors.fontText.light.primary3 },
            ]}
          >
            若無該選項，請填寫於備註欄位
          </Text>
        </View>

        {/* 地區 */}
        <View style={[Gutters.tinyBMargin, { paddingLeft: 0 }]}>
          <View
            style={[
              Layout.row,

              Layout.justifyContentBetween,
              { alignItems: 'baseline' },
            ]}
          >
            <SelectDropdown
              ref={regionDropdownRef}
              data={regionList || []}
              disabled={!regionList?.length}
              // defaultValueByIndex={titleIndex || 0}
              defaultButtonText="地區"
              // disabledIndexs={titleGroup?.length === 1 ? [0] : []}
              onSelect={selectedItem => {
                if (spiritData?.regionRemark?.length) {
                  setSpiritData({
                    ...spiritData,
                    regionRemark: '',
                    region: selectedItem.title,
                  })
                  return
                }
                setSpiritData({
                  ...spiritData,
                  region: selectedItem.title,
                })
              }}
              buttonTextAfterSelection={selectedItem => {
                // text represented after item is selected
                // if data array is an array of objects then return selectedItem.property to render after item is selected
                return selectedItem.title
              }}
              rowTextForSelection={item => {
                // text represented for each item in dropdown
                // if data array is an array of objects then return item.property to represent item in dropdown
                return item.title
              }}
              dropdownStyle={{
                backgroundColor: Colors.background.onSurface,
              }}
              rowTextStyle={{
                color: Colors.fontText.light.primary3,
                textAlign: 'left',
                paddingLeft: 8,
                paddingVertical: 8,
              }}
              buttonStyle={{
                width: '48%',
                borderWidth: 1,
                borderColor: 'rgba(255, 255, 255, 0.6)',
                borderRadius: 10,
                height: 48,
                backgroundColor: !regionList?.length
                  ? Colors.background.onSurface
                  : Colors.background.default,
                opacity: !regionList?.length ? 0.8 : 1,
              }}
              buttonTextStyle={{
                color: spiritData?.region
                  ? Colors.fontText.light.primary2
                  : Colors.fontText.light.primary3,
                textAlign: 'left',
                opacity: !regionList?.length ? 0.8 : 1,
                fontSize: 16,
              }}
              renderDropdownIcon={() => (
                <Image
                  source={Images.arrowRight}
                  resizeMode="contain"
                  style={[
                    Layout.iconSize20,
                    Gutters.smallRPadding,
                    {
                      transform: [{ rotate: '90deg' }],
                    },
                  ]}
                />
              )}
            />
            <TextInput
              value={spiritData?.regionRemark}
              label="備註"
              underlineColor="transparent"
              activeUnderlineColor="transparent"
              style={[styles.textInput, { width: '48%' }]}
              theme={{
                colors: { onSurfaceVariant: Colors.fontText.light.primary3 },
                roundness: 10,
              }}
              textColor="#fff"
              mode="outlined"
              outlineStyle={{
                width: '100%',
                borderWidth: 1,
              }}
              activeOutlineColor={Colors.primary}
              onChangeText={regionRemark => {
                if (spiritData?.region?.length) {
                  setSpiritData({
                    ...spiritData,
                    region: '',
                    regionRemark,
                  })
                  regionDropdownRef?.current?.reset()
                }
                setSpiritData({
                  ...spiritData,
                  regionRemark,
                })
              }}
            />
          </View>
          <Text
            style={[
              Fonts.size14,
              Fonts.weight400,
              { color: Colors.fontText.light.primary3 },
            ]}
          >
            若無該選項，請填寫於備註欄位
          </Text>
        </View>

        {/* 容量 */}
        <View style={[Gutters.tinyBMargin, { paddingLeft: 0 }]}>
          <TextInput
            value={spiritData?.volume?.toString()}
            label="容量"
            underlineColor="transparent"
            activeUnderlineColor="transparent"
            style={[styles.textInput]}
            theme={{
              colors: { onSurfaceVariant: Colors.fontText.light.primary3 },
              roundness: 10,
            }}
            textColor="#fff"
            mode="outlined"
            outlineStyle={{
              borderWidth: 1,
            }}
            activeOutlineColor={Colors.primary}
            keyboardType={'number-pad'}
            onChangeText={volume => {
              setSpiritData({
                ...spiritData,
                volume: handleInputTextNumber(volume),
              })
            }}
          />
        </View>

        {/* 價格 */}
        <View style={[Gutters.tinyBMargin, { paddingLeft: 0 }]}>
          <TextInput
            value={spiritData?.marketPrice?.toString()}
            label="價格"
            underlineColor="transparent"
            activeUnderlineColor="transparent"
            style={[styles.textInput]}
            theme={{
              colors: { onSurfaceVariant: Colors.fontText.light.primary3 },
              roundness: 10,
            }}
            textColor="#fff"
            mode="outlined"
            outlineStyle={{
              borderWidth: 1,
            }}
            activeOutlineColor={Colors.primary}
            onChangeText={marketPrice => {
              setSpiritData({
                ...spiritData,
                marketPrice: handleInputTextNumber(marketPrice),
              })
            }}
          />
        </View>

        {/* 年份 */}
        <View style={[Gutters.tinyBMargin, { paddingLeft: 0 }]}>
          <TextInput
            value={spiritData?.facts?.age?.toString()}
            label="年份"
            underlineColor="transparent"
            activeUnderlineColor="transparent"
            style={[styles.textInput]}
            theme={{
              colors: { onSurfaceVariant: Colors.fontText.light.primary3 },
              roundness: 10,
            }}
            textColor="#fff"
            mode="outlined"
            outlineStyle={{
              borderWidth: 1,
            }}
            activeOutlineColor={Colors.primary}
            onChangeText={age => {
              setSpiritData({
                ...spiritData,
                facts: {
                  ...spiritData.facts,
                  age: handleInputTextNumber(age),
                },
              })
            }}
          />
        </View>
      </List.Accordion>
    )
  }

  // 下方 CTA 區塊
  const renderCTA = () => {
    const disable = spiritData?.imagePath?.foreground.length === 0
    const isLoading = uploadFileIsLoading || postUploadSpiritsRequestIsLoading

    return (
      <View
        style={[
          Gutters.smallVPadding,
          Gutters.regularHPadding,
          Layout.fullWidth,
          styles.CTAButton,
        ]}
      >
        <TouchableOpacity
          style={[
            Layout.fullWidth,
            Layout.rowCenter,
            {
              backgroundColor: disable ? '#D9D9D9' : Colors.primary,
              borderRadius: BorderRadius.radius8,
              height: Height.height47,
            },
          ]}
          disabled={disable}
          onPress={handleSubmit}
        >
          {isLoading ? (
            <ActivityIndicator size="large" color={Colors.white} />
          ) : (
            <Text
              style={[
                Fonts.weight500,
                Fonts.size16,
                {
                  color: disable ? Colors.gray : Colors.fontText.dark.primary2,
                },
              ]}
            >
              確認並提交
            </Text>
          )}
        </TouchableOpacity>
      </View>
    )
  }

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <Header
        title={'新增的酒資料 '}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
      />
      <FadeInView duration={500} style={Layout.fill}>
        <ScrollView
          style={[
            Layout.fill,
            Gutters.regularHPadding,
            Gutters.regularVPadding,
            { paddingBottom: 100 },
          ]}
        >
          <View style={[Gutters.regularBMargin]}>
            <Text
              style={[
                Fonts.size16,
                Fonts.weight500,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              感謝您幫助我們完善資料
            </Text>
            <Text
              style={[
                Fonts.size16,
                Fonts.weight500,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              您提交的資訊將於審核通過後增補至酒資料。
            </Text>
          </View>
          {/* 正面酒標 */}
          <View style={[Gutters.regularBMargin]}>
            <Text
              style={[
                Fonts.size16,
                Fonts.weight400,
                Gutters.tinyBMargin,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              正面酒標*
            </Text>
            <TouchableOpacity onPress={() => handleOpenGallery('foreground')}>
              {spiritData?.imagePath?.foreground.length ? (
                <Image
                  source={{ uri: spiritData?.imagePath?.foreground }}
                  style={[{ width: 100, height: 100, borderRadius: 8 }]}
                  resizeMode="cover"
                />
              ) : (
                <Image
                  source={Images.profile_upload_spirits_detail_uploadImage}
                  style={[{ width: 100, height: 100 }]}
                  resizeMode="cover"
                />
              )}
            </TouchableOpacity>
          </View>
          {/* 酒名稱 */}
          <View style={[Gutters.tinyBMargin]}>
            <TextInput
              value={spiritData?.title}
              label="酒名稱"
              underlineColor="transparent"
              activeUnderlineColor="transparent"
              style={[styles.textInput]}
              theme={{
                colors: { onSurfaceVariant: Colors.fontText.light.primary3 },
                roundness: 10,
              }}
              textColor="#fff"
              mode="outlined"
              outlineStyle={{
                borderWidth: 1,
              }}
              activeOutlineColor={Colors.primary}
              onChangeText={title => {
                setSpiritData({
                  ...spiritData,
                  title,
                })
              }}
            />
          </View>
          {/* 類別 */}
          <View style={[Gutters.regularBMargin]}>
            <SelectDropdown
              data={categoryList || []}
              // defaultValueByIndex={titleIndex || 0}
              defaultButtonText="類別"
              // disabledIndexs={titleGroup?.length === 1 ? [0] : []}
              onSelect={selectedItem => {
                setSpiritData({
                  ...spiritData,
                  category: selectedItem.title,
                  subcategory: '',
                })
                subcategoryRef?.current?.reset()
              }}
              buttonTextAfterSelection={selectedItem => {
                // text represented after item is selected
                // if data array is an array of objects then return selectedItem.property to render after item is selected
                return selectedItem.title
              }}
              rowTextForSelection={item => {
                // text represented for each item in dropdown
                // if data array is an array of objects then return item.property to represent item in dropdown
                return item.title
              }}
              dropdownStyle={{
                backgroundColor: Colors.background.onSurface,
              }}
              rowTextStyle={{
                color: Colors.fontText.light.primary3,
                textAlign: 'left',
                paddingLeft: 8,
                paddingVertical: 8,
              }}
              buttonStyle={{
                width: '100%',
                borderWidth: 1,
                borderColor: 'rgba(255, 255, 255, 0.6)',
                borderRadius: 10,
                height: 48,
                backgroundColor: Colors.background.default,
              }}
              buttonTextStyle={{
                color: spiritData?.category
                  ? Colors.fontText.light.primary2
                  : Colors.fontText.light.primary3,
                textAlign: 'left',
                fontSize: 16,
              }}
              renderDropdownIcon={() => (
                <Image
                  source={Images.arrowRight}
                  resizeMode="contain"
                  style={[
                    Layout.iconSize20,
                    Gutters.smallRPadding,
                    {
                      transform: [{ rotate: '90deg' }],
                    },
                  ]}
                />
              )}
            />
          </View>
          {/* 進階資訊 */}
          <View style={[{ marginBottom: 80 }]}>
            {renderIBNoteInfo()}
            {renderAdvanceNoteInfo()}
          </View>
          {/* 授權說明 */}
          <View
            style={[
              Layout.row,
              Layout.fullWidth,
              Layout.alignItemsCenter,
              Gutters.tinyBPadding,
            ]}
          >
            <Image
              source={Images.info}
              style={[Layout.iconSize24, Gutters.tinyRMargin]}
              resizeMode="contain"
            />
            <View style={[Gutters.smallRPadding, Layout.fullWidth]}>
              <Text
                style={[
                  Fonts.size12,
                  Fonts.weight400,
                  { color: Colors.fontText.light.primary3 },
                ]}
              >
                授權說明：
              </Text>
              <Text
                style={[
                  Fonts.size12,
                  Fonts.weight400,
                  { color: Colors.fontText.light.primary3 },
                ]}
              >
                若確認提交，即表示您同意烈酒探索家存取並於審核通過後顯示您上傳的圖片。
              </Text>
            </View>
          </View>
        </ScrollView>
      </FadeInView>
      {renderCTA()}
      <DownLoadSign bottom={100} />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    CTAButton: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: 100,
      backgroundColor: Colors.background.default,
    },
    textInput: {
      backgroundColor: Colors.background.default,
    },
    toggleList: {
      backgroundColor: Colors.background.default,
    },
  })

export default UploadSpiritsDetailContainer
