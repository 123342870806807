import React from 'react'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import { FadeInDirectionView } from '@/Components'
import { Portal } from 'react-native-paper'
import { useTheme } from '@/Hooks'
import { useDispatch } from 'react-redux'
import { Colors } from '@/Theme/Variables'
import { globalActions } from '@/Store/Global'

interface BottomDialogProps {
  title: string
  titleStyle: object
  content: string | JSX.Element | React.FC | undefined
  disabledBackgroundClose?: boolean
  handleClickBackground: () => void
}

const BottomDialog = ({
  content,
  title,
  titleStyle,
  disabledBackgroundClose = false,
  handleClickBackground,
}: Partial<BottomDialogProps>) => {
  const dispatch = useDispatch()
  const { Fonts, Layout } = useTheme()

  const handleCloseDialog = () => {
    dispatch(globalActions.closeBottomDialog())
  }

  return (
    <Portal>
      <View style={styles.container}>
        <View style={styles.filter}>
          <TouchableOpacity
            style={[Layout.fullHeight]}
            onPress={() => {
              if (!disabledBackgroundClose) {
                handleCloseDialog()
                handleClickBackground
              }
            }}
          />
        </View>
        <FadeInDirectionView
          direction="bottom"
          duration={500}
          style={styles.contentContainer}
        >
          {title && (
            <View style={styles.title}>
              <Text
                style={[Fonts.bold, { color: Colors.white, ...titleStyle }]}
              >
                {title}
              </Text>
            </View>
          )}
          <View style={styles.content}>
            {typeof content === 'object' &&
              React.isValidElement(content) &&
              content}
          </View>
        </FadeInDirectionView>
      </View>
    </Portal>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  filter: {
    width: '100%',
    height: '100%',
    backgroundColor: '#000',
    opacity: 0.8,
    position: 'absolute',
    top: 0,
  },
  contentContainer: {
    width: '100%',
    maxWidth: 428,
    marginTop: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#1E1E1E',
    opacity: 1,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  content: {
    minHeight: 100,
  },
  title: {
    paddingVertical: 24,
    textAlign: 'center',
  },
})

export default BottomDialog
