import React from 'react'
import {
  View,
  Image,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from 'react-native'
import { useShare, useTheme } from '@/Hooks'
import { useSelector, useDispatch } from 'react-redux'
import { Colors } from '@/Theme/Variables'
import StarRating from 'react-native-star-rating-widget'
import ReactTimeAgo from 'react-time-ago'
import { useTranslation } from 'react-i18next'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { globalActions } from '@/Store/Global'
import spiritsApi from '@/Services/modules/spirits'
import { Booze } from '@/Services/modules/spirits/spirits.type'
import {
  capitalizedSentence,
  getConfig,
  handleFormatRating,
} from '@/Util/global'
import { RootState } from '@/Store'
import { spiritActions } from '@/Store/Spirit'
import { navigate } from '@/Navigators/utils'
import { StarIcon14 } from '../SpiritCard'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { PagesEnum } from '@/Navigators/Application'

import FlavorItems from '../FlavorItems'
import SpiritImage from '../SpiritImage/SpiritImage'

type Props = {
  item: Booze
  navigation: any
  showNote?: boolean
  showMoreBtn?: boolean
  wishlist?: boolean
  showDivider?: boolean
  isMyProfile?: boolean
  flavorsRowWrap?: boolean
  openFlavors?: boolean
}

const SpiritCardWithContent = ({
  item,
  navigation,
  showNote,
  wishlist,
  showMoreBtn,
  showDivider,
  isMyProfile,
  flavorsRowWrap = false,
  openFlavors = false,
}: Props) => {
  // 上層來的資料
  const {
    id: spiritId,
    createdAt,
    content,
    flavors,
    rating: myRating,
    image,
  } = item

  const { title, id, collected, titleUrlSlug, ratings } = item.spirit
  const { Layout, Images, Gutters, Fonts } = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const handleShare = useShare({
    url: `${getConfig()?.DOMAIN}/ReviewNote?id=${spiritId}`,
    title: '',
    text: '',
    snackbarContent: '成功複製分享連結！',
  })
  const { language } = useSelector((state: RootState) => state.theme)

  // 加入 wishlist
  const [
    addWishlistRequest,
    // { isSuccess: addWishlistSuccess, reset: addWishlistReset },
  ] = spiritsApi.usePostAddWishlistMutation({})

  // 移除 wishlist
  const [
    removeWishlistRequest,
    // { isSuccess: removeWishlistSuccess, reset: removeWishlistReset },
  ] = spiritsApi.usePostRemoveWishlistMutation({})

  // 將這支酒從 my spirit 中移除
  const [
    removeMySpiritRequest,
    // { isSuccess: removeMySpiritSuccess, reset: removeMySpiritReset },
  ] = spiritsApi.useDeleteMySpiritMutation({
    fixedCacheKey: 'shared-delete-note-mutation',
  })

  const handleClickCard = () => {
    navigation.navigate(PagesEnum.ProductDetail, {
      lang: language,
      titleUrlSlug: titleUrlSlug,
      id,
    })
    if (wishlist) {
      AsyncStorage.setItem('previousScreen', AuthPagesEnum.Wishlist)
      return
    }
    AsyncStorage.setItem('previousScreen', AuthPagesEnum.MySpirit)
  }

  // 點擊編輯筆記
  const handleClickEditNote = () => {
    const routeParams = {
      id: id,
      title: titleUrlSlug,
      mySpiritId: spiritId,
    }

    navigation.navigate(AuthPagesEnum.ReviewDetail, routeParams)
    // 用 card 區分是否點擊卡片中的 edit note
    AsyncStorage.setItem('previousScreen', 'MySpiritEditNoteCard')
    dispatch(globalActions.closeBottomDialog())
  }

  // 點擊分享筆記
  const handleShareSpirit = () => {
    handleShare()
    dispatch(globalActions.closeBottomDialog())
  }

  // 點擊評分與評論區塊，轉到評論頁面
  const handleClickContent = () => {
    navigate(PagesEnum.ReviewNote, {
      id: spiritId,
    })
  }

  // 加入或移除 wishlist
  const handleAddWishlist = () => {
    if (collected) {
      removeWishlistRequest({
        id: 'default',
        spiritId: id.toString(),
      })
    } else {
      addWishlistRequest({
        id: 'default',
        spiritId: id.toString(),
      })
    }
  }

  const handleOpenImageLightBox = (imagePath: string) => {
    dispatch(
      globalActions.openProductImageLightBox({
        visible: true,
        resizeMode: 'cover',
        imagePath: imagePath || '',
        imageStyle: { width: 300, height: 300, borderRadius: 10 },
        containerStyle: {
          backgroundColor: 'transparent',
        },
        modalStyle: {
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }),
    )
  }

  // 點擊更多按鈕
  const openMoreDialog = () => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        content: (
          <View
            style={[
              Layout.center,
              Gutters.regularVMargin,
              Gutters.regularHPadding,
            ]}
          >
            {/* 修改品飲筆記 */}
            {isMyProfile && (
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.rowCenter,
                  Gutters.smallBMargin,
                  styles.moreDialogBtn,
                ]}
                onPress={handleClickEditNote}
              >
                <Image
                  source={Images.components_spirit_edit}
                  resizeMode="contain"
                  style={Layout.iconSize24}
                />
                <Text style={[Fonts.weight500, { color: Colors.white }]}>
                  {t('components.spiritCardWithContent.editSpirit')}
                </Text>
              </TouchableOpacity>
            )}
            {/* 刪除品飲筆記 */}
            {isMyProfile && (
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.rowCenter,
                  Gutters.smallBMargin,
                  styles.moreDialogBtn,
                ]}
                onPress={openConfirmDeleteDialog}
              >
                <Image
                  source={Images.components_spirit_delete}
                  resizeMode="contain"
                  style={Layout.iconSize24}
                />
                <Text style={[Fonts.weight500, { color: Colors.white }]}>
                  {t('components.spiritCardWithContent.removeSpirit')}
                </Text>
              </TouchableOpacity>
            )}

            {/* 分享品飲筆記 */}
            <TouchableOpacity
              style={[
                Layout.fullWidth,
                Layout.rowCenter,
                Gutters.smallBMargin,
                styles.moreDialogBtn,
              ]}
              onPress={handleShareSpirit}
            >
              <Image
                source={Images.share}
                resizeMode="contain"
                style={Layout.iconSize24}
              />
              <Text style={[Fonts.weight500, { color: Colors.white }]}>
                {t('components.spiritCardWithContent.shareSpirit')}
              </Text>
            </TouchableOpacity>
          </View>
        ),
      }),
    )
  }

  // 確認將酒從清單移除
  const openConfirmDeleteDialog = () => {
    dispatch(
      globalActions.openBottomDialog({
        visible: true,
        title: t('components.spiritCardWithContent.removeSpiritDialogTitle'),
        titleStyle: {
          fontSize: 20,
        },
        content: (
          <View>
            <View
              style={[
                Gutters.smallBPadding,
                Gutters.regularHPadding,
                styles.removeSpiritDialogContent,
              ]}
            >
              <Text
                style={[
                  Fonts.weight500,
                  Fonts.size14,
                  { color: Colors.secondaryText },
                ]}
              >
                {t(
                  'components.spiritCardWithContent.removeSpiritDialogContent',
                )}
              </Text>
            </View>
            <View
              style={[
                Layout.center,
                Gutters.regularVMargin,
                Gutters.regularHPadding,
              ]}
            >
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.rowCenter,
                  Gutters.smallBMargin,
                  styles.removeSpiritDialogConfirmBtn,
                ]}
                onPress={() => {
                  removeMySpiritRequest({ id: spiritId })
                  // 取得刪除的 my spirit id ，將 my spirit list 重新渲染
                  dispatch(
                    spiritActions.setDeleteMySpiritId(spiritId.toString()),
                  )
                  dispatch(globalActions.closeBottomDialog())
                }}
              >
                <Text
                  style={[
                    Fonts.weight500,
                    Fonts.size16,
                    { color: Colors.dark },
                  ]}
                >
                  {t(
                    'components.spiritCardWithContent.removeSpiritDialogConfirm',
                  )}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  Layout.fullWidth,
                  Layout.rowCenter,
                  Gutters.smallBMargin,
                  Gutters.smallVPadding,
                ]}
                onPress={() => {
                  dispatch(globalActions.closeBottomDialog())
                }}
              >
                <Text style={[Fonts.weight500, { color: Colors.white }]}>
                  {t(
                    'components.spiritCardWithContent.removeSpiritDialogCancel',
                  )}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        ),
      }),
    )
  }

  return (
    <View
      style={[
        Layout.fullSize,
        Gutters.smallBMargin,
        Gutters.smallTPadding,
        { backgroundColor: Colors.background.surface },
      ]}
    >
      <TouchableOpacity
        style={[Layout.row, Gutters.smallBMargin]}
        onPress={handleClickCard}
      >
        {/* 使用者拍攝照片 */}
        <TouchableOpacity
          onPress={() => handleOpenImageLightBox(item?.spirit?.imageUrl || '')}
        >
          <SpiritImage
            imageUrl={item?.spirit?.imageUrl || ''}
            style={[styles.cardImage, Gutters.smallHMargin]}
            linearGradientContainerStyle={{
              width: 60,
              height: 60,
              marginRight: 16,
              marginLeft: 16,
              borderRadius: 8,
            }}
          />
        </TouchableOpacity>
        {/* 酒的基本資料 */}
        <View style={[styles.width55]}>
          <View style={[Gutters.smallBMargin, Layout.fullWidth]}>
            <Text
              style={[
                Fonts.size12,
                Fonts.weight400,
                Gutters.tinyBMargin,
                { color: Colors.gray },
              ]}
            >
              {capitalizedSentence(item?.spirit?.brand) || ''}
            </Text>
            <Text
              style={[
                Fonts.size14,
                Fonts.weight500,
                Gutters.tinyBMargin,
                { color: Colors.white },
              ]}
              ellipsizeMode="tail"
              numberOfLines={2}
            >
              {capitalizedSentence(title)}
            </Text>
            <View style={[Layout.row, Layout.alignItemsCenter]}>
              <Text
                style={[
                  Fonts.weight700,
                  Fonts.size14,
                  Gutters.miniRMargin,
                  { color: Colors.white },
                ]}
              >
                {ratings?.average
                  ? handleFormatRating(ratings?.average as number)
                  : t('components.spiritCardWithContent.noRating')}
              </Text>
              {ratings?.average && (
                <Image
                  source={Images.components_spirit_rating_star}
                  resizeMode="contain"
                  style={[Layout.iconSize14, Gutters.miniRMargin]}
                />
              )}
              <Text
                style={[
                  Fonts.weight400,
                  Fonts.size14,
                  { color: Colors.fontText.light.primary3 },
                ]}
              >
                {`( ${ratings?.count}個評分 )`}
              </Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>
      {showDivider && (
        <View style={[Gutters.smallHPadding, Gutters.smallBMargin]}>
          <View
            style={[
              { height: 1, backgroundColor: Colors.background.onSurface },
            ]}
          />
        </View>
      )}
      {/* 酒的評論 */}
      {showNote && (
        <TouchableOpacity
          style={[Gutters.smallHPadding, Gutters.tinyVPadding]}
          onPress={handleClickContent}
        >
          <View
            style={[Layout.row, Layout.alignItemsCenter, Gutters.tinyBMargin]}
          >
            <StarRating
              rating={myRating || 0}
              starSize={18}
              color="#EECD96"
              StarIconComponent={StarIcon14}
              onChange={e => {
                console.log(e)
              }}
              starStyle={styles.starStyle}
              style={[Gutters.tinyRMargin]}
              animationConfig={{
                scale: 1,
              }}
            />
            <Text
              style={[Fonts.size12, Fonts.weight400, { color: Colors.white }]}
            >
              {createdAt && (
                <ReactTimeAgo
                  date={Date.parse(createdAt)}
                  locale={language || 'en'}
                />
              )}
            </Text>
          </View>
          <FlavorItems
            flavors={flavors}
            rowWrap={flavorsRowWrap}
            openFlavors={openFlavors}
          />
          {content && (
            <Text
              numberOfLines={3}
              style={[Fonts.weight400, Fonts.size14, { color: Colors.white }]}
            >
              {content}
            </Text>
          )}
          {!content && !flavors?.length && (
            <Text
              style={[
                Fonts.weight500,
                Fonts.size14,
                {
                  color: Colors.darkText,
                },
              ]}
            >
              尚未標記風味與撰寫文字筆記
            </Text>
          )}
          {image?.url && (
            <View style={[Gutters.smallVMargin, { width: 60 }]}>
              <TouchableOpacity
                onPress={() => handleOpenImageLightBox(image?.url)}
              >
                {Platform.OS === 'web' ? (
                  <img
                    src={image?.url || ''}
                    alt={title || ''}
                    title={title || ''}
                    style={{
                      width: 60,
                      height: 60,
                      borderRadius: 8,
                      objectFit: 'cover',
                    }}
                  />
                ) : (
                  <Image
                    source={{ uri: image?.url || '' }}
                    resizeMode="cover"
                    style={[Layout.iconSize60, { borderRadius: 8 }]}
                  />
                )}
              </TouchableOpacity>
            </View>
          )}
        </TouchableOpacity>
      )}
      {/* 更多按鈕 */}
      {showMoreBtn && (
        <View style={[Layout.row, Layout.alignItemsCenter, styles.moreBtn]}>
          <TouchableOpacity
            style={[Gutters.smallVPadding, Layout.rowCenter]}
            onPress={handleAddWishlist}
          >
            <Image
              source={
                Images[
                  collected
                    ? 'components_spirit_collected'
                    : 'components_spirit_collect'
                ]
              }
              resizeMode="contain"
              style={[Gutters.tinyRMargin, Layout.iconSize20]}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={openMoreDialog}>
            <Image
              source={Images.more}
              resizeMode="contain"
              style={Layout.iconSize24}
            />
          </TouchableOpacity>
        </View>
      )}
      <View style={styles.divider} />
    </View>
  )
}

const styles = StyleSheet.create({
  cardImage: {
    width: 60,
    height: 60,
    marginRight: 16,
    borderRadius: 8,
    objectFit: 'cover',
  },
  divider: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 8,
    backgroundColor: Colors.background.default,
  },
  flavorsItem: {
    backgroundColor: Colors.darkLight,
    paddingVertical: 4,
    paddingHorizontal: 8,
    textAlign: 'center',
    borderRadius: 32,
    marginRight: 10,
    marginBottom: 8,
    width: 'fit-content',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  moreDialogBtn: {
    borderWidth: 1,
    borderColor: '#505050',
    paddingVertical: 9,
    borderRadius: 8,
  },
  removeSpiritDialogContent: {
    borderBottomColor: Colors.gray,
    borderBottomWidth: 1,
  },
  removeSpiritDialogConfirmBtn: {
    backgroundColor: '#ED5454',
    paddingVertical: 16,
    borderRadius: 8,
  },
  starStyle: {
    marginLeft: 0,
    marginRight: 0,
  },
  moreBtn: {
    position: 'absolute',
    top: 0,
    right: 10,
  },
  width55: {
    width: '55%',
  },
  flavorDot: {
    width: 8,
    height: 8,
    borderRadius: 4,
  },
})

SpiritCardWithContent.defaultProps = {
  showNote: true,
  wishlist: false,
  showMoreBtn: false,
  showDivider: false,
  isMyProfile: true,
}

export default SpiritCardWithContent
