import React, { useCallback, useEffect } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  Dimensions,
  ActivityIndicator,
} from 'react-native'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { FadeInView, Header } from '@/Components'
// import { useTranslation } from 'react-i18next'
import userApi from '@/Services/modules/users'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from '@/Navigators/utils'
import { FlatList } from 'react-native-gesture-handler'
import { InviteUser } from '@/Services/modules/users/user.type'
import { RootState } from '@/Store'
import dayjs from 'dayjs'
import { Avatar } from 'react-native-paper'
import { useIsFocused } from '@react-navigation/native'
import { userActions } from '@/Store/User'
import { DownLoadSign } from '@/Components'
import { AuthPagesEnum } from '@/Navigators/withAuthPages'
import { PagesEnum } from '@/Navigators/Application'

const InviteFriendListContainer = ({ _route, navigation }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()
  // const { t } = useTranslation()
  const dispatch = useDispatch()
  const isFocused = useIsFocused()
  const styles = getStyle()
  const { inviteFriendList } = useSelector((state: RootState) => state.user)

  const { data: getMeData } = userApi.useGetMeQuery()

  const [
    getInviteFriendListRequest,
    {
      data: getInviteFriendListData,
      isSuccess: getInviteFriendListIsSuccess,
      isLoading: getInviteFriendListIsLoading,
      isFetching: getInviteFriendListIsFetching,
    },
  ] = userApi.useLazyGetInviteFriendListQuery()

  const [postFollowUserRequest] = userApi.usePostFollowUserMutation()

  // 判斷是否有成功邀請還有資料
  const hasFriend =
    getInviteFriendListData?.data?.length && getInviteFriendListIsSuccess

  const initFetch = useCallback(() => {
    dispatch(userActions.clearInviteFriendList())
    getInviteFriendListRequest({ perPage: 10 })
  }, [dispatch, getInviteFriendListRequest])

  useEffect(() => {
    if (isFocused) {
      initFetch()
    }
  }, [isFocused, initFetch])

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigate(PagesEnum.Main, {
      screen: 'Profile',
    })
  }, [navigation])

  const handleClickFriendItem = (id: string) => {
    navigation.navigate(PagesEnum.ProfileMainContainer, { id })
  }

  const handleFetchMoreData = () => {
    if (getInviteFriendListIsFetching) {
      return
    }
    if (inviteFriendList?.meta?.nextCursor) {
      getInviteFriendListRequest({
        cursor: inviteFriendList?.meta?.nextCursor,
      })
    }
  }

  if (getInviteFriendListIsLoading) {
    return (
      <View
        style={[
          Layout.fill,
          Layout.colCenter,
          { backgroundColor: Colors.background.default },
        ]}
      >
        <ActivityIndicator size="large" color={Colors.primary} />
      </View>
    )
  }

  // 追隨或取消追隨按鈕
  const handleFollowBtn = (id: string) => {
    postFollowUserRequest({ id })
    dispatch(userActions.setInviteFriendListFollow({ id }))
  }

  const renderListLoading = () => {
    return (
      <View>
        {getInviteFriendListIsFetching && (
          <ActivityIndicator size="large" color={Colors.primary} />
        )}
      </View>
    )
  }

  const renderFriendItem = ({ item }: { item: InviteUser }) => {
    const followText = item?.followed ? '追蹤中' : '追蹤'
    return (
      <TouchableOpacity
        style={[
          Layout.row,
          Layout.alignItemsCenter,
          Gutters.regularTPadding,
          Gutters.regularHPadding,
          Gutters.smallBPadding,
          styles.friendItemContainer,
        ]}
        onPress={() => handleClickFriendItem(item?.id)}
      >
        <Avatar.Image
          source={{ uri: item?.avatar }}
          size={48}
          style={[Layout.iconSize48, Gutters.smallRMargin]}
        />
        <View>
          <Text
            style={[
              Fonts.size14,
              Fonts.weight700,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            {item?.name}
          </Text>
          <Text
            style={[
              Fonts.size14,
              Fonts.weight400,
              { color: Colors.fontText.light.primary2, marginBottom: 4 },
            ]}
          >
            {item?.accounts[0]?.account}
          </Text>
          <Text
            style={[
              Fonts.size12,
              Fonts.weight400,
              { color: Colors.fontText.light.primary3 },
            ]}
          >
            {`註冊於 ${dayjs(item?.createdAt).format('YYYY/MM/DD')}`}
          </Text>
        </View>
        <TouchableOpacity
          style={[
            Layout.colCenter,
            {
              width: 90,
              height: 32,
              borderWidth: 1,
              borderColor: Colors.primary,
              borderRadius: 8,
              backgroundColor: item?.followed ? 'transparent' : Colors.primary,
              marginLeft: 'auto',
            },
          ]}
          onPress={() => handleFollowBtn(item?.id)}
        >
          <Text
            style={[
              Fonts.weight500,
              Fonts.size14,
              {
                color: item?.followed
                  ? Colors.fontText.light.primary2
                  : Colors.fontText.dark.primary2,
              },
            ]}
          >
            {followText}
          </Text>
        </TouchableOpacity>
      </TouchableOpacity>
    )
  }

  // 好友列表
  const renderInviteFriendList = () => {
    // 如果邀請碼總數為無限的話，會是 null 則不顯示分子分母數量
    const inviteDescription =
      getMeData?.inviteAccount?.maxUsages === null
        ? `成功邀請的好友（${getMeData?.inviteAccount?.uses}）`
        : `成功邀請的好友（${getMeData?.inviteAccount?.uses}/${getMeData?.inviteAccount?.maxUsages}）`

    return (
      <View
        style={[
          Layout.fill,
          Gutters.regularVPadding,
          { backgroundColor: Colors.background.surface },
        ]}
      >
        <FlatList
          data={inviteFriendList.data}
          keyExtractor={item => item.id.toString()}
          contentContainerStyle={[Layout.fill]}
          renderItem={renderFriendItem}
          onEndReached={({ distanceFromEnd }) => {
            if (distanceFromEnd >= 1) {
              handleFetchMoreData()
            }
          }}
          onEndReachedThreshold={1}
          showsVerticalScrollIndicator={false}
          ListHeaderComponent={() => (
            <Text
              style={[
                Fonts.size16,
                Fonts.weight700,
                Gutters.smallBMargin,
                Gutters.regularHPadding,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              {inviteDescription}
            </Text>
          )}
          ListFooterComponent={renderListLoading}
        />
      </View>
    )
  }

  // 尚未有邀請好友頁面
  const renderEmptyList = () => {
    return (
      <View style={[Layout.fill, Gutters.regularHPadding, { paddingTop: 150 }]}>
        <View style={[Gutters.regularBMargin, Layout.colCenter]}>
          <Image
            source={Images.invite_friend_list_empty}
            resizeMode="contain"
            style={[Gutters.tinyBMargin, { width: 150, height: 150 }]}
          />
          <Text
            style={[
              Fonts.size16,
              Fonts.weight700,
              Gutters.tinyBMargin,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            尚未有邀請的好友加入
          </Text>
          <Text
            style={[
              Fonts.size14,
              Fonts.weight400,
              { color: Colors.fontText.light.primary2 },
            ]}
          >
            當邀請的好友使用邀請碼註冊成功，將顯示於此
          </Text>
        </View>
        <TouchableOpacity
          style={[styles.ctaButton, Layout.rowCenter]}
          onPress={() => navigation.navigate(AuthPagesEnum.InviteFriend)}
        >
          <Text
            style={[
              Fonts.size16,
              Fonts.weight500,
              { color: Colors.fontText.dark.primary2 },
            ]}
          >
            返回邀請好友
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.default,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <Header
        title={'成功邀請的好友'}
        leftIcon={
          <Image
            style={[styles.arrowLeftIcon]}
            source={Images.arrowLeft}
            resizeMode="contain"
          />
        }
        leftIconPress={handleGoBack}
        rightEmptyIconWidth="50"
      />
      <FadeInView duration={500} style={Layout.fill}>
        {hasFriend ? renderInviteFriendList() : renderEmptyList()}
      </FadeInView>
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    ctaButton: {
      width: '100%',
      height: 48,
      borderRadius: 8,
      backgroundColor: Colors.primary,
    },
    friendItemContainer: {
      borderBottomWidth: 1,
      borderBottomColor: Colors.background.onSurface,
      backgroundColor: Colors.background.surface,
    },
  })

export default InviteFriendListContainer
