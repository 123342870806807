import React, { useEffect, useState, useCallback } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  ActivityIndicator,
  Dimensions,
} from 'react-native'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { FadeInView, ProgressBar } from '@/Components'
import achievementsApi from '@/Services/modules/achievements'
import { ScrollView } from 'react-native-gesture-handler'
import { navigate } from '@/Navigators/utils'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'

const AchievementSingleModal = ({ route, navigation }: any) => {
  const { Layout, Images, Fonts, Gutters } = useTheme()

  const {
    data: getMyAchievementsData,
    isSuccess: getMyAchievementsIsSuccess,
    isLoading: getMyAchievementsIsLoading,
  } = achievementsApi.useGetMyAchievementsQuery()

  const [groupData, setGroupData] = useState<any>()
  const [activeAchievements, setActiveAchievements] = useState<any>()

  // 找到目前的進度徽章 1.先用 params 的 group 找到 group 2.再找到 group 裡面的 inProgress 徽章 3.如果沒有 inProgress 都是 false 代表全部都完成了，即可找最後一筆 group?.data?.at(-1)
  useEffect(() => {
    if (getMyAchievementsIsSuccess) {
      const group = getMyAchievementsData?.data.find(
        item => item?.group === decodeURIComponent(route?.params?.group),
      )
      const inProgressData =
        group?.data?.find(item => item?.inProgress) || group?.data?.at(-1)
      setGroupData(group)
      setActiveAchievements(inProgressData)
    }
  }, [
    getMyAchievementsData?.data,
    getMyAchievementsIsSuccess,
    route?.params?.group,
  ])

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }

    navigate(PagesEnum.Main, {
      screen: 'Profile',
    })
  }, [navigation])

  if (getMyAchievementsIsLoading) {
    return (
      <View style={[Layout.fill]}>
        <View
          style={[
            Layout.fill,
            Layout.colCenter,
            { backgroundColor: Colors.background.default },
          ]}
        >
          <ActivityIndicator size="large" color={Colors.primary} />
        </View>
      </View>
    )
  }

  return (
    <View
      style={[
        Layout.fill,
        {
          backgroundColor: Colors.background.surface,
          height: Dimensions.get('window').height,
        },
      ]}
    >
      <View
        style={[
          Layout.row,
          Layout.alignItemsCenter,
          Layout.justifyContentBetween,
          Gutters.regularVPadding,
          Gutters.regularHPadding,
          {
            borderBottomColor: Colors.background.onSurface,
            borderBottomWidth: 1,
          },
        ]}
      >
        <TouchableOpacity onPress={handleGoBack}>
          <Image
            source={navigation.canGoBack() ? Images.arrowLeft : Images.close}
            style={[Layout.iconSize24]}
            resizeMode="contain"
          />
        </TouchableOpacity>
        <Text
          style={[
            Fonts.size16,
            Fonts.weight500,
            { color: Colors.fontText.light.primary2 },
          ]}
        >
          {decodeURIComponent(route?.params?.group) || ''}
        </Text>
        <View style={[Layout.iconSize24]} />
      </View>
      <FadeInView duration={500} style={Layout.fill}>
        <ScrollView style={[Layout.fill, Gutters.regularHPadding]}>
          {/* 目前徽章進度 */}
          <View style={[Layout.colCenter, Gutters.regularBMargin]}>
            <Image
              source={{ uri: activeAchievements?.imageUrl }}
              resizeMode="contain"
              style={{
                width: 100,
                height: 230,
              }}
            />
            <Text
              style={[
                Gutters.smallBMargin,
                Fonts.size16,
                Fonts.weight700,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              {activeAchievements?.name || ''}
            </Text>
            <View style={[Layout.fullWidth]}>
              {activeAchievements?.goals.map((item: any) => {
                return (
                  <View style={[Gutters.smallBMargin]}>
                    <Text
                      style={[
                        Fonts.size14,
                        Fonts.weight400,
                        { color: Colors.fontText.light.primary3 },
                      ]}
                    >
                      {item?.description}
                    </Text>
                    <ProgressBar
                      progress={
                        (item?.steps.current / item?.steps.expect) * 100
                      }
                      text={`${item?.steps.current}/${item?.steps.expect}`}
                      completeTextColor={'#fff'}
                    />
                  </View>
                )
              })}
            </View>
          </View>
          {/* 徽章等級 */}
          <View>
            <Text
              style={[
                Fonts.size16,
                Fonts.weight700,
                Gutters.regularBMargin,
                { color: Colors.fontText.light.primary2 },
              ]}
            >
              徽章等級
            </Text>
            {groupData?.data?.map((item: any) => {
              const finishProgress = item?.progress === 100
              return (
                <View
                  style={[
                    Layout.row,
                    Layout.alignItemsCenter,
                    Gutters.largeBMargin,
                  ]}
                >
                  <Image
                    source={{ uri: item?.imageUrl }}
                    resizeMode="contain"
                    style={[
                      Gutters.smallRMargin,
                      {
                        width: 70,
                        height: 120,
                        opacity: finishProgress ? 1 : 0.2,
                      },
                    ]}
                  />
                  <View>
                    {/* 如果可以選擇稱號 */}
                    {item?.asUserTitle && (
                      <View
                        style={[
                          {
                            width: 'fit-content',
                            borderColor: Colors.primary,
                            borderWidth: 1,
                            borderRadius: 4,
                            marginBottom: 2,
                            paddingVertical: 2,
                            paddingHorizontal: 10,
                          },
                        ]}
                      >
                        <Text
                          style={[
                            Fonts.size12,
                            Fonts.weight400,
                            {
                              color: Colors.fontText.light.primary2,
                            },
                          ]}
                        >
                          可顯示稱號
                        </Text>
                      </View>
                    )}
                    <Text
                      style={[
                        Fonts.size16,
                        Fonts.weight700,
                        {
                          color: Colors.fontText.light.primary2,
                          marginBottom: 2,
                        },
                      ]}
                    >
                      {item?.name}
                    </Text>
                    {item?.goals?.map((goal: any) => {
                      return (
                        <Text
                          style={[
                            Fonts.size14,
                            Fonts.weight400,
                            {
                              color: Colors.fontText.light.primary3,
                              marginBottom: 2,
                            },
                          ]}
                        >{`・${goal?.description}`}</Text>
                      )
                    })}
                  </View>
                </View>
              )
            })}
          </View>
          {/* info 資訊 */}
          <View
            style={[Layout.row, Layout.alignItemsCenter, Gutters.tinyBPadding]}
          >
            <Image
              source={{ uri: Images.info }}
              resizeMode="contain"
              style={[Layout.iconSize24, Gutters.tinyRMargin]}
            />
            <Text
              style={[
                Fonts.size12,
                Fonts.weight400,
                { color: Colors.fontText.light.primary3 },
              ]}
            >
              若徽章標記為「可顯示稱號」，在取得此徽章後，可前往個人頁面設定顯示稱號。
            </Text>
          </View>
        </ScrollView>
      </FadeInView>
      <DownLoadSign />
    </View>
  )
}

export default AchievementSingleModal
