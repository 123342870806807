import React from 'react'
import { View, ActivityIndicator, StyleSheet } from 'react-native'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'

interface LoadingComponentProps {
  backgroundColor?: string
}

const LoadingComponent = ({
  backgroundColor = 'rgba(0,0,0,0.5)',
}: LoadingComponentProps) => {
  const { Layout } = useTheme()

  const styles = getStyle(backgroundColor)

  return (
    <View style={[Layout.fullSize, Layout.colCenter, styles.container]}>
      <ActivityIndicator size="large" color={Colors.primary} />
    </View>
  )
}

const getStyle = (backgroundColor: string) =>
  StyleSheet.create({
    container: {
      backgroundColor: backgroundColor,
      position: 'absolute',
      top: 0,
      bottom: 0,
      zIndex: 999,
    },
  })

LoadingComponent.defaultProps = {
  backgroundColor: 'rgba(0,0,0,0.5)',
}

export default LoadingComponent
