import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getConfig } from '@/Util/global'
import { ThreadDataType } from '@/Containers/AIChatRoom/AIChatRoomContainer'

interface LogSearchText {
  page: number
  selected_row: number
  request_id: string
  selected_spirit_id: string
}

// has_result 0: 無結果 1: 有結果
export enum LogSearchTextResultEnum {
  NO_RESULT = 0,
  HAS_RESULT = 1,
}
interface LogSearchTextResult {
  request_id: string
  query: string
  has_result: LogSearchTextResultEnum
}

// status 0: 辨識有結果，且使用者接受, 1: 辨識有結果，但是使用者想重新掃描, 2: 辨識無結果, 3: 辨識 Label 分析非酒 4: 使用者主動回報
interface LogImageScan {
  request_id: string
  status: number
}

interface LogAIChatRoomRecommend extends ThreadDataType {
  selected_reference_id: string
}

export const prefixProxyLogEndpoint = (url: string) => {
  return getConfig().ENV === 'local' ? `/proxy-log-api${url}` : url
}

const convertBaseUrl = () => {
  return getConfig()?.API_URL_LOG
}

export const logApi = createApi({
  reducerPath: 'logApi',
  baseQuery: fetchBaseQuery({
    baseUrl: convertBaseUrl(),
    prepareHeaders: async (headers, { getState }: any) => {
      // TODO: 因為 SPA 先不用 token，透過 cookie 來驗證，所以先註解掉，等到改回 mobile 再開啟
      // const authToken = await AsyncStorage.getItem('auth-token')
      // header.set('Content-Type', 'application/json;charset=UTF-8')
      // if (authToken) {
      //   header.set('Authorization', `Bearer ${authToken}`)
      // }
      const { language: locale } = getState().theme
      if (locale) {
        headers.set('Accept-Language', locale)
      }
      return headers
    },
  }),
  endpoints: builder => ({
    // 文字搜尋結果（點擊卡片行為）
    getSearchLog: builder.query<void, LogSearchText>({
      query: params => {
        return {
          url: prefixProxyLogEndpoint(
            '/events/spirit_text_search_user_behavior',
          ),
          method: 'GET',
          params,
        }
      },
    }),
    // 文字搜尋結果（文字搜尋完的紀錄）
    getSearchResultLog: builder.query<void, LogSearchTextResult>({
      query: params => {
        return {
          url: prefixProxyLogEndpoint('/events/spirit_text_search'),
          method: 'GET',
          params,
        }
      },
    }),
    // 圖片辨識結果
    getImageScanLog: builder.query<void, LogImageScan>({
      query: params => {
        return {
          url: prefixProxyLogEndpoint('/events/spirit_image_detection'),
          method: 'GET',
          params,
        }
      },
    }),
    // ai chat room 推薦結果卡片點擊紀錄
    getAIChatRoomRecommendLog: builder.query<void, LogAIChatRoomRecommend>({
      query: params => {
        return {
          url: prefixProxyLogEndpoint(
            '/events/assistant_run_reference_clicked',
          ),
          method: 'GET',
          params,
        }
      },
    }),
  }),
})
