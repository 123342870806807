import React, { useCallback, useEffect, useState } from 'react'
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  StyleSheet,
  FlatList,
  ScrollView,
  Dimensions,
} from 'react-native'
import { useTheme } from '@/Hooks'
import { Colors } from '@/Theme/Variables'
import { FadeInView, Header } from '@/Components'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/Store'
import { navigateAndSimpleReset } from '@/Navigators/utils'
import noteApi from '@/Services/modules/note'
import { DropdownsType } from '@/Services/modules/note/note.type'
import { spiritActions } from '@/Store/Spirit'
import { DownLoadSign } from '@/Components'
import { PagesEnum } from '@/Navigators/Application'

const PairingContainer = ({ navigation }: any) => {
  const { Layout, Gutters, Images, Fonts } = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { language } = useSelector((state: RootState) => state.theme)
  const { spirit: spiritData } = useSelector((state: RootState) => state.spirit)

  // 取得下拉選單（配餐）
  const { data: pairingData, isSuccess: pairingDataIsSuccess } =
    noteApi.useGetNoteDropdownsOptionsQuery({
      type: DropdownsType.PAIRING,
      locale: language,
    })

  const [selectedPairingData, setSelectedPairingDataList] = useState<string[]>(
    [],
  )
  const [pairingDataList, setPairingDataList] = useState<any>([])

  const styles = getStyle()

  // convert 配餐列表成陣列
  const convertPairingDataToArray = useCallback(() => {
    if (!pairingData) {
      return
    }
    if (Object?.keys(pairingData?.data)?.length) {
      const result = Object?.keys(pairingData?.data).map(key => {
        return {
          key,
          value: pairingData?.data[key],
        }
      })
      return result
    }
  }, [pairingData])

  useEffect(() => {
    if (pairingDataIsSuccess) {
      const data = convertPairingDataToArray()
      setPairingDataList(data)
    }
  }, [convertPairingDataToArray, pairingData, pairingDataIsSuccess])

  useEffect(() => {
    if (spiritData?.pairing) {
      setSelectedPairingDataList(spiritData?.pairing)
    }
  }, [spiritData?.pairing])

  const handleGoBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
      return
    }
    navigateAndSimpleReset(PagesEnum.Main)
  }, [navigation])

  const handleSelectPairingItem = (pairing: string) => {
    setSelectedPairingDataList([...selectedPairingData, pairing])
    dispatch(
      spiritActions.setSpiritData({
        pairing: [...selectedPairingData, pairing],
      }),
    )
  }

  const handleRemoveSelectPairingItem = (pairing: string) => {
    const newSelectedPairingData = selectedPairingData.filter(
      item => item !== pairing,
    )
    setSelectedPairingDataList(newSelectedPairingData)
    dispatch(
      spiritActions.setSpiritData({
        pairing: newSelectedPairingData,
      }),
    )
  }

  // 渲染配餐列表
  const renderPairingItem = ({ item }: any) => {
    const isSelected = selectedPairingData?.includes(item.key)
    return (
      <TouchableOpacity
        style={[styles.pairingItem, { display: isSelected ? 'none' : 'flex' }]}
        onPress={() => handleSelectPairingItem(item.key)}
      >
        <Text
          style={[
            Fonts.weight500,
            Fonts.size14,
            { color: Colors.fontText.light.primary2 },
          ]}
        >
          {t(`pairing:${item.key}`)}
        </Text>
      </TouchableOpacity>
    )
  }

  return (
    <View style={[Layout.fill, { backgroundColor: Colors.background.default }]}>
      <ScrollView
        style={(Layout.fill, { height: Dimensions.get('window').height })}
      >
        <FadeInView duration={500} style={Layout.fill}>
          <Header
            title="餐飲搭配"
            leftIcon={
              <Image
                style={[styles.arrowLeftIcon]}
                source={Images.arrowLeft}
                resizeMode="contain"
              />
            }
            leftIconPress={() => {
              handleGoBack()
            }}
            rightEmptyIconWidth="50"
          />
          <View
            style={[
              Gutters.tinyBMargin,
              { backgroundColor: Colors.background.surface },
            ]}
          >
            {/* 已選擇 */}
            <View style={[Gutters.regularHPadding, Gutters.smallVPadding]}>
              <Text
                style={[
                  Fonts.weight700,
                  Fonts.size16,
                  { color: Colors.secondaryText },
                ]}
              >
                已選擇
              </Text>
            </View>
            {/* 被選取的配餐列表 */}
            <View
              style={[
                Layout.row,
                Gutters.smallBPadding,
                Gutters.regularHPadding,
                styles.pairingSelectedItemContainer,
              ]}
            >
              {selectedPairingData.length ? (
                selectedPairingData.map(item => {
                  return (
                    <View
                      style={[
                        Layout.row,
                        Layout.alignItemsCenter,
                        styles.pairingSelectedItem,
                      ]}
                    >
                      <Text
                        style={[
                          Fonts.size14,
                          Fonts.weight700,
                          Gutters.miniRMargin,
                          {
                            color: Colors.fontText.light.primary2,
                          },
                        ]}
                      >
                        {t(`pairing:${item}`)}
                      </Text>
                      <TouchableOpacity
                        onPress={() => handleRemoveSelectPairingItem(item)}
                      >
                        <Image
                          source={Images.close}
                          resizeMode="contain"
                          style={Layout.iconSize14}
                        />
                      </TouchableOpacity>
                    </View>
                  )
                })
              ) : (
                <Text
                  style={[
                    Fonts.weight400,
                    Fonts.size14,
                    { color: Colors.darkText },
                  ]}
                >
                  尚無紀錄
                </Text>
              )}
            </View>
          </View>
          <View style={{ backgroundColor: Colors.background.surface }}>
            <View style={[Gutters.regularHPadding, styles.marginB60]}>
              <FlatList
                data={pairingDataList || []}
                renderItem={renderPairingItem}
              />
            </View>
          </View>
        </FadeInView>
      </ScrollView>
      <DownLoadSign />
    </View>
  )
}

const getStyle = () =>
  StyleSheet.create({
    marginB60: { marginBottom: 60 },
    arrowLeftIcon: {
      width: 24,
      height: 24,
      paddingLeft: 50,
    },
    pairingSelectedItemContainer: {
      flexWrap: 'wrap',
    },
    pairingSelectedItem: {
      width: 'fit-content',
      marginLeft: 16,
      marginBottom: 16,
      paddingVertical: 8,
      paddingHorizontal: 16,
      borderRadius: 80,
      backgroundColor: Colors.darkLight,
    },
    pairingItem: {
      height: 56,
      justifyContent: 'center',
      borderBottomColor: Colors.background.onSurface,
      borderBottomWidth: 1,
    },
  })

export default PairingContainer
