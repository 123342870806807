import { Config } from '@/Config'
import { FLAVORS_MAP } from '@/Containers/data/FlavorData'

export const getConfig = () => {
  // @ts-ignore
  if (window && window.Config) {
    return window.Config
  }
  return Config
}

/**
 * @param {string} value - 字串
 * @returns {string} - 返回首字大寫字串
 */
export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

/**
 * @param {string} value - 字串
 * @returns {string} - 返回每個單字首字大寫字串
 * @example
 * capitalizedSentence('hello world') // Hello World
 * */
export const capitalizedSentence = (value: string) => {
  return value
    ?.split(' ')
    ?.map(word => capitalizeFirstLetter(word))
    ?.join(' ')
}

export const removeEmptyKeys = (obj: any) => {
  if (!obj || typeof obj !== 'object') {
    return obj
  }

  const result: any = {}
  for (const key in obj) {
    if (Object.getOwnPropertyDescriptor(obj, key)) {
      const value = obj[key]

      if (value && typeof value === 'object') {
        const nestedObj = removeEmptyKeys(value)

        if (Object.keys(nestedObj)?.length > 0) {
          result[key] = nestedObj
        }
      } else if (value) {
        result[key] = value
      }
    }
  }

  return result
}

/**
 *
 * @param ratingNum - 評分數字
 * @returns 如果評分是整數的話，會加上小數點後一位
 */
export const handleFormatRating = (ratingNum: number) => {
  return ratingNum % 1 === 0 ? ratingNum.toFixed(1) : ratingNum
}

/**
 *
 * @param envs - 環境字串陣列
 * @returns 返回是否在該環境
 */
export const isEnv = (envs: string[]) => {
  return envs.includes(getConfig().ENV)
}

/**
 * @param {string} dataUri - dataUri
 * @param {string} filename - 檔案名稱
 * @returns 檔案
 */
export const dataUriToFile = (dataUri: string, filename: string) => {
  const byteString = atob(dataUri.split(',')[1])
  const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0]
  const ab = new ArrayBuffer(byteString?.length)
  const ia = new Uint8Array(ab)

  for (let i = 0; i < byteString?.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  const blob = new Blob([ab], { type: mimeString })
  return new File([blob], filename, { type: mimeString })
}

export const openApplyInviteCodeWeb = () => {
  window.open('https://lihi.cc/CIBPq')
}
