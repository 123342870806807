import React, { useEffect } from 'react'
import { View, StyleSheet, Image } from 'react-native'
import { useTheme } from '@/Hooks'
import { setDefaultTheme } from '@/Store/Theme'
import { navigateAndSimpleReset } from '@/Navigators/utils'
import { Colors } from '@/Theme/Variables'
import { PagesEnum } from '@/Navigators/Application'

const StartupContainer = () => {
  const { Layout, Images } = useTheme()

  const init = async () => {
    await new Promise(resolve =>
      setTimeout(() => {
        resolve(true)
      }, 2000),
    )
    await setDefaultTheme({ theme: 'default', darkMode: false })
    navigateAndSimpleReset(PagesEnum.Main)
  }

  useEffect(() => {
    init()
  })

  return (
    <View
      style={[
        Layout.fill,
        Layout.colCenter,
        { backgroundColor: Colors.primary },
      ]}
    >
      <Image
        style={styles.icon}
        source={Images.logoSlogan}
        resizeMode="contain"
      />
    </View>
  )
}

const styles = StyleSheet.create({
  icon: {
    width: 286,
    height: 204,
    marginBottom: 8,
  },
  title: {
    color: Colors.background.default,
    letterSpacing: 10,
    fontSize: 16,
    fontweight: 400,
  },
})

export default StartupContainer
