import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import noteApi from '@/Services/modules/note'
import { DropdownsType } from '@/Services/modules/note/note.type'
import spiritsApi from '@/Services/modules/spirits'
import { RootState } from '@/Store'

import { convertMetaData } from '../utils/convertMetaData'
import { checkDataAndWaring } from '../utils/checkDataAndWaring'

const pairingEmojis: { [key: string]: string } = {
  beef: '🐂',
  pork: '🐖',
  lamb: '🐑',
  poultry: '🐓',
  venison: '🦌',
  fish: '🐟',
  shellfish: '🦞',
  'cured-meat': '🥓',
  cheese: '🧀',
  'appetizers-and-snacks': '🥗',
  'spicy-food': '🌶️',
  veggie: '🥬',
  vegetarian: '🥦',
  'hot-pot': '🍲',
  'yakiniku-bbq': '🍖',
  pasta: '🍝',
  sushi: '🍣',
  chocolate: '🍫',
  'ice-cream': '🍦',
  pastry: '🍞',
  dessert: '🍰',
  'dried-fruit': '🍇',
  'fresh-fruit': '🍎',
  'african-cuisine': '🍽️',
  'american-cuisine': '🌭',
  'chinese-cuisine': '🥟',
  'french-cuisine': '🥐',
  'german-cuisine': '🥨',
  'halal-cuisine': '🕌',
  'indian-cuisine': '🍛',
  'italian-cuisine': '🍕',
  'japanese-cuisine': '🍙',
  'korean-cuisine': '🥒',
  'mexican-cuisine': '🌮',
  'middle-eastern-cuisine': '🥙',
  'portuguese-cuisine': '🍤',
  'singaporean-and-malaysian-cuisine': '🥥',
  'spanish-cuisine': '🥘',
  'taiwanese-cuisine': '🍗',
  'thai-cuisine': '🍍',
  'vietnamese-cuisine': '🌿',
}

export interface PairingDataItem {
  zhName: string
  enName: string
  count: number
  emoji: string
  i18nKey: string
}

const useGetPairingsData = () => {
  const { language } = useSelector((state: RootState) => state.theme)
  const [initialData, setInitialData] = useState<PairingDataItem[]>([])

  // 取得 meta 條件
  const {
    data: filterMetaData,
    isSuccess: filterMetaDataIsSuccess,
    isLoading: filterMetaDataIsLoading,
  } = spiritsApi.useGetSearchMetaQuery()

  // 取得餐酒搭配資訊
  const {
    data: pairingData,
    isLoading: pairingDataIsLoading,
    isSuccess: pairingDataIsSuccess,
  } = noteApi.useGetNoteDropdownsOptionsQuery({
    type: DropdownsType.PAIRING,
    locale: language,
  })

  // 整理初始化資料
  useEffect(() => {
    if (pairingDataIsSuccess && filterMetaDataIsSuccess) {
      const pairingArr: PairingDataItem[] = []
      const pairingMeta = convertMetaData(filterMetaData, 'pairings')
      for (const pairing of pairingMeta) {
        const zhName = pairingData?.data?.[pairing.key] || ''
        const enName = pairing.key

        const isDataValid = checkDataAndWaring(zhName, pairing.key, 'pairings')

        if (isDataValid) {
          pairingArr.push({
            zhName,
            enName,
            count: pairing.count,
            emoji: pairingEmojis[pairing.key],
            i18nKey: pairing.key,
          })
        }
      }
      setInitialData(pairingArr)
    }
  }, [
    pairingData?.data,
    pairingDataIsSuccess,
    filterMetaData,
    filterMetaDataIsSuccess,
  ])

  return {
    pairingsData: initialData,
    filterMetaDataIsLoading,
    pairingDataIsLoading,
  }
}

export default useGetPairingsData
