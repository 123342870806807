export const RatingDescription = [
  {
    rating: 0,
    content: '選擇你的評分',
  },
  {
    rating: 1,
    content: '喝一點點就好',
  },
  {
    rating: 2,
    content: '可以嚐一口',
  },
  {
    rating: 3,
    content: '不錯的味道',
  },
  {
    rating: 4,
    content: '值得推薦！',
  },
  {
    rating: 5,
    content: '非喝不可！',
  },
]

export const RatingCupIcons = [
  {
    rating: 0,
    icon: 'rating_comment_cup0',
  },
  {
    rating: 1,
    icon: 'rating_comment_cup1',
  },
  {
    rating: 2,
    icon: 'rating_comment_cup2',
  },
  {
    rating: 3,
    icon: 'rating_comment_cup3',
  },
  {
    rating: 4,
    icon: 'rating_comment_cup4',
  },
  {
    rating: 5,
    icon: 'rating_comment_cup5',
  },
]
